import sendGAEvent from '../../../lib/sendGAEvent';

export function connectClick(userRole, setExperienceType, experienceType, setStateWaiting, selectedExperienceData, poolType, setPoolType, setStateFirstTimeGuidance, openNoOneInCircleModal) {
	if (poolType === "myCircle" && !userRole.myCircleMatched) {
		return openNoOneInCircleModal();
	}
	sendGAEvent(selectedExperienceData.name, 'Connect', '', userRole.completedHumanMinutes)
	if(poolType) {
		setPoolType(poolType)
	}
	setExperienceType(experienceType)
	if (experienceType === "oneminute" && !userRole.HMGuidance1Accepted) {
		setStateFirstTimeGuidance()
	} else {
		setStateWaiting()
	}
	
}