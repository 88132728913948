export const SET_STATE_PRESENT = "SET_STATE_PRESENT";
// On the page and active

export const SET_STATE_ABSENT = "SET_STATE_ABSENT";
// Page open but user is in a different tab or has been inactive for > 5min

export const SET_STATE_WAITING = "SET_STATE_WAITING";
// User has requested a connection and is waiting to be paired with someone

export const SET_STATE_FIRST_TIME_GUIDANCE = "SET_STATE_FIRST_TIME_GUIDANCE";
// User has been paired and it's their first time so we show guidelines

export const SET_STATE_ENTERING_ROOM = "SET_STATE_ENTERING_ROOM";
// User has been paired and sees guidelines reminder

export const SET_STATE_GETTING_MEDIA = "SET_STATE_GETTING_MEDIA";
// User has been paired and we are requesting access to their camera

export const SET_STATE_MEDIA_DENIED = "SET_STATE_MEDIA_DENIED";
// User has denied access to their camera

export const SET_STATE_PREPARING = "SET_STATE_PREPARING";
// User sees herself and needs to click "start" when she is ready

export const SET_STATE_READY = "SET_STATE_READY";
// Everything is ready and user is waiting for the video to start

export const SET_STATE_CONNECTED = "SET_STATE_CONNECTED";
// User is in an active connection

export const SET_STATE_DISCONNECTED = "SET_STATE_DISCONNECTED";
// User has finished a connection

export const SET_STATE_DIGESTING = "SET_STATE_DIGESTING";
// User is digesting after a connection

export function setStatePresent() {
  return {
    type: SET_STATE_PRESENT
  };
}

export function setStateAbsent() {
  return {
    type: SET_STATE_ABSENT
  };
}

export function setStateWaiting() {
  return {
    type: SET_STATE_WAITING
  };
}

export function setStateFirstTimeGuidance() {
  return {
    type: SET_STATE_FIRST_TIME_GUIDANCE
  };
}

export function setStateEnteringRoom() {
  return {
    type: SET_STATE_ENTERING_ROOM
  };
}

export function setStatePreparing() {
  return {
    type: SET_STATE_PREPARING
  };
}

export function setStateGettingMedia() {
  return {
    type: SET_STATE_GETTING_MEDIA
  };
}

export function setStateMediaDenied() {
  return {
    type: SET_STATE_MEDIA_DENIED
  };
}

export function setStateReady() {
  return {
    type: SET_STATE_READY
  };
}

export function setStateConnected() {
  return {
    type: SET_STATE_CONNECTED
  };
}

// giving a default reason because some code calls this without a reason
// if a reason is always provided, this can be removed
export function setStateDisconnected({ reason }) {
  return {
    type: SET_STATE_DISCONNECTED,
    payload: reason
  };
}

export function setStateDigesting() {
  return {
    type: SET_STATE_DIGESTING
  };
}
