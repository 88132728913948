import { connect } from 'react-redux';
import WaitingState from './WaitingState';
import { setStateGettingMedia } from '../../../actions/userState';
import { setExperienceType } from '../../../actions/peerRoom';
import { closeLanguageDropDown, setScreenOpenTime } from '../../../actions/selectLanguage';

const mapStateToProps = (state) => {
  let stateForStyles = state.userState;
  if (state.userState === "waiting" && state.peerRoom.experienceType !== "oneminute") {
    stateForStyles = "present";
  }
  return {
    userState: stateForStyles,
    userRole: state.userRole,
    selectedLanguage: state.selectLanguage.selectedLanguage,
    languageDropDown: state.selectLanguage.languageDropDown
  };
};

const mapDispatchtoProps = {
  setStateGettingMedia,
  setExperienceType,
  closeLanguageDropDown,
  setScreenOpenTime
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(WaitingState);