import constants from "../constants";

export const INVITATION_START = "INVITATION_START";
export const INVITATION_SUCCESS = "INVITATION_SUCCESS";
export const INVITATION_FAILURE = "INVITATION_FAILURE";
export const INVITATION_REACHED_LIMIT = "INVITATION_REACHED_LIMIT";
export const CLEAR_INVITATION = "CLEAR_INVITATION";

export function invitationStart() {
  return {
    type: INVITATION_START
  };
}

export function invitationSuccess() {
  return {
    type: INVITATION_SUCCESS
  };
}

export function invitationFailure(error) {
  return {
    type: INVITATION_FAILURE,
    error
  };
}

export function invitationReachedLimit() {
  return {
    type: INVITATION_REACHED_LIMIT
  };
}

export function clearInvitation() {
  return {
    type: CLEAR_INVITATION
  };
}



export function sendInvitation(toEmail) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `toEmail=${toEmail}`
  };
  return dispatch => {
    dispatch(invitationStart());
    fetch(`${constants.baseUrl}/api/v1/invitation`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          response.limit ?
          dispatch(invitationReachedLimit()) :
          dispatch(invitationFailure("Sending invitation failed"));
        } else {
          dispatch(invitationSuccess());
        }        
      })
      .catch(err => {
      	dispatch(invitationFailure(err));
        console.log("Sending invitation error, ", err);
      });
  };
}