import React from "react";
import { connect } from 'react-redux';
import StartButton from './StartButton';
import { setStateReady } from "../../actions/userState";
import sendGAEvent from '../../lib/sendGAEvent';
import {sendAmplitudeEvent} from '../../lib/amplitude';


class StartButtonContainer extends React.Component {
	constructor() {
		super();
		this.showTimeout = null;
		this.timeStartShown = 0;

		this.state = ({
			ready: false
		})

		this.onClick = this.onClick.bind(this);
	}

	componentDidMount() {
		this.showTimeout = setTimeout(() => {
			this.setState({
				ready: true
			});
			this.timeStartShown = Date.now();
		}, 3000);
	}

	componentWillUnmount() {
		if (this.showTimeout) {
			clearTimeout(this.showTimeout);
		}
	}

	onClick() {
		this.props.setStateReady();
		let timePreparing = 0;
		let { experienceType, noRepeatMatchmakerOption, groupSpace, poolType } = this.props.peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
		if (this.timeStartShown) {
			timePreparing = Math.floor((Date.now() - this.timeStartShown)/1000);
		}
		sendGAEvent('Human Minute', 'Start', '', timePreparing);
		sendAmplitudeEvent('click start', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'preparation time': timePreparing})	
	}

  render() {
  	if (!this.state.ready) {
  		return null;
  	}
    return <StartButton onClick={this.onClick} userState={this.props.userState} />;
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
		userState: state.userState,
		peerRoom: state.peerRoom
  };
};

const mapDispatchtoProps = {
	setStateReady
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(StartButtonContainer);