import React from 'react';
import LayoutWrapper from '../elements/LayoutWrapper';
import PrivacyMarkup from './PrivacyMarkup';
import textResource from '../../lib/textLoader';


const PrivacyPage = () => {

	return (
  	<LayoutWrapper>
	  	<h1 className="pb-3">{textResource["privacy_markup.privacy_policy_title"]}</h1>
		  <PrivacyMarkup />
	  </LayoutWrapper>
	)
}

export default PrivacyPage;