import React from 'react';
import textResource from '../../lib/textLoader';
import ParagraphCreator from '../elements/ParagraphCreator';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import LayoutWrapper from '../elements/LayoutWrapper';


const CameraGeneralFailure = () => {


	return (
  	<LayoutWrapper>
		  	<h1 className="pb-3">{textResource["general_camera_failure.title"]}</h1>
		  	<br/>
		  	<ParagraphCreator text={textResource["general_camera_failure.text"]} />
		  	<br/>
		  	<p>{textResource["general_camera_failure.press_continue"]}</p>
			  <Link to="/" style={{ textDecoration: "none", color: "black" }}>
		  		<Button color="primary">
		  			{textResource["general_camera_failure.continue_btn"]}
		    	</Button>
	    	</Link>
		</LayoutWrapper>
	)
}

export default CameraGeneralFailure;