import validate from 'validate.js'

export function getColorFromError(error) {
  return !!error ? 'danger' : 'default'
}

export function isInvalid(error) {
  return !!error ? 'true' : 'false';
}

export function isEmpty(object) {
  return Object.keys(object).length === 0
}

const basicCredentialsConstraints = {
  email: {
    presence: true,
    email: true,
  },
  password: {
    presence: true,
    length: {minimum: 8},
  }
}

const registrationCredentialsConstraints = {
  email: {
    presence: true,
    email: true,
  },
  name: {
    presence: true
  },
  password: {
    presence: true,
    length: {minimum: 8},
  }
}

const socialLoginCredentialsConstraints = {
  email: {
    presence: true,
    email: true,
  }
}

const socialLoginAlreadyRegisterdConstraints = {
  password: {
    presence: true,
    length: {minimum: 8},
  }
}

const changePasswordConstraints = {
  oldPassword: {
    presence: true,
  },
  newPassword: {
    presence: true,
    length: {minimum: 8},
  }
}

const socialPasswordConstraints = {
  newPassword: {
    presence: true,
    length: {minimum: 8},
  }
}

const deleteAccountConstraints = {
  password: {
    presence: true,
  },
}

const feedbackConstraints = {
  content: {
    presence: true,
  },
}

const userReportConstraints = {
  reportExplanation: {
    presence: true,
  },
}

const invitationConstraints = {
  email: {
    presence: true,
    email: true,
  }
}

const requestRecoverPasswordConstraints = {
  email: {
    presence: true,
    email: true,
  }
}

const setNewPasswordConstraints = {
  password: {
    presence: true,
    length: {minimum: 8},
  }
}

const donationConstraints = {
  amount: {
    presence: true,
    numericality: {
      greaterThan: 499,
      message: "minimum is 5€"
    }
  }
}

const paymentConstraints = {
  email: {
    presence: true,
    email: true,
  },
  first_name: {
    presence: true,
  },
  last_name: {
    presence: true,
  },
  postal_code: {
    presence: true,
  },
  country: {
    presence: true,
  }
}

const paymentDetailConstraints = {
  email: {
    presence: true,
    email: true,
  },
  first_name: {
    presence: true,
  },
  last_name: {
    presence: true,
  }
}

const constraintsByFormType = {
  login: basicCredentialsConstraints,
  register: registrationCredentialsConstraints,
  socialLogin: socialLoginCredentialsConstraints,
  socialLoginAlreadyRegisterd: socialLoginAlreadyRegisterdConstraints,
  changePassword: changePasswordConstraints,
  setSocialPassword: socialPasswordConstraints,
  deleteAccount: deleteAccountConstraints,
  feedback: feedbackConstraints,
  invitation: invitationConstraints,
  requestRecoverPassword: requestRecoverPasswordConstraints,
  setNewPassword: setNewPasswordConstraints,
  userReport: userReportConstraints,
  donation: donationConstraints,
  payment: paymentConstraints,
  paymentDetail: paymentDetailConstraints
};


export function validateForm(formData, formType) {
  let constraints = constraintsByFormType[formType];
  return validate(formData, constraints)
}



