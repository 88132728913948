import React from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import '../../AccountPages/AccountPagesStyles.css';
import textResource from '../../../lib/textLoader';
import { Button, Form, FormGroup, Input, Col, Row } from 'reactstrap';
import ValidationErrorMessage from '../ValidationErrorMessage';
import ErrorMessage from '../ErrorMessage';
import LayoutWrapper from '../../elements/LayoutWrapper';
import Spinner from '../../elements/BlackSpinner';


class SetNewPassword extends React.Component {

	componentDidMount() {
		let hash = this.props.match.params.hash;
		if (hash) {
			this.props.validateRecoveryHash(hash)
		}
	}

	componentDidUpdate(prevProps){
		if (!prevProps.accountChanges.validateRecoveryHashSuccess && this.props.accountChanges.validateRecoveryHashSuccess) {
			this.props.logout();
		}
	}

	render() {

		if (this.props.accountChanges.passwordResetSuccessful || (this.props.accountChanges.validateRecoveryHashReady && !this.props.accountChanges.validateRecoveryHashSuccess)) {
			return <Redirect to='/' />
		}

		if (!this.props.accountChanges.validateRecoveryHashReady) {
			return <Spinner />
		}

		return (
			<LayoutWrapper>
			<Prompt
			message={textResource["reset_password_enter_new.prompt"]}
			/>
		  	<h3 className="pt-5 pb-3">{textResource["reset_password_enter_new.title"]}</h3>
		  	<br/>
		  	<p>{textResource["reset_password_enter_new.enter_password"]}</p>
		    <Form id="FormSetNewPassword" onSubmit={this.props.onSubmit}>
		      <ErrorMessage errors={this.props.errors} />

		      <FormGroup row>
		        <Col xs={4}>
			        <Input 
			          invalid={!!this.props.errors.password}
			          type="password" 
			          name="password" 
			          id="password" 
			        />
			        <ValidationErrorMessage message={this.props.errors.password} />
			      </Col>		        
		      </FormGroup>
		      <br/>
		      <Row>
		      	<Col xs={{ size: 4 }}>
				      <Button id="BtnSetNewPassword" color="primary" block>
				        {textResource["reset_password_enter_new.btn_send"]}
				      </Button>
		      	</Col>
		      </Row>
		    </Form>
			</LayoutWrapper>
		)
	}
}

export default SetNewPassword;