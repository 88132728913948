import React from 'react';
import textResource from '../../lib/textLoader';
import { Button } from 'reactstrap';



const StartButton = ({userState, onClick}) => {
	if (userState !== "preparing") {return null}

  return (
		<Button id="BtnStart" size="lg" className="mr-3" color="default" onClick={onClick}>
			{textResource["session_controls.start_btn"]}
		</Button>
  )
}

export default StartButton;