import React from "react";
import { connect } from 'react-redux';
import RegisterForm from './RegisterForm';
import { registerUser } from "../../actions/userRole";
import { closeAuthModal } from "../../actions/uiControls";
import { 
  setCollapseTerms, 
  setCollapsePrivacy,
  setCollapseGathering,
  setCollapseClosed,
  setRegisterEmailsAfterLogin,
  clearRegisterEmailsAfterLogin,
  setAuthData
  } from "../../actions/uiControls";
import {validateForm} from '../../lib/validationHelpers';
import serialize from 'form-serialize';
import sendGAEvent from '../../lib/sendGAEvent';

class RegisterFormContainer extends React.Component {
	constructor() {
		super();
    this.state = {
      errors: {},
      success: false
    }
		this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
    this.onClickPrivacy = this.onClickPrivacy.bind(this);
    this.onClickTerms = this.onClickTerms.bind(this);
    this.onClickGathering = this.onClickGathering.bind(this);
    this.onChangeRegisterEmails = this.onChangeRegisterEmails.bind(this);
  }

  componentDidUpdate(prevProps){
    if (!prevProps.userRole.error && this.props.userRole.error) {
      this.setState({
        errors: {
          fromServer: "Account could not be created"
        },
      })      
    }
    if(!prevProps.userRole.fbPasswordExistError && this.props.userRole.fbPasswordExistError) {
      this.setState({
        errors: {
          facebookError: true
        },
      })
    }
  }

  onClickPrivacy(e) {
    e.preventDefault();
    if (this.props.termsPrivacyCollapse === "privacy") {
      this.props.setCollapseClosed();
      sendGAEvent('Authentication', 'Close terms - privacy', '');
    } else {
      this.props.setCollapsePrivacy();
      sendGAEvent('Authentication', 'Expand privacy', '');
    }    
  }

  onClickTerms(e) {
    e.preventDefault();
    if (this.props.termsPrivacyCollapse === "terms") {
      this.props.setCollapseClosed();
      sendGAEvent('Authentication', 'Close terms - privacy', '');
    } else {
      this.props.setCollapseTerms();
      sendGAEvent('Authentication', 'Expand terms', '');
    }    
  }

  onClickGathering(e) {
    e.preventDefault();
    if (this.props.termsPrivacyCollapse === "gathering") {
      this.props.setCollapseClosed();
    } else {
      this.props.setCollapseGathering();
    }    
  }

  onChangeRegisterEmails(e) {

    this.props.registerEmailsAfterLogin ? 
    this.props.clearRegisterEmailsAfterLogin() : 
    this.props.setRegisterEmailsAfterLogin()   
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target
    const data = serialize(form, {hash: true});
    Object.keys(data).forEach((key) => {
      data[key] = data[key].trim();
    })
    data.emailListRegistration = data.emailListRegistration || "";

    const errors = validateForm(data, "register");

    if (errors) {
      this.setState({errors})
      sendGAEvent(this.props.authEventsCategory, 'Create account', gaEventLabel(errors));
    } else {
      this.formSuccess(form, data)
    }
  }

  formSuccess(form, data) {
    // form.reset()
    this.props.registerUser(data.email, data.password, data.name, data.emailListRegistration, this.props.registeringFromInvitation.invitationHash, this.props.userRole.referrerUrl, this.props.userRole.currentUrl);
    // this.setState({
    //   success: true,
    //   errors: {},
    // })
  }

  render() {
    return <RegisterForm 
      onSubmit={this.onSubmit}
      onClickTerms={this.onClickTerms}
      onClickPrivacy={this.onClickPrivacy}
      onClickGathering={this.onClickGathering}
      registerEmailsAfterLogin={this.props.registerEmailsAfterLogin}
      onChangeRegisterEmails={this.onChangeRegisterEmails}
      registeringFromInvitation={this.props.registeringFromInvitation}
      closeAuthModal={this.props.closeAuthModal}
      userRole={this.props.userRole}
      authData={this.props.authData}
      setAuthData={this.props.setAuthData}
      {...this.state}
    />;
  }
}

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    termsPrivacyCollapse: state.uiControls.termsPrivacyCollapse,
    registerEmailsAfterLogin: state.uiControls.registerEmailsAfterLogin,
    registeringFromInvitation: state.registeringFromInvitation,
    authModalForm: state.uiControls.authModalForm,
    authData: state.uiControls.authData,
    authEventsCategory: state.uiControls.authEventsCategory
  };
};

const mapDispatchtoProps = {
		registerUser,
    setCollapseTerms,
    setCollapsePrivacy,
    setCollapseGathering,
    setCollapseClosed,
    setRegisterEmailsAfterLogin,
    clearRegisterEmailsAfterLogin,
    closeAuthModal,
    setAuthData
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(RegisterFormContainer);


function gaEventLabel(errors) {
 if (errors.email && errors.email[0] === "Email can't be blank") {
  return "Fail email blank";
 }

 if (errors.email && errors.email[0] === "Email is not a valid email") {
  return "Fail email invalid";
 }

 if (errors.password && errors.password[0] === "Password can't be blank") {
  return "Fail password blank";
 }

 if (errors.password && errors.password[0] === "Password is too short (minimum is 8 characters)") {
  return "Fail password too short";
 }

 if (errors.name && errors.name[0] === "Name can't be blank") {
  return "Fail name blank";
 }

 if (errors.termsAndPrivacy && errors.termsAndPrivacy[0] === "Terms and privacy need to be accepted to create an account") {
  return "Fail agree terms";
 }

 return "Fail";
}