import React from 'react';
import { Row } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import ExperiencesHorizontalScrollContainer from './ExperiencesHorizontalScrollContainer';


class ExperiencesArea extends React.Component {

  componentDidMount(){
      let { experienceStatus } = this.props;
      this.props.getExperiences(experienceStatus)
  }

  componentDidUpdate(prevProps){
  	// On logout, search for experiences again
  	if (prevProps.userRole.email && !this.props.userRole.email) {
  		this.props.getExperiences("production");
  	}
  }

  componentWillUnmount() {
      // this.props.clearSelectedExperienceData()
  }

	render() {
    let { experiencesByCategory, experiencesWithPeopleWaiting, userRole } = this.props;

		return (	
      <div>
	      {
	        experiencesByCategory.length ? 
	        <div style={{display: 'block', marginTop: 100}}>
	          <h2 className="pl-1 pr-1" style={{paddingBottom: "1rem"}}>Relational experiences from expert facilitators</h2>
          	{
          		!!(userRole.subscription.activeSubscription && experiencesWithPeopleWaiting && experiencesWithPeopleWaiting.length) &&
          		<ExperiencesHorizontalScrollContainer 
	          		experienceCategory={{
	          			categoryName: "Experiences with people waiting right now",
	          			description: "If other people are waiting for a partner for a specific experience, you will see them here",
	          			experiences: experiencesWithPeopleWaiting
	          		}}
	          	/>
	          }
	          {experiencesByCategory.map((experienceCategory, key) => {
	            return(
	             	<div key={key}>
	                <ExperiencesHorizontalScrollContainer experienceCategory={experienceCategory}/>
	              </div>
	            )
	          })}
	        </div> :
	         null
	      }
      </div>
        
          
		)	
	}
}

export default ExperiencesArea;