import * as Actions from "../actions/emailVerification";

const initialState = {
  resentEmail: false,
  processed: false,
  verified: false,
  isFetching: false,
  error: null
};

export function emailVerification(state = initialState, action) {
  switch (action.type) {
    case Actions.VERIFICATION_START:
      return {
        resentEmail: false,
        processed: false,
        verified: false,
			  isFetching: true,
			  error: null
			};
    case Actions.VERIFICATION_SUCCESS:
      return {
        resentEmail: false,
        processed: true,
        verified: true,
  			isFetching: false,
        error: null
      };
    case Actions.RESEND_SUCCESS:
      return {
        resentEmail: true,
        processed: false,
        verified: false,
        isFetching: false,
        error: null
      };
    case Actions.VERIFICATION_FAILURE:
      return {
        resentEmail: false,
        processed: true,
        verified: false,
  			isFetching: false,
        error: null
      };
    case Actions.FETCH_FAILURE:
      return {
        resentEmail: false,
        processed: false,
        verified: false,
        isFetching: false,
        error: action.error
      };
    case Actions.CLEAR_VERIFICATION:
      return {
        resentEmail: false,
			  processed: false,
			  verified: false,
			  isFetching: false,
			  error: null
      };
    default:
      return state;
  }
}