import constants from "../constants";

export const SEND_FAILURE_DATA_START = "SEND_FAILURE_DATA_START";
export const SEND_FAILURE_DATA_SUCCESS = "SEND_FAILURE_DATA_SUCCESS";
export const SEND_FAILURE_DATA_FAILURE = "SEND_FAILURE_DATA_FAILURE";
export const SET_CAMERA_FAILURE_REASON = "SET_CAMERA_FAILURE_REASON";
export const CLEAR_FAILURE_DATA = "CLEAR_FAILURE_DATA";


export function sendFailureDataStart() {
  return {
    type: SEND_FAILURE_DATA_START
  };
}

export function sendFailureDataSuccess() {
  return {
    type: SEND_FAILURE_DATA_SUCCESS
  };
}

export function sendFailureDataFailure(error) {
  return {
    type: SEND_FAILURE_DATA_FAILURE,
    error
  };
}

export function setCameraFailureReason(data) {
  return {
    type: SET_CAMERA_FAILURE_REASON,
    data
  };
}

export function clearFailureData() {
  return {
    type: CLEAR_FAILURE_DATA
  };
}


export function sendFailureData(reason) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `reason=${reason}`
  };
  return dispatch => {
    dispatch(sendFailureDataStart());
    fetch(`${constants.baseUrl}/api/v1/cameraFailure`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(sendFailureDataFailure("Sending cameraFailure failed"));
        } else {
          console.log("sent failure data");
          dispatch(sendFailureDataSuccess());
        }        
      })
      .catch(err => {
        dispatch(sendFailureDataFailure(err));
        console.log("Sending cameraFailure error: ", err);
      });
  };
}

