import React from "react";
import { connect } from 'react-redux';
import CancelButton from './CancelButton';
import { endConnectionReasons } from "../../actions/endConnectionReason";
import { setStatePresent, setStateDisconnected } from "../../actions/userState";
import sendGAEvent from '../../lib/sendGAEvent';


class CancelButtonContainer extends React.Component {
	constructor() {
		super();
		this.onClick = this.onClick.bind(this);
	}

	getSeconds = () => {
		var duration = Math.abs(new Date() - this.props.screenOpenTime) / 1000;
		return parseInt(duration)
	}

	onClick() {
		const { peerRoom, setStatePresent, setStateDisconnected } = this.props;

		if (peerRoom.confirmed || (peerRoom.position === 2)) {
			setStateDisconnected({ reason: endConnectionReasons.CANCEL_SELF });
			sendGAEvent('Human Minute', 'Cancel after paired', '');
		} else {

			setStatePresent();
		}
	}

  render() {
    return <CancelButton 
    	onClick={this.onClick} 
    	selectedLanguage={this.props.selectedLanguage}
    	userState={this.props.userState}
    	experienceType={this.props.peerRoom.experienceType}
    	/>;
  }
}


const mapStateToProps = (state) => {
  return {
    userState: state.userState,
		peerRoom: state.peerRoom,
		selectedLanguage: state.selectLanguage.selectedLanguage,
		screenOpenTime: state.selectLanguage.screenOpenTime
  };
};

const mapDispatchToProps = {
	setStatePresent,
	setStateDisconnected
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelButtonContainer);
