import { connect } from 'react-redux';
import ConnectOptions from './ConnectOptions';
import { openAuthModal } from "../../../actions/uiControls";


const mapStateToProps = (state, ownProps) => {
  return {
    userState: state.userState,
    peerRoom: state.peerRoom,
    userRole: state.userRole,
    experience: ownProps.experience
  };
};

const mapDispatchtoProps = {
  openAuthModal
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ConnectOptions);