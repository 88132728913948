import React from 'react';
import textResource from '../../../lib/textLoader';
import ConnectButtonContainer from '../../ConnectionControls/ConnectButtonContainer';
import Spinner from "../../elements/Spinner"
import PauseIcon from "../../elements/PauseIcon"

var interval = null;
var timeout = null;
var oneSecondDelayTimeout = null;


const Loader = ({pauseLoader}) => (
	<span className="spincontainer">
		{
			pauseLoader ?
			<PauseIcon />
			:
			<Spinner />
		}
	</span>
)

class EnteringRoomState extends React.Component {
    constructor() {
        super();
        this.state = {
            activateTime: 1,
            pauseLoader: false
        }
    }

    componentDidMount() {
        this.setState({ activateTime: 1, pauseLoader: false })
        this.setEnteringRoomInterval()
        this.setEnteringRoomTimeout(10000)
        this.props.setScreenOpenTime(new Date())    	
    }

    setEnteringRoomInterval = () => {
			interval = setInterval(() => {
				if(this.state.activateTime === 10) {
					return clearInterval(interval)
	      }
	      this.setState({ activateTime: this.state.activateTime + 1 })
			}, 1000);
    }
  
    setEnteringRoomTimeout = (time) => {
			timeout = setTimeout(() => {
        clearTimeout(timeout)
        clearTimeout(oneSecondDelayTimeout)
        this.props.setScreenOpenTime(new Date());
        this.setState({ pauseLoader: false });
        this.props.setStateGettingMedia();
			}, time);
    }

    componentDidUpdate(prevProps) {
      
        if(!prevProps.languageDropDown && this.props.languageDropDown) {
            clearInterval(interval)

            clearTimeout(timeout)
            this.setState({ pauseLoader: true })
        }
        
        if(prevProps.languageDropDown && !this.props.languageDropDown) {
            oneSecondDelayTimeout = setTimeout(() => {
                clearTimeout(oneSecondDelayTimeout)
                this.restartEnteringRoomClock()
                this.setState({ pauseLoader: false })
            }, 1000)
        }
    }

    restartEnteringRoomClock = () => {

			if(this.props.languageDropDown){
				this.props.closeLanguageDropDown();
			}
			if(this.state.activateTime < 10) {
				this.setEnteringRoomInterval()
				this.setEnteringRoomTimeout(10000 - (this.state.activateTime *1000))
			}
    }
    
    componentWillUnmount() {
        clearInterval(interval)
        clearTimeout(timeout)
    }

    renderTitle = () => {
    		let { selectedLanguage } = this.props;
        return <p className="someone-text">{textResource[`video_area.entering_room.${selectedLanguage}`]}</p>

    }

    renderExperienceTitle() {
      return null;
    }

    renderStyle() {
      return {}
    }

    render() {
        let { selectedLanguage, userState, peerRoom } = this.props;
        if (peerRoom.experienceType === "oneminute") {
            return(
                <div className="enteringRoomState" style={this.renderStyle()}>
                    {this.renderTitle()}
                    <Loader pauseLoader={this.state.pauseLoader}/>
                    {this.renderExperienceTitle()}

                    <div className="TipsContainer">
                        <p><b>{textResource[`video_area.remember_text.${selectedLanguage}`]}</b></p>
                        <p>{textResource[`guidelines.stay_still.title.${selectedLanguage}`]}</p>
                        <p>{textResource[`guidelines.avoid_gestures.title.${selectedLanguage}`]}</p>
                        <p>{textResource[`guidelines.stay_present.title.${selectedLanguage}`]}</p>
                    </div>
                </div>
            )
        } else {
            return(
                <div className="enteringRoomState" style={this.renderStyle()}>
                    {this.renderTitle()}
                    <Spinner />

                    <div className="TipsContainer">

                    </div>
                </div>
            )            
        }
    }
}

export default EnteringRoomState;