import { connect } from 'react-redux';
import App from './App';
import { setSocketEventListenerFor } from "../lib/socketInit";
import { setReferrerUrl, setCurrentUrl } from '../actions/userRole';

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    landingAfterSession: state.uiControls.landingAfterSession,
    userState: state.userState,
    experienceType: state.peerRoom.experienceType
  };
};

const mapDispatchtoProps = {
  setSocketEventListenerFor,
  setReferrerUrl,
  setCurrentUrl
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(App);
