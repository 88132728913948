import { connect } from 'react-redux';
import NoRepeatSwitch from './NoRepeatSwitch';
import { setNoRepeatMatchmakerOption, clearNoRepeatMatchmakerOption } from '../../../actions/peerRoom';

const mapStateToProps = (state, ownProps) => {
  return {
    userRole: state.userRole,
    noRepeatMatchmakerOption: state.peerRoom.noRepeatMatchmakerOption,
    userState: state.userState,
		experienceType: ownProps.experienceType,
    isChecked: state.peerRoom.noRepeatMatchmakerOption[ownProps.experienceType] || false
  };
};


const mapDispatchtoProps = {
    setNoRepeatMatchmakerOption,
    clearNoRepeatMatchmakerOption
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(NoRepeatSwitch);