import { connect } from 'react-redux';
import GatheringsArea from './GatheringsArea';



const mapStateToProps = (state, ownProps) => {

  return {
    userRole: state.userRole,
    currentGathering: ownProps.currentGathering,
    mostRecentlyEndedGathering: ownProps.mostRecentlyEndedGathering
  }
}


export default connect(mapStateToProps)(GatheringsArea);
