class WebRTCService {

	constructor(iceServers, signalingService, setRemoteStream, setPeerConnectionReady, setPeerConnectionFailed) {

		// Initilize class variables
		this.pc = null;
		this.configuration = { iceServers: iceServers };
		this.signalingService = signalingService;
		this.setRemoteStream = setRemoteStream;
		this.setPeerConnectionReady = setPeerConnectionReady;
		this.setPeerConnectionFailed = setPeerConnectionFailed;

		// Functions that manage the WebRTC connection
		this.initializePeerConnection = this.initializePeerConnection.bind(this);
		this.createOffer = this.createOffer.bind(this);
		this.sendLocalDesc = this.sendLocalDesc.bind(this);
		this.onIceCandidate = this.onIceCandidate.bind(this);
		this.onTrack = this.onTrack.bind(this);
		this.addTracks = this.addTracks.bind(this);
		this.stopTracks = this.stopTracks.bind(this);
		this.onReceiveSDP = this.onReceiveSDP.bind(this);
		this.onReceiveIceCandidate = this.onReceiveIceCandidate.bind(this);
		this.close = this.close.bind(this);

		this.initializePeerConnection()
	}


	onReceiveSDP(sdp) {
		this.pc.setRemoteDescription(new RTCSessionDescription(sdp))
		.then(() => {
			// if we received an offer, we need to answer
			if (this.pc.signalingState === "have-remote-offer") {
				this.pc.createAnswer()
				.then(this.sendLocalDesc)
				.catch(onCreateSessionDescriptionError)
			}
		})
		.catch(onSetSessionDescriptionError);
	}

	onReceiveIceCandidate(candidate) {
		this.pc.addIceCandidate(new RTCIceCandidate(candidate))
		.catch(onAddIceCandidateError);
	}


	initializePeerConnection() {
		return new Promise((resolve, reject) => {

			this.pc = new RTCPeerConnection(this.configuration);

			this.pc.onicecandidate = this.onIceCandidate;
			this.pc.ontrack = this.onTrack;

			this.pc.onsignalingstatechange = () => {
				if (this.pc) {
					console.log("signaling state change, ", this.pc.signalingState);
				}
			}

			this.pc.onicegatheringstatechange = () => {
				if (this.pc) {
					console.log("ice gathering state change, ", this.pc.iceGatheringState);
				}
			}

			this.pc.oniceconnectionstatechange = () => {
				if (this.pc) {
					console.log("ice connection state change, ", this.pc.iceConnectionState);

					if (["connected", "completed"].includes(this.pc.iceConnectionState)) {
						console.log("In WebRTC class, iceStateChange, setPeerConnectionReady");
						this.setPeerConnectionReady();
					}
					if (this.pc.iceConnectionState === "failed") {
						console.log("In WebRTC class, iceStateChange, setPeerConnectionFailed");
						this.setPeerConnectionFailed();
					}
				}
	
			}

			this.pc.onconnectionstatechange = () => {
				if (this.pc) {
					console.log("connection state change, ", this.pc.connectionState);

					if (this.pc.connectionState === "connected") {
						console.log("In WebRTC class, connectionStateChange, setPeerConnectionReady");
						this.setPeerConnectionReady();
					}
					if (this.pc.connectionState === "failed") {
						console.log("In WebRTC class, connectionStateChange, setPeerConnectionFailed");
						this.setPeerConnectionFailed();
					}
				}
			}

			this.signalingService.setSDPListener(this.onReceiveSDP); // API from SignalingService
			this.signalingService.setIceCandidateListener(this.onReceiveIceCandidate); // API from SignalingService

			return resolve();
		})
	}

	createOffer() {
		this.pc.createOffer()
	  .then(this.sendLocalDesc)
	  .catch(onCreateSessionDescriptionError)		
	}

	sendLocalDesc(desc) {
		this.pc.setLocalDescription(desc)
		.then(() => {
			let data = JSON.stringify({ 'sdp': this.pc.localDescription });
			this.signalingService.sendSDP(data); // NEEDED API
		})
		.catch(onSetSessionDescriptionError)
	}

	onIceCandidate(e) {
		if (e.candidate) {
			console.log("self candidate, ", e.candidate.type);
			let data = JSON.stringify({ 'candidate': e.candidate });
			this.signalingService.sendIceCandidate(data); // NEEDED API
		}
	};

	onTrack(e) {
	  this.setRemoteStream(e.streams[0]);	
	}


	addTracks(stream) {
	  stream.getTracks().forEach((track) => {
      this.pc.addTrack(
        track,
        stream
      );
	  });
	}

	stopTracks(stream) {
	  stream.getTracks().forEach((track) => {
      track.stop();
	  });
	}

	close(localStream, remoteStream) {

		if (this.pc) {

			this.pc.ontrack = null;
	    this.pc.onicecandidate = null;
	    this.pc.oniceconnectionstatechange = null;
	    this.pc.onsignalingstatechange = null;
	    this.pc.onicegatheringstatechange = null;
	    this.pc.onconnectionstatechange = null;

			if (localStream) {
				this.stopTracks(localStream);
			}
			if (remoteStream) {
				this.stopTracks(remoteStream);
			}
			
			this.pc.close();		
			this.pc = null;
		}
	}
}


const buildWebrtcService = (iceServers, signalingService, setRemoteStream, setPeerConnectionReady, setPeerConnectionFailed) => {

	let WebRTCInstance = new WebRTCService(iceServers, signalingService, setRemoteStream, setPeerConnectionReady, setPeerConnectionFailed);

	return {
		createOffer: WebRTCInstance.createOffer,
		addTracks: WebRTCInstance.addTracks,
		close: WebRTCInstance.close
	}
}

export default buildWebrtcService;


function onCreateSessionDescriptionError(error) {
  console.log('Failed to create session description: ' + error.toString());
}

function onSetSessionDescriptionError(error) {
  console.log('Failed to set session description: ' + error.toString());
}

function onAddIceCandidateError(error) {
  console.log('failed to add ICE Candidate: ' + error.toString());
}
