import React from 'react';
import textResource from '../../lib/textLoader';
import { Button } from 'reactstrap';



const EndConnectionButton = ({userState, show, onClickEndConnection}) => {
  return (
  	<div>
  		{show
			? (<Button id="BtnEndConnection" size="lg" color="default" onClick={() => {onClickEndConnection(userState)}}>
				{textResource["session_controls.end_connection_btn"]}
			</Button>)
			: null
			}
		</div>
  )		

}

export default EndConnectionButton;