import * as Actions from "../actions/peerRoom";
import * as SessionStateActions from "../actions/sessionState";
import * as ExperienceInvitationActions from "../actions/experienceInvitation";

const initialState = {
  experienceType: "oneminute",
  roomID: "",
  position: 0,
  cancelled: false,
  confirmed: false,
  isFetching: false,
  error: null,
  haveReceivedThanks: false,
  haveGivenThanks: false,
  noRepeatMatchmakerOption: {},
  poolType: 'anyone',
  groupSpace: 'open',
  smallVideo: null,
  bigVideo: null,
  videoWrap: null,
  connectClickOriginUrl: "/",
  activeExperienceInvitation: false
};

export function peerRoom(state = initialState, action) {
  switch (action.type) {
    case Actions.MATCHMAKER_START:
      return {
        ...state,
        experienceType: action.data.experienceType,
			  roomID: "",
			  position: 0,		
        cancelled: false,
        confirmed: false,
			  isFetching: true,
			  error: null
			};
    case Actions.MATCHMAKER_SUCCESS:
      return {
        ...state,
			  roomID: action.data.roomID,
			  position: action.data.position,
			  isFetching: false,
			  error: null
      };
    case Actions.MATCHMAKER_FAILURE:
      return {
			  ...state,        
  			isFetching: false,
        error: action.error
      };
    case Actions.CLEAR_PEER_ROOM:
      return {
        ...state,
        roomID: "",
        position: 0,
        cancelled: false,
        confirmed: false,
        isFetching: false,
        error: null,
        activeExperienceInvitation: false
      };
    case SessionStateActions.RESET_ALL_SESSION_STATE:
      return {
        ...state,
        experienceType: "oneminute",
        roomID: "",
        position: 0,
        cancelled: false,
        confirmed: false,
        isFetching: false,
        haveReceivedThanks: false,
        haveGivenThanks: false,
        error: null,
        smallVideo: null,
        bigVideo: null,
        videoWrap: null,
        poolType: 'anyone',
        activeExperienceInvitation: false
      };
    case Actions.SET_ROOM_CANCELLED:
      return {
        ...state,
        cancelled: true
      };
    case Actions.SET_ROOM_CONFIRMED:
      return {
        ...state,
        confirmed: true
      };
    case Actions.SET_HAVE_RECEIVED_THANKS:
      return {
        ...state,
        haveReceivedThanks: true
      };
    case Actions.SET_HAVE_GIVEN_THANKS:
      return {
        ...state,
        haveGivenThanks: true
      };
    case Actions.SET_EXPERIENCE_TYPE:
      return {
        ...state,
        experienceType: action.data
      };
    case Actions.SET_NO_REPEAT_MATCHMAKER_OPTION:
      let noRepeatMatchmakerOption1 = {
        ...state.noRepeatMatchmakerOption
      }
      noRepeatMatchmakerOption1[action.data] = true;
      return {
        ...state,
        noRepeatMatchmakerOption: noRepeatMatchmakerOption1
      };
    case Actions.CLEAR_NO_REPEAT_MATCHMAKER_OPTION:
      let noRepeatMatchmakerOption2 = {
        ...state.noRepeatMatchmakerOption
      }
      noRepeatMatchmakerOption2[action.data] = false;
      return {
        ...state,
        noRepeatMatchmakerOption: noRepeatMatchmakerOption2
      };
    case Actions.SET_POOL_TYPE:
      return {
        ...state,
        poolType: action.poolType
      };
    case Actions.SET_GROUP_SPACE:
      return {
        ...state,
        groupSpace: action.data
      };
    case Actions.SET_VIDEO_TAG:
      return {
        ...state,
        smallVideo: action.data.smallVideo,
        bigVideo: action.data.bigVideo,
        videoWrap: action.data.videoWrap
      };
    case Actions.SET_CONNECT_CLICK_ORIGIN_URL:
      return {
        ...state,
        connectClickOriginUrl: action.data
      };
    case Actions.EXPERIENCE_INVITATION_START:
      return {
        ...state,
        activeExperienceInvitation: true
      };
    case ExperienceInvitationActions.RETRIEVE_EXPERIENCE_INVITATION_SUCCESS:
      return {
        ...state,
        activeExperienceInvitation: true
      };
    default:
      return state;
  }
}