import { connect } from 'react-redux';
import AfterConnectionContent from './AfterConnectionContent';


const mapStateToProps = (state) => {
  return {
    endConnectionReason: state.endConnectionReason,
  };
};


export default connect(
  mapStateToProps,
  null
)(AfterConnectionContent);