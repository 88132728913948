import { connect } from 'react-redux';
import SelectLanguage from './SelectLanguage';
import { openLanguageDropdown, closeLanguageDropDown, setSelectedLanguage } from '../../../actions/selectLanguage';


const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    languageDropDown: state.selectLanguage.languageDropDown,
    userRole: state.userRole,
    selectedFullLanguage: state.selectLanguage.selectedFullLanguage
  };
};

const mapDispatchtoProps = {
    openLanguageDropdown,
    closeLanguageDropDown,
    setSelectedLanguage
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(SelectLanguage);