import { connect } from 'react-redux';
import ExperienceCard from './ExperienceCard';
import { openExperienceModal } from '../../actions/experienceModal';
import { openAuthModal, setAuthModalTypeLogin, setAuthEventsCategory } from "../../actions/uiControls";
import { clearSocialLoginButtons } from "../../actions/socialLogin";
import sendGAEvent from '../../lib/sendGAEvent';
import { createExperienceInvitation, setExperienceType } from "../../actions/peerRoom";



const mapStateToProps = (state,ownProps) => {
    return {
        userRole: state.userRole,
        userState: state.userState,
        peerRoom: state.peerRoom,
        experience: ownProps.experience
    };
};

const mapDispatchtoProps = (dispatch) => {
	return {
		openAuthModal: () => {
      dispatch(clearSocialLoginButtons());
			dispatch(openAuthModal());
      dispatch(setAuthEventsCategory('Authentication-btnExperience'));
      sendGAEvent('Authentication-btnExperience', 'Open', '');
		},
		openExperienceModal: (data) => {
			dispatch(openExperienceModal(data));
		},
    createExperienceInvitation: (data) => {
      dispatch(createExperienceInvitation(data));
    },
    setExperienceType: (data) => {
      dispatch(setExperienceType(data));
    },    
	}
};


export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(ExperienceCard);