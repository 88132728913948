import { connect } from 'react-redux';
import FacilitatorPage from './FacilitatorPage';
import { getFacilitatorData, clearFacilitatorData } from '../../../actions/facilitator';


const mapStateToProps = (state,ownProps) => {
    return {
        userRole: state.userRole,
        userState: state.userState,
        peerRoom: state.peerRoom,
        facilitator: state.facilitator
    };
};

const mapDispatchtoProps = {
    getFacilitatorData,
    clearFacilitatorData
};

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(FacilitatorPage);