import { connect } from 'react-redux';
import ExperienceTitle from './ExperienceTitle';


const mapStateToProps = (state) => {
  return {
    experienceName: state.selectedExperienceData.data.name
  };
};



export default connect(
  mapStateToProps,
  null
)(ExperienceTitle);