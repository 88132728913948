import React from 'react';
import './FeedbackStyles.css';
import textResource from '../../lib/textLoader';
import { Button, Form, FormGroup, Label, Input, Col, Row, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import ValidationErrorMessage from '../Authentication/ValidationErrorMessage';
import ErrorMessage from '../Authentication/ErrorMessage';
import ParagraphCreator from '../elements/ParagraphCreator';
import LayoutWrapper from '../elements/LayoutWrapper';
import Spinner from '../elements/BlackSpinner';


const Feedback = ({ onSubmit, checked, onChangeCheckBox, email, feedback, errors }) => {

	return (
		<LayoutWrapper>
		  	<h2 className="pb-3">{textResource["feedback.title"]}</h2>
		  	<p>This is a new service and we want to improve it, so we would really like to hear from you. We appreciate your comments, suggestions and want to know how the experiences were for you.</p>
		  	<br/>
		  	<div>
			    {
			    	feedback.sent 
			    	? (<Alert color="success">
					      {textResource["feedback.success"]}
					    </Alert>)
			    	: null
			    }
			  </div>
			  <h4>Please answer at least one field:</h4>
		    <Form id="FormSendFeedback" onSubmit={onSubmit} className="pt-3 pb-3">
		      <ErrorMessage errors={errors.fromServer ? errors : {}} />
		      <FormGroup className="pb-3">
		      	<h5>My circle</h5>
		      	<p>What do you think of the “My circle” system?</p>
		        <Input 
		          type="textarea"
		          maxLength="10000"
		          name="circleAnswer" 
		          id="circleAnswer"
		          style={{height: "100px"}}
		        />       
		      </FormGroup>
{/*		      <FormGroup className="pb-3">
		      	<h5>Relational experiences</h5>
		      	<p>What do you think of the different experiences we’ve created?</p>
		        <Input 
		          type="textarea"
		          maxLength="10000"
		          name="experiencesAnswer" 
		          id="experiencesAnswer"
		          style={{height: "100px"}}
		        />	        
		      </FormGroup>
		      <FormGroup className="pb-3">
		      	<h5>Contributing membership</h5>
		      	<p>We are aiming to build a sustainable social enterprise. What do you think of our membership system?</p>
		        <Input 
		          type="textarea"
		          maxLength="10000"
		          name="membershipAnswer" 
		          id="membershipAnswer"
		          style={{height: "100px"}}
		        />	        
		      </FormGroup>*/}
		      <FormGroup>
		      	<h5>Other</h5>
		        <Input 
		          type="textarea"
		          maxLength="10000"
		          name="content" 
		          id="content"
		          style={{height: "100px"}}
		        />	        
		      </FormGroup>
				  <Row>
			      	<Col  lg={{ size: 6 }} md={{ size: 12 }} sm={{ size: 12  }} xs={{ size: 12 }} className="pb-sm-0 pb-3">
							<FormGroup check>
								<Label check>
									<Input
										name="anon"
										type="checkbox"
										checked={checked}
										onChange={onChangeCheckBox}
									/>{' '}
									{textResource["feedback.anonymous"]}
								</Label>
							</FormGroup>
						</Col>
						<Col  lg={{ size: 6 }} md={{ size: 12 }} sm={{ size: 12 }} xs={{ size: 12 }} className="text-right text-sm-left mobiletext-left">
							{checked ? null : <p>{email}</p>}
						</Col>
					</Row>
		      
		    
		      <br/>
		      <Row>
		      	<Col  lg={{ size: 4 }}  sm={{ size: 6 }} xs={{ size: 12 }}>
		      		{
		      			feedback.isFetching ?
		      			<Spinner /> :
					      <Button 
					      	id="BtnSendFeedback" 
					      	color="primary" 
					      	block
								  disabled={feedback.sent}
								  className="mb-1"
					      >
					        {textResource["feedback.submit"]}
					      </Button>
		      		}
		      	</Col>
		      	<Col  lg={{ size: 4 }}  md={{ size: 5 }}  sm={{ size: 5 }} xs={{ size: 12 }} >
						  <Link to="/" >
					  		<Button color="secondary" block>
					  			{textResource["feedback.cancel"]}
					    	</Button>
				    	</Link>				    	
		      	</Col>
		      </Row>
		    </Form>
		</LayoutWrapper>
	)
}

export default Feedback;