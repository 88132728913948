import moment from 'moment-timezone';

export function getTimeDiff({
  startDate,
  endDate
}) {
  const countAndUnit = getCountAndUnitDiff({ startDate, endDate });
  const { unitCount, unit } = countAndUnit;

  const string = unit.includes('second')
    ? 'a few moments'
    : `${unitCount} ${unit}`;

  const stringWithTense = unitCount < 0 ? `${string} ago` : `in ${string}`;

  return {
    ...countAndUnit,
    humanReadableString: stringWithTense
  };
}

export function getCountAndUnitDiff({
  startDate,
  endDate
}) {
  const now = Date.now();

  if (startDate == null) {
    startDate = now;
  }

  if (endDate == null) {
    endDate = now;
  }

  const end = moment(endDate);
  const start = moment(startDate);

  const hoursDiff = end.diff(start, 'hours', true);
  // "2 hours" until 90 minutes remain, then "90 minutes"
  if (Math.abs(hoursDiff) > 1.5) {
    const roundedHours = Math.ceil(hoursDiff);
    return {
      unitCount: roundedHours,
      unit: pluralize(roundedHours, 'hour')
    };
  }

  const minutesDiff = end.diff(start, 'minutes');
  if (Math.abs(minutesDiff) > 0) {
    return {
      unitCount: minutesDiff,
      unit: pluralize(minutesDiff, 'minute')
    };
  }

  const secondsDiff = end.diff(start, 'seconds');
  if (Math.abs(secondsDiff) >= 0) {
    return {
      unitCount: secondsDiff,
      unit: pluralize(secondsDiff, 'second')
    };
  }
}

function pluralize(count, unit) {
  return count === 1 ? unit : unit + 's';
}
