import io from 'socket.io-client';
import queryString from 'query-string';
import { handleMatchmakerSocketEvent, handleDisconnectSocketEvent } from '../actions/peerRoom';
import { handleNewPeopleCount, setPresenceOnReconnect } from '../actions/presenceCount';
import constants from "../constants";


let socket = null;

let events = {};

let setListener = (event, callback) => {
    if (socket) {
        console.log("logged in, registering event, ",event);
        socket.on(event, callback);
    } else {
        console.log("not logged, saving event", event);
        events[event] = callback;
    }
}

export function disconnectSocket() {
  if (!socket) {
    return;
  }
  socket.disconnect();
  socket = null;
}

function getSocket() {
    if (socket) {
        return socket;
    }
    console.log("initializing socket.io");
    let token = localStorage.getItem("token");
    let addr = queryString.stringifyUrl({url: constants.realtimeUrl, query: { token }});
    socket = io(addr);
    socket.on("error", (data) => {
        console.log("socket error", data);
    });

    for (let e in events) {
        console.log("registering saved event, ", e);
        socket.on(e, events[e]);
    }
}

export function setSocketEventListenerFor(type) {
    return (dispatch) => {
        setListener(type, (data) => {
            if(type === 'newPeopleCount'){
                return dispatch(handleNewPeopleCount(data))
            }
            if(type === 'reconnect'){
                return dispatch(setPresenceOnReconnect(data))
            }
            if(type === 'matchmakerEvent'){
                return dispatch(handleMatchmakerSocketEvent(data))
            }
            if(type === 'disconnect'){
                return dispatch(handleDisconnectSocketEvent())
            }
        })
    }
}

export function emitAction ( type, data, actions ) {

    return (dispatch) => {
        if (!localStorage.getItem("token")) {
            return;
        }
        getSocket();

        if(socket.connected || type === 'present'){
            console.log("emitting ", type);
            socket.emit( type, data )
            if(actions && actions.onSuccess){
                dispatch(actions.onSuccess())
            }
        }
        else{
            if(actions && actions.onError){
                dispatch(actions.onError())
            }
        }
    }
}
