import { connect } from 'react-redux';
import TextInstructionsManager from './TextInstructionsManager';
import { 
	showTextInstructionMessage,
	clearTextInstructionMessage 
} from "../../../actions/uiControls";

const mapStateToProps = (state,ownProps) => {
  return {
    userState: state.userState,
    peerRoom: state.peerRoom,
    selectedExperienceData: state.selectedExperienceData.data,
    peerReady: state.sessionState.peerReady
  };
};

const mapDispatchtoProps = {
	showTextInstructionMessage,
	clearTextInstructionMessage
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(TextInstructionsManager);