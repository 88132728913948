import React from 'react';
import './ExperiencesHorizontalScrollStyles.css';
import { Row, Button } from 'reactstrap';
import textResource from '../../lib/textLoader';
import ExperienceCardContainer from './ExperienceCardContainer';
import ArrowLeft from '../../resources/images/arrow-left.png';
import ArrowRight from '../../resources/images/arrow-right.png';

const getItemWidth = () => {
  // The "6" below refers to the biggest margin itemElement can have, plus 1.
  // This is a hot number, if the css changes it should be replaced.
  let itemElement = document.querySelector('.itemContainer');
  let itemWidth = itemElement.offsetWidth + 6;

  return itemWidth;
}


class ExperiencesHorizontalScroll extends React.Component {

  constructor() {
    super();

    this.state = {
      showLeftScrollArrow: false,
      showRightScrollArrow: false
    }

    this.setShowScrollArrows = this.setShowScrollArrows.bind(this);
    this.nextExperience = this.nextExperience.bind(this);
    this.previousExperience = this.previousExperience.bind(this);
  }

  componentDidMount() {
    this.setShowScrollArrows();
    window.addEventListener('resize', this.setShowScrollArrows)
    this.categoryRowDiv.addEventListener('scroll', this.setShowScrollArrows)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setShowScrollArrows)
    this.categoryRowDiv.removeEventListener('scroll', this.setShowScrollArrows)
  }

  setShowScrollArrows() {
    let { scrollWidth, scrollLeft, offsetWidth } = this.categoryRowDiv;

    if (scrollWidth > offsetWidth) {
      
      let itemWidth = getItemWidth();
      let showLeftScrollArrow = !!(scrollLeft > 0);
      let showRightScrollArrow = !!((scrollWidth - scrollLeft) > offsetWidth);

      this.setState({
        showLeftScrollArrow,
        showRightScrollArrow
      })
    } else {
      this.setState({
        showLeftScrollArrow: false,
        showRightScrollArrow: false
      })      
    }
  }

  nextExperience() {
    let itemWidth = getItemWidth();

    let currentExperience = Math.floor(this.categoryRowDiv.scrollLeft/itemWidth);

    this.categoryRowDiv.scrollTo((currentExperience + 1)*itemWidth, 0);
  }

  previousExperience() {
    let itemWidth = getItemWidth();

    let currentExperience = Math.ceil(this.categoryRowDiv.scrollLeft/itemWidth);

    this.categoryRowDiv.scrollTo((currentExperience - 1)*itemWidth, 0);
  }

	render() {
    let { experienceCategory } = this.props;
    let { experiences } = experienceCategory;
        
		return (	
      <div>
        <div style={{display: 'block', paddingTop: "2rem"}}>
          <h3 className="categoryTitle pl-1 pr-1">{experienceCategory.categoryName}</h3>
          <h4 className="categoryDescription">{experienceCategory.description}</h4>
          {
            !!(this.state.showLeftScrollArrow || this.state.showRightScrollArrow) &&
            <div className="experience-scroll-arrows">

              <div className="arrow-left">
                {
                  this.state.showLeftScrollArrow &&
                  <Button color="default" onClick={this.previousExperience}>
                    <img src={ArrowLeft} style={{width: "0.5rem", height: "1rem"}} />
                  </Button>
                }
              </div>            
              <div className="arrow-right">
                {
                  this.state.showRightScrollArrow &&
                  <Button color="default" onClick={this.nextExperience}>
                    <img src={ArrowRight} style={{width: "0.5rem", height: "1rem"}} />
                  </Button>
                }
              </div>
            </div>
          }
          <Row className="experienceCardRow">
            <div 
              className="flexBox" 
              id={`experience-row-${experienceCategory.categoryName}`}
              ref={element => (this.categoryRowDiv = element)}
            >
              {experiences.length ? experiences.map((experience, key) => {
                return(
                  <div className="itemContainer" key={key}>
                    <ExperienceCardContainer experience={experience}/>
                  </div>
                )
              }):null}
            </div>
          </Row>
        </div>
      </div>
		)	
	}
}

export default ExperiencesHorizontalScroll;