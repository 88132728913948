import React from 'react';
import constants from '../../../constants';
import { Progress } from 'reactstrap';

class ProgressBar extends React.Component {

	constructor() {
		super();

		this.interval = null;
		this.state = ({
			progress: 500,
			show: false
		})

		this.startProgressInterval = this.startProgressInterval.bind(this);
		this.stopProgressInterval = this.stopProgressInterval.bind(this);
		this.increaseProgress = this.increaseProgress.bind(this);
		this.showAlways = this.showAlways.bind(this);
		this.setShowAlwaysTimeout = this.setShowAlwaysTimeout.bind(this);
		this.clearShowAlwaysTimeout = this.clearShowAlwaysTimeout.bind(this);
		this.setStopProgressTimeout = this.setStopProgressTimeout.bind(this);
		this.clearStopProgressTimeout = this.clearStopProgressTimeout.bind(this);
		this.timerDuration = this.timerDuration.bind(this);
	}

	componentDidMount() {
		this.startProgressInterval();
		this.setShowAlwaysTimeout();
		this.setStopProgressTimeout();
	}

	componentWillUnmount() {
		this.stopProgressInterval();
		this.clearShowAlwaysTimeout();
		this.clearStopProgressTimeout();
	}

	startProgressInterval() {
		this.interval = setInterval(() => {
			this.increaseProgress();
		}, 100)
	}

	stopProgressInterval() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	increaseProgress() {
		this.setState({
			progress: Date.now() + 500 - this.props.startTime,
		});
	}

	setShowAlwaysTimeout() {
		this.showAlwaysTimeout = setTimeout(this.showAlways, this.props.selectedExperienceData.connectionDuration - 10000);
	}

	clearShowAlwaysTimeout() {
		if (this.showAlwaysTimeout) {
			clearTimeout(this.showAlwaysTimeout);
		}
	}

	setStopProgressTimeout() {
		this.stopProgressTimeout = setTimeout(this.stopProgressInterval, this.timerDuration());
	}

	clearStopProgressTimeout() {
		if (this.setStopProgressTimeout) {
			clearTimeout(this.stopProgressTimeout);
		}
	}

	showAlways() {
		this.setState({
			show: true,
		});
	}

	timerDuration() {
		return this.props.selectedExperienceData.connectionDuration;
	}

	render() {
	  return (
	  	<div>
	  	{(this.props.show || this.state.show)
	    	? (<Progress max={this.timerDuration()} value={this.state.progress} className="rounded-0" />)
	    	: null
	  	}
	  	</div>
	  );
	}
}



export default ProgressBar;
