import { connect } from 'react-redux';
import HumanMinuteArea from './HumanMinuteArea';

const mapStateToProps = (state, ownProps) => {
  return {
    userState: state.userState,
    userRole: state.userRole,
    socialLogin: state.socialLogin,
    experienceType: state.peerRoom.experienceType,
    fromInvite: ownProps.fromInvite
  };
};


export default connect(
  mapStateToProps,
  null
)(HumanMinuteArea);