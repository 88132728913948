import { connect } from 'react-redux';
import ResendEmail from './ResendEmail';
import { resendVerificationEmail, clearVerification } from "../../../actions/emailVerification";
import { openAuthModal } from "../../../actions/uiControls";


const mapStateToProps = (state) => {
  return {
    email: state.userRole.email,
    emailVerification: state.emailVerification,
  };
};

const mapDispatchtoProps = {
	resendVerificationEmail,
	clearVerification,
	openAuthModal
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ResendEmail);