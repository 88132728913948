import React from 'react';
import './PresenceIndicatorStyles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PresenceIndicator = ({ presenceCount, gatheringsInfo, userRole, openNoOneInCircleModal }) => {
	if (!gatheringsInfo.ready) { return null; }
	if (userRole.role === "guest") { return null; }

	let presenceText = `${presenceCount.general} people`;
	let myCircleText = `${presenceCount.myCircle} from my circle`

	return (
		<div>
			<div className="presenceIndicatorDiv">
				<div className="presenceIndicatorBox1">
					<p className="people-number">{presenceCount.general}</p>
					<p>people present</p>
				</div>
				<div className="presenceIndicatorBox2">
					{
						!userRole.myCircleMatched &&
						<div id="presence-circle-explainer">
							<a href="#" onClick={(e) => {
								e.preventDefault();
								openNoOneInCircleModal();
							}}>
	            	<FontAwesomeIcon
			            icon="question-circle"
		            />
	            </a>
	          </div>
					}
					<p className="people-number">{presenceCount.myCircle}</p>
					<p>from your circle</p>
				</div>
				
				
			</div>
		</div>
	)

}

export default PresenceIndicator;