export function formatEndReasonForUser(endReason) {
	let formattedEndReason = "";
	switch (endReason) {
		case "cancel_self":
			formattedEndReason = "cancelled by self";
			break;
		case "cancel_other":
			formattedEndReason = "cancelled by other";
			break;
		case "manual_self":
			formattedEndReason = "ended by self";
			break;
		case "manual_other":
			formattedEndReason = "ended by other";
			break;
		case "ice_failure":
			formattedEndReason = "connection failed";
			break;
		default:
			formattedEndReason = endReason;
	}
	return formattedEndReason;
}
