import * as Actions from "../actions/invitation";

const initialState = {
  sent: false,
  limit: false,
  isFetching: false,
  error: null
};

export function invitation(state = initialState, action) {
  switch (action.type) {
    case Actions.INVITATION_START:
      return {
        sent: false,
        limit: false,
			  isFetching: true,
			  error: null
			};
    case Actions.INVITATION_SUCCESS:
      return {
        sent: true,
        limit: false,
  			isFetching: false,
        error: null
      };
    case Actions.INVITATION_FAILURE:
      return {
        sent: false,
        limit: false,
  			isFetching: false,
        error: action.error
      };
    case Actions.INVITATION_REACHED_LIMIT:
      return {
        sent: false,
        limit: true,
        isFetching: false,
        error: null
      };
    case Actions.CLEAR_INVITATION:
      return {
			  sent: false,
        limit: false,
			  isFetching: false,
			  error: null
      };
    default:
      return state;
  }
}