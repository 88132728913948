import { connect } from 'react-redux';
import VideoManager from './VideoManager';



const mapStateToProps = (state, ownProps) => {
  return {
    smallVideo: ownProps.smallVideo,
    bigVideo: ownProps.bigVideo,
    videoWrap: ownProps.videoWrap,
    localStream: ownProps.localStream,
    remoteStream: ownProps.remoteStream,
    userState: state.userState,
    fullScreen: state.fullScreen
  };
};



export default connect(
  mapStateToProps,
  null
)(VideoManager);