import amplitude from 'amplitude-js';
import constants from '../constants';

var amplitudeClient = null;

export function initializeAmplitude(userId) {

		amplitudeClient = amplitude.getInstance();

		amplitudeClient.onInit(() => {
			console.log("init callback")
			if (userId) {
				console.log("setting user id", userId)
				amplitudeClient.setUserId(userId);
				amplitudeClient.logEvent('open website');
			}			
		})

		amplitudeClient.init(constants.amplitudeKey);

}


export function sendAmplitudeEvent(eventType, eventProperties) {

	if (!amplitudeClient) {return;}
  if (window.location.pathname === "/testing/testrtc") {return;}
    
	console.log(eventType, eventProperties);
    return amplitudeClient.logEvent(eventType, eventProperties)
}

export function setUserProperties(properties) {

  if (!amplitudeClient) {return;}
  if (window.location.pathname === "/testing/testrtc") {return;}
    
	console.log(properties);
    return amplitudeClient.setUserProperties(properties)
}
