import React from 'react';

class AudioManager extends React.Component {
    constructor() {
        super();
        this.introAudioStartTimer = null
        this.mainAudioStartTimer = null
        this.afterConnectionAudioStartTimer = null
        this.audioChangeTimeouts = null;

        this.introAudio = null
        this.mainAudio = null
        this.afterConnectionAudio = null
        
        this.setUserAudio = this.setUserAudio.bind(this);

    }

    componentDidMount() {
        let {selectedExperienceData} = this.props;
        if(selectedExperienceData.introAudioURL){
            this.introAudio = new Audio (selectedExperienceData.introAudioURL)
            this.introAudio.load()
        }
        if(selectedExperienceData.mainAudioURL){
            this.mainAudio = new Audio (selectedExperienceData.mainAudioURL)
            this.mainAudio.load()
        }
        if(selectedExperienceData.afterConnectionAudioURL){
            this.afterConnectionAudio = new Audio (selectedExperienceData.afterConnectionAudioURL)
            this.afterConnectionAudio.load()
        }
    }

    componentDidUpdate(prevProps) {
        let oldState = prevProps.userState;
        let newState = this.props.userState;
        let {selectedExperienceData} = this.props;

        // When the state becomes "ready", since this is the last user "click", we play all audios then immediately pause them. That way we can escape autoplay blocking.
        if (newState === "ready" && oldState !== "ready") {
            if (this.introAudio) {
                this.introAudio.play();
                this.introAudio.pause();
            }
            if (this.mainAudio) {
                this.mainAudio.play();
                this.mainAudio.pause();
            }
            if (this.afterConnectionAudio) {
                this.afterConnectionAudio.play();
                this.afterConnectionAudio.pause();
            }
        }        


        if (this.introAudio) {
            if (newState === "ready" && this.props.peerReady && 
                (oldState !== "ready" || !prevProps.peerReady)
            ) {
                this.introAudioStartTimer = setTimeout(() => {
                    this.introAudio.play()
                }, selectedExperienceData.introAudioStart)
            }

            if (oldState === "ready" && newState !== "ready") {
                clearTimeout(this.introAudioStartTimer);
                this.introAudio.pause();
            }                
        }



        if (this.mainAudio) {
            if (oldState !== "connected" && newState === "connected") {
                this.mainAudioStartTimer = setTimeout(() => {
                    this.mainAudio.play()
                }, selectedExperienceData.mainAudioStart)                    
            }

            if (oldState === "connected" && newState !== "connected") {
                clearTimeout(this.mainAudioStartTimer);
                this.mainAudio.pause()
            }
        }


                        
        if(this.afterConnectionAudio) {
            if (oldState === "connected" && newState === "disconnected" && this.props.endConnectionReason === "natural") {
                this.afterConnectionAudioStartTimer = setTimeout(() => {
                    this.afterConnectionAudio.play()
                }, selectedExperienceData.afterConnectionAudioStart)
            }

            if(oldState === "disconnected" && newState !== "disconnected"){
                clearTimeout(this.afterConnectionAudioStartTimer);
                this.afterConnectionAudio.pause()
            }
        }


        if (oldState !== "connected" && newState === "connected") {
            if(selectedExperienceData && selectedExperienceData.userAudioFromStart) {
				this.props.smallVideo.srcObject.getAudioTracks()[0].enabled = true
			}
			if(selectedExperienceData && selectedExperienceData.userAudioChanges && selectedExperienceData.userAudioChanges.length) {

                this.audioChangeTimeouts = selectedExperienceData.userAudioChanges.map((audioItem) => {
                    let timeout = setTimeout(() => {
                        this.setUserAudio(audioItem);
                    }, audioItem.time);
                    return timeout;
                })
			}
        }

        if (oldState === "connected" && newState !== "connected") {
            if (this.audioChangeTimeouts) {
                this.audioChangeTimeouts.forEach((timeout) => {
                    clearTimeout(timeout);
                })
                this.audioChangeTimeouts = null;           
            }
        }
        
    }


    setUserAudio(audioItem) {
        let action = true;
        if((this.props.peerRoom.position === 1 && audioItem.user1Audio === 'off') || (this.props.peerRoom.position === 2 && audioItem.user2Audio === 'off')){
            action = false
        }
        this.props.smallVideo.srcObject.getAudioTracks()[0].enabled = action;
        if (action !== this.props.userAudio) {
            if (action) {
                this.props.setUserAudio();
            } else {
                this.props.clearUserAudio()
            }
            this.props.showUserAudioMessage();
            setTimeout(() => {
                this.props.clearUserAudioMessage();
            }, 4000)
        }        
    }

    componentWillUnmount() {
        if (this.audioChangeTimeouts) {
            this.audioChangeTimeouts.forEach((timeout) => {
                clearTimeout(timeout);
            })
            this.audioChangeTimeouts = null;           
        }
        if (this.introAudioStartTimer) {
            clearTimeout(this.introAudioStartTimer);
            this.introAudioStartTimer = null;
        }
        if (this.mainAudioStartTimer) {
            clearTimeout(this.mainAudioStartTimer);
            this.mainAudioStartTimer = null;
        }
        if (this.afterConnectionAudioStartTimer) {
            clearTimeout(this.afterConnectionAudioStartTimer);
            this.afterConnectionAudioStartTimer = null;
        }
    }


    render() {
        return null
    }
}

export default AudioManager;