import { connect } from 'react-redux';
import ProgressBar from './ProgressBar';


const mapStateToProps = (state) => {
  return {
    show: state.uiControls.connectionControls,
    startTime: state.sessionState.startTime,
    selectedExperienceData: state.selectedExperienceData.data
  };
};


export default connect(
  mapStateToProps,
  null
)(ProgressBar);