import { connect } from 'react-redux';
import ExperienceInvitationPage from './ExperienceInvitationPage';
import { 
    retrieveExperienceInvitation, 
    storeSessionFromInvitation, 
    clickConnectExperienceInvite, 
    clickCancelExperienceInvite 
} from '../../../actions/experienceInvitation';
import { matchmakerSuccess, setExperienceType, setPoolType } from '../../../actions/peerRoom';
import { instantiateRealtime } from "../../../actions/realtime";
import { logout } from "../../../actions/userRole";


const mapStateToProps = (state,ownProps) => {
    return {
        userRole: state.userRole,
        userState: state.userState,
        peerRoom: state.peerRoom,
        experienceInvitation: state.experienceInvitation,
        realtime: state.realtime
    };
};

const mapDispatchtoProps = {
    retrieveExperienceInvitation,
    matchmakerSuccess,
    setExperienceType,
    setPoolType,
    instantiateRealtime,
    storeSessionFromInvitation,
    logout,
    clickConnectExperienceInvite, 
    clickCancelExperienceInvite 
};

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(ExperienceInvitationPage);