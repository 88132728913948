import constants from "../constants";

export const FEEDBACK_START = "FEEDBACK_START";
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
export const FEEDBACK_FAILURE = "FEEDBACK_FAILURE";
export const CLEAR_FEEDBACK = "CLEAR_FEEDBACK";

export function feedbackStart() {
  return {
    type: FEEDBACK_START
  };
}

export function feedbackSuccess() {
  return {
    type: FEEDBACK_SUCCESS
  };
}

export function feedbackFailure(error) {
  return {
    type: FEEDBACK_FAILURE,
    error
  };
}

export function clearFeedback() {
  return {
    type: CLEAR_FEEDBACK
  };
}


export function sendFeedback(content, anon, circleAnswer, experiencesAnswer, membershipAnswer) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `content=${content}&anon=${anon}&circleAnswer=${circleAnswer}&experiencesAnswer=${experiencesAnswer}&membershipAnswer=${membershipAnswer}`
  };
  return dispatch => {
    dispatch(feedbackStart());
    fetch(`${constants.baseUrl}/api/v1/feedback`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(feedbackFailure("Sending feedback failed"));
        } else {
          dispatch(feedbackSuccess());
        }        
      })
      .catch(err => {
      	dispatch(feedbackFailure(err));
        console.log("Sending feedback error: ", err);
      });
  };
}