export const OPEN_AUTH_MODAL = "OPEN_AUTH_MODAL";
export const CLOSE_AUTH_MODAL = "CLOSE_AUTH_MODAL";
export const OPEN_SOCIAL_AUTH_MODAL = "OPEN_SOCIAL_AUTH_MODAL";
export const CLOSE_SOCIAL_AUTH_MODAL = "CLOSE_SOCIAL_AUTH_MODAL";
export const SET_AUTH_MODAL_TYPE_LOGIN = "SET_AUTH_MODAL_TYPE_LOGIN";
export const SET_AUTH_MODAL_TYPE_REGISTER = "SET_AUTH_MODAL_TYPE_REGISTER";
export const SHOW_REGISTRATION_CONFIRMATION = "SHOW_REGISTRATION_CONFIRMATION";
export const HIDE_REGISTRATION_CONFIRMATION = "HIDE_REGISTRATION_CONFIRMATION";
export const SHOW_MATCHMAKER_TIMEOUT_ALERT = "SHOW_MATCHMAKER_TIMEOUT_ALERT";
export const HIDE_MATCHMAKER_TIMEOUT_ALERT = "HIDE_MATCHMAKER_TIMEOUT_ALERT";
export const SHOW_CONNECTION_CONTROLS = "SHOW_CONNECTION_CONTROLS";
export const HIDE_CONNECTION_CONTROLS = "HIDE_CONNECTION_CONTROLS";
export const SET_REGISTER_GATHERING_AFTER_LOGIN = "SET_REGISTER_GATHERING_AFTER_LOGIN";
export const CLEAR_REGISTER_GATHERING_AFTER_LOGIN = "CLEAR_REGISTER_GATHERING_AFTER_LOGIN";
export const SET_REGISTER_EMAILS_AFTER_LOGIN = "SET_REGISTER_EMAILS_AFTER_LOGIN";
export const CLEAR_REGISTER_EMAILS_AFTER_LOGIN = "CLEAR_REGISTER_EMAILS_AFTER_LOGIN";
export const SET_COLLAPSE_TERMS = "SET_COLLAPSE_TERMS";
export const SET_COLLAPSE_PRIVACY = "SET_COLLAPSE_PRIVACY";
export const SET_COLLAPSE_GATHERING = "SET_COLLAPSE_GATHERING";
export const SET_COLLAPSE_CLOSED = "SET_COLLAPSE_CLOSED";
export const SET_EMAIL_FROM_LINK = "SET_EMAIL_FROM_LINK";
export const CLEAR_EMAIL_FROM_LINK = "CLEAR_EMAIL_FROM_LINK";
export const SET_AUTH_MODAL_FORM = "SET_AUTH_MODAL_FORM";
export const CLEAR_AUTH_MODAL_FORM = "CLEAR_AUTH_MODAL_FORM";
export const SET_LOGIN_WITH_EMAIL = "SET_LOGIN_WITH_EMAIL";
export const CLEAR_LOGIN_WITH_EMAIL = "CLEAR_LOGIN_WITH_EMAIL";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const CLEAR_AUTH_DATA = "CLEAR_AUTH_DATA";
export const SET_ALREADY_REGISTER_WITH_EMAIL = "SET_ALREADY_REGISTER_WITH_EMAIL";
export const CLEAR_ALREADY_REGISTER_WITH_EMAIL = "CLEAR_ALREADY_REGISTER_WITH_EMAIL";
export const SET_AUTH_EVENTS_CATEGORY = "SET_AUTH_EVENTS_CATEGORY";
export const CLEAR_AUTH_EVENTS_CATEGORY = "CLEAR_AUTH_EVENTS_CATEGORY";

export const SET_USER_AUDIO = "SET_USER_AUDIO";
export const CLEAR_USER_AUDIO = "CLEAR_USER_AUDIO";
export const SHOW_USER_AUDIO_MESSAGE = "SHOW_USER_AUDIO_MESSAGE";
export const CLEAR_USER_AUDIO_MESSAGE = "CLEAR_USER_AUDIO_MESSAGE";
export const SHOW_TEXT_INSTRUCTION_MESSAGE = "SHOW_TEXT_INSTRUCTIONS_MESSAGE";
export const CLEAR_TEXT_INSTRUCTION_MESSAGE = "CLEAR_TEXT_INSTRUCTIONS_MESSAGE";

export const OPEN_NO_ONE_IN_CIRCLE_MODAL = "OPEN_NO_ONE_IN_CIRCLE_MODAL";
export const CLOSE_NO_ONE_IN_CIRCLE_MODAL = "CLOSE_NO_ONE_IN_CIRCLE_MODAL";


export function setUserAudio() {
  return {
    type: SET_USER_AUDIO
  };
}

export function clearUserAudio() {
  return {
    type: CLEAR_USER_AUDIO
  };
}

export function showUserAudioMessage() {
  return {
    type: SHOW_USER_AUDIO_MESSAGE
  };
}

export function clearUserAudioMessage() {
  return {
    type: CLEAR_USER_AUDIO_MESSAGE
  };
}

export function showTextInstructionMessage(message) {
  return {
    type: SHOW_TEXT_INSTRUCTION_MESSAGE,
    message
  };
}

export function clearTextInstructionMessage() {
  return {
    type: CLEAR_TEXT_INSTRUCTION_MESSAGE
  };
}


export function openAuthModal() {
  return {
    type: OPEN_AUTH_MODAL
  };
}

export function closeAuthModal() {
  return {
    type: CLOSE_AUTH_MODAL
  };
}

export function openSocialAuthModal() {
  return {
    type: OPEN_SOCIAL_AUTH_MODAL
  };
}

export function closeSocialAuthModal() {
  return {
    type: CLOSE_SOCIAL_AUTH_MODAL
  };
}

export function setAuthModalTypeLogin() {
  return {
    type: SET_AUTH_MODAL_TYPE_LOGIN
  };
}

export function setAuthModalTypeRegister() {
  return {
    type: SET_AUTH_MODAL_TYPE_REGISTER
  };
}

export function showRegistrationConfirmation() {
  return {
    type: SHOW_REGISTRATION_CONFIRMATION
  };
}

export function hideRegistrationConfirmation() {
  return {
    type: HIDE_REGISTRATION_CONFIRMATION
  };
}

export function showMatchmakerTimeoutAlert() {
  return {
    type: SHOW_MATCHMAKER_TIMEOUT_ALERT
  };
}

export function hideMatchmakerTimeoutAlert() {
  return {
    type: HIDE_MATCHMAKER_TIMEOUT_ALERT
  };
}

export function showConnectionControls() {
  return {
    type: SHOW_CONNECTION_CONTROLS
  };
}

export function hideConnectionControls() {
  return {
    type: HIDE_CONNECTION_CONTROLS
  };
}

export function setRegisterGatheringAfterLogin() {
  return {
    type: SET_REGISTER_GATHERING_AFTER_LOGIN
  };
}

export function clearRegisterGatheringAfterLogin() {
  return {
    type: CLEAR_REGISTER_GATHERING_AFTER_LOGIN
  };
}

export function setRegisterEmailsAfterLogin() {
  return {
    type: SET_REGISTER_EMAILS_AFTER_LOGIN
  };
}

export function clearRegisterEmailsAfterLogin() {
  return {
    type: CLEAR_REGISTER_EMAILS_AFTER_LOGIN
  };
}

export function setCollapseTerms() {
  return {
    type: SET_COLLAPSE_TERMS
  };
}

export function setCollapsePrivacy() {
  return {
    type: SET_COLLAPSE_PRIVACY
  };
}

export function setCollapseGathering() {
  return {
    type: SET_COLLAPSE_GATHERING
  };
}

export function setCollapseClosed() {
  return {
    type: SET_COLLAPSE_CLOSED
  };
}

export function setEmailFromLink(data) {
  return {
    type: SET_EMAIL_FROM_LINK,
    data
  };
}

export function clearEmailFromLink() {
  return {
    type: CLEAR_EMAIL_FROM_LINK
  };
}

export function setAuthModalForm() {
  return {
    type: SET_AUTH_MODAL_FORM
  };
}

export function clearAuthModalForm() {
  return {
    type: CLEAR_AUTH_MODAL_FORM
  };
}

export function setLoginWithEmail() {
  return {
    type: SET_LOGIN_WITH_EMAIL
  };
}

export function clearLoginWithEmail() {
  return {
    type: CLEAR_LOGIN_WITH_EMAIL
  };
}

export function setAuthData(data) {
  return {
    type: SET_AUTH_DATA,
    data
  };
}

export function clearAuthData() {
  return {
    type: CLEAR_AUTH_DATA
  };
}

export function setAlreadyRegisterWithEmail() {
  return {
    type: SET_ALREADY_REGISTER_WITH_EMAIL
  };
}

export function clearAlreadyRegisterWithEmail() {
  return {
    type: CLEAR_ALREADY_REGISTER_WITH_EMAIL
  };
}

export function setAuthEventsCategory(data) {
  return {
    type: SET_AUTH_EVENTS_CATEGORY,
    data
  };
}

export function clearAuthEventsCategory() {
  return {
    type: CLEAR_AUTH_EVENTS_CATEGORY
  };
}

export function openNoOneInCircleModal() {
  return {
    type: OPEN_NO_ONE_IN_CIRCLE_MODAL
  };
}

export function closeNoOneInCircleModal() {
  return {
    type: CLOSE_NO_ONE_IN_CIRCLE_MODAL
  };
}
