import constants from "../constants";

export const GET_SELECTED_EXPERIENCE_DATA_START = "GET_SELECTED_EXPERIENCE_DATA_START";
export const GET_SELECTED_EXPERIENCE_DATA_FAILURE = "GET_SELECTED_EXPERIENCE_DATA_FAILURE";
export const GET_SELECTED_EXPERIENCE_DATA_SUCCESS = "GET_SELECTED_EXPERIENCE_DATA_SUCCESS";
export const CLEAR_SELECTED_EXPERIENCE = "CLEAR_SELECTED_EXPERIENCE";


export function getSelectedExperienceDataStart() {
  return {
    type: GET_SELECTED_EXPERIENCE_DATA_START
  };
}

export function getSelectedExperienceDataFailure(error) {
  return {
    type: GET_SELECTED_EXPERIENCE_DATA_FAILURE,
    error
  };
}

export function getSelectedExperienceDataSuccess(data) {
  return {
    type: GET_SELECTED_EXPERIENCE_DATA_SUCCESS,
    data
  };
}

export function clearSelectedExperienceData() {
  return {
    type: CLEAR_SELECTED_EXPERIENCE
  };
}


export function getSelectedExperienceData(type, position) {
  const config = {
    method: "POST",
    headers: {
      "Authorization": `bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `type=${type}&position=${position}`
  };

  return (dispatch) => {
  	dispatch(getSelectedExperienceDataStart())
    return fetch(`${constants.baseUrl}/api/v1/experiences/selectedexperiencedata`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
       dispatch(getSelectedExperienceDataSuccess(response))
      })
      .catch(err => {
        console.error("getExperiences error: ", err);
        dispatch(getSelectedExperienceDataFailure(err))
      });
  }
}