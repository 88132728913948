import * as Actions from "../actions/facilitator";

const initialState = {
	ready: false,
    name: "",
    title: "",
    pronouns: "",
    bio: "",
    imageUrl: "",
    outboundLink: "",
    experiences: [],
    isFetching: false,
    error: null
};

export function facilitator(state = initialState, action) {
    switch (action.type) {
        case Actions.GET_FACILITATOR_START:
            return {
			    ready: false,
			    name: "",
                title: "",
                pronouns: "",
			    bio: "",
			    imageUrl: "",
                outboundLink: "",
			    experiences: [],
                isFetching: true,
                error: null
            };
        case Actions.GET_FACILITATOR_SUCCESS:
            return {
			    ready: true,
			    name: action.data.name,
                title: action.data.title,
                pronouns: action.data.pronouns,
			    bio: action.data.bio,
			    imageUrl: action.data.imageUrl,
                outboundLink: action.data.outboundLink,
			    experiences: action.data.experiences,
                isFetching: false,
                error: null
            };
        case Actions.GET_FACILITATOR_FAILURE:
            return {
                ...state,
                ready: true,
                isFetching: false,
                error: action.error
            };
        case Actions.CLEAR_FACILITATOR_DATA:
            return {
			    ready: false,
			    name: "",
                title: "",
                pronouns: "",
			    bio: "",
			    imageUrl: "",
                outboundLink: "",
			    experiences: [],
                isFetching: false,
                error: null
            };
        default:
            return state;
    }
}