import * as Actions from "../actions/fdTest";

const initialState = {
  testDone: false,
  webrtcAvailable: false,
};

export function fdTest(state = initialState, action) {
  switch (action.type) {
    case Actions.FD_TEST_POSITIVE:
      return {
        testDone: true,
			  webrtcAvailable: true,
			};
    case Actions.FD_TEST_NEGATIVE:
      return {
        testDone: true,
  			webrtcAvailable: false,
      };
    default:
      return state;
  }
}