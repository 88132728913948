import React from 'react';
import '../ActiveExperienceStyles.css';
import textResource from '../../../lib/textLoader';
import Spinner from '../../elements/Spinner';
import CrossImg from '../../../resources/images/cross.png'


class PreparingContent extends React.Component {
	constructor() {
		super();

		this.showTimeout = null;
		this.guidelineTimeOut = null;

		this.setShowTimeout = this.setShowTimeout.bind(this);
		this.clearShowTimeout = this.clearShowTimeout.bind(this);
		this.setGuidelineTimeout = this.setGuidelineTimeout.bind(this);
		this.clearGuidelineTimeout = this.clearGuidelineTimeout.bind(this);
		this.hideGuidelineCard = this.hideGuidelineCard.bind(this);
		this.hideGuidelineCardOnCancel = this.hideGuidelineCardOnCancel.bind(this);
		

		this.state = {
			show: false,
			showGuideline: false
		}
	}

	componentDidMount() {

		if(this.props.userRole.showPositionGuideline) {
			this.setState({ showGuideline: true })
			this.setGuidelineTimeout();
		}

	}

	componentDidUpdate(prevProps){

		if (!prevProps.peerReady && this.props.peerReady) {
			this.setShowTimeout();
		}
	}

	componentWillUnmount() {
		this.clearShowTimeout();
		this.clearGuidelineTimeout();
	}

	setShowTimeout() {
		this.showTimeout = setTimeout(() => {
			this.setState({show: true})	
		}, 5000);
	}

	clearShowTimeout() {
		if (this.showTimeout) {
			clearTimeout(this.showTimeout);			
		}
		this.showTimeout = null;
	}

	setGuidelineTimeout() {
		this.guidelineTimeOut = setTimeout(() => {
		  this.hideGuidelineCard()
		}, 10000);
	}

	clearGuidelineTimeout() {
		if (this.guidelineTimeOut) {
			clearTimeout(this.guidelineTimeOut);			
		}
		this.guidelineTimeOut = null;
	}
	
	hideGuidelineCard() {
		this.clearGuidelineTimeout();
		this.setState({ showGuideline: false })
	}

	hideGuidelineCardOnCancel() {
		if (this.props.userRole.email !== "experience-guest") {
			this.props.permanentlyHidePositionGuideline();
		}		
		this.hideGuidelineCard();
	}

	render() {
		return (
			<div>
				{
					!!(this.state.show && this.props.peerReady) &&
					<div className="low-text">
						<p>{textResource["before_call.other_ready"]}</p>
						<p>{textResource["before_call.press_start"]}</p>
					</div>					
				}
				{
					!!(this.state.showGuideline && !this.state.show) &&
					<div className="low-text">
						<img src={CrossImg} onClick={this.hideGuidelineCardOnCancel}/>
						<p>{textResource["before_call.hide_guideline_card.content"]}</p>
					</div>					
				}
			</div>
		)		
	}
}

export default PreparingContent;