import React from 'react';
import textResource from '../../../lib/textLoader';
import ConnectButtonContainer from '../../ConnectionControls/ConnectButtonContainer';
import Spinner from "../../elements/Spinner"


class WaitingState extends React.Component {
    

    render() {
        let { selectedLanguage, userRole, userState } = this.props;
        return(
            <div className="waitingStateContainer" style={{position: 'relative', paddingTop: 24, zIndex: 1}}>
                <p className="someone-text">{textResource[`video_area.finding_someone.${selectedLanguage}`]}</p>
                <span className="spincontainer">
                    <Spinner />
                </span>

            </div>
        )
    }
}

export default WaitingState;