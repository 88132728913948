import React from 'react';
import { Row } from 'reactstrap';
import MatchmakerContainer from '../imperative/MatchmakerContainer';
import CancelButtonContainer from '../ConnectionControls/CancelButtonContainer';
import ActiveExperienceContainer from '../ActiveExperience/ActiveExperienceContainer';
import textResource from '../../lib/textLoader';
import { Redirect } from 'react-router-dom';
import ConnectButtonContainer from '../ConnectionControls/ConnectButtonContainer';
import ExperiencesAreaContainer from './ExperiencesAreaContainer';

class BetaExperiencesPage extends React.Component {

	render() {
    let { userState, userRole } = this.props;

    if (!userRole.betaExperienceAccess) { return <Redirect to='/' /> }

    if(!["present", "absent", "waiting"].includes(this.props.userState)) {
      return <Redirect to='/experience' />
    }

		return (	
            <div>
              <ExperiencesAreaContainer experienceStatus="beta"/>
            </div>
		)	
	}
}

export default BetaExperiencesPage;