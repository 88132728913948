import React from 'react';
import textResource from '../../lib/textLoader';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoginFormContainer from './LoginFormContainer';
import RegisterFormContainer from './RegisterFormContainer';
import AdditionalInfoFormContainer from './AdditionalInfoFormContainer';
import sendGAEvent from '../../lib/sendGAEvent';
import SocialLoginContainer from './SocialLoginContainer';

const modalHeader = (formType, userRole) => {
  if (formType === "login") {
    return textResource["sign_in.title"];
  } else {
    if (!userRole.registeringStage) {
      return textResource["join.title"];
    } else {
      return textResource["waitlist.title"];
    }
  } 
}

const modalBody = (formType, userRole, authModalForm, loginWithEmail) => {
  let stage = userRole.registeringStage;
  if (formType === "login") {
    return (
      <div>
        {!loginWithEmail && <SocialLoginContainer />}
        {(authModalForm || loginWithEmail) && <LoginFormContainer />}
      </div>
    )
  } else {
    if (!stage) {
      return (
        <div>
          {!loginWithEmail && <SocialLoginContainer />}
          {(authModalForm || loginWithEmail) && <RegisterFormContainer />}
        </div>
      );
    } else {
      if (stage === "sentCredentials") {
        return <AdditionalInfoFormContainer />;
      } else {
        return (
          <p>
            {textResource["waitlist.already_sent_info"]}
          </p>
        )
      }      
    }
  } 
}

const modalFooter = (formType, onClick) => {
  if (formType === "login") {
    return (
      <div className="w-100 mt-2">
        <div className="textmodalfooter">{textResource["sign_in.new_to_hm"]}</div>
        <Button id="BtnRegModal" color="secondary" onClick={onClick}>
          {textResource["sign_in.new_to_hm.create_account_link"]}
        </Button>
      </div>    
    )
  } else {
    return (
      <div className="w-100 mt-2">
        <div className="textmodalfooter">
          {textResource["join.already_have_account"]}
        </div>
        <a href="/" className="btn btn-secondary" onClick={onClick}>
            {textResource["join.already_have_account.sign_in_link"]}
        </a>
      </div> 
    )
  }
}

class AuthModal extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.changeType = this.changeType.bind(this);
  }

  componentDidUpdate(prevProps){
    if (!prevProps.userRole.registeringStage && this.props.userRole.registeringStage) {
      if (prevProps.authModalType === "login") {
        this.props.setAuthModalTypeRegister(); 
      }
    }
    if (prevProps.userRole.role === "guest" && this.props.userRole.role !== "guest") {
      this.props.closeAuthModal();
    }
    if (!prevProps.userRole.needsVerification && this.props.userRole.needsVerification) {
      this.props.closeAuthModal();
    }
    if (prevProps.userRole.registeringStage !== "sentInfo" && this.props.userRole.registeringStage === "sentInfo") {
      this.props.closeAuthModal();
    }
    if (prevProps.userRole.registeringStage !== "sentCredentials" && this.props.userRole.registeringStage === "sentCredentials") {
      this.props.openAuthModal();
    }
    if (!prevProps.emailFromLink && this.props.emailFromLink) {
      this.props.setAuthModalTypeRegister(); 
    }
  }

  componentWillUnmount() {
    this.props.closeAuthModal();
  }

  toggle() {
    if (this.props.authModalOpen) {
      this.props.closeAuthModal();
      this.props.clearAuthModalForm();
      this.props.clearLoginWithEmail();
      this.props.clearAuthData();
      sendGAEvent(this.props.authEventsCategory, 'Close', '');
    } else {
      this.props.openAuthModal();
    }
  }

  changeType(e) {
    e.preventDefault();
    this.props.clearFacebookError();
  	if (this.props.authModalType === "login") {
	    this.props.setAuthModalTypeRegister();
      sendGAEvent(this.props.authEventsCategory, 'Open from modal', 'Create account');
  	} else {
	    this.props.setAuthModalTypeLogin();
      sendGAEvent(this.props.authEventsCategory, 'Open from modal', 'Sign in');
  	}
  }



  render() {
    if (this.props.userRole.role !== "guest" && !["present", "absent", "waiting"].includes(this.props.userState)) { return null; }

    // This was meant to redirect user to lobby after login. Interferes with lobby on other pathnames such as /testing/testrtc
    // if (this.props.authModalOpen && this.props.userRole.role !== "guest" && this.props.location.pathname !== "/") {
    //   return <Redirect to='/' />;
    // }
    return (
      <div>
        <Modal isOpen={this.props.authModalOpen} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>
          	{modalHeader(this.props.authModalType, this.props.userRole)}
          </ModalHeader>
          <ModalBody style={{"textAlign": "left"}}>
            {modalBody(this.props.authModalType, this.props.userRole, this.props.authModalForm, this.props.loginWithEmail)}
          </ModalBody>
          <ModalFooter>
          	{modalFooter(this.props.authModalType, this.changeType)}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AuthModal;