import * as Actions from "../actions/registeringFromInvitation";
import * as UserRoleActions from "../actions/userRole";

const initialState = {
  preapprovedEmail: "",
  name: "",
  invitationHash: "",
  hashCheckReady: false,
  hashCheckSuccess: false,
  isFetching: false,
  error: null
};

export function registeringFromInvitation(state = initialState, action) {
  switch (action.type) {
    case Actions.CHECK_HASH_START:
      return {
			  ...state,
			  invitationHash: action.data.invitationHash, 
			  isFetching: true,
			  error: null
			};
    case UserRoleActions.AUTH_SUCCESS:
      return {
			  preapprovedEmail: "",
			  name: "",
			  invitationHash: "",
			  hashCheckReady: false,
			  hashCheckSuccess: false,
			  isFetching: false,
			  error: null
			};
    case Actions.CHECK_HASH_FAILURE:
      return {
			  ...state, 
			  hashCheckReady: true,
			  isFetching: false,
			  error: action.error
			};
    case Actions.CHECK_HASH_SUCCESS:
      return {
      	...state,      	
			  preapprovedEmail: action.data.email,
			  name: action.data.name,
			  hashCheckReady: true,
			  hashCheckSuccess: true,
			  isFetching: false,
			  error: null
			};
    default:
      return state;
  }
}