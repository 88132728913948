import { connect } from 'react-redux';
import PresentState from './PresentState';
import { matchmakerSuccess } from '../../../actions/peerRoom';
import { clickConnectExperienceInvite, clickCancelExperienceInvite } from '../../../actions/experienceInvitation';


const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    peerRoom: state.peerRoom,
    experienceInvitation: state.experienceInvitation,
    realtime: state.realtime
  };
};

const mapDispatchtoProps = {
    matchmakerSuccess,
    clickConnectExperienceInvite,
    clickCancelExperienceInvite
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(PresentState);