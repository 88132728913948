import * as Actions from "../actions/userRole";
import { OPEN_SOCIAL_AUTH_MODAL, SET_ALREADY_REGISTER_WITH_EMAIL } from "../actions/uiControls";
import { endConnectionReasons } from "../actions/endConnectionReason";
import * as UserStateActions from "../actions/userState";
import * as SubscriptionActions from "../actions/subscription";
import * as MyCircleActions from "../actions/myCircle";

const initialState = {
  role: "guest",
  email: "",
  name: "",
  solicitedEmails: false,
  registeringStage: "",
  needsVerification: false,
  completedSession: false,
  hasTriedToConnect: false,
  accountSuspended: false,
  isFetching: false,
  ready: true,
  error: null,
  referrerUrl: '',
  currentUrl: '',
  completedHumanMinutes: null,
  betaExperienceAccess: false,
  facebookID: '',
  isPasswordExist: false,
  fbPasswordExistError: false,
  showPositionGuideline: true,
  thanksReceived: null,
  myCircleMatched: 0,
  sessionManuallyEnded: null,
  HMGuidance1Accepted: false,
  userId: '',
  subscription: {
    activeSubscription: false,
    initialPaymentSucceeded: false,
    subscriptionAmount: 0,
    pendingInvoice: "",
    subscriptionCancelsAt: 0,
    trialUsed: false,
    trialEnds: 0,
    freeExperienceUsed: false
  }
};

export function userRole(state = initialState, action) {
  switch (action.type) {
    case Actions.AUTH_START:
      return {
        ...state,
        role: "guest",
        email: "",
        name: "",
        solicitedEmails: false,
        registeringStage: "",
        needsVerification: false,
        completedSession: false,
        hasTriedToConnect: false,
        accountSuspended: false,
        fbPasswordExistError: false,
        isFetching: true,
        ready: false,
        error: null,
        
      };
    case OPEN_SOCIAL_AUTH_MODAL: 
      return {
        ...state,
        isFetching: false,
        ready: true,
      }
    case SET_ALREADY_REGISTER_WITH_EMAIL: 
      return {
        ...state,
        isFetching: false,
        ready: true,
      }
    case Actions.AUTH_FAILURE:
      return {
			  ...state,
  			isFetching: false,
        ready: true,
        error: action.error
      };
    case Actions.SET_STATE_ROLE_READY:
      return {
        ...state,
        role: "guest",
        email: "",
        name: "",
        solicitedEmails: false,
        registeringStage: "",
        needsVerification: false,
        completedSession: false,
        hasTriedToConnect: false,
        accountSuspended: false,
        isFetching: false,
        ready: true,
        error: null
      };

    case Actions.SET_TRIED_CONNECT_TRUE:  
      return {  
        ...state, 
        hasTriedToConnect: true,  
      };
    case Actions.SET_SOLICITED_EMAILS_TRUE:
      return {
        ...state,
        solicitedEmails: true,
      };
    case Actions.SET_NEEDS_VERIFICATION_FALSE:
      return {
        ...state,
        needsVerification: false,
      };
    case Actions.AUTH_SUCCESS:
      return {
        ...state,
        role: action.data.role,
        email: action.data.email,
        name: action.data.name,
        solicitedEmails: action.data.solicitedEmails,
        registeringStage: action.data.registeringStage,
        needsVerification: action.data.needsVerification,
        completedSession: action.data.completedSession,
        hasTriedToConnect: action.data.hasTriedToConnect,
        accountSuspended: action.data.accountSuspended,
        completedHumanMinutes: action.data.completedHumanMinutes,
        betaExperienceAccess: action.data.betaExperienceAccess,
        subscription: action.data.subscription,
        facebookID: action.data.facebookID,
        isPasswordExist: action.data.isPasswordExist,
        showPositionGuideline: action.data.showPositionGuideline,
        thanksReceived: action.data.thanksReceived,
        myCircleMatched: action.data.myCircleMatched,
        sessionManuallyEnded: action.data.sessionManuallyEnded,
        HMGuidance1Accepted: action.data.HMGuidance1Accepted,
        userId: action.data.userId,
        fbPasswordExistError: false,
        isFetching: false,
        ready: true,
        error: null
      };
    case Actions.SET_ACCEPTED_GUIDANCE:
      return {
        ...state,
        HMGuidance1Accepted: true
      }
    case Actions.SET_ISPASSWORD_EXIST:
      return {
        ...state,
        isPasswordExist: true
      }
    case Actions.SET_FB_PASSWORD_EXIST_ERROR:
      return {
        ...state,
        fbPasswordExistError: true,
        isFetching: false,
        ready: true,
      }
    case Actions.ADD_COMPLETED_HUMAN_MINUTE:
      return {
        ...state,
        completedHumanMinutes: state.completedHumanMinutes + 1
      }
    case Actions.SET_REFERRER_URL:  
      return {  
        ...state, 
        referrerUrl: action.data
      };
    case Actions.SET_CURRENT_URL:
      return {
        ...state,
        currentUrl: action.data
      };
    case Actions.HIDE_POSITION_GUIDELINE:
      return {
        ...state,
        showPositionGuideline: false
      };
    case Actions.LOGOUT:
      return {
        role: "guest",
        email: "",
        name: "",
        solicitedEmails: false,
        registeringStage: "",
        needsVerification: false,
        completedSession: false,
        hasTriedToConnect: false,
        accountSuspended: false,
        isFetching: false,
        ready: true,
        error: null,
        referrerUrl: '',
        currentUrl: '',
        completedHumanMinutes: null,
        betaExperienceAccess: false,
        facebookID: '',
        isPasswordExist: false,
        fbPasswordExistError: false,
        showPositionGuideline: true,
        thanksReceived: null,
        myCircleMatched: 0,
        sessionManuallyEnded: null,
        acceptedExperiencesGuidance: {},
        HMGuidance1Accepted: false,
        userId: '',
        subscription: {
          activeSubscription: false,
          initialPaymentSucceeded: false,
          subscriptionAmount: 0,
          pendingInvoice: "",
          subscriptionCancelsAt: 0,
          trialUsed: false,
          trialEnds: 0,
          freeExperienceUsed: false
        }
      };
    case UserStateActions.SET_STATE_DISCONNECTED:
      if (action.payload === endConnectionReasons.NATURAL) {
        return {
        ...state,
        completedSession: true
        };
      }
      return state;
    case Actions.SET_FREE_EXPERIENCE_USED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          freeExperienceUsed: true
        }
      };
    case Actions.SET_EXISTING_SUBSCRIPTION_AMOUNT:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          subscriptionAmount: action.amount
        }
      };
    case SubscriptionActions.SET_PENDING_INVOICE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          pendingInvoice: action.data
        }
      }
    case SubscriptionActions.SUBSCRIPTION_SUCCESS:
      let initialPaymentSucceeded = state.subscription.trialUsed ? true : false;
      return {
        ...state,
        subscription: {
          ...state.subscription,
          initialPaymentSucceeded,
          activeSubscription: true,
          pendingInvoice: ""
        }
      };
    case SubscriptionActions.SUBSCRIPTION_CANCEL_SUCCESS:
      return {
        ...state,
        subscription: action.data
      };
    case SubscriptionActions.RECOVER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.data
      };
    case MyCircleActions.ADD_TO_MY_CIRCLE_COUNT:
      return {
        ...state,
        myCircleMatched: state.myCircleMatched + 1
      };
    default:
      return state;
  }
}
