import React from 'react';
import textResource from '../../lib/textLoader';
import { Button } from 'reactstrap';
import '../ActiveExperience/ActiveExperienceStyles.css'
import sendGAEvent from '../../lib/sendGAEvent';
import Spinner from "../elements/Spinner";
import SignInButtonContainer from './SignInButtonContainer';
import { connectClick } from './lib/connectClick';


const buttonLabel = (poolType, waitingCount, experienceType, userSubscription) => {
	let waitingText = "";
	if (waitingCount) {
		waitingText = ` (${waitingCount})`
	}
	if (experienceType !== "oneminute" && !userSubscription.activeSubscription && !userSubscription.freeExperienceUsed) {
		return `Try one experience free${waitingText}`
	}
	if (poolType === "anyone") {
		return `Connect with anyone${waitingText}`
	}
	if (poolType === "myCircle") {
		return `Someone from my circle${waitingText}`
	}
}

const ConnectButton = ({userState, userRole, fdTest, hasCancelled, accountSuspended, realtime, setExperienceType, experienceType, setStateWaiting, selectedExperienceData, peerRoom, waitingCount, poolType, setPoolType, setStateFirstTimeGuidance, openNoOneInCircleModal, disabledFromProps}) => {

	if (!userRole.ready) {return null;}

	if (userRole.role === "guest") {
		return <SignInButtonContainer />
	} 

	if (fdTest.testDone && !fdTest.webrtcAvailable) {return null;} 

	let disabled=false;
	if (disabledFromProps || hasCancelled || accountSuspended || !realtime ) { 
		disabled =true;
	}
	
	if ((!["present", "absent"].includes(userState) && peerRoom.experienceType === experienceType && poolType === peerRoom.poolType)) { return null; }

	let color = "default";
	if (poolType === "anyone" && experienceType !== "oneminute") {
		color = "primary";
	}

  return (
		<Button id="BtnConnect" size="lg" color={color} onClick={() => connectClick(userRole, setExperienceType, experienceType, setStateWaiting, selectedExperienceData, poolType, setPoolType, setStateFirstTimeGuidance, openNoOneInCircleModal)} disabled={disabled}>
			{buttonLabel(poolType, waitingCount, experienceType, userRole.subscription)}
		</Button>
  )
}

export default ConnectButton;