import { connect } from 'react-redux';
import EmailTooltip from './EmailTooltip';


const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};


export default connect(
  mapStateToProps,
  null
)(EmailTooltip);