import React from 'react';
import { Redirect } from 'react-router-dom';
import LayoutWrapper from '../elements/LayoutWrapper';
import sendGAEvent from '../../lib/sendGAEvent';
import textResource from '../../lib/textLoader';
import { Button } from 'reactstrap';
import { sendSurveyRequest } from '../../actions/surveyAnswer';
import { connect } from 'react-redux';

class EmailResponse extends React.Component {
  constructor(){
    super();
    this.state = {
      redirect: false
    }
  }

	componentDidMount() {
    let { response, surveyNum } = this.props.match.params;
    sendGAEvent(`Email response ${surveyNum}`, response)
    this.props.sendSurveyRequest(surveyNum,response)			
  }
  
  goBackHome = (label) => {
    sendGAEvent('Email response', 'Return Home', label)
    this.setState({ redirect: true })
  }

  render() {
    let { response, surveyNum } = this.props.match.params;
    if (this.state.redirect) { return <Redirect to='/' /> }
    
    if(response === 'yes') {
      return (
        <LayoutWrapper>
          <h1 className="pb-3 text-center">{textResource["email_response.thankyou"]}</h1>
          <p>{textResource["email_response.thankyou_for_telling_us"]}</p>
          <p>{textResource["email_response.contact_text"]}</p>
          <div className="text-center pt-5 mt-5">
            <Button size="lg" color="primary" onClick={this.goBackHome.bind(this,`scrResponse${surveyNum}Yes`)}>
              {textResource["email_response.return_home_btn"]}
            </Button>
          </div>
        </LayoutWrapper>
      )
    }

    return(
      <LayoutWrapper>
        <h1 className="pb-3 text-center">{textResource["email_response.thankyou"]}</h1>
        <p>{textResource["email_response.thankyou_for_telling_us"]}</p>
        <div className="text-center pt-5 mt-5">
          <Button size="lg" color="primary" onClick={this.goBackHome.bind(this,`scrResponse${surveyNum}No`)}>
            {textResource["email_response.return_home_btn"]}
          </Button>
        </div>
      </LayoutWrapper>
    )
  }
}

const mapDispatchtoProps = {
	sendSurveyRequest
};


export default connect(
  null,
  mapDispatchtoProps
)(EmailResponse);