import constants from "../constants";

export const GET_FACILITATOR_START = "GET_FACILITATOR_START";
export const GET_FACILITATOR_SUCCESS = "GET_FACILITATOR_SUCCESS";
export const GET_FACILITATOR_FAILURE = "GET_FACILITATOR_FAILURE";
export const CLEAR_FACILITATOR_DATA = "CLEAR_FACILITATOR_DATA";

export function getFacilitatorStart() {
    return {
      type: GET_FACILITATOR_START,
    };
}

export function getFacilitatorSuccess(data) {
    return {
        type: GET_FACILITATOR_SUCCESS,
        data
    };
}

export function getFacilitatorFailure(error) {
    return {
        type: GET_FACILITATOR_FAILURE,
        error
    };
}

export function clearFacilitatorData() {
    return {
      type: CLEAR_FACILITATOR_DATA,
    };
}

export function getFacilitatorData(name) {
    const config = {
      method: "POST",
      headers: {
        "Authorization": `bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `name=${name}`
    };
  
    return (dispatch) => {
      dispatch(getFacilitatorStart())
      return fetch(`${constants.baseUrl}/api/v1/experiences/getfacilitator`, config)
        .then(res => res.json())
        .then(response => {
          if (response.error) {
            throw new Error(`Error: ${response.error}`);
          }
          dispatch(getFacilitatorSuccess(response))
        })
        .catch(err => {
          console.error("getExperiences error: ", err);
          dispatch(getFacilitatorFailure(err))
        });
    }
}