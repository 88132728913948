import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const WelcomeToGathering = ({currentGathering, location, userRole}) => {

	if (currentGathering.isRegular) {
		if (!userRole.hasTriedToConnect) { return null;}
  	return (
	  	<div style={{marginTop: "40px", marginBottom: "40px"}}>
	      <h3>Welcome to today's gathering</h3><br/>
	      <p>However you are, that’s the way to be here.</p>
	      <p>When you’re ready to meet someone, press Connect.</p>
	    </div>
	  )
	}

	if (!currentGathering.groupID) {
		console.log(currentGathering);
  	return (
	  	<div style={{marginTop: "40px", marginBottom: "40px"}}>
	      <h3>{currentGathering.welcomeStatement}</h3><br/>
	      <p>However you are, that’s the way to be here.</p>
	      <p>When you’re ready to meet someone, press Connect.</p>
	    </div>
	  )
	}

	if (location.pathname === `/groupspace/${currentGathering.groupID}`) { 
		return (
	  	<div style={{marginTop: "40px", marginBottom: "40px"}}>
	      <h3>{currentGathering.welcomeStatement}</h3>
				<p className="lead">This is a private space for participants in this event</p>
	    </div>
	  )
	}

  return (
  	<div style={{marginTop: "40px", marginBottom: "40px"}}>
      <h3>{currentGathering.name} has started</h3>
		  <Link to={`/groupspace/${currentGathering.groupID}`} style={{ textDecoration: "none", color: "black" }}>
	  		<Button color="primary" >
	  			Join gathering
	    	</Button>
    	</Link>
    </div>
  )


};

export default WelcomeToGathering;
