import constants from '../constants';

export const SET_PARTNER_IS_IN_MY_CIRCLE = "SET_PARTNER_IS_IN_MY_CIRCLE";
export const CIRCLE_ACTION_START = "CIRCLE_ACTION_START";
export const CIRCLE_ACTION_FAILURE = "CIRCLE_ACTION_FAILURE";
export const ADD_TO_CIRCLE_SUCCESS = "ADD_TO_CIRCLE_SUCCESS";
export const REMOVE_FROM_CIRCLE_SUCCESS = "REMOVE_FROM_CIRCLE_SUCCESS";
export const SET_MY_CIRCLE_ADDED_BY_OTHER = "SET_MY_CIRCLE_ADDED_BY_OTHER";

export const ADD_TO_MY_CIRCLE_COUNT = "ADD_TO_MY_CIRCLE_COUNT";

export const SET_OTHER_IS_GUEST = "SET_OTHER_IS_GUEST";


export function circleActionStart() {
  return {
    type: CIRCLE_ACTION_START
  };
}

export function circleActionFailure() {
  return {
    type: CIRCLE_ACTION_FAILURE
  };
}

export function setPartnerIsInMyCircle(data) {
  return {
    type: SET_PARTNER_IS_IN_MY_CIRCLE,
    data
  }
}

export function addToCircleSuccess() {
  return {
    type: ADD_TO_CIRCLE_SUCCESS
  };
}

export function removeFromCircleSuccess() {
  return {
    type: REMOVE_FROM_CIRCLE_SUCCESS
  };
}

export function setMyCircleAddedByOther() {
  return {
    type: SET_MY_CIRCLE_ADDED_BY_OTHER,
  }
}

export function addToMyCircleCount() {
  return {
    type: ADD_TO_MY_CIRCLE_COUNT
  };
}

export function setOtherIsGuest() {
  return {
    type: SET_OTHER_IS_GUEST
  };
}


export function addUserToMyCircle(roomID, position) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `bearer ${localStorage.getItem("token")}`
    },
    body: `roomID=${roomID}&position=${position}`
  };
  return dispatch => {
  	dispatch(circleActionStart());
    fetch(`${constants.baseUrl}/api/v1/friends/addtomycircle`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        dispatch(addToCircleSuccess())
      })
      .catch(err => {
        console.log(err);
        dispatch(circleActionFailure())
      });
  };
}

export function checkUserIsInMyCircle(roomID, position) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `bearer ${localStorage.getItem("token")}`
    },
    body: `roomID=${roomID}&position=${position}`
  };
  return dispatch => {
  	dispatch(circleActionStart());
    fetch(`${constants.baseUrl}/api/v1/friends/checkpartnercircle`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (response.otherIsGuest) {
          dispatch(setOtherIsGuest())
        } else {
          dispatch(setPartnerIsInMyCircle(response))
        }        
      })
      .catch(err => {
        console.log(err);
        dispatch(circleActionFailure());
      });
  };
}

export function removeUserFromMyCircle(roomID, position) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `bearer ${localStorage.getItem("token")}`
    },
    body: `roomID=${roomID}&position=${position}`
  };
  return dispatch => {
  	dispatch(circleActionStart());
    fetch(`${constants.baseUrl}/api/v1/friends/removefrommycircle`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        dispatch(removeFromCircleSuccess())
      })
      .catch(err => {
        console.log(err);
        dispatch(circleActionFailure());
      });
  };
}
