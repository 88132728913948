import React from 'react';
import { Link } from 'react-router-dom';
import textResource from '../../lib/textLoader';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import ValidationErrorMessage from './ValidationErrorMessage';
import ErrorMessage from './ErrorMessage';
import "./AuthModal.css"
import FacebookErrorMessage from './FacebookErrorMessage';
import Spinner from '../elements/BlackSpinner';

export default class LoginForm extends React.Component {
  constructor(){
    super();
    this.state = {
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(key,event) {
    let { authData } = this.props;
    authData[key] = event.target.value
    this.props.setAuthData(authData)
  }
  render() {
    let { onSubmit, errors, userRole, authData } = this.props;
    return (
      <Form id="FormSignIn" onSubmit={onSubmit}>
        {userRole && !userRole.fbPasswordExistError ?
        <ErrorMessage errors={errors} /> : 
        <FacebookErrorMessage errors={errors} closeAuthModal={this.props.closeAuthModal}/>}
        <FormGroup>
          <Label for="email">
            {textResource["sign_in.email"]}
          </Label>
          <Input 
            invalid={!!errors.email}
            type="text" 
            name="email" 
            id="loginEmail" 
            defaultValue={authData.email}
            onChange={(event) => this.handleChange('email',event)}
          />
          <ValidationErrorMessage message={errors.email} />
        </FormGroup>
        <FormGroup>
          <Label for="password">
            {textResource["sign_in.password"]}
          </Label>
          <Input 
            invalid={!!errors.password}
            type="password" 
            name="password" 
            id="loginPassword" 
            defaultValue={authData.password}
            onChange={(event) => this.handleChange('password',event)}
          />
          <ValidationErrorMessage message={errors.password} />
        </FormGroup>
        <Link to="/requestrecoverpassword" style={{textDecoration: "underline"}} onClick={this.props.closeAuthModal}>
          {textResource["forgot_password.text"]}
        </Link>
        <br/><br/>
        {
          userRole.isFetching ?
          (<div className="authSpinner">
            <Spinner />
          </div>) :
          <Button id="BtnSignIn" color="primary" size="lg">
            {textResource["sign_in.sign_in_btn"]}
          </Button>
        }
      </Form>
    );
  }
}