import React from "react";
import textResource from '../../lib/textLoader';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import { changePassword } from "../../actions/accountChanges";
import {validateForm} from '../../lib/validationHelpers';
import serialize from 'form-serialize';

class ChangePasswordContainer extends React.Component {
	constructor() {
		super();

    this.state = {
      redirect: false,
      errors: {}
    }
		this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
	}

  componentDidUpdate(prevProps){
    if (!prevProps.accountChanges.error && this.props.accountChanges.error) {
      this.setState({
        errors: {
          fromServer: textResource["change_password.messages.password_wrong"]
        },
      })      
    }

    if (!prevProps.accountChanges.passwordChanged && this.props.accountChanges.passwordChanged) {
      this.setState({
        redirect: true
      })
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target
    const data = serialize(form, {hash: true})
    let errors;
    if(this.props.userRole.isPasswordExist) {
      errors = validateForm(data, "changePassword");
    }else {
      errors = validateForm(data, "setSocialPassword");
    }
    if (errors) {
      this.setState({errors})
    } else {
      this.formSuccess(form, data)
    }
  }

  formSuccess(form, data) {
    // form.reset()
    this.props.changePassword(this.props.userRole.email, data.oldPassword, data.newPassword, this.props.userRole.facebookID);
    // this.setState({
    //   success: true,
    //   errors: {},
    // })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/account/profile' />
    }
    return <ChangePassword onSubmit={this.onSubmit} {...this.state} userRole={this.props.userRole}/>;
  }
}

// yyPilot1

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    accountChanges: state.accountChanges,
  };
};

const mapDispatchtoProps = {
	changePassword
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ChangePasswordContainer);