import { connect } from 'react-redux';
import ExperienceInvitationModal from './ExperienceInvitationModal';
import { cancelExperienceInvitation } from "../../../actions/peerRoom";
import { closeExperienceInvitation } from "../../../actions/experienceInvitation";



const mapStateToProps = (state,ownProps) => {
    return {
      peerRoom: state.peerRoom
    };
};

const mapDispatchtoProps = {
    cancelExperienceInvitation,
    closeExperienceInvitation
};

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(ExperienceInvitationModal);