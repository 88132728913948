import { connect } from 'react-redux';
import Testimonial from './Testimonial';


const mapStateToProps = (state, ownProps) => {
    return {
        userRole: state.userRole,
    };
};

export default connect(
    mapStateToProps,
    null
)(Testimonial);