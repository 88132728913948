import { connect } from 'react-redux';
import AppInitializer from './AppInitializer';
import { loginFromToken, setStateRoleReady } from "../../actions/userRole";
import { instantiateRealtime, closeRealtime } from "../../actions/realtime";
import { fdTestPositive, fdTestNegative } from "../../actions/fdTest";
import {
  getGatheringsInfo,
  recalculateDailyGatherings,
  triggerRerender
} from "../../actions/gatheringsInfo";
import {
  setStartingRequestedNotifications
} from "../../actions/connectNotifications";
import { setSocketEventListenerFor } from "../../lib/socketInit";
import { getPresenceCount } from '../../actions/presenceCount';


const mapStateToProps = (state, ownProps) => {
  return {
    userRole: state.userRole,
    realtime: state.realtime,
    gatherings: state.gatheringsInfo.gatherings,
    uiControls: state.uiControls,
    socket: ownProps.socket
  };
};

const mapDispatchtoProps = {
	loginFromToken,
	setStateRoleReady,
	instantiateRealtime,
	closeRealtime,
  fdTestPositive,
  fdTestNegative,
  getGatheringsInfo,
  setStartingRequestedNotifications,
  recalculateDailyGatherings,
  triggerRerender,
  setSocketEventListenerFor,
  getPresenceCount
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(AppInitializer);
