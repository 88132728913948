import { connect } from 'react-redux';
import LogoutItem from './LogoutItem';
import {logout} from "../../../actions/userRole";

// yyPilot1

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

const mapDispatchtoProps = {
	logout
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(LogoutItem);