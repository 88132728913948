import * as Actions from "../actions/connectNotifications";

const initialState = {
  requestedNotifications: [],
  lastNotificationShown: 0,
  lastNotificationType: ""
};

export function connectNotifications(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_REQUESTED_NOTIFICATIONS:
      return {
        ...state,
      	requestedNotifications: [...state.requestedNotifications, action.data]
			};
    case Actions.CLEAR_REQUESTED_NOTIFICATIONS:
      return {
        ...state,
        requestedNotifications: state.requestedNotifications.filter(item => item !== action.data)
      };
    case Actions.SET_STARTING_REQUESTED_NOTIFICATIONS:
      return {
        ...state,
        requestedNotifications: action.data
      };
    case Actions.CLEAR_ALL_REQUESTED_NOTIFICATIONS:
      return {
        ...state,
      	requestedNotifications: [],
			};
    case Actions.SET_LAST_NOTIFICATION_SHOWN:
      return {
        ...state,
        lastNotificationShown: action.data.time,
        lastNotificationType: action.data.type
      };
    default:
      return state;
  }
}