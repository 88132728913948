export const endConnectionReasons = Object.freeze({
  UNKNOWN: "unknown",
  // While initial state
  NATURAL: "natural",
  // The connection ended after the normal time
  MANUAL_OTHER: "manual_other",
  // The other user clicked "End Connection" during the connection
  CANCEL_OTHER: "cancel_other",
  // The other user clicked "cancel" before starting
  MANUAL_SELF: "manual_self",
  // The user clicked "End Connection" during the connection
  CANCEL_SELF: "cancel_self",
  // The user clicked "cancel" before starting
  TIMEOUT_SELF: "timeout_self",
  // The user clicked "End Connection" during the connection
  TIMEOUT_OTHER: "timeout_other",
  // The other user clicked "cancel" before starting
  ICE_FAILURE: "ice_failure"
  // The connection ended because the ice connection state changed to failed
});
