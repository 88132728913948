import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import './TargetGroupValuePropositionsStyles.css';
import sendGAEvent from '../../../lib/sendGAEvent';
import textResource from '../../../lib/textLoader';

const handleClick = (openAuthModal,clearSocialLoginButtons, setAuthEventsCategory, category) => {
    return (
        clearSocialLoginButtons(),
        openAuthModal(),
        setAuthEventsCategory(`Authentication-${category}`),
        sendGAEvent(`Authentication-${category}`, 'Open', '')
    )
}

const TargetGroupValuePropositions = ({ openAuthModal, userRole, clearSocialLoginButtons, setAuthEventsCategory }) => {
  if (!userRole.ready || userRole.email) { return null; }
  
  return (
    <div>
        <Col sm={12} className="cardRow">
            <Row className="cardRowContainer">
                <Col sm={4} className="alignCenter">
                    <div className="relateCardContainer">
                    <div className="cardTitle">
                        <p>{textResource["target_group_value_propositions.relate_to_other.title"]}</p>
                    </div>
                    <p className="cardDescription">{textResource["target_group_value_propositions.relate_to_other.content"]}</p>
                    <div className="joinButton">
                        <Button id="BtnJoin" size="lg" color="secondary" onClick={() => handleClick(openAuthModal,clearSocialLoginButtons, setAuthEventsCategory, "btnValuePropRelate")}>
                            {textResource["target_group_value_propositions.join_us_btn"]}
                        </Button>
                    </div>
                    </div>
                </Col>
                <Col sm={4} className="alignCenter">
                    <div className="selfCardContainer">
                    <div className="cardTitle">
                        <p>{textResource["target_group_value_propositions.self_and_mutual_awareness.title"]}</p>
                    </div>
                    <p className="cardDescription">{textResource["target_group_value_propositions.self_and_mutual_awareness.content"]}</p>
                    <div className="joinButton">
                        <Button id="BtnJoin" size="lg" color="secondary" onClick={() => handleClick(openAuthModal,clearSocialLoginButtons, setAuthEventsCategory, "btnValuePropAwareness")}>
                            {textResource["target_group_value_propositions.join_us_btn"]}
                        </Button>
                    </div>
                    </div>
                </Col>
                <Col sm={4} className="alignCenter">
                    <div className="cardContainer">
                    <div className="cardTitle">
                        <p>{textResource["target_group_value_propositions.technology_real_human_connection.title"]}</p>
                    </div>
                    <p className="cardDescription">{textResource["target_group_value_propositions.technology_real_human_connection.content"]}</p>
                    <div className="joinButton">
                        <Button id="BtnJoin" size="lg" color="secondary" onClick={() => handleClick(openAuthModal,clearSocialLoginButtons, setAuthEventsCategory, "btnValuePropTechnology")}>
                            {textResource["target_group_value_propositions.join_us_btn"]}
                        </Button>
                    </div>
                    </div>
                </Col>
            </Row>
        </Col>
    </div>
  )
}

export default TargetGroupValuePropositions;
