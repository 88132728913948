import React from "react";
import constants from '../../constants';
import {initializeAmplitude} from "../../lib/amplitude"


class AppInitializer extends React.Component {

  gatheringTimeoutIds = [];

	componentDidMount() {
    // If there is a token, request authentication, otherwise set role ready.
    let token = localStorage.getItem("token");
		if (token) {
			this.props.loginFromToken(token);
		} else {
      this.props.setStateRoleReady();
		}

		// Run feature detection and process result.
    if (window.Modernizr.getusermedia && window.Modernizr.peerconnection) {
      this.props.fdTestPositive();
    } else {
      this.props.fdTestNegative();
    }

    if (localStorage.getItem('requestedNotifications')) {

      this.props.setStartingRequestedNotifications(localStorage.getItem('requestedNotifications').split(","));
    }

    this.props.getPresenceCount();
    this.props.getGatheringsInfo();
	}

  componentDidUpdate(prevProps){
    let oldRole = prevProps.userRole.role;
    let newRole = this.props.userRole.role;

    if ((oldRole === "guest") && (newRole !== "guest")) {

      // If role was guest and becomes something else, I've just become authenticated
    	// Therefore I need to instantiate Ably
      this.props.instantiateRealtime();

      // and set socket listeners (this is for our own realtime server, not Ably)
      this.props.setSocketEventListenerFor('newPeopleCount');
      this.props.setSocketEventListenerFor('matchmakerEvent');
      this.props.setSocketEventListenerFor('reconnect');
      this.props.setSocketEventListenerFor('disconnect');


      // Also, at this point we can activate optimize
      if (newRole === "admin" && window.dataLayer) {
        window.dataLayer.push({'event': 'optimize.admin.activate'});
      }
      
    }

    // If I logout, close Ably
    if ((oldRole !== "guest") && (newRole === "guest")) {
      this.props.closeRealtime(this.props.realtime);
    }

    if (prevProps.userRole.role !== this.props.userRole.role) {
      this.props.getGatheringsInfo();
    }

    if (prevProps.gatherings !== this.props.gatherings) {
      this.updateGatheringTimeouts();
    }

    if(this.props.userRole.ready && !prevProps.userRole.ready){
      initializeAmplitude(this.props.userRole.userId);

    }
  }

  componentWillUnmount() {
    this.gatheringTimeoutIds.forEach(timeoutId => window.clearTimeout(timeoutId));
  }

  updateGatheringTimeouts = () => {
    const {
      gatherings,
      getGatheringsInfo,
      recalculateDailyGatherings,
      triggerRerender
    } = this.props;

    const now = Date.now();

    this.gatheringTimeoutIds.forEach(timeoutId => window.clearTimeout(timeoutId));

    this.gatheringTimeoutIds = gatherings.reduce((timeoutIds, gathering) => {
      // refresh all gatherings whenever a gathering starts, updating the
      // store with fresh data and causing a rerender
      const startTimeDiff = gathering.startTimeMillSec - now;
      if (startTimeDiff >= 0) {
        timeoutIds.push(window.setTimeout(() => {
          getGatheringsInfo();
          recalculateDailyGatherings();
        }, startTimeDiff));
      }

      // rerender when gathering ends
      const endTimeDiff = gathering.endTimeMillSec - now;
      if (endTimeDiff >= 0) {
        timeoutIds.push(window.setTimeout(triggerRerender, endTimeDiff));
      }

      // rerender 15 minutes after a gathering ends, to hide AfterGathering
      const afterTimeDiff = gathering.endTimeMillSec + constants.gatherings.seekFeedbackForLengthInTime - now;
      if (afterTimeDiff >= 0) {
        timeoutIds.push(window.setTimeout(triggerRerender, afterTimeDiff));
      }

      return timeoutIds;
    }, []);
  }

  render() {
  	return null;
  }
}

export default AppInitializer;
