import React from 'react';
import './PartnerStyles.css';
import { Row, Col } from 'reactstrap';
import OneWorldLogo from "../../../resources/images/one_world.png"
import USPLogo from '../../../resources/images/usp_logo.png'
import KidnessMattersLogo from "../../../resources/images/KidnessMatters.png"
import BZNLogo from "../../../resources/images/bzn_logo.png"
import UniAmsterdamLogo from "../../../resources/images/uni_amsterdam.png"
import ForPeaceLogo from "../../../resources/images/for_peace_logo.png"
import WHTCLogo from "../../../resources/images/wehavethechoice_logo.png"

const partners = [
	{logo: USPLogo, key: 1, url: "https://www5.usp.br/"},
	{logo: UniAmsterdamLogo, key: 2, url: "https://www.uva.nl/en"},
	{logo: OneWorldLogo, key: 3, url: "https://oneworldindialogue.com/"},
	{logo: ForPeaceLogo, key: 4, url: "https://www.forpeace.org/"},
	{logo: BZNLogo, key: 5, url: "https://www.bzn.be/nl/home"},
  {logo: WHTCLogo, key: 6, url: "https://wehavethechoice.com/"},
	{logo: KidnessMattersLogo, key: 7, url: "https://kindnessmatters.co.uk/"},
]


class Partners extends React.Component {

	render() {
		if (!this.props.userRole.ready) { return null; }
        
		return (	
      <div>
        <div style={{display: 'block'}}>
        	{this.props.withTitle && <h2 className="pb-5">Our partners</h2>}
          <Row className="partnersRow" className="pb-5 pt-2">
              {partners.map((partner) => {
                return(
                  <Col md={3} sm={6} className="pb-2" key={partner.key}>
                  	<div className="partnerItem">
	                    <a href={partner.url} target="_blank" >
	                    	<div className="centeredBoxPartner">
	                      	<img src={partner.logo} />
	                      </div>
	                    </a>
                    </div>                 
                  </Col>
                )
              })}
          </Row>
        </div>
      </div>
		)	
	}
}

export default Partners;