import constants from "../constants";

export const CHECK_HASH_START = "CHECK_HASH_START";
export const CHECK_HASH_SUCCESS = "CHECK_HASH_SUCCESS";
export const CHECK_HASH_FAILURE = "CHECK_HASH_FAILURE";
export const CLEAR_DATA = "CLEAR_DATA";

export function checkHashStart(data) {
  return {
    type: CHECK_HASH_START,
    data
  };
}

export function checkHashSuccess(data) {
  return {
    type: CHECK_HASH_SUCCESS,
    data
  };
}

export function checkHashFailure(error) {
  return {
    type: CHECK_HASH_FAILURE,
    error
  };
}


export function clearData() {
  return {
    type: CLEAR_DATA
  };
}



export function checkHash(invitationHash) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `invitationHash=${invitationHash}`
  };
  return dispatch => {
    dispatch(checkHashStart({invitationHash}));
    fetch(`${constants.baseUrl}/api/v1/invitation/checkhash`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(checkHashFailure("Invitation hash failed"));
        } else {
          dispatch(checkHashSuccess(response));
        }        
      })
      .catch(err => {
      	dispatch(checkHashFailure(err));
        console.log("Invitation hash failed: ", err);
      });
  };
}