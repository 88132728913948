import React from 'react';
import { Col, Row } from 'reactstrap';


const LayoutWrapperWithoutHome = ({children}) => {

	return (
		<div style={{textAlign: "left"}}>
	  	<Row style={{margin: 0}}>

	  		<Col className="reportContainer" xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
			  	{children}
			  </Col>
		  </Row>
		</div>
	)
}

export default LayoutWrapperWithoutHome;