import React from 'react';
import textResource from '../../../lib/textLoader';
import { DropdownItem } from 'reactstrap';


const LogoutItem = ({userRole, logout}) => {

	return (
  	<DropdownItem id="BtnLogout" onClick={logout} style={{cursor: 'pointer'}}>
      {textResource["navbar.dropdown.logout"]}
    </DropdownItem>
	)
}

export default LogoutItem;