import React from "react";
// import textResource from '../../lib/textLoader';
import { Card, CardText, CardBody, Button } from "reactstrap";
import constants from "../../../constants";

function userIsInRecentlyEndedGatheringSpace(
  mostRecentlyEndedGathering,
  location
) {
  if (
    mostRecentlyEndedGathering.groupID &&
    location.pathname === `/groupspace/${mostRecentlyEndedGathering.groupID}`
  ) {
    return true;
  }

  return !mostRecentlyEndedGathering.groupID && location.pathname === "/";
}

function displayTimeRemaining(timeRemaining) {
  let minutesRemaining = Math.floor(timeRemaining / 60000);
  if (minutesRemaining > 1) {
    return `${minutesRemaining} minutes`;
  } else if (minutesRemaining > 0) {
    return "1 minute";
  } else {
    return "a few moments";
  }
}

class AfterGathering extends React.Component {
  constructor(props) {
    super(props);

    this.timeRemainingIntervalId = null;

    let timeRemaining =
      constants.gatherings.seekFeedbackForLengthInTime +
      this.props.mostRecentlyEndedGathering.endTimeMillSec -
      Date.now();

    this.state = { timeRemaining };
  }

  componentDidMount() {
    if (this.props.mostRecentlyEndedGathering) {
      this.setTimeRemainingInterval();
    }
  }

  componentWillUnmount() {
    this.timeRemainingIntervalId &&
      window.clearInterval(this.timeRemainingIntervalId);
  }

  setTimeRemainingInterval = () => {
    this.timeRemainingIntervalId &&
      window.clearInterval(this.timeRemainingIntervalId);

    this.timeRemainingIntervalId = window.setInterval(() => {
      this.setTimeRemaining();
    }, 60000);
  };

  setTimeRemaining = () => {
    const timeRemaining = this.state.timeRemaining - 60000;
    this.setState({ timeRemaining });
  };

  render() {
    const { mostRecentlyEndedGathering, location } = this.props;

    if (!mostRecentlyEndedGathering) {
      return null;
    }

    if (
      !userIsInRecentlyEndedGatheringSpace(mostRecentlyEndedGathering, location)
    ) {
      return null;
    }

    let surveyUrl =
      mostRecentlyEndedGathering.surveyUrl ||
      "https://docs.google.com/forms/d/e/1FAIpQLSclHDKZ7OyB1leZmejbaKjq4sepXMlMl6aFfszCUKc8s0fSOw/viewform?usp=pp_url";
    let title = null;
    if (mostRecentlyEndedGathering.groupID) {
      title = (
        <div>
          <p className="lead">
            This private space will close in{" "}
            {displayTimeRemaining(this.state.timeRemaining)}
          </p>
        </div>
      );
    }

    return (
      <div
        style={{
          width: "80%",
          maxWidth: "680px",
          margin: "auto",
          marginBottom: "20px"
        }}
      >
        {title}
        <Card>
          <CardBody>
            <CardText>
              Thank you. This gathering has officially ended. However, you can
              continue to connect to people if they are present.
            </CardText>
            {
              !mostRecentlyEndedGathering.groupID &&
              <div>
                <CardText>
                  This is a new service and we want to improve it. We would like to
                  hear from you.
                </CardText>
                <CardText>
                  <a href={surveyUrl} target="_blank" rel="noopener noreferrer">
                    <Button id="FeedbackSurvey" color="primary">
                      Feedback Survey
                    </Button>
                  </a>
                </CardText>
              </div>
            }
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default AfterGathering;
