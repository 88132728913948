import { connect } from 'react-redux';
import EnteringRoomState from './EnteringRoomState';
import { setStateGettingMedia } from '../../../actions/userState';
import { setExperienceType } from '../../../actions/peerRoom';
import { closeLanguageDropDown, setScreenOpenTime } from '../../../actions/selectLanguage';

const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    selectedLanguage: state.selectLanguage.selectedLanguage,
    languageDropDown: state.selectLanguage.languageDropDown,
    peerRoom: state.peerRoom
  };
};

const mapDispatchtoProps = {
  setStateGettingMedia,
  setExperienceType,
  closeLanguageDropDown,
  setScreenOpenTime
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(EnteringRoomState);