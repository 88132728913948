import React from 'react';
import '../../Experiences/ExperienceCardStyles.css';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import CancelButtonContainer from '../CancelButtonContainer';
import ConnectButtonContainer from '../ConnectButtonContainer';

import ConnectWithDropdownContainer from '../ConnectWithDropdown/ConnectWithDropdownContainer';


const ConnectOptions = ({userRole, userState, peerRoom, experience, openAuthModal}) => {

  if (!userRole.email) {
    return (
      <Button color="default" onClick={openAuthModal}>
        Connect
      </Button> 
    )
  }

  if (!userRole.subscription.activeSubscription && experience.type !== "oneminute") {

    return (
      <div>
        {
         !!(!userRole.subscription.freeExperienceUsed) &&
          connectWithDropdownOrCancelButton(userState, peerRoom, experience)
        }
        <div className="experienceConnectButton">
          <Link to="/membership" >
            <Button color="default" lg>
              Become a contributing member
            </Button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>         
        <div>
          {connectWithAnyoneOrCancelButton(userState, peerRoom, experience)}
        </div>
        <div>         
          {connectWithDropdownOrCancelButton(userState, peerRoom, experience)}
        </div>              
    </div>  
  )                    
}



function connectWithAnyoneOrCancelButton(userState, peerRoom, experience) {

  if (["present", "absent"].includes(userState) || peerRoom.experienceType !== experience.type || peerRoom.poolType !== "anyone") {
    return (
      <div className="experienceConnectButton">
        <ConnectButtonContainer experienceType={experience.type} poolType="anyone" disabled={!!(experience.myCircleOnly)}/>
      </div>
    )
  } else if (userState === 'waiting' && peerRoom.experienceType === experience.type && peerRoom.poolType === "anyone") {
    return (
      <div className="experienceCancelButton">
        <CancelButtonContainer />
      </div>
    )
  }

}


function connectWithDropdownOrCancelButton(userState, peerRoom, experience) {

  if (["present", "absent"].includes(userState) || peerRoom.experienceType !== experience.type || peerRoom.poolType !== "myCircle") {
    return (
      <div className="experienceConnectButton">
        <ConnectWithDropdownContainer experienceType={experience.type} />
      </div>
    )
  } else if (userState === 'waiting' && peerRoom.experienceType === experience.type && peerRoom.poolType === "myCircle") {
    return (
      <div className="experienceCancelButton">
        <CancelButtonContainer />
      </div>
    )
  }

}


export default ConnectOptions;
