import { connect } from 'react-redux';
import WelcomePotrait from './WelcomePotrait';
import "./WelcomePotraitStyle.css"

function isCurrentGathering(gathering) {
  const now = Date.now();
  return gathering.startTimeMillSec <= now && gathering.endTimeMillSec > now;
}

const mapStateToProps = (state) => {

  const { gatherings } = state.gatheringsInfo;
  let currentGathering;

  for (const gathering of gatherings) {
    if (isCurrentGathering(gathering)) {
      currentGathering = currentGathering || gathering;
    }
  }

  return {
    userRole: state.userRole,
    userState: state.userState,
    currentGathering
  };
};


export default connect(
  mapStateToProps,
  null
)(WelcomePotrait);