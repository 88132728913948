import { connect } from 'react-redux';
import ExperiencesArea from './ExperiencesArea';
import { getExperiences } from '../../actions/experiences';
import { clearSelectedExperienceData } from '../../actions/selectedExperience';


const mapStateToProps = (state,ownProps) => {
    return {
        userRole: state.userRole,
        experienceStatus: ownProps.experienceStatus,
        experiencesByCategory: state.experiences.experiencesByCategory,
        experiencesWithPeopleWaiting: state.experiencesWithPeopleWaiting
    };
};

const mapDispatchtoProps = {
    getExperiences,
    clearSelectedExperienceData
};

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(ExperiencesArea);