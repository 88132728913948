import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import sendGAEvent from '../../lib/sendGAEvent';
import SocialLoginFormContainer from './SocialLoginFormContainer';

class SocialAuthModal extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps){
    if (prevProps.userRole.role === "guest" && this.props.userRole.role !== "guest") {
      this.props.closeSocialAuthModal();
    }
  }

  componentWillUnmount() {
    this.props.closeSocialAuthModal();
  }

  toggle() {
    if (this.props.socialAuthModalOpen) {
      this.props.closeSocialAuthModal();
      sendGAEvent('Authentication', 'Close', '');
    } else {
      this.props.openSocialAuthModal();
    }
  }

  render() {
    if (!["present", "absent", "waiting"].includes(this.props.userState)) { return null; }

    return (
      <div>
        <Modal isOpen={this.props.socialAuthModalOpen} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>
          </ModalHeader>
          <ModalBody>
            <SocialLoginFormContainer />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default SocialAuthModal;