import React from 'react';
import './GatheringInfoStyles.css';
import { UncontrolledTooltip } from 'reactstrap';
import textResource from '../../../lib/textLoader';
import ParagraphCreator from '../../elements/ParagraphCreator';



const WhatAreGatheringsTooltip = ({userRole}) => {

  return (
    <UncontrolledTooltip
    	placement="right"
    	boundariesElement="window"
    	target="WhatAreGatheringsTrigger"
    	innerClassName="inner-tooltip"
    	className="not-transparent"
    	style={{opacity: 1}}
    >
      <ParagraphCreator text={textResource["gathering.what_is_gathering.text"]} />
    </UncontrolledTooltip>
  )
}

export default WhatAreGatheringsTooltip;
