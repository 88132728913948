import { connect } from 'react-redux';
import KeepMeInformedButton from './KeepMeInformedButton';
import { registerToGatheringsEmailList } from "../../../actions/accountChanges";
import { setRegisterEmailsAfterLogin, openAuthModal, setAuthEventsCategory } from "../../../actions/uiControls";


const mapStateToProps = (state, ownProps) => {
  return {
    userRole: state.userRole,
    origin: ownProps.origin
  };
};


const mapDispatchToProps = {
  registerToGatheringsEmailList,
  setRegisterEmailsAfterLogin,
  openAuthModal,
  setAuthEventsCategory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeepMeInformedButton);