import constants from "../constants";

export const SET_REQUESTED_NOTIFICATIONS = "SET_REQUESTED_NOTIFICATIONS";
export const CLEAR_REQUESTED_NOTIFICATIONS = "CLEAR_REQUESTED_NOTIFICATIONS";
export const SET_STARTING_REQUESTED_NOTIFICATIONS = "SET_STARTING_REQUESTED_NOTIFICATIONS";
export const CLEAR_ALL_REQUESTED_NOTIFICATIONS = "CLEAR_ALL_REQUESTED_NOTIFICATIONS";
export const SET_LAST_NOTIFICATION_SHOWN = "SET_LAST_NOTIFICATION_SHOWN";



export function setRequestedNotifications(data) {
  return {
    type: SET_REQUESTED_NOTIFICATIONS,
    data
  };
}

export function clearRequestedNotifications(data) {
  return {
    type: CLEAR_REQUESTED_NOTIFICATIONS,
    data
  };
}

export function setStartingRequestedNotifications(data) {
  return {
    type: SET_STARTING_REQUESTED_NOTIFICATIONS,
    data
  };
}

export function clearAllRequestedNotifications() {
  return {
    type: CLEAR_ALL_REQUESTED_NOTIFICATIONS
  };
}

export function setLastNotificationShown(data) {
  return {
    type: SET_LAST_NOTIFICATION_SHOWN,
    data
  };
}

