import sendGAEvent from '../lib/sendGAEvent';
import { socialUserLogin } from './userRole'
import { clearAuthData } from './uiControls'

export const SET_SOCIAL_LOGIN_BUTTONS = "SET_SOCIAL_LOGIN_BUTTONS";
export const CLEAR_SOCIAL_LOGIN_BUTTONS = "CLEAR_SOCIAL_LOGIN_BUTTONS";
export const SET_FACEBOOK_DATA = "SET_FACEBOOK_DATA";
export const FACEBOOK_FAILURE = "FACEBOOK_FAILURE";
export const CLEAR_FACEBOOK_ERROR = "CLEAR_FACEBOOK_ERROR";
export const REMOVE_ACCESS_TOKEN = "REMOVE_ACCESS_TOKEN"



export function setSocialLoginButtons() {
  return {
    type: SET_SOCIAL_LOGIN_BUTTONS
  };
}

export function clearSocialLoginButtons() {
  return {
    type: CLEAR_SOCIAL_LOGIN_BUTTONS
  };
}

export function setFacebookData(data) {
  return {
    type: SET_FACEBOOK_DATA,
    data
  };
}

export function facebookFailure() {
  return {
    type: FACEBOOK_FAILURE,
  };
}

export function clearFacebookError() {
  return {
    type: CLEAR_FACEBOOK_ERROR,
  };
}

export function removeAccessToken() {
  return {
    type: REMOVE_ACCESS_TOKEN
  };
}

export function loginGraphApi(token) {
  let config = {
    method: "GET"
  };
  return (dispatch,getState) => {
    fetch(`https://graph.facebook.com/me?access_token=${token}&fields=name,email`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        let { userRole, registeringFromInvitation, uiControls } = getState()
        if(response.id){
          response.accessToken = token;
          let { email, name, accessToken, id } = response;
          dispatch(setFacebookData(response))
          dispatch(socialUserLogin(email, name, id, userRole.referrerUrl, userRole.currentUrl, registeringFromInvitation.invitationHash, accessToken))
          dispatch(removeAccessToken())
        }else{
          dispatch(facebookFailure());
          dispatch(clearAuthData());
          sendGAEvent(uiControls.authEventsCategory, 'Sign in', 'Fail no Facebook profile');
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
}