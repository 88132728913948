import * as Actions from "../actions/myCircle";
import * as SessionStateActions from "../actions/sessionState";

const initialState = {
  isFetching: false,
  error: null,
  alreadyInMyCircleAddedByMe: false,
  alreadyInMyCircleMatched: false,
  addedToCircleSuccess: false,
  removedFromCircleSuccess: false,
  addedByOther: false,
  otherIsGuest: false
};

export function myCircle(state = initialState, action) {
  switch (action.type) {
    case Actions.CIRCLE_ACTION_START:
      return {
        ...state,
			  isFetching: true,
			  error: null,
			  addedToCircleSuccess: false,
			  removedFromCircleSuccess: false			  
			};
    case Actions.CIRCLE_ACTION_FAILURE:
      return {
			  ...state,        
  			isFetching: false,
        error: action.error
      };
    case Actions.ADD_TO_CIRCLE_SUCCESS:
      return {
        ...state,
        alreadyInMyCircleAddedByMe: true,
			  addedToCircleSuccess: true,
			  isFetching: false,
			  error: null
      };
    case Actions.REMOVE_FROM_CIRCLE_SUCCESS:
      return {
        ...state,
        alreadyInMyCircleAddedByMe: false,
        alreadyInMyCircleMatched: false,
			  removedFromCircleSuccess: true,
			  isFetching: false,
			  error: null
      };
    case Actions.SET_PARTNER_IS_IN_MY_CIRCLE:
      return {
        ...state,
        alreadyInMyCircleAddedByMe: action.data.alreadyInMyCircleAddedByMe,
        alreadyInMyCircleMatched: action.data.alreadyInMyCircleMatched,
			  isFetching: false,
			  error: null
      };
    case Actions.SET_MY_CIRCLE_ADDED_BY_OTHER:
      return {
        ...state,
        addedByOther: true,
      };
    case Actions.SET_OTHER_IS_GUEST:
      return {
        ...state,
        otherIsGuest: true,
        isFetching: false,
        error: null
      };
    case SessionStateActions.RESET_ALL_SESSION_STATE:
      return {
			  isFetching: false,
			  error: null,
			  alreadyInMyCircleAddedByMe: false,
        alreadyInMyCircleMatched: false,
			  addedToCircleSuccess: false,
			  removedFromCircleSuccess: false,
        addedByOther: false,
        otherIsGuest: false
      };
    default:
      return state;
  }
}