import React, { Component } from 'react';
import { Col, Row, Button } from 'reactstrap';
import textResource from '../../lib/textLoader';
import ParagraphCreator from '../elements/ParagraphCreator';
import LayoutWrapper from '../elements/LayoutWrapper';
import '../Lobby/ConnectVideo/ConnectVideoStyles.css'
import './GuidancePageStyles.css'
import VideoHowTo from '../Lobby/ConnectVideo/VideoHowTo';
import ConnectMyCircle from '../../resources/images/btn_connect_within_my_circle.png'
import ExpandedExperience from "../../resources/images/expanded_experience.png"
import SendDirectInvite from "../../resources/images/send_direct_invite.png"
import AddMyCircle from "../../resources/images/btn_add_my_circle.png"
import DigestingScreen from "../../resources/images/digesting_screen.png"
import FaqPage from "./FaqPage"

class GuidancePage extends Component {


  render() {

  return (
    <div>
      <div>
        <LayoutWrapper>
      	  <Row className="pb-3">
            <Col xs={12} md={6}>
              <div className="centeredBox">
                <div>
                	<h3 className="pb-3">{textResource["guidance.human_minute.title"]}</h3>
                	<p>{textResource["guidance.human_minute.text"]}</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
            	 <VideoHowTo className="p-3" label="scrHowToUse"/>
            </Col>
      	  </Row>
        </LayoutWrapper>
      </div>
      <div style={{background: "#F8E9DE"}}>
        <LayoutWrapper>
      	  <Row className="pb-3">
              <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                <div className="centeredBox">
                  <img className="p-3" src={AddMyCircle} />
                </div>
              </Col>
              <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
                <div className="centeredBox">
                  <div>              
                	<h3 className="pb-3">{textResource["guidance.add_someone.title"]}</h3>
                	<p>{textResource["guidance.add_someone.text"]}</p>
                  </div>
                </div>
              </Col>
      	  </Row>
        </LayoutWrapper>
      </div>
      <div>
        <LayoutWrapper>
      	  <Row className="pb-3">
              <Col xs={12} md={6}>
                <div className="centeredBox">
                  <div>              
                    <h3 className="pb-3">{textResource["guidance.my_circle.title"]}</h3>
                    <p>{textResource["guidance.my_circle.text"]}</p>
                  </div>
                </div>              
              </Col>
              <Col xs={12} md={6}>
              	<div className="centeredBox">
              		<img className="p-3" src={ConnectMyCircle} />
              	</div>
              </Col>
      	  </Row>
        </LayoutWrapper>
      </div>
      <div style={{background: "#431D1F", color: "white"}}>
        <LayoutWrapper>
      	  <Row className="pb-3">
              <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                <div className="centeredBox">
                  <img className="p-3" src={SendDirectInvite} />
                </div>
              </Col>
              <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
                <div className="centeredBox">
                  <div>              
                    <h3 className="pb-3">{textResource["guidance.direct_invite.title"]}</h3>
                    <p>{textResource["guidance.direct_invite.text"]}</p>
                  </div>
                </div>                
              </Col>
      	  </Row>
        </LayoutWrapper>
      </div>
      {
        /*<div style={{background: "#431D1F", color: "white"}}>
          <LayoutWrapper>
            <Row className="pb-3">
                <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                  <div className="centeredBox">
                    <img className="p-3" src={ExpandedExperience} />
                  </div>
                </Col>
                <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
                  <div className="centeredBox">
                    <div>              
                      <h3 className="pb-3">{textResource["guidance.experiences.title"]}</h3>
                      <p>{textResource["guidance.experiences.text"]}</p>
                    </div>
                  </div>                
                </Col>
            </Row>
          </LayoutWrapper>
        </div>*/
      }
      <div>
        <LayoutWrapper>
      	  <Row className="pb-3">
              <Col xs={12} md={6}>
                <div className="centeredBox">
                  <div>     
                  	<h3 className="pb-3">{textResource["guidance.end_connection.title"]}</h3>
                  	<p>{textResource["guidance.end_connection.good"]}</p>
                  	<p>{textResource["guidance.end_connection.bad"]}</p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
              	<div className="centeredBox">
              		<img className="p-3" src={DigestingScreen} />
              	</div>
              </Col>
      	  </Row>
        </LayoutWrapper>
      </div>
      <div>
        <LayoutWrapper>
          <Row>
              <p>{textResource["guidance.end"]}</p>
          </Row>
        </LayoutWrapper>
      </div>
      <div>
        <div className="pt-5">
        <FaqPage />
        </div>
      </div>
    </div>
  )

    return (
      <LayoutWrapper>
        <h1 className="pb-3">{textResource["guidance.title"]}</h1>
				<VideoHowTo label="scrHowToUse"/>
				<h3 className="pt-5 pb-3">{textResource["guidance.start.title"]}</h3>
			
		  	<ol>
		  		<li>{textResource["guidance.start.register"]}</li>
		  		<li>{textResource["guidance.start.commit"]}</li>
		  		<li>{textResource["guidance.start.connect"]}</li>
		  		<li>{textResource["guidance.start.gatherings"]}</li>
		  	</ol>
		  	
		<h3 className="pt-5 pb-3">{textResource["guidance.experience_steps.title"]}</h3>
		  
		  	<ol>
		  		<li>{textResource["guidance.experience_steps.allow_camera"]}</li>
		  		<li>{textResource["guidance.experience_steps.silhouette"]}</li>
		  		<li>{textResource["guidance.experience_steps.start"]}</li>
				<li>{textResource["guidance.experience_steps.end_timer"]}
				  <br/><br/>
				  {textResource["guidance.experience_steps.end_manually"]}</li>
		  	</ol>

		  	 	
		  	<h3 className="pt-5 pb-3">{textResource["guidance.during.title"]}</h3>
		  
		  	<ParagraphCreator text={textResource["guidance.during.instructions"]} />
		  	
		  	<h3 className="pt-5 pb-3">{textResource["guidance.preparing.title"]}</h3>
		  
		  	<p>{textResource["guidance.preparing.intro"]}</p>
		  	<p>{textResource["guidance.preparing.physical"]}</p>
		  	<p>{textResource["guidance.preparing.digital"]}</p>
		  	<p>{textResource["guidance.preparing.mind"]}</p>

			<h3 className="pt-5 pb-3">{textResource["guidance.notifications.title"]}</h3>
        
			<p>{textResource["guidance.notifications.explanation"]}
			<br/><br/>
			{textResource["guidance.notifications.instruction"]}
			</p>
			
			<h3 className="pt-5 pb-3">{textResource["gathering.what_is_gathering.title"]}</h3>
			<ParagraphCreator text={textResource["gathering.what_is_gathering.text"]} />
			  
      </LayoutWrapper>
    );
  }
}

export default GuidancePage;