import React from 'react';
import './FaqPage.css';
import LayoutWrapper from '../elements/LayoutWrapper';
import { Collapse } from 'reactstrap';
import CloseCollapseImg from '../../resources/images/number.png'
import OpenCollapseImg from '../../resources/images/open_collapse.png'
import textResource from '../../lib/textLoader';

class FaqPage extends React.Component {
    constructor(){
        super();
        this.state = {
            data: [
                {
                    title: textResource["faq.title1"],
                    description: textResource["faq.description1"],
                },
                {
                    title: textResource["faq.title2"],
                    description: textResource["faq.description2"],
                },
                {
                    title: textResource["faq.title3"],
                    description: textResource["faq.description3"],
                },
                {
                    title: textResource["faq.title4"],
                    description: textResource["faq.description4"],
                },
                {
                    title: textResource["faq.title5"],
                    description: textResource["faq.description5"],
                },
                {
                    title: textResource["faq.title6"],
                    description: textResource["faq.description6"],
                },
                {
                    title: textResource["faq.title7"],
                    description: textResource["faq.description7"],
                },
                {
                    title: textResource["faq.title8"],
                    description: textResource["faq.description8"],
                },
                {
                    title: textResource["faq.title9"],
                    description: textResource["faq.description9"],
                },
                {
                    title: textResource["faq.title10"],
                    description: textResource["faq.description10"],
                },
                // {
                //     title: textResource["faq.title11"],
                //     description: textResource["faq.description11"]
                // },
                // {
                //     title: textResource["faq.title12"],
                //     description: textResource["faq.description12"]
                // },
                {
                    title: textResource["faq.title13"],
                    description: textResource["faq.description13"]
                },
                {
                    title: textResource["faq.title14"],
                    description: textResource["faq.description14"]
                }
            ],
            isOpen: false
        }
    }
    toggle = (index) => {
        if(this.state.isOpen === index) {
            this.setState({ isOpen: false })
        }else{
            this.setState({ isOpen: index })
        }
    }
    render(){
        let { data, isOpen } = this.state;
        return (
            <div style={{background: "white"}}>
                <LayoutWrapper>
                    <h3 className="pb-5" style={{ textAlign: "center" }}>{textResource["faq.main_title"]}</h3>
                    {data.map((item,key) => {
                        return (
                            <div key={key} className="pb-5 main-container">
                                <div onClick={() => this.toggle(key)}>
                                    <p className="title-heading">{item.title}</p>
                                    <div className="collapse-container">
                                        {
                                            isOpen === key ? <img src={OpenCollapseImg} />:<img src={CloseCollapseImg} />
                                        }
                                    </div>
                                </div>
                                <Collapse isOpen={isOpen === key}>
                                    {item.description}
                                </Collapse>
                            </div>
                        )
                    })}
                </LayoutWrapper>
            </div>
        )
    }   
}

export default FaqPage;
