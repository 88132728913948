import React from 'react';

class TextInstructionsManager extends React.Component {
    constructor() {
        super();
        this.textInstructionsTimeouts = null;
        this.introTextInstructionsTimeouts = null;
        this.setUserInstructionText = this.setUserInstructionText.bind(this);

    }

    componentDidUpdate(prevProps) {
        let oldState = prevProps.userState;
        let newState = this.props.userState;

        if (oldState !== "connected" && newState === "connected") {
			if(this.props.selectedExperienceData && this.props.selectedExperienceData.textInstructions && this.props.selectedExperienceData.textInstructions.length) {

                this.textInstructionsTimeouts = this.props.selectedExperienceData.textInstructions.map((textItem) => {
                    let timeout = setTimeout(() => {
                        this.setUserInstructionText(textItem);
                    }, textItem.time);
                    return timeout;
                })
			}
        }

        if (oldState === "connected" && newState !== "connected") {
            if (this.textInstructionsTimeouts) {
                this.textInstructionsTimeouts.forEach((timeout) => {
                    clearTimeout(timeout);
                })
                this.textInstructionsTimeouts = null;           
            }
        }

        if (newState === "ready" && this.props.peerReady && 
            (oldState !== "ready" || !prevProps.peerReady)
        ) {
            if(this.props.selectedExperienceData && this.props.selectedExperienceData.introTextInstructions && this.props.selectedExperienceData.introTextInstructions.length) {
                setTimeout(() => {
                    this.introTextInstructionsTimeouts = this.props.selectedExperienceData.introTextInstructions.map((textItem) => {
                        let timeout = setTimeout(() => {
                            this.setUserInstructionText(textItem);
                        }, textItem.time);
                        return timeout;
                    })
                }, this.props.selectedExperienceData.introAudioStart)
            }
        }

        if (oldState === "ready" && newState !== "ready") {
            if (this.introTextInstructionsTimeouts) {
                this.introTextInstructionsTimeouts.forEach((timeout) => {
                    clearTimeout(timeout);
                })
                this.introTextInstructionsTimeouts = null;           
            }
        }
        
    }


    setUserInstructionText(textItem) {
        let message = textItem.user1Instruction;
        if(this.props.peerRoom.position === 2) {
            message = textItem.user2Instruction
        }
        if (message) {
            this.props.showTextInstructionMessage(message);
            setTimeout(() => {
                this.props.clearTextInstructionMessage();
            }, 5000)
        }
    }

    componentWillUnmount() {
        if (this.textInstructionsTimeouts) {
            this.textInstructionsTimeouts.forEach((timeout) => {
                clearTimeout(timeout);
            })
            this.textInstructionsTimeouts = null;           
        }
        if (this.introTextInstructionsTimeouts) {
            this.introTextInstructionsTimeouts.forEach((timeout) => {
                clearTimeout(timeout);
            })
            this.introTextInstructionsTimeouts = null;           
        }
    }


    render() {
        return null
    }
}

export default TextInstructionsManager;