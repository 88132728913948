import constants from "../constants";
import { logout, setSolicitedEmailsTrue, setIsPasswordExist } from "./userRole";

export const CHANGE_START = "CHANGE_START";
export const CLEAR_CHANGES = "CLEAR_CHANGES";
export const CHANGE_FAILURE = "CHANGE_FAILURE";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const REGISTER_EMAILS_SUCCESS = "REGISTER_EMAILS_SUCCESS";
export const PASSWORD_RECOVERY_REQUEST_SUCCESS = "PASSWORD_RECOVERY_REQUEST_SUCCESS";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const VALIDATE_RECOVERY_HASH_SUCCESS = "VALIDATE_RECOVERY_HASH_SUCCESS";
export const VALIDATE_RECOVERY_HASH_FAILURE = "VALIDATE_RECOVERY_HASH_FAILURE";



export function changeStart() {
  return {
    type: CHANGE_START
  };
}

export function clearChanges() {
  return {
    type: CLEAR_CHANGES
  };
}

export function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS
  };
}

export function deleteAccountSuccess() {
  return {
    type: DELETE_ACCOUNT_SUCCESS
  };
}

export function registerEmailsSuccess(howSolicited) {
  return {
    type: REGISTER_EMAILS_SUCCESS,
    howSolicited
  };
}

export function passwordRecoveryRequestSuccess() {
  return {
    type: PASSWORD_RECOVERY_REQUEST_SUCCESS
  };
}

export function validateRecoveryHashSuccess() {
  return {
    type: VALIDATE_RECOVERY_HASH_SUCCESS
  };
}

export function validateRecoveryHashFailure() {
  return {
    type: VALIDATE_RECOVERY_HASH_FAILURE
  };
}

export function passwordResetSuccess() {
  return {
    type: PASSWORD_RESET_SUCCESS
  };
}

export function changeFailure(error) {
  return {
    type: CHANGE_FAILURE,
    error
  };
}


export function registerToGatheringsEmailList(howSolicited) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `howSolicited=${howSolicited}`
  };
  return dispatch => {
    dispatch(changeStart());
    fetch(`${constants.baseUrl}/api/v1/account/joinemailinglist`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(changeFailure("Register to emails failed"));
        } else {
          dispatch(setSolicitedEmailsTrue());
          dispatch(registerEmailsSuccess(howSolicited));          
        }
      })
      .catch(err => {
        dispatch(changeFailure(err));
      });
  };
}


export function startPasswordRecovery(email) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `email=${email}`
  };
  return dispatch => {
    dispatch(changeStart());
    fetch(`${constants.baseUrl}/api/v1/account/startrecoverpassword`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          dispatch(changeFailure(response.error));
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(changeFailure("Recover password request failed"));
        }
        dispatch(passwordRecoveryRequestSuccess());
      })
      .catch(err => {
        console.log("There was an error");
      });
  };
}

export function validateRecoveryHash(hash) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `hash=${hash}`
  };
  return dispatch => {
    dispatch(changeStart());
    fetch(`${constants.baseUrl}/api/v1/account/validaterecoveryhash`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          dispatch(changeFailure(response.error));
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(validateRecoveryHashFailure());
        } else {
          dispatch(validateRecoveryHashSuccess());
        }        
      })
      .catch(err => {
        console.log("There was an error");
      });
  };
}

export function setNewRecoveredPassword(password, hash) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `password=${password}&hash=${hash}`
  };
  return dispatch => {
    dispatch(changeStart());
    fetch(`${constants.baseUrl}/api/v1/account/resetpassword`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          dispatch(changeFailure(response.error));
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(changeFailure("Reset password request failed"));
        }
        dispatch(passwordResetSuccess());
      })
      .catch(err => {
        console.log("There was an error");
      });
  };
}



export function changePassword(email, oldPassword, newPassword,facebookID) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `email=${email}&oldPassword=${oldPassword || ''}&newPassword=${newPassword}&facebookID=${facebookID || ''}`
  };
  return dispatch => {
    dispatch(changeStart());
    fetch(`${constants.baseUrl}/api/v1/account/changepassword`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          dispatch(changeFailure(response.error));
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
        	dispatch(changeFailure("Change password failed"));
        }
        dispatch(changePasswordSuccess());
        dispatch(setIsPasswordExist())
      })
      .catch(err => {
        console.log("There was an error");
      });
  };
}


export function deleteAccount(email, password) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `email=${email}&password=${password}`
  };
  return dispatch => {
    dispatch(changeStart());
    fetch(`${constants.baseUrl}/api/v1/account/deleteaccount`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          dispatch(changeFailure(response.error));
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(changeFailure("Delete account failed"));
        }
        dispatch(logout());
        dispatch(deleteAccountSuccess());
      })
      .catch(err => {
        console.log("There was an error");
      });
  };
}