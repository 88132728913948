import * as Actions from "../actions/presenceCount";

const initialState = {
  general: 0,
  myCircle: 0
};

export function presenceCount(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_NUM_PEOPLE:
      return action.data;
    default:
      return state;
  }
}