import constants from "../constants";
import sendGAEvent from '../lib/sendGAEvent';
import {sendAmplitudeEvent} from '../lib/amplitude';
import sendFBPixelEvent from '../lib/sendFBPixelEvent';
import { clearSocialLoginButtons } from "./socialLogin";
import moment from 'moment-timezone';
import { openSocialAuthModal, clearAuthData, clearAuthModalForm, closeSocialAuthModal, setAlreadyRegisterWithEmail } from "./uiControls";
import { disconnectSocket } from '../lib/socketInit'
export const AUTH_START = "AUTH_START";
export const SET_STATE_ROLE_READY = "SET_STATE_ROLE_READY";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SET_SOLICITED_EMAILS_TRUE = "SET_SOLICITED_EMAILS_TRUE";
export const SET_NEEDS_VERIFICATION_FALSE = "SET_NEEDS_VERIFICATION_FALSE";
export const SET_TRIED_CONNECT_TRUE = "SET_TRIED_CONNECT_TRUE";
export const SET_REFERRER_URL = "SET_REFERRER_URL";
export const SET_CURRENT_URL = "SET_CURRENT_URL";
export const ADD_COMPLETED_HUMAN_MINUTE = "ADD_COMPLETED_HUMAN_MINUTE";
export const SET_ISPASSWORD_EXIST = "SET_ISPASSWORD_EXIST";
export const SET_FB_PASSWORD_EXIST_ERROR = "SET_FB_PASSWORD_EXIST_ERROR";
export const HIDE_POSITION_GUIDELINE = "HIDE_POSITION_GUIDELINE";
export const SET_ACCEPTED_GUIDANCE = "SET_ACCEPTED_GUIDANCE";
export const SET_EXISTING_SUBSCRIPTION_AMOUNT = "SET_EXISTING_SUBSCRIPTION_AMOUNT";
export const SET_FREE_EXPERIENCE_USED = "SET_FREE_EXPERIENCE_USED";


export function authStart() {
  return {
    type: AUTH_START
  };
}

export function setStateRoleReady() {
  return {
    type: SET_STATE_ROLE_READY
  };
}

export function authSuccess(data) {
  return {
    type: AUTH_SUCCESS,
    data
  };
}

export function authFailure(error) {
  return {
    type: AUTH_FAILURE,
    error
  };
}

export function logout() {
  localStorage.removeItem("token");
  disconnectSocket();  
  console.log("socket disconnected");
  return {
    type: LOGOUT
  };
}

export function setSolicitedEmailsTrue() {
  return {
    type: SET_SOLICITED_EMAILS_TRUE
  };
}

export function setNeedsVerificationFalse() {
  return {
    type: SET_NEEDS_VERIFICATION_FALSE
  };
}

export function setTriedConnectTrue() {
  return {
    type: SET_TRIED_CONNECT_TRUE
  };
}

export function setReferrerUrl(data) {
  return {
    type: SET_REFERRER_URL,
    data
  };
}

export function setCurrentUrl(data) {
  return {
    type: SET_CURRENT_URL,
    data
  };
}

export function addCompletedHumanMinute() {
  return {
    type: ADD_COMPLETED_HUMAN_MINUTE
  };
}

export function setIsPasswordExist() {
  return {
    type: SET_ISPASSWORD_EXIST
  };
}

export function setFbPasswordExistError() {
  return {
    type: SET_FB_PASSWORD_EXIST_ERROR
  }
}

export function hidePositionGuideline() {
  return {
    type: HIDE_POSITION_GUIDELINE
  };
}


export function setAcceptedGuidance() {
  return {
    type: SET_ACCEPTED_GUIDANCE
  };
}

export function setExistingSubscriptionAmount(amount) {
  return {
    type: SET_EXISTING_SUBSCRIPTION_AMOUNT,
    amount
  };
}

export function setFreeExperienceUsed() {
  return {
    type: SET_FREE_EXPERIENCE_USED
  };
}



export function loginUser(email, password) {
  let zone = moment.tz.guess();
  let timeZone = `${moment.tz(zone).format('zz')}`;
  let { appName, language, appCodeName, userAgent } = navigator
  let sessionId = localStorage.getItem('sessionId');

  let config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `email=${email}&password=${password}&appName=${appName}&language=${language}&userAgent=${userAgent}&appCodeName=${appCodeName}&timeZone=${timeZone}&sessionId=${sessionId}`
  };
  return async (dispatch,getState) => {
    let category = getState().uiControls.authEventsCategory;

    dispatch(authStart());
    dispatch(clearSocialLoginButtons());
    fetch(`${constants.baseUrl}/api/v1/account/login`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if(response.isPasswordNotExist) {
          return dispatch(setFbPasswordExistError())
        }
        if (response.token) {
          localStorage.setItem("token", response.token);
        }
        dispatch(authSuccess(response));
        dispatch(clearAuthData())
        dispatch(clearAuthModalForm())
        sendGAEvent(category, 'Sign in', 'Success');
        sendAmplitudeEvent('login success', {'login type': 'email'});
      })
      .catch(err => {
        console.log(err);
        dispatch(authFailure(err));
        sendGAEvent(category, 'Sign in', 'Fail credentials');
        sendAmplitudeEvent('login fail', {'login type': 'email'});
      });
  };
}

export function registerUser(email, password, name, emailListRegistration, invitationHash, referrerUrl, currentUrl) {
  let zone = moment.tz.guess();
  let timeZone = `${moment.tz(zone).format('zz')}`;
  let { appName, language, appCodeName, userAgent } = navigator
  let sessionId = localStorage.getItem('sessionId');

  let config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `email=${email}&password=${password}&name=${name}&emailListRegistration=${emailListRegistration}&invitationHash=${invitationHash}&appName=${appName}&appCodeName=${appCodeName}&language=${language}&userAgent=${userAgent}&referrerUrl=${referrerUrl}&currentUrl=${currentUrl}&timeZone=${timeZone}&sessionId=${sessionId}`
  };
  return async (dispatch,getState) => {
    let category = getState().uiControls.authEventsCategory;

    dispatch(authStart());
    dispatch(clearSocialLoginButtons());
    fetch(`${constants.baseUrl}/api/v1/account/register`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if(response.isPasswordNotExist) {
          return dispatch(setFbPasswordExistError())
        }
        if (response.token) {
          localStorage.setItem("token", response.token);
        }
        dispatch(authSuccess(response));
        dispatch(clearAuthData())
        dispatch(clearAuthModalForm())
        sendAnalyticsAuthEvents(response.needsVerification, invitationHash, category);
        
      })
      .catch(err => {
        console.log(err);
        dispatch(authFailure(err));
        sendGAEvent(category, 'Create account', 'Fail server');
      });
  };
}

export function addRegistrationInfo(email, motivation, reference, explanation) {
  let config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `email=${email}&motivation=${motivation}&reference=${reference}&explanation=${explanation}`
  };
  return dispatch => {
    dispatch(authStart());
    fetch(`${constants.baseUrl}/api/v1/account/addregistrationinfo`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (response.token) {
          localStorage.setItem("token", response.token);
        }
        dispatch(authSuccess(response));
      })
      .catch(err => {
        console.log(err);
        dispatch(authFailure(err));
      });
  };
}

export function loginFromToken(token) {
  let config = {
    method: "GET",
    headers: {
      Authorization: `bearer ${token}`
    }
  };
  return dispatch => {
    dispatch(authStart());
    fetch(`${constants.baseUrl}/api/v1/account/loginfromtoken`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (response.token) {
          localStorage.setItem("token", response.token);
        }
        dispatch(authSuccess(response));
      })
      .catch(err => {
        console.log(err);
        dispatch(authFailure(err));
      });
  }
}

export function socialUserLogin(email, name, facebookID, referrerUrl, currentUrl,invitationHash,accessToken, password='') {
  let zone = moment.tz.guess();
  let timeZone = `${moment.tz(zone).format('zz')}`;
  let { appName, language, appCodeName, userAgent } = navigator
  let sessionId = localStorage.getItem('sessionId');

  let config = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `email=${email || ''}&name=${name}&appName=${appName}&appCodeName=${appCodeName}&language=${language}&userAgent=${userAgent}&referrerUrl=${referrerUrl}&currentUrl=${currentUrl}&timeZone=${timeZone}&facebookID=${facebookID}&invitationHash=${invitationHash}&accessToken=${accessToken}&password=${password}&sessionId=${sessionId}`
  };
  return async (dispatch,getState) => {
    let category = getState().uiControls.authEventsCategory;

    dispatch(authStart());
    fetch(`${constants.baseUrl}/api/v1/account/socialLogin`, config)
      .then(res => {
        if (res.status === 401) {
          throw new Error("401");
        }
        return res.json();
      })
      .then(response => {

        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (response.needEmail) {
          return (dispatch(openSocialAuthModal()),sendGAEvent(category, 'Create account', 'Facebook account without Email'))
        }
        if (response.needPassword) {
          return (dispatch(setAlreadyRegisterWithEmail()),sendGAEvent(category, 'Sign in', 'Facebook login require Password'))
        }
        if (response.token) {
          localStorage.setItem("token", response.token);
        }

        dispatch(authSuccess(response));
        dispatch(clearAuthData());
        dispatch(clearSocialLoginButtons());
        dispatch(closeSocialAuthModal());
        sendGAEvent(category, response.action, response.label);
        
        if (response.label === "Success with Facebook") {
          sendFBPixelEvent('CompleteRegistration');
        }
        if (response.action === "Sign in") {
          sendAmplitudeEvent('login success', {'login type': 'facebook'});
        }
        if (response.action === "Create account") {
          let needsVerification = true;
          if (response.label === "Success with Facebook") {
            needsVerification = false;
          }
          sendAmplitudeEvent('create account',{'account type': 'facebook', 'needs verification': needsVerification})
        }

      })
      .catch(err => {
        console.log(err);
        dispatch(authFailure(err));
        dispatch(clearAuthData());
        sendAmplitudeEvent('login fail', {'login type': 'facebook'});
        if (err === "401") {
          sendGAEvent(category, 'Sign in', 'Fail with Facebook - first time login - connect to existing HO account - wrong password');
        } else {
          sendGAEvent(category, 'Facebook login', 'Fail server');
        }
      });
  };
}

export function permanentlyHidePositionGuideline() {
    let config = {
      method: "GET",
      headers: {
        Authorization: `bearer ${localStorage.getItem("token")}`
      }
    };
    return dispatch => {
        fetch(`${constants.baseUrl}/api/v1/userpreferences/positionguideline`, config)
        .then(res => {
            return res.json();
        })
        .then(response => {
            if (response.error) {
            throw new Error(`Error: ${response.error}`);
            }
            dispatch(hidePositionGuideline());
        })
        .catch(err => {
            console.log(err);
        });
    }
}

export function acceptedGuidance() {
  let config = {
    method: "POST",
    headers: {
      Authorization: `bearer ${localStorage.getItem("token")}`
    }
  };
  return dispatch => {
      fetch(`${constants.baseUrl}/api/v1/account/acceptedguidance`, config)
      .then(res => {
          return res.json();
      })
      .then(response => {
          if (response.error) {
          throw new Error(`Error: ${response.error}`);
          }
          dispatch(setAcceptedGuidance());
      })
      .catch(err => {
          console.log(err);
      });
  }
}

export function rejectedGuidance() {
  let config = {
    method: "POST",
    headers: {
      Authorization: `bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };
  return dispatch => {
      fetch(`${constants.baseUrl}/api/v1/account/rejectedguidance`, config)
      .then(res => {
          return res.json();
      })
      .then(response => {
          if (response.error) {
          throw new Error(`Error: ${response.error}`);
          }
      })
      .catch(err => {
          console.log(err);
      });
  }
}

function sendAnalyticsAuthEvents(needsVerification, invitationHash, category) {
  if (!needsVerification && !invitationHash) {
    sendAmplitudeEvent('login success', {'login type': 'email'});
    return sendGAEvent(category, 'Sign in', 'Success via Create account');
  }

  sendAmplitudeEvent('create account',{'account type': 'email', 'needs verification': needsVerification})

  if (needsVerification && invitationHash) {
    return sendGAEvent(category, 'Create account', 'Completed account form via invitation');
  }

  if (needsVerification && !invitationHash) {
    return sendGAEvent(category, 'Create account', 'Completed account form');
  }

  if (!needsVerification && invitationHash) {
    sendFBPixelEvent('CompleteRegistration');
    return sendGAEvent(category, 'Create account', 'Success via invitation');
  }
}
