import { connect } from 'react-redux';
import BetaExperiencesPage from './BetaExperiencesPage';
import { getExperiences } from '../../actions/experiences';



const mapStateToProps = (state) => {
    return {
        userRole: state.userRole,
        userState: state.userState,
        peerRoom: state.peerRoom,
        experiences: state.experiences
    };
};


const mapDispatchtoProps = {
    getExperiences
};

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(BetaExperiencesPage);
