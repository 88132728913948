import * as Actions from "../actions/experienceInvitation";

const initialState = {
    experience: {},
    roomID: "",
    clickedConnect: false,
    clickedCancel: false,
    isFetching: false,
    error: null
};

export function experienceInvitation(state = initialState, action) {
    switch (action.type) {
        case Actions.RETRIEVE_EXPERIENCE_INVITATION_START:
            return {
                ...state,
                roomID: action.data,
                experience: {},
                isFetching: true,
                error: null
            };
        case Actions.RETRIEVE_EXPERIENCE_INVITATION_SUCCESS:
            return {
                ...state,
                experience: action.data.experience,
                isFetching: false,
                error: null
            };
        case Actions.RETRIEVE_EXPERIENCE_INVITATION_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case Actions.CLICKED_CONNECT_EXPERIENCE_INVITE:
            return {
                ...state,
                clickedConnect: true
            };
        case Actions.CLICKED_CANCEL_EXPERIENCE_INVITE:
            return {
                ...state,
                clickedConnect: false,
                clickedCancel: true
            };
        default:
            return state;
    }
}