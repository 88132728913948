import React from 'react';
import textResource from '../../lib/textLoader';
import chromeCameraImg from "../../resources/images/camera_chrome.png";
import firefoxCameraImg from "../../resources/images/camera_firefox.png";
import ParagraphCreator from '../elements/ParagraphCreator';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import LayoutWrapper from '../elements/LayoutWrapper';


const CameraGeneralFailure = () => {


	return (
  	<LayoutWrapper>
		  	<h1 className="pb-3">{textResource["camera_not_allowed.title"]}</h1>
		  	<br/>
		  	<ParagraphCreator text={textResource["camera_not_allowed.introduction"]} />
		  	<br/><br/>
		  	<p>
		  		<a href="#chrome">
		  			{textResource["camera_not_allowed.chrome_instructions_link"]}
		  		</a>
		  	</p>
		  	<p>
		  		<a href="#firefox">
		  			{textResource["camera_not_allowed.firefox_instructions_link"]}
		  		</a>
		  	</p>
		  	<br/><br/><br/><br/>
		  	<h3 className="pt-5 pb-3">
		  		<a name="chrome" href="/#">
		  			{textResource["camera_not_allowed.chrome_instructions.title"]}
		  		</a>
		  	</h3>
		  	<br/>
		  	<img id="chromeCameraImg" alt="" src={chromeCameraImg} />
		  	<br/><br/>
		  	<ol>
		  		<li>{textResource["camera_not_allowed.chrome_instructions.click_camera"]}</li>
		  		<li>{textResource["camera_not_allowed.chrome_instructions.select_option"]}</li>
		  		<li>{textResource["camera_not_allowed.chrome_instructions.press_continue"]}</li>
		  	</ol>
		  	<Link to="/" style={{ textDecoration: "none", color: "black" }}>
		  		<Button color="primary">
		  			{textResource["general_camera_failure.continue_btn"]}
		    	</Button>
	    	</Link>
		  	<br/><br/><br/><br/><br/><hr/><br/><br/><br/><br/>
		  	<h3 className="pt-5 pb-3">
		  		<a name="firefox" href="/#">
		  			{textResource["camera_not_allowed.firefox_instructions.title"]}
		  		</a>
		  	</h3>
		  	<br/>
		  	<img id="firefoxCameraImg" alt="" src={firefoxCameraImg} />
		  	<br/><br/>
		  	<ol>
		  		<li>{textResource["camera_not_allowed.firefox_instructions.click_camera"]}</li>
		  		<li>{textResource["camera_not_allowed.firefox_instructions.click_x"]}</li>
		  		<li>{textResource["camera_not_allowed.firefox_instructions.next_time"]}</li>
		  		<li>{textResource["camera_not_allowed.firefox_instructions.press_continue"]}</li>
		  	</ol>
		  	<Link to="/" style={{ textDecoration: "none", color: "black" }}>
		  		<Button color="primary">
		  			{textResource["general_camera_failure.continue_btn"]}
		    	</Button>
	    	</Link>
		  	<br/>
		  	<br/>
		  	<br/>
		</LayoutWrapper>
	)
}

export default CameraGeneralFailure;