import React from 'react';
import textResource from '../../lib/textLoader';
import { Button } from 'reactstrap';
import '../ActiveExperience/ActiveExperienceStyles.css'
import sendGAEvent from '../../lib/sendGAEvent';


const handleConnectClick = (userRole,setSocialLoginButtons,openAuthModal, setAuthEventsCategory) => {

	if(userRole.registeringStage === "sentCredentials") {	
		return openAuthModal()	
	}	
	setAuthEventsCategory('Authentication-btnConnect');
	sendGAEvent('Authentication-btnConnect', 'Open', '');
	// if(window.innerWidth <= 767) {
	// 	let containerOffSet = document.getElementById("hm-area-wrap").offsetTop;
	// 	window.scrollTo({ top: containerOffSet - 15, behavior: 'smooth'})
	// }
	return setSocialLoginButtons()	
}

const SignInButton = ({userRole, setSocialLoginButtons, openAuthModal, setAuthEventsCategory}) => {

	if (!userRole.ready) {return null;}

    let buttonText = (userRole.registeringStage === "sentCredentials") ? textResource["lobby.continue_creating_account_btn"] : textResource["lobby.sign_in_btn"];
  return (
    <Button size="lg" className="BtnAuthModal" color="default" onClick={() => handleConnectClick(userRole,setSocialLoginButtons,openAuthModal, setAuthEventsCategory)}>
        {buttonText}
    </Button>
  )
}

export default SignInButton;