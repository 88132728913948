import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import textResource from '../../lib/textLoader';
import './TopNavbarStyles.css';

const Footer = ({ userRole, openAuthModal, isInExperience, location }) => {

	if (isInExperience) {
		return null;
	}

  if (location.pathname.split("/")[1] === "xp-i") {
    return (
		<footer>
			<div className="footer">
				<div className="pb-5 footerLinks">
				</div>
				<div className="pb-5 footerLinks">
				</div>
			</div>
		</footer>
    );
  }

	return (
		<footer>
			<div className="footer">
				<p style={{color: "white"}}>{textResource["footer.follow_us"]}</p>
				<ul className="socialMediaLinks">
					<li>
						<a className="facebook" href="https://www.facebook.com/www.human.online" target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon icon={faFacebookSquare} color="white" size="sm" />
						</a>
					</li>
					<li>
						<a className="instagram" href="https://www.instagram.com/wwwhumanonline" target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon icon={faInstagram} color="white" size="sm" />
						</a>
					</li>
					<li>
						<a className="youtube" href="https://www.youtube.com/channel/UCRZ3QgekpfABg0ublvi9X_Q" target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon icon={faYoutube} color="white" size="sm" />
						</a>
					</li>
				</ul>
			</div>
			<div className="pb-5 footerLinks">
				<NavLink to="/about" className="footer-link" activeStyle={{ textDecoration: "none" }}>
					{textResource["footer.about"]}
				</NavLink>
				{
					/*
					userRole.email ?
						(<NavLink to="/feedback" className="footer-link" activeStyle={{ textDecoration: "none" }}>
							{textResource["footer.feedback"]}
						</NavLink>) :
						(<NavLink to="/" className="footer-link" onClick={openAuthModal}>
							{textResource["footer.feedback"]}
						</NavLink>)
					*/
				}

				<NavLink to="/terms" className="footer-link" activeStyle={{ textDecoration: "none" }}>
					{textResource["footer.terms"]}
				</NavLink>
			</div>
		</footer>
	);

};

export default Footer;
