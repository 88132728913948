import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import textResource from '../../../lib/textLoader';



const NoRepeatInfoTooltip = () => {

  return (
    <UncontrolledTooltip
    	placement="right"
    	boundariesElement="window"
    	target="NoRepeatInfoTrigger"
    	innerClassName="inner-tooltip"
    	className="not-transparent"
    	style={{opacity: 1}}
    >
      If you select this option, you will not be connected to people with whom you have completed any experience within the last hour.
    </UncontrolledTooltip>
  )
}

export default NoRepeatInfoTooltip;