import constants from "../constants";

export const USER_REPORT_START = "USER_REPORT_START";
export const USER_REPORT_SUCCESS = "USER_REPORT_SUCCESS";
export const USER_REPORT_FAILURE = "USER_REPORT_FAILURE";
export const CLEAR_USER_REPORT = "CLEAR_USER_REPORT";

export function userReportStart() {
  return {
    type: USER_REPORT_START
  };
}

export function userReportSuccess() {
  return {
    type: USER_REPORT_SUCCESS
  };
}

export function userReportFailure(error) {
  return {
    type: USER_REPORT_FAILURE,
    error
  };
}

export function clearUserReport() {
  return {
    type: CLEAR_USER_REPORT
  };
}


export function sendUserReport(reportExplanation='', roomID, position, selectedOptions, noRepeat) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `reportExplanation=${reportExplanation}&roomID=${roomID}&position=${position}&selectedOptions=${JSON.stringify(selectedOptions)}&noRepeat=${noRepeat}`
  };
  return dispatch => {
    dispatch(userReportStart());
    fetch(`${constants.baseUrl}/api/v1/userreport`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(userReportFailure("Sending user report failed"));
        } else {
          dispatch(userReportSuccess());
        }        
      })
      .catch(err => {
      	dispatch(userReportFailure(err));
        console.log("Sending user report error: ", err);
      });
  };
}