import { connect } from 'react-redux';
import PresenceIndicator from './PresenceIndicator';
import { openNoOneInCircleModal } from "../../actions/uiControls";


const mapStateToProps = (state) => {
  return {
    presenceCount: state.presenceCount,
    gatheringsInfo: state.gatheringsInfo,
    userState: state.userState,
    userRole: state.userRole
  };
};

const mapDispatchtoProps = {
  openNoOneInCircleModal
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(PresenceIndicator);