import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import '../../AccountPages/AccountPagesStyles.css';
import textResource from '../../../lib/textLoader';
import { Button, Form, FormGroup, Input, Col, Row } from 'reactstrap';
import ValidationErrorMessage from '../ValidationErrorMessage';
import ErrorMessage from '../ErrorMessage';
import LayoutWrapper from '../../elements/LayoutWrapper';


const RequestRecoverPassword = ({ onSubmit, accountChanges, errors }) => {

	if (accountChanges.passwordRecoveryRequested) {
		return <Redirect to='/' />
	}

	return (
		<LayoutWrapper>
		  	<h3 className="pt-5 pb-3">{textResource["reset_password_enter_email.title"]}</h3>
		  	<br/>
		  	<p>{textResource["reset_password_enter_email.enter_email"]}</p>
		    <Form id="FormRequestRecoverPassword" onSubmit={onSubmit}>
		      <ErrorMessage errors={errors.fromServer ? errors : {}} />

		      <FormGroup row>
		        <Col xs={8} lg={4}>
			        <Input 
			          invalid={!!errors.email}
			          type="text" 
			          name="email" 
			          id="recoveryEmail" 
			        />
			        <ValidationErrorMessage message={errors.email} />
			      </Col>		        
		      </FormGroup>
		      <br/>
		      <Row>
		      	<Col xs={{ size: 4 }}>
				      <Button id="BtnRequestRecoverPassword" color="primary" block>
				        {textResource["reset_password_enter_email.btn_send"]}
				      </Button>
		      	</Col>
		      	<Col xs={{ size: 4 }}>
						  <Link to="/" style={{ textDecoration: "none", color: "black" }}>
					  		<Button color="secondary" block>
					  			Cancel
					    	</Button>
				    	</Link>
		      	</Col>
		      </Row>
		    </Form>
		</LayoutWrapper>
	)
}

export default RequestRecoverPassword;