import { connect } from 'react-redux';
import Profile from './Profile';
import { clearChanges } from "../../actions/accountChanges";


const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    passwordChanged: state.accountChanges.passwordChanged
  };
};

const mapDispatchtoProps = {
  clearChanges
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(Profile);