import React, { Component } from 'react';

import InvitationFormContainer from './InvitationFormContainer';
import CheckEmailContainer from './EmailVerification/CheckEmailContainer';
import LobbyAlertsContainer from './LobbyAlertsContainer';
import GatheringsAreaContainer from './GatheringsArea/GatheringsAreaContainer';
import WaitingList from './EmailVerification/WaitingList';

import { Redirect } from 'react-router-dom';

import qs from 'qs';
import TestimonialContainer from './Testimonial/TestimonialContainer';
import PartnerContainer from './OurPartners/PartnerContainer';
import TargetGroupValuePropositionsContainer from './TargetGroupValuePropositions/TargetGroupValuePropositionsContainer';
import KeepMeInformedButtonContainer from './GatheringsArea/KeepMeInformedButtonContainer';
import ConnectVideoContainer from './ConnectVideo/ConnectVideoContainer';
// import ExperiencesAreaContainer from '../Experiences/ExperiencesAreaContainer';
// import ExperienceModalContainer from '../Experiences/ExperienceModalContainer';
import ExperienceInvitationModalContainer from '../Experiences/ExperienceInvitation/ExperienceInvitationModalContainer';
import NoOneInCircleModalContainer from '../Experiences/NoOneInCircleModalContainer';
import PresenceIndicatorContainer from './PresenceIndicatorContainer';
import HumanMinuteAreaContainer from './HumanMinuteArea/HumanMinuteAreaContainer';

import TopCarouselContainer from './TopCarouselContainer';

class Lobby extends Component {
  componentDidMount() {
    // For users coming from Invitation email, open auth modal and preload it:
    let { search } = this.props.location;
    // We do the following only if there is no token, otherwise it's not a new user
    if (search && !localStorage.getItem("token")) {
      let invitationHash = qs.parse(search, { ignoreQueryPrefix: true }).invitation;
      if (invitationHash) {
        this.props.checkHash(invitationHash);
      }
    }
    if(document.location.hash && this.props.userRole.role === 'guest') {
      let access_token = new URL(document.location.href).hash.split('&').filter(function(el) { if(el.match('access_token') !== null) return true; })[0].split('=')[1];
      if(access_token) {
        this.props.loginGraphApi(access_token)
      }
    }

    if (this.props.userRole.email === "experience-guest") {
      this.props.logout()
    }   
  }

  componentDidUpdate(prevProps){
    if (
      !prevProps.registeringFromInvitation.hashCheckReady &&
      this.props.registeringFromInvitation.hashCheckReady &&
      this.props.registeringFromInvitation.hashCheckSuccess
    ) {
      this.props.openAuthModal();
      this.props.setAuthModalForm();
    }

    if (!prevProps.userRole.email && this.props.userRole.email && this.props.userRole.email === "experience-guest") {
      this.props.logout()
    }   
  }

  render() {
    const { userRole, removeToken, currentGathering, mostRecentlyEndedGathering, nextRegularGathering, nextSpecialGathering,} = this.props;
    if (userRole.needsVerification) {

      return (
        <div>
          <LobbyAlertsContainer currentGathering={currentGathering}/>
          <CheckEmailContainer />
        </div>
      );
    }

    // This part is only relevant if invitation-only mode is activated in the server
    if (userRole.registeringStage === "sentInfo") {
      return (
        <div>
          <LobbyAlertsContainer currentGathering={currentGathering}/>
          <WaitingList />
        </div>
      )
    }

    return <div id="Lobby"></div>

    /*
    return (
      
      <div id="Lobby">
        {removeToken && <Redirect to='/' />}
        {
          // userRole.role !== "guest" && <ExperienceModalContainer />
        }
        {userRole.role !== "guest" && <ExperienceInvitationModalContainer />}
        {userRole.role !== "guest" && <NoOneInCircleModalContainer />}   
        {
          userRole.role !== "guest" &&
          this.props.location.pathname === "/" &&
          <TopCarouselContainer
            currentGathering={currentGathering}
            nextRegularGathering={nextRegularGathering}
            nextSpecialGathering={nextSpecialGathering}
          />
        }
        <div className="presence-indicator">
          {
            this.props.location.pathname === "/" &&
            <PresenceIndicatorContainer />
          }
        </div>
        
        <LobbyAlertsContainer currentGathering={currentGathering}/>
        <GatheringsAreaContainer 
          location={this.props.location} 
          currentGathering={currentGathering}
          mostRecentlyEndedGathering={mostRecentlyEndedGathering}
        />
        <HumanMinuteAreaContainer />
        {
          // <ConnectVideoContainer />
        }
        {
          // userRole.role !== "guest" && <ExperiencesAreaContainer experienceStatus="production"/>
        }
        {userRole.role !== "guest" && <InvitationFormContainer />}
        <div style={{marginBottom: "40px"}}>
          <TestimonialContainer></TestimonialContainer>
        </div>
        
        {
          //<TargetGroupValuePropositionsContainer />
        }
        {
          // !userRole.email && <ExperiencesAreaContainer experienceStatus="production"/>
        }
        {!userRole.email && <PartnerContainer withTitle={true}/>}
      </div>
    );
  */
  }
}

export default Lobby;
