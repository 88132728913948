import { connect } from 'react-redux';
import AboutPage from './AboutPage';



const mapStateToProps = (state, ownProps) => {
  return {
    userRole: state.userRole,
    registeredToEmails: state.accountChanges.registeredToEmails,
    howSolicited: state.accountChanges.howSolicited,
  };
};


export default connect(
  mapStateToProps,
  null
)(AboutPage);