import React from 'react';
import textResource from '../../../lib/textLoader';
import ConnectButtonContainer from '../../ConnectionControls/ConnectButtonContainer';
import { Button } from 'reactstrap';
import ConnectOptionsContainer from '../../ConnectionControls/ConnectOptions/ConnectOptionsContainer';
import Spinner from "../../elements/Spinner";
import {sendAmplitudeEvent} from "../../../lib/amplitude"


class PresentState extends React.Component {

    constructor() {
        super();

        this.onClickConnectInvitePage = this.onClickConnectInvitePage.bind(this);
        this.onClickCancelInvitePage = this.onClickCancelInvitePage.bind(this);
    }

    onClickConnectInvitePage() {
        this.props.clickConnectExperienceInvite();
        this.props.matchmakerSuccess({
            roomID: this.props.experienceInvitation.roomID,
            position: 2
        });
        if (!this.props.experienceInvitation.clickedCancel) {
            sendAmplitudeEvent('click connect on invite page', {'experience type': this.props.experienceInvitation.experience.type});
        }
    }

    onClickCancelInvitePage() {
        if (!this.props.experienceInvitation.clickedCancel) {
            sendAmplitudeEvent('click cancel on invite page', {'experience type': this.props.experienceInvitation.experience.type});
        }
        this.props.clickCancelExperienceInvite();
    }

    render() {
        let { userRole, peerRoom } = this.props;

        if (peerRoom.activeExperienceInvitation) {

            let disabled = this.props.realtime ? false : true;
            return(
                <div className="centeredBoxHM">
                    {
                        this.props.experienceInvitation.clickedConnect ?
                        <Button size="lg" color="secondary" onClick={this.onClickCancelInvitePage}>
                            Cancel
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Spinner size="1.5rem"/>    
                        </Button> :
                        <Button id="BtnConnect" size="lg" color="default" disabled={disabled} onClick={this.onClickConnectInvitePage}>
                            Connect
                        </Button>
                    }
                </div>
            )
        }

        return(
            <div className="centeredBoxHM">
                <div>
                <ConnectOptionsContainer experience={{type: "oneminute"}} />
                </div>
            </div>
        )
    }
}

export default PresentState; 