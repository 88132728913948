import React from 'react';
import '../ActiveExperienceStyles.css';

const message = (audioState) => {
	let textMessage;
	if (audioState === "on") {
		textMessage = "Your audio is now ON"
	} else {
		textMessage = "Your audio is now OFF"
	}
	return (
		<div className="low-text">
			<p>{textMessage}</p>
		</div>
	)
}

class AudioOpenIndicator extends React.Component {

	render() {
		if (this.props.userState !== "connected") {return null;}
		if (!this.props.showUserAudioMessage) {return null;}
		let textMessage;
		if (this.props.userAudio) {
			textMessage = "Your audio is now ON"
		} else {
			textMessage = "Your audio is now OFF"
		}
		return (
			<div className="audio-message-text">
				<p>{textMessage}</p>
			</div>
		)
	}
}

export default AudioOpenIndicator;