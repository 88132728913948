import constants from "../constants";

import { setNeedsVerificationFalse, authSuccess } from "./userRole";
import sendGAEvent from '../lib/sendGAEvent';
import {sendAmplitudeEvent} from '../lib/amplitude';
import sendFBPixelEvent from '../lib/sendFBPixelEvent';

export const VERIFICATION_START = "VERIFICATION_START";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_FAILURE = "VERIFICATION_FAILURE";
export const CLEAR_VERIFICATION = "CLEAR_VERIFICATION";
export const RESEND_SUCCESS = "RESEND_SUCCESS";
export const RESEND_FAILURE = "RESEND_FAILURE";
export const FETCH_FAILURE = "FETCH_FAILURE";

export function verificationStart() {
  return {
    type: VERIFICATION_START
  };
}

export function verificationSuccess() {
  return {
    type: VERIFICATION_SUCCESS
  };
}

export function verificationFailure() {
  return {
    type: VERIFICATION_FAILURE
  };
}

export function resendSuccess() {
  return {
    type: RESEND_SUCCESS
  };
}

export function clearVerification() {
  return {
    type: CLEAR_VERIFICATION
  };
}

export function fetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    error
  };
}



export function sendVerification(hash) {
  let config = {
    method: "GET",
    headers: {
      Authorization: `bearer ${localStorage.getItem("token")}`
    }
  };
  return dispatch => {
    dispatch(verificationStart());
    fetch(`${constants.baseUrl}/api/v1/account/confirmemail/${hash}`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(verificationFailure());
          sendGAEvent('Authentication', 'Confirm email', 'Failure');
        } else {
          if (response.token) {
            localStorage.setItem("token", response.token);
          }
          if (response.email) {
            dispatch(authSuccess(response));
          } else {
            dispatch(setNeedsVerificationFalse());
          }          
        	dispatch(verificationSuccess());
          if (response.facebookID) {
            sendGAEvent('Authentication', 'Confirm email for Facebook account', 'Success');
          } else {
            sendGAEvent('Authentication', 'Confirm email', 'Success');
            sendAmplitudeEvent('verify account');
          }
          
          sendFBPixelEvent('CompleteRegistration');
        }        
      })
      .catch(err => {
      	dispatch(fetchFailure(err));
        console.log("Verification error: ", err);
      });
  };
}



export function resendVerificationEmail() {
  let config = {
    method: "GET",
    headers: {
      Authorization: `bearer ${localStorage.getItem("token")}`
    }
  };
  return dispatch => {
    dispatch(verificationStart());
    fetch(`${constants.baseUrl}/api/v1/account/resendemail`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (!response.success) {
          dispatch(fetchFailure("could not resend"));
        } else {
          dispatch(resendSuccess());
        }        
      })
      .catch(err => {
        dispatch(fetchFailure(err));
        console.log("Verification error: ", err);
      });
  };
}