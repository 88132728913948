import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col } from 'reactstrap';
import textResource from '../../lib/textLoader';


const TeamMemberModal = ({type, name}) => {

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <div onClick={toggle}>
      	<img src={`https://ho-testing.s3.eu-west-2.amazonaws.com/${type}-${name}.jpg`} />                     
				<h5 className="pt-3"> {textResource[`about.${type}.${name}.title`]} </h5>
				<p>{textResource[`about.${type}.${name}.role`]}</p>                
      </div>

      <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
        	<div className="team-area-modal">
          	<img src={`https://ho-testing.s3.eu-west-2.amazonaws.com/${type}-${name}.jpg`} />
            <h5 className="pt-4">{textResource[`about.${type}.${name}.title`]}</h5>
						<p className="pt-3">{textResource[`about.${type}.${name}.role`]}</p>
						<p className="pt-3 pb-3">{textResource[`about.${type}.${name}.note`]}</p>
					</div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default TeamMemberModal;