import { emitAction } from '../lib/socketInit'

export const SET_PEER_READY = "SET_PEER_READY";
export const SET_PEER_CONNECTION_READY = "SET_PEER_CONNECTION_READY";
export const SET_SESSION_DATA_SENT = "SET_SESSION_DATA_SENT";
export const RESET_ALL_SESSION_STATE = "RESET_ALL_SESSION_STATE";
export const SET_PEER_CONNECTION_FAILED = "SET_PEER_CONNECTION_FAILED";


export function setPeerReady() {
  return {
    type: SET_PEER_READY
  };
}

export function setPeerConnectionReady() {
  return {
    type: SET_PEER_CONNECTION_READY
  };
}

export function setSessionDataSent() {
  return {
    type: SET_SESSION_DATA_SENT
  };
}

export function resetAllSessionState() {
  return {
    type: RESET_ALL_SESSION_STATE
  };
}

export function setPeerConnectionFailed() {
  return {
    type: SET_PEER_CONNECTION_FAILED
  };
}

export function sendSessionData(position, roomID, duration, endReason, experienceType) {
  let token = localStorage.getItem("token")
  return dispatch => {
    dispatch(emitAction( 'updatesessiondata', {position, roomID, duration, endReason, experienceType, token} ))
  };
}