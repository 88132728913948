import React from 'react';
import textResource from '../../lib/textLoader';
import { Button } from 'reactstrap';
import Spinner from "../elements/Spinner"

const CancelButton = ({onClick, selectedLanguage, userState, experienceType}) => {
	if (userState === "waiting") {
	  return (
			<Button id="BtnCancel" size="lg" color="secondary" onClick={onClick}>
				{
					experienceType === "oneminute" ?
					"Cancel" :
					<div>
						Cancel (Waiting for a partner)
						&nbsp;&nbsp;&nbsp;&nbsp;
						<Spinner size="1.5rem"/>						
					</div>
				}
			</Button>
	  )		
	} else {
	  return (
			<Button id="BtnCancel" size="lg" color="secondary" onClick={onClick}>
				{textResource[`session_controls.cancel_btn.${selectedLanguage}`]}
			</Button>
	  )
	}
}

export default CancelButton;