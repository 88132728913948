import React from 'react';
import textResource from '../../../lib/textLoader';
import { Button, Modal, ModalHeader, Row, Col, Badge } from 'reactstrap';
import './ExperienceInvitationModalStyles.css';
import copy from 'copy-to-clipboard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import {sendAmplitudeEvent} from "../../../lib/amplitude"
import CheckIcon from '../../../resources/images/check.png'

class ExperienceInvitationModal extends React.Component {

	constructor() {
		super();

    this.state = {
      copied: false
    }

		this.onClickCopyLink = this.onClickCopyLink.bind(this);
	}

	componentWillUnmount() {
		let { peerRoom} = this.props;
		if (peerRoom.confirmed && peerRoom.activeExperienceInvitation) {
			this.props.closeExperienceInvitation(peerRoom.roomID);
		}
	}

	onClickCopyLink() {
  	let { peerRoom } = this.props;
  	let hostname = window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname;
  	if (hostname === "client-staging.human.online") {
  		hostname = "https://client-staging.human.online"
  	}
		copy(`${hostname}/xp-i/${peerRoom.roomID.split(":")[1]}`);
    this.setState({
      copied: true
    })
	}


  render() {
  	let { peerRoom} = this.props;
  	let hostname = window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname;
  	if (hostname === "client-staging.human.online") {
  		hostname = "https://client-staging.human.online"
  	}


    return (
    <Modal isOpen={peerRoom.activeExperienceInvitation} contentClassName="experience-invitation-modal-classname">
	    <Row>
	      <Col xs="12" >
	      	<p className="experience-invite-title">This is your personal invite:</p>
	      	<p className="experience-invite-link">{`${hostname}/xp-i/${peerRoom.roomID.split(":")[1]}`}</p>
	      	<div className="copy-invite-link" onClick={this.onClickCopyLink}>
	      		<FontAwesomeIcon
            	icon={faCopy}
            	size="lg"
            />
	      	</div>
	      	<p>
	      	{
	      		this.state.copied &&
	      		<div className="link-copied">
	      			<img src={CheckIcon} />
	      			Link copied!
	      		</div>
	      	}
	      	</p>
	      	<p>Copy the link and send it to a friend</p>
	      	<p className="stay-on-screen">Stay on this screen and you will be redirected when your friend joins</p>
	      	<Button 
	      		onClick={() => {
	      			this.props.cancelExperienceInvitation(peerRoom.roomID);
	      			sendAmplitudeEvent('cancel experience invite', {'experience type': peerRoom.experienceType});
					    this.setState({
					      copied: false
					    })
	      		}
	      	}>
	      		Cancel invitation
	      	</Button>
	      </Col>
	    </Row>
    </Modal>
    );
  }
}

export default ExperienceInvitationModal;