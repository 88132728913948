import { connect } from 'react-redux';
import Guidelines from './Guidelines';
import { acceptedGuidance, rejectedGuidance } from '../../../actions/userRole';
import { closeLanguageDropDown } from '../../../actions/selectLanguage';
import { setStatePresent, setStateWaiting } from '../../../actions/userState';


const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    userRole: state.userRole,
    languageDropDown: state.selectLanguage.languageDropDown,
    selectedLanguage: state.selectLanguage.selectedLanguage,
    experienceType: state.peerRoom.experienceType
  };
};

const mapDispatchtoProps = {
    acceptedGuidance,
    setStateWaiting,
    closeLanguageDropDown,
    rejectedGuidance,
    setStatePresent
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(Guidelines);