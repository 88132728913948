import { connect } from 'react-redux';
import PreparingContent from './PreparingContent';
import { permanentlyHidePositionGuideline } from '../../../actions/userRole';


const mapStateToProps = (state) => {
  return {
    peerReady: state.sessionState.peerReady,
    userState: state.userState,
    userRole: state.userRole
  };
};

const mapDispatchToProps = {
  permanentlyHidePositionGuideline
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreparingContent);