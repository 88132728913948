import React from 'react'
import {Alert} from 'reactstrap'
import {isEmpty} from '../../lib/validationHelpers'


const ErrorMessage = ({errors}) => {
  if (!errors || isEmpty(errors)) {
    return null
  }

  if (errors.fromServer) {
  	return (
	    <Alert color="danger">
	      {errors.fromServer}
	    </Alert>
  	)
  }

  return (
    <Alert color="danger">
      Please correct the fields in red.
    </Alert>
  )
}


ErrorMessage.defaultProps = {
  errors: {},
}

export default ErrorMessage