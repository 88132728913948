import React from 'react';
import textResource from '../../../lib/textLoader';
import { Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import LayoutWrapper from '../../elements/LayoutWrapper';


class ResendEmail extends React.Component {

	constructor() {
		super();

		this.onClickResend = this.onClickResend.bind(this);
	}

	componentWillUnmount() {
		if (!this.props.emailVerification.resentEmail) {
			this.props.clearVerification();
		}				
	}

	onClickResend() {
		if (this.props.email) {
			this.props.resendVerificationEmail();
		} else {
			this.props.openAuthModal();
		}
	}


  render() {
  	if (this.props.emailVerification.resentEmail) { return <Redirect to='/confirmemail' /> }

    return (
	  	<LayoutWrapper>
			  	<h1 className="pb-3">{textResource["resend_email.title"]}</h1>
			  	<br/>
			  	<p>{textResource["resend_email.explanation"]}</p>
			  	<br/>
			  	<Button color="primary" onClick={this.onClickResend}>
			  		{textResource["resend_email.send_btn"]}
			  	</Button>
			</LayoutWrapper>
    );
  }
}

export default ResendEmail;