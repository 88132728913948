import React from 'react';
import { Button, Alert } from 'reactstrap';
import FacebookLogin from 'react-facebook-login';
import ErrorMessage from './ErrorMessage';
import constants from "../../constants";
import sendGAEvent from '../../lib/sendGAEvent';
import Spinner from '../elements/BlackSpinner';
import textResource from '../../lib/textLoader';

class SocialLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
    this.handleFacebookResponse = this.handleFacebookResponse.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onFacebookClick = this.onFacebookClick.bind(this);
  }

  handleFacebookResponse(response) {
    let { userRole, registeringFromInvitation } = this.props;
    let { email, name, id, accessToken } = response;
    if(response.id){
      this.props.setFacebookData(response)
      this.props.socialUserLogin(email, name, id, userRole.referrerUrl, userRole.currentUrl, registeringFromInvitation.invitationHash,accessToken)
    }else{
      this.props.facebookFailure();
      this.props.clearAuthData();
      sendGAEvent(this.props.authEventsCategory, 'Sign in', 'Fail no Facebook profile');
    }
  }

  handleClick() {
    let { socialLogin, setLoginWithEmail, setAuthModalForm, openAuthModal, clearFacebookError } = this.props;
    sendGAEvent(this.props.authEventsCategory, 'Select Email');
    if(socialLogin.socialLoginButtons) {
      return(setLoginWithEmail(),clearFacebookError(),openAuthModal())
    }
    return (clearFacebookError(),setAuthModalForm())
  }

  onFacebookClick() {
    this.props.clearAuthModalForm();
    sendGAEvent(this.props.authEventsCategory, 'Select Facebook', '');
  }

  componentWillUnmount() {
    this.props.clearSocialLoginButtons()
  }

  render() {
    let { userRole, authModalType, authModalForm, socialLogin } = this.props;

    if(userRole.role !== "guest"){return null};
    return (
        <div className={socialLogin.socialLoginButtons ? "socialloginContainer connectButtonContainer" : "socialloginContainer"}>
          <p>{textResource["social_login_title.text"]} <a href="/faq" target="_blank">{textResource["social_login_learn_more_link.text"]}</a>. {textResource["social_login_agree.text"]} <a href="/communityguidelines" target="_blank">{textResource["social_login_community_guidelines_link.text"]}</a>, <a href="/terms" target="_blank">{textResource["social_login_terms_link.text"]}</a> {textResource["social_login_and.text"]} <a href="/privacy" target="_blank">{textResource["social_login_privacy_policy_link.text"]}</a></p>
          {socialLogin.error && 
            <Alert color="danger">
             {textResource["social_login_error_message.text"]}
            </Alert>
          }
          {userRole.isFetching ?
            (<div className="authSpinner">
              <Spinner />
            </div>) :
            <div>
              <FacebookLogin
                appId={constants.facebookId}
                fields="name,email"
                callback={this.handleFacebookResponse}
                cssClass="facebookButton btn"
                icon="fa-facebook-official"  
                textButton={textResource["social_login.continue_with_facebook_btn"]}
                onClick={this.onFacebookClick}
                redirectUri={window.location.origin}
                responseType={'token'}
              />
              <Button size="lg" color="default" onClick={this.handleClick} className={authModalForm ? "activeButton": ""}>{textResource["social_login.continue_with_email_btn"]}</Button>
            </div>
          }
          
        </div>
    )
  }
}

export default SocialLogin;
