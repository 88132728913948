import { emitAction } from "../lib/socketInit";
import constants from "../constants";
import { setExperiencesWithPeopleWaiting } from "./experiencesWithPeopleWaiting";

export const SET_NUM_PEOPLE = "SET_NUM_PEOPLE";

export function setNumPeople(data) {
  return {
    type: SET_NUM_PEOPLE,
    data
  };
}

export function emitPeopleStatus(type) {
  let token = localStorage.getItem("token") || "";
  return dispatch => {
    dispatch(emitAction(type, {token}))
  }
}

export function handleNewPeopleCount(data) {
  return (dispatch, getState) => {
    if(data.type === 'newPeopleCount'){
      dispatch(setNumPeople(data))
      let {experiences} = getState();
      let {experiencesByCategory} = experiences;
      let experiencesWithPeopleWaiting = calculateExperiencesWithPeopleWaiting(experiencesByCategory, data);
      dispatch(setExperiencesWithPeopleWaiting(experiencesWithPeopleWaiting));
    }
  }
}

export function setPresenceOnReconnect() {
  return (dispatch,getState) => {
    if(getState().userState !== 'absent'){
      dispatch(emitPeopleStatus('present'))
    }
  } 
}

export function getPresenceCount() {
  let config = {
    method: "GET"
  };
  return dispatch => {
    fetch(`${constants.realtimeUrl}/api/v1/presencecount`, config)
    .then(res => {
      return res.json();
    })
    .then(response => {
      if (response.error) {
        throw new Error(`Error: ${response.error}`);
      }
      dispatch(setNumPeople(response))
    })
    .catch(err => {
      console.log(err);
    });
  }
}



function calculateExperiencesWithPeopleWaiting(experiencesByCategory, presenceCount) {
  let experienceTypesWithPeopleWaiting = [];
  Object.keys(presenceCount).forEach((key) => {
    if (presenceCount[key] < 1) {return;}
    if (["general", "myCircle", "oneminute:anyone", "oneminute:myCircle"].includes(key)) {return;}
    let experienceType = key.split(":")[0];
    if (!experienceTypesWithPeopleWaiting.includes(experienceType)) {
      experienceTypesWithPeopleWaiting.push(experienceType);
    }
  })
  if (!experienceTypesWithPeopleWaiting.length) {
    return [];
  }
  let allExperiences = [];
  experiencesByCategory.forEach((experienceCategory) => {
    allExperiences = allExperiences.concat(experienceCategory.experiences);
  })
  let experiencesWithPeopleWaiting = allExperiences.filter((experience) => {
    return experienceTypesWithPeopleWaiting.includes(experience.type);
  })
  return experiencesWithPeopleWaiting;
}

