export const startVideo = (videoTag, stream) => {
	videoTag.srcObject = stream;
}

export const stopVideo = (videoTag) => {
	videoTag.style["transform"] = "none";
	videoTag.style["-webkit-transform"] = "none";
	videoTag.srcObject = null;
}

// line 7, 52 (put own video in big screen), 151 (put own video in small screen), 164 (put other video in big screen)

export const startSelfVideo = (videoTag, stream) => {
	videoTag.style["transform"] = "scaleX(-1)";
	videoTag.style["-webkit-transform"] = "scaleX(-1)";
	videoTag.srcObject = stream;
}