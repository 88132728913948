import React from 'react';
import LayoutWrapper from '../elements/LayoutWrapper';
import TermsMarkup from './TermsMarkup';
import textResource from '../../lib/textLoader';


const TermsPage = () => {


	return (
  	<LayoutWrapper>
		  {/* Welcome to Human Online -  */}
	  	<h1 className="pb-3">{textResource["terms.terms_of_use.title"]}</h1>
		  <TermsMarkup />
	  </LayoutWrapper>
	)
}

export default TermsPage;
