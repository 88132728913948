import React from 'react';
import textResource from '../../../lib/textLoader';
import { Link } from 'react-router-dom';
import LayoutWrapper from '../../elements/LayoutWrapper';


class CheckEmail extends React.Component {

  render() {
    return (
	  	<LayoutWrapper>
			  	<h1 className="pb-3">{textResource["check_email.title"]}</h1>
			  	<br/>
			  	<p>{`${textResource["check_email.sent_to"]}${this.props.userRole.email}.`}</p>
			  	<p>{textResource["check_email.instruction"]}</p>
			  	<br/>
			  	<p>
					  <Link to="/resendemail" style={{textDecoration: "underline"}}>
				  		{textResource["check_email.did_not_receive_link"]}
			    	</Link>
		    	</p>
		    	<br/>
		    	<p>{textResource["check_email.spam_folder.text"]}</p>
			</LayoutWrapper>
    );
  }
}

export default CheckEmail;