import * as Actions from "../actions/realtime";

const initialState = null;

export function realtime(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_REALTIME:
      return action.realtime;
    case Actions.CLEAR_REALTIME:
      return null;      
    default:
      return state;
  }
}