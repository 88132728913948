import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import LanguageImg from '../../../resources/images/icon Language - pink E4B2B2.svg'
import LanguageBrownImg from '../../../resources/images/icon Language -brown 794D4F.svg'
import sendGAEvent from '../../../lib/sendGAEvent';
import constants from "../../../constants";
import './SelectLanguageStyles.css';

class SelectLanguage extends React.Component {

    componentDidMount() {
        let language = navigator.language.substring(0,2)
        let foundLanguage = constants.languages.find(item => item.code === language)

        if(foundLanguage) {
            this.props.setSelectedLanguage(foundLanguage)
        }
    }    
    
    onTranslate = () => {
        let url = `https://translate.google.com/#view=home&op=translate&sl=en&tl=auto&text=For%20integrated%20translation%20use%20Chrome%20browser.%0A%0ASTAY%20STILL%2C%20SIMPLY%20LOOK%20AT%20THE%20OTHER%20PERSON%20-%20Give%20yourself%20time%20to%20feel%20what%20happens%20when%20you%20meet%20someone%2C%20stay%20still%20and%20not%20do%20anything%20else.%20Yes%2C%20it%20is%20different.%0A%0AAVOID%20GESTURES%20OR%20WRITTEN%20SIGNS%20-%20Resist%20the%20urge%20to%20replace%20verbal%20communication%20with%20written%20signs%20or%20gestures.%20The%20aim%20is%20to%20connect%20simply%20and%20subtly%20without%20words.%20This%20is%20a%20different%20way%20to%20connect%2C%20give%20it%20a%20try!%0A%0ASTAY%20PRESENT%20FOR%20THE%20ENTIRE%20MINUTE%20-%20Respect%20that%20the%20other%20person%20is%20giving%20you%20one%20minute%20of%20their%20time.%0A%0AHelp%20us%20build%20a%20special%20and%20safe%20community`
        sendGAEvent('Language', 'Open Translator', this.getScreenLabel())
        window.open(url, "_blank")
    }

    getScreenLabel = () => {
        if(this.props.userState === "firstTimeGuidance") {
            return 'scrExpHM-Guidance 1'
        }
        if(this.props.userState === "enteringRoom") {
            return 'scrExpHM-EnteringRoom'
        }
        return 'scrExpHM-Waiting';
    }

    setSelectedLanguage = (item) => {
        sendGAEvent('Language', `Open Language Selector, Select language code ${item.code}`, this.getScreenLabel())
        this.props.setSelectedLanguage(item)
    }

	render() {
        let { languageDropDown, openLanguageDropdown, selectedFullLanguage } = this.props;
		return(
			<div className="selectedLanguage">
                <div className="mainLanguageContainer">
                    <div className="outerLanguageConatiner" style={languageDropDown ? {background: '#fff'} : {}} onClick={openLanguageDropdown}>
                        <Button id="BtnSelectLanguage" size="lg"  color="secondary" className={`${languageDropDown && "openDropdown"} BtnSelectLanguage`}>
                            {languageDropDown ? 
                                <img src={LanguageBrownImg} />:
                                <img src={LanguageImg} />
                            }
                            {selectedFullLanguage}
                        </Button>
                    </div>
                    {languageDropDown && <div className="selectLanguageDropdown">
                        {constants.languages.map((item, key) => {
                            return <div key={key} className="languageItem" onClick={this.setSelectedLanguage.bind(this, item)}>{item.language}</div>
                        })}
                        <div className="languageItem border-top" onClick={this.onTranslate}>{'Other - Google translate'}</div>
                    </div>}
                </div>
			</div>
		)
	}
}

export default SelectLanguage;