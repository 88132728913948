import React from 'react';
import './TopNavbarStyles.css';
import textResource from '../../lib/textLoader';
import EmailTooltipContainer from "./EmailTooltipContainer";
import ExperienceTitleContainer from "./ExperienceTitleContainer";
import sendGAEvent from '../../lib/sendGAEvent';
import avatar from "../../resources/images/avatar-grey-46x35px.png";
import logoWordmark from "../../resources/images/humanonline_logo.png";
import DonationIcon from "../../resources/images/donationIcon.png"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { Link } from 'react-router-dom';

import FullscreenSettingItemContainer from "./DropdownItems/FullscreenSettingItemContainer";
import LogoutItemContainer from "./DropdownItems/LogoutItemContainer";
import ProfileLinkItem from "./DropdownItems/ProfileLinkItem";

const dropdownOrSignInButton = (userRole, pathname, openAuth) => {

  if (!userRole.ready) { return <div className="topRightNav"></div>; }
  if (!userRole.email) {
    // if (pathname === "/") { return null; }
    let onClick = (e) => {
      e.preventDefault();
      openAuth();
    }
    return (
      <button id="NavbarSignIn" className="btn btn-secondary userDropdown" onClick={onClick}>{textResource["navbar.sign_in_btn"]}</button>
    )
  }

  return (
    <div>
      <UncontrolledDropdown nav innavbar="true" className="userDropdown">
        <DropdownToggle id="BtnMenu" className="userbtn">
          <img id="avatarImg" src={avatar} alt="" />
        </DropdownToggle>
        <DropdownMenu right>
          {userRole.role === "guest" ? null : <ProfileLinkItem />}
          <LogoutItemContainer />
          <FullscreenSettingItemContainer />
        </DropdownMenu>
      </UncontrolledDropdown>
      <EmailTooltipContainer />
    </div>
  )
}


const howToUseDropdown = (pathname) => {

  return (
    <div>
      <UncontrolledDropdown >
        <DropdownToggle 
          tag="a" 
          className={`${["/howtouse", "/faq", "/communityguidelines"].includes(pathname) && "active"} nav-link`}
          style={{cursor: 'pointer'}}>
            {textResource["navbar.how_to_use"]}
        </DropdownToggle>
        <DropdownMenu right>
          <Link className="dropdown-item" style={{fontSize: 16}}
            to="/howtouse"
          >
            {textResource["navbar.how_to_use"]}
          </Link>
          <Link className="dropdown-item" style={{fontSize: 16}}
            to="/faq"

          >
            FAQ
          </Link>
          <Link className="dropdown-item" style={{fontSize: 16}}
            to="/communityguidelines"
          >
            Community guidelines
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
      <EmailTooltipContainer />
    </div>
  )
}





export default class TopNavbar extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      renderExperienceTitle:false
    };

    this.navbarClassName = this.navbarClassName.bind(this);
  }
  

  componentDidUpdate(prevProps){
    if(this.props.userState === 'disconnected' && prevProps.userState === 'preparing'){
      this.setState({renderExperienceTitle:true})
    }
    else if(this.props.userState === 'present' && prevProps.userState === 'disconnected'){
      this.setState({renderExperienceTitle:false})
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onSubmitDonate = () => {
    let { userRole } = this.props;
		let value = '';
		if(userRole.email) {
			value = userRole.completedHumanMinutes
		}
    sendGAEvent('Donate', 'Open Donation', 'btnDonate Header', value)
  }

  navbarClassName() {
    if (["/about", "/membership"].includes(this.props.location.pathname)) {
      return "navbar-in-lobby"
    }
    if (this.props.location.pathname === "/" && this.props.userRole.email) {
      return "navbar-in-lobby"
    }
    if (this.props.location.pathname.split("/")[1] === "facilitator") {
      return "navbar-facilitator-page"
    }
    return "navbar-out-of-lobby";
  }

  render() {

    let { userRole, userState } = this.props;
    let {
      subscriptionAmount,
      initialPaymentSucceeded,
      trialUsed,
      activeSubscription
    } = userRole.subscription;
    
    if (this.props.location.pathname.split("/")[1] === "xp-i") {
      return (
        <Navbar light expand="md" className={this.navbarClassName()}>
          <Link className="navbar-brand mr-auto" to="/" >
            <img className="pl-2" id="logoWordmark" src={logoWordmark} alt="" />
          </Link>
        </Navbar>
      );
    }
  
    if (this.state.renderExperienceTitle || ["gettingMedia", "mediaDenied", "preparing", "enteringRoom"].includes(userState)) {
      return <ExperienceTitleContainer />;
    }

    if (!["present", "absent", "waiting", "firstTimeGuidance"].includes(userState)) {
      return null;
    }

    let noEstablishedSubscription = !!(!activeSubscription && (!subscriptionAmount || (trialUsed && !initialPaymentSucceeded)));

    return (
      <Navbar light expand="md" className={this.navbarClassName()}>
        <Link className="navbar-brand mr-auto" to="/" >
          <img className="pl-2" id="logoWordmark" src={logoWordmark} alt="" />
        </Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse className="pr-xl-2 pr-lg-2 pr-md-2" isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link
                to="/"
                className={`${this.props.location.pathname === "/" && "active"} nav-link`}
              >
                {textResource["navbar.home"]}
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/about"
                className={`${this.props.location.pathname === "/about" && "active"} nav-link`}
              >
                {textResource["navbar.about_us"]}
              </Link>
            </NavItem>
            <NavItem>
              {howToUseDropdown(this.props.location.pathname)}
            </NavItem>
            {
            /*<NavItem>
              {userRole.email ? (
                <Link
                  to="/feedback"
                  className={`${this.props.location.pathname === "/feedback" && "active"} nav-link`}
                >
                  {textResource["navbar.feedback"]}
                </Link>
              ) : (
                  <Link
                    to="/"
                    className={`${this.props.location.pathname === "/feedback" && "active"} nav-link`}
                    onClick={this.props.openAuthModal}
                  >
                    {textResource["navbar.feedback"]}
                </Link>
                )}
            </NavItem>
            */
            }
            { /*
              !!(userRole.email && noEstablishedSubscription) &&
              <NavItem>
                <Link
                  to="/membership"
                  className={`${this.props.location.pathname === "/membership" && "active"} nav-link`}
                >
                  Membership
                </Link>
              </NavItem>
            */}
            {
              //dropdownOrSignInButton(this.props.userRole, this.props.location.pathname, this.props.openAuthModal)
            }
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
