import { connect } from 'react-redux';
import TermsCollapse from './TermsCollapse';


const mapStateToProps = (state) => {
  return {
    termsPrivacyCollapse: state.uiControls.termsPrivacyCollapse
  };
};




export default connect(
  mapStateToProps,
  null
)(TermsCollapse);