import React, { Component } from 'react';
import './AppStyles.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import AppInitializerContainer from './imperative/AppInitializerContainer';


import ScrollToTop from './elements/ScrollToTop';
import TopNavbarContainer from './Navigation/TopNavbarContainer';
import FooterContainer from './Navigation/FooterContainer';
import LobbyContainer from './Lobby/LobbyContainer';
import MatchmakerContainer from './imperative/MatchmakerContainer';
import WelcomePotraitContainer from './WelcomePotrait/WelcomePotraitContainer';
import TermsPage from './ContentPages/TermsPage';
import FaqPage from './ContentPages/FaqPage';
import PrivacyPage from './ContentPages/PrivacyPage';
import CommunityGuidelinesPage from './ContentPages/CommunityGuidelinesPage';
import AboutPageContainer from './ContentPages/AboutPageContainer';
import GuidancePage from './ContentPages/GuidancePage';
import FeedbackContainer from './FeedbackPage/FeedbackContainer';
import ProfileContainer from './AccountPages/ProfileContainer';
import ChangePasswordContainer from './AccountPages/ChangePasswordContainer';
import DeleteAccountContainer from './AccountPages/DeleteAccountContainer';
import AuthModalContainer from './Authentication/AuthModalContainer';
import VerifyScreenContainer from './Lobby/EmailVerification/VerifyScreenContainer';
import RequestRecoverPasswordContainer from './Authentication/RecoverPassword/RequestRecoverPasswordContainer';
import SetNewPasswordContainer from './Authentication/RecoverPassword/SetNewPasswordContainer';
import ResendEmailContainer from './Lobby/EmailVerification/ResendEmailContainer';
import CameraFailureContainer from './CameraFailure/CameraFailureContainer';
import reactReferer from 'react-referer';
import NewsBannerContainer from './NewsBannerContainer';

import '../lib/socketInit';


import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import BetaExperiencesPageContainer from './Experiences/BetaExperiencesPageContainer';
import FacilitatorPageContainer from './Experiences/Facilitators/FacilitatorPageContainer';
import SocialAuthModalContainer from './Authentication/SocialAuthModalContainer';
import EmailResponse from './EmailResponse/EmailResponse';
import ConnectNotificationsContainer from './imperative/ConnectNotificationsContainer';

/*
import MembershipContainer from './Membership/MembershipContainer';
import RetryFailedInvoiceContainer from './Membership/RetryFailedInvoiceContainer';
import MembershipSuccessContainer from './Membership/MembershipSuccessContainer';
import CancelMembershipContainer from './Membership/CancelMembershipContainer';
import UpdateMembershipContainer from './Membership/UpdateMembershipContainer';
import RecoverCancelledMembershipContainer from './Membership/RecoverCancelledMembershipContainer';
import ManagePaymentMethodsContainer from './Membership/ManagePaymentMethodsContainer';
*/

import PresenceSignallingContainer from './imperative/PresenceSignallingContainer';
import ActiveExperienceContainer from './ActiveExperience/ActiveExperienceContainer';
import ExperienceInvitationPageContainer from './Experiences/ExperienceInvitation/ExperienceInvitationPageContainer';

library.add(faQuestionCircle);






class App extends Component {

	constructor() {
		super();
		this.PrivateRoute = this.PrivateRoute.bind(this);

  }
  componentDidMount(){

    this.props.setReferrerUrl(reactReferer.referer())
    this.props.setCurrentUrl(document.location.href)
  }

	PrivateRoute({ component: Component, ...rest }) {
		return (
		  <Route {...rest} render={
		  	(props) => {
		  		if (!this.props.userRole.ready) {
		  			return null;
		  		}
		  		return (
				    this.props.userRole.role === "guest"
				      ? <Redirect to='/' />
				      : <Component {...props} />
		  		)
		  	}
		  } />
		)
	}

  render() {
    let { PrivateRoute } = this;
    let { userState, experienceType } = this.props;
    let fullBackground = ""
    if ([ "enteringRoom", "firstTimeGuidance", "preparing", "gettingMedia", "ready", "connected", "disconnected"].includes(userState)) {
      fullBackground = "fullBackground";
    }
    let style = (userState === "waiting" && experienceType !== "oneminute") ? {overflow: 'auto'} : {}
    return (
      <div className="full-height d-flex flex-column">

        <Router>
          <>
            <div className={`flex-grow-1 ${fullBackground}`} style={style}>
              <ScrollToTop>
                <Route component={NewsBannerContainer} />
                <Route component={TopNavbarContainer} />
                <Route component={WelcomePotraitContainer} />

                {/*
                <Route component={AuthModalContainer} />
                <Route component={SocialAuthModalContainer} />
                <Route component={MatchmakerContainer} />
                <Route component={ConnectNotificationsContainer} />
                <Route component={PresenceSignallingContainer} />
                */}

                <Switch>
                  <Route exact path="/" component={LobbyContainer} />
                  {/*
                  <Route path="/groupspace/:groupid" component={LobbyContainer} />
                  <Route path="/xp-i/:roomid" component={ExperienceInvitationPageContainer} />
                  <Route path="/confirmemail/:hash?" component={VerifyScreenContainer} />
                  <Route path="/snum/:surveyNum/res/:response" component={EmailResponse} />
                  <Route path="/resendemail" component={ResendEmailContainer} />
                  <Route path="/requestrecoverpassword" component={RequestRecoverPasswordContainer} />
                  <Route path="/recoverpassword/:hash?" component={SetNewPasswordContainer} />
                  */}
                  <Route path="/terms" component={TermsPage} />
                  <Route path="/privacy" component={PrivacyPage} />
                  <Route path="/about" component={AboutPageContainer} />
                  <Route path="/howtouse" component={GuidancePage} />
                  {/*
                    <PrivateRoute exact path="/membership" component={MembershipContainer} />
                    <Route path="/membership/make-payment" component={RetryFailedInvoiceContainer} />
                    <PrivateRoute path="/membership/success" component={MembershipSuccessContainer} />
                    <PrivateRoute exact path="/membership/cancel" component={CancelMembershipContainer} />
                    <PrivateRoute exact path="/membership/update" component={UpdateMembershipContainer} />
                    <PrivateRoute exact path="/membership/recover" component={RecoverCancelledMembershipContainer} />
                    <PrivateRoute exact path="/membership/payment-methods" component={ManagePaymentMethodsContainer} />
                  */}
                  <Route path="/communityguidelines" component={CommunityGuidelinesPage} />
                  <Route path="/faq" component={FaqPage} />
                  {/*
                  <PrivateRoute path="/camerafailure" component={CameraFailureContainer} />
                  <PrivateRoute path="/feedback" component={FeedbackContainer} />
                  <PrivateRoute path="/experiences/beta" component={BetaExperiencesPageContainer} />
                  <Route path="/experience" component={ActiveExperienceContainer} />
                  <PrivateRoute path="/facilitator/:facilitator" component={FacilitatorPageContainer} />
                  <PrivateRoute path="/account/profile" component={ProfileContainer} />
                  <PrivateRoute path="/account/changepassword" component={ChangePasswordContainer} />
                  <PrivateRoute path="/account/deleteaccount" component={DeleteAccountContainer} />
                  */}
                  <Route render={() => <Redirect to='/' />} />
                </Switch>
              </ScrollToTop>
            </div>
            <Route component={FooterContainer} />
          </>
        </Router>
      </div>
    );
  }
}

export default App;
