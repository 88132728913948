import * as Actions from "../actions/uiControls";
import * as SessionStateActions from "../actions/sessionState";
import * as UserRoleActions from "../actions/userRole"

const initialState = {
  authModalOpen: false,
  socialAuthModalOpen: false,
  authModalType: "register",
  termsPrivacyCollapse: "",
  registrationConfirmation: false,
  matchmakerTimeout: false,
  connectionControls: true,
  registerGatheringAfterLogin: false,
  registerEmailsAfterLogin: false,
  emailFromLink: "",
  loginWithEmail: false,
  authModalForm: false,
  authData: {
    name: "",
    email: "",
    password: ""
  },
  alreadyRegisteredWithEmail: false,
  authEventsCategory: "",
  userAudio: false,
  showUserAudioMessage: false,
  showTextInstructionMessage: '',
  noOneInCircleModalOpen: false,
};

export function uiControls(state = initialState, action) {
  switch (action.type) {
    case Actions.OPEN_AUTH_MODAL:
      return {
        ...state,
      	authModalOpen: true,
      };
    case Actions.CLOSE_AUTH_MODAL:
      return {
        ...state,
      	authModalOpen: false,
        authModalType: "register"
      };
    case Actions.OPEN_NO_ONE_IN_CIRCLE_MODAL:
      return {
        ...state,
        noOneInCircleModalOpen: true,
      };
    case Actions.CLOSE_NO_ONE_IN_CIRCLE_MODAL:
      return {
        ...state,
        noOneInCircleModalOpen: false,
      };
    case Actions.OPEN_SOCIAL_AUTH_MODAL:
      return {
        ...state,
      	socialAuthModalOpen: true,
      };
    case Actions.CLOSE_SOCIAL_AUTH_MODAL:
      return {
        ...state,
      	socialAuthModalOpen: false
      };
    case Actions.SET_AUTH_MODAL_TYPE_LOGIN:
      return {
        ...state,
        authModalType: "login",
      };
    case Actions.SET_AUTH_MODAL_TYPE_REGISTER:
      return {
        ...state,
        authModalType: "register",
      };
    case Actions.SHOW_REGISTRATION_CONFIRMATION:
      return {
        ...state,
        registrationConfirmation: true,
      };
    case Actions.HIDE_REGISTRATION_CONFIRMATION:
      return {
        ...state,
        registrationConfirmation: false,
      };
    case Actions.SHOW_MATCHMAKER_TIMEOUT_ALERT:
      return {
        ...state,
        matchmakerTimeout: true,
      };
    case Actions.HIDE_MATCHMAKER_TIMEOUT_ALERT:
      return {
        ...state,
        matchmakerTimeout: false,
      };
    case Actions.SHOW_CONNECTION_CONTROLS:
      return {
        ...state,
        connectionControls: true,
      };
    case SessionStateActions.RESET_ALL_SESSION_STATE:
      return {
        ...state,
        connectionControls: true,
      };
    case Actions.HIDE_CONNECTION_CONTROLS:
      return {
        ...state,
        connectionControls: false,
      };
    case Actions.SET_REGISTER_GATHERING_AFTER_LOGIN:
      return {
        ...state,
        registerGatheringAfterLogin: true,
      };
    case Actions.CLEAR_REGISTER_GATHERING_AFTER_LOGIN:
      return {
        ...state,
        registerGatheringAfterLogin: false,
      };
    case Actions.SET_REGISTER_EMAILS_AFTER_LOGIN:
      return {
        ...state,
        registerEmailsAfterLogin: true,
      };
    case Actions.CLEAR_REGISTER_EMAILS_AFTER_LOGIN:
      return {
        ...state,
        registerEmailsAfterLogin: false,
      };
    case Actions.SET_COLLAPSE_TERMS:
      return {
        ...state,
        termsPrivacyCollapse: "terms",
      };
    case Actions.SET_COLLAPSE_PRIVACY:
      return {
        ...state,
        termsPrivacyCollapse: "privacy",
      };
    case Actions.SET_COLLAPSE_GATHERING:
      return {
        ...state,
        termsPrivacyCollapse: "gathering",
      };
    case Actions.SET_COLLAPSE_CLOSED:
      return {
        ...state,
        termsPrivacyCollapse: "",
      };
    case Actions.SET_EMAIL_FROM_LINK:
      return {
        ...state,
        emailFromLink: action.data,
      };
    case Actions.CLEAR_EMAIL_FROM_LINK:
      return {
        ...state,
        emailFromLink: "",
      };
    case Actions.SET_AUTH_MODAL_FORM:
      return {
        ...state,
        authModalForm: true
      };
    case Actions.CLEAR_AUTH_MODAL_FORM:
      return {
        ...state,
        authModalForm: false
      };
    case Actions.SET_LOGIN_WITH_EMAIL:
      return {
        ...state,
        loginWithEmail: true
      };
    case Actions.CLEAR_LOGIN_WITH_EMAIL:
      return {
        ...state,
        loginWithEmail: false
      };
    case Actions.SET_AUTH_DATA:
      return {
        ...state,
        authData: action.data
      };
    case Actions.CLEAR_AUTH_DATA:
      return {
        ...state,
        authData: {
          name: "",
          email: "",
          password:""
        }
      };
    case Actions.SET_ALREADY_REGISTER_WITH_EMAIL:
      return {
        ...state,
        alreadyRegisteredWithEmail: true
      };
    case Actions.CLEAR_ALREADY_REGISTER_WITH_EMAIL:
      return {
        ...state,
        alreadyRegisteredWithEmail: false
      };
    case Actions.SET_AUTH_EVENTS_CATEGORY:
      return {
        ...state,
        authEventsCategory: action.data
      };
    case Actions.CLEAR_AUTH_EVENTS_CATEGORY:
      return {
        ...state,
        authEventsCategory: ""
      };
    case Actions.SET_USER_AUDIO:
      return {
        ...state,
        userAudio: true
      };
    case Actions.CLEAR_USER_AUDIO:
      return {
        ...state,
        userAudio: false
      };
    case Actions.SHOW_USER_AUDIO_MESSAGE:
      return {
        ...state,
        showUserAudioMessage: true
      };
    case Actions.CLEAR_USER_AUDIO_MESSAGE:
      return {
        ...state,
        showUserAudioMessage: false
      };
    case Actions.SHOW_TEXT_INSTRUCTION_MESSAGE:
      return {
        ...state,
        showTextInstructionMessage: action.message
      };
    case Actions.CLEAR_TEXT_INSTRUCTION_MESSAGE:
      return {
        ...state,
        showTextInstructionMessage: ''
      };
    default:
      return state;
  }
}