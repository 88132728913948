import React from 'react';
import {withRouter} from 'react-router-dom'

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
  	let { location } = this.props;

    if (location !== prevProps.location && !location.hash) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export default withRouter(ScrollToTop)
