import * as Actions from "../actions/socialLogin";

const initialState = {
  socialLoginButtons: false,
  facebookData: {},
  error: null,
  removeToken: false
};

export function socialLogin(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SOCIAL_LOGIN_BUTTONS:
      return {
        ...state,
        socialLoginButtons: true,
        error: false
      };
    case Actions.CLEAR_SOCIAL_LOGIN_BUTTONS:
      return {
        ...state,
        socialLoginButtons: false,
        error: false
      };
    case Actions.SET_FACEBOOK_DATA:
      return {
        ...state,
        facebookData: action.data,
        removeToken: false,
        error: false
      };
    case Actions.FACEBOOK_FAILURE:
      return {
        ...state,
        error: true
      };
    case Actions.CLEAR_FACEBOOK_ERROR:
      return {
        ...state,
        error: false
      };
    case Actions.REMOVE_ACCESS_TOKEN:
      return {
        ...state,
        removeToken: true
      };

    default:
      return state;
  }
}
