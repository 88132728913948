import React from 'react';
import WelcomeToGathering from './WelcomeToGathering';
import AfterGathering from './AfterGathering';
import { Redirect } from 'react-router-dom';



function isGroupSpaceOpen(currentGathering, mostRecentlyEndedGathering) {
  // Group spaces are open during active group gatherings and up to 10 minutes after
  return (
    (currentGathering && currentGathering.groupID) ||
    (mostRecentlyEndedGathering && mostRecentlyEndedGathering.groupID && mostRecentlyEndedGathering.endTimeMillSec >= Date.now() - 10*60*1000)
  )
}


const GatheringsArea = ({
  userRole,
  mostRecentlyEndedGathering,
  currentGathering,
  location
}) => {
  if (!userRole.email) {
    return null;
  }

  // If there is not a current or recently ended group gathering,
  // no group spaces are allowed
  if (!isGroupSpaceOpen(currentGathering, mostRecentlyEndedGathering)){
    if (location.pathname !== "/") {
      return <Redirect to="/" />
    }
  }


  // If there's a group gathering, participant can only be in the group space
  if (currentGathering && currentGathering.groupID && location.pathname !== `/groupspace/${currentGathering.groupID}`) {
      return <Redirect to={`/groupspace/${currentGathering.groupID}`} />
  }



  return (
    <div>
      {currentGathering &&
        <WelcomeToGathering
          currentGathering={currentGathering}
          location={location}
          userRole={userRole}
        />
       } 
      {mostRecentlyEndedGathering && userRole.hasTriedToConnect &&
        <AfterGathering
          mostRecentlyEndedGathering={mostRecentlyEndedGathering}
          location={location}
        />
       } 
    </div>
  );
}

export default GatheringsArea;
