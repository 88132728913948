import { connect } from 'react-redux';
import AudioOpenIndicator from './AudioOpenIndicator';


const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    userAudio: state.uiControls.userAudio,
    showUserAudioMessage: state.uiControls.showUserAudioMessage
  };
};


export default connect(
  mapStateToProps,
  null
)(AudioOpenIndicator);