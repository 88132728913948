import * as Actions from "../actions/endConnectionReason";
import * as SessionStateActions from "../actions/sessionState";
import * as UserStateActions from "../actions/userState";

const initialState = Actions.endConnectionReasons.UNKNOWN;

export function endConnectionReason(state = initialState, action) {
  switch (action.type) {
    case SessionStateActions.RESET_ALL_SESSION_STATE:
      return initialState;

    case UserStateActions.SET_STATE_DISCONNECTED:
      return action.payload;

    default:
      return state;
  }
}
