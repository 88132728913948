import { connect } from 'react-redux';
import CheckEmail from './CheckEmail';
import { withRouter } from 'react-router-dom';


const mapStateToProps = (state) => {
  return {
    userRole: state.userRole
  };
};


export default withRouter(connect(
  mapStateToProps,
  null
)(CheckEmail));