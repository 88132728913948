import constants from "../constants";
import { setExistingSubscriptionAmount } from "./userRole";
import stripe from '../lib/stripe';
import {sendAmplitudeEvent} from "../lib/amplitude";

export const SET_SUBSCRIPTION_PRICE = "SET_SUBSCRIPTION_PRICE";
export const SUBSCRIPTION_START = "SUBSCRIPTION_START";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_FAILURE = "SUBSCRIPTION_FAILURE";
export const SET_PENDING_INVOICE = "SET_PENDING_INVOICE";
export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";

export const SUBSCRIPTION_CANCEL_SUCCESS = "SUBSCRIPTION_CANCEL_SUCCESS";
export const SET_EXISTING_PAYMENT_METHODS = "SET_EXISTING_PAYMENT_METHODS";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const UPDATE_SUBSCRIPTION_PRICE_SUCCESS = "UPDATE_SUBSCRIPTION_PRICE_SUCCESS";
export const RECOVER_SUBSCRIPTION_SUCCESS = "RECOVER_SUBSCRIPTION_SUCCESS";



export function setSubscriptionPrice(price) {
  return {
    type: SET_SUBSCRIPTION_PRICE,
    price
  };
}

export function subscriptionStart() {
  return {
    type: SUBSCRIPTION_START
  };
}

export function subscriptionSuccess() {
  return {
    type: SUBSCRIPTION_SUCCESS
  };
}

export function subscriptionFailure(err) {
  return {
    type: SUBSCRIPTION_FAILURE,
    err
  };
}

export function setPendingInvoice(data) {
  return {
    type: SET_PENDING_INVOICE,
    data
  };
}

export function clearSubscription() {
  return {
    type: CLEAR_SUBSCRIPTION
  };
}


export function subscriptionCancelSuccess(data) {
  return {
    type: SUBSCRIPTION_CANCEL_SUCCESS,
    data
  };
}

export function recoverSubscriptionSuccess(data) {
  return {
    type: RECOVER_SUBSCRIPTION_SUCCESS,
    data
  };
}

export function setExistingPaymentMethods(data) {
  return {
    type: SET_EXISTING_PAYMENT_METHODS,
    data
  };
}

export function addPaymentMethodSuccess(data) {
  return {
    type: ADD_PAYMENT_METHOD_SUCCESS,
    data
  };
}

export function updateSubscriptionPriceSuccess() {
  return {
    type: UPDATE_SUBSCRIPTION_PRICE_SUCCESS
  };
}



export function createNewSubscriptionWithoutTrial({
  cardNumber, 
  cardExpiry, 
  cardCvc
}) {
  let paymentMethodId;
  let subscription;
  let invoiceId;
  let paymentIntent;
  return async (dispatch, getState) => {

    dispatch(subscriptionStart());

    let price = getState().subscriptionState.subscriptionPrice;

    sendAmplitudeEvent('attempt membership completion', {'amount': price, 'with trial': false});

    try {
      let paymentMethod = await createPaymentMethod(cardNumber, cardExpiry, cardCvc);
      paymentMethodId = paymentMethod.id;
    } catch(err) {
      console.log(err);
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': false});
      return dispatch(subscriptionFailure("Could not create payment method"));
    }

    try {
      subscription = await fetchCreateSubscriptionWithoutTrial(price, paymentMethodId); 
    } catch(err) {
      console.log(err);
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': false});
      return dispatch(subscriptionFailure(`${err}`));
    }


    if (!subscription) {
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': false});
      return dispatch(subscriptionFailure("Could not create subscription"));
    }

    dispatch(setExistingSubscriptionAmount(price));
    invoiceId = subscription.latest_invoice.id;
    paymentIntent = subscription.latest_invoice.payment_intent;


    if (paymentIntent && paymentIntent.status === 'requires_action') {

      let result;
      try {
        result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
      } catch(err) {
        console.log(err);
        sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': false});
        return dispatch(subscriptionFailure("Subscription payment failed"));
      }

      if (result.error) {
        console.log(result.error);
        sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': false});
        return dispatch(subscriptionFailure(result.error.message));
      } else {
        paymentIntent = result.paymentIntent;
      }
    }

    if (paymentIntent && paymentIntent.status ==='requires_payment_method') {
      dispatch(setPendingInvoice(invoiceId));
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': false});
      return dispatch(subscriptionFailure('Your card was declined.'));
    }

    if ((subscription.status === 'active') || (paymentIntent && paymentIntent.status === "succeeded")) {
      sendAmplitudeEvent('membership completion success', {'amount': price, 'with trial': false});
      dispatch(subscriptionSuccess())
    } else {
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': false});
      dispatch(subscriptionFailure("Unknown error"));
    }
  };
}


export function createNewSubscriptionWithTrial({
  cardNumber, 
  cardExpiry, 
  cardCvc
}) {
  let paymentMethod;
  let paymentMethodId;
  let clientSecret;
  let subscription;
  return async (dispatch, getState) => {

    dispatch(subscriptionStart());

    let price = getState().subscriptionState.subscriptionPrice;

    sendAmplitudeEvent('attempt membership completion', {'amount': price, 'with trial': true});

    try {
      paymentMethod = await createPaymentMethod(cardNumber, cardExpiry, cardCvc);
      paymentMethodId = paymentMethod.id;
    } catch(err) {
      console.log(err);
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': true});
      return dispatch(subscriptionFailure("Could not create payment method"));
    }

    try {
      clientSecret = await fetchSetupIntentSecret(paymentMethodId);
    } catch(err) {
      console.log(err);
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': true});
      return dispatch(subscriptionFailure("Could not create payment method"));
    }
    
    if (!paymentMethodId || !clientSecret) {
      console.log(paymentMethod, clientSecret)
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': true});
      return dispatch(subscriptionFailure("Could not create payment method"));
    }

    let result;
    try {
      result = await stripe.confirmCardSetup(clientSecret,
        { payment_method: paymentMethodId }
      );
    } catch(err) {
      console.log(err);
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': true});
      return dispatch(subscriptionFailure("Could not create payment method"));
    }

    if (result.error) {
      console.log(result.error);
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': true});
      return dispatch(subscriptionFailure(result.error.message));
    }

    try {
      subscription = await fetchCreateSubscriptionWithTrial(price, paymentMethodId);
    } catch(err) {
      console.log(err);
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': true});
      return dispatch(subscriptionFailure(`${err}`));
    }

    if (!subscription) {
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': true});
      return dispatch(subscriptionFailure("Could not create subscription"));
    }

    dispatch(setExistingSubscriptionAmount(price));

    if (subscription.status === 'trialing') {
      sendAmplitudeEvent('membership completion success', {'amount': price, 'with trial': true});
      dispatch(subscriptionSuccess())
    } else {
      sendAmplitudeEvent('membership completion failure', {'amount': price, 'with trial': true});
      dispatch(subscriptionFailure("Unknown error"));
    }
  };
}


export function payPendingInvoice({
  cardNumber, 
  cardExpiry, 
  cardCvc,
  paymentMethodId
}) {
  let invoice;
  let paymentIntent;
  return async (dispatch, getState) => {

    dispatch(subscriptionStart());

    let invoiceId = getState().userRole.subscription.pendingInvoice;

    if (cardNumber) {
      try {
        let paymentMethod = await createPaymentMethod(cardNumber, cardExpiry, cardCvc);
        paymentMethodId = paymentMethod.id;
      } catch(err) {
        console.log(err);
        return dispatch(subscriptionFailure("Could not create payment method"));
      }
    }

    // This function works either with a new payment method (given as card data) or an existing payment method (given as paymentMethodId).
    // If none of them was provided we cannot continue.
    if (!paymentMethodId) {
      return dispatch(subscriptionFailure("Could not create payment method"));
    }


    try {
      invoice = await retryInvoice(paymentMethodId, invoiceId);
    } catch(err) {
      console.log(err);
      return dispatch(subscriptionFailure(`${err}`));
    }


    if (!invoice) {
      return dispatch(subscriptionFailure("Payment failed"));
    }

    paymentIntent = invoice.payment_intent;

    if (paymentIntent && paymentIntent.status === 'requires_action') {

      let result;
      try {
        result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
      } catch(err) {
        console.log(err);
        return dispatch(subscriptionFailure("Payment failed"));
      }

      if (result.error) {
        console.log(result.error);
        return dispatch(subscriptionFailure(result.error.message));
      } else {
        paymentIntent = result.paymentIntent;
      }
    }

    if (paymentIntent && paymentIntent.status ==='requires_payment_method') {
      dispatch(setPendingInvoice(invoiceId));
      return dispatch(subscriptionFailure('Your card was declined.'));
    }

    if (paymentIntent && paymentIntent.status === "succeeded") {
      dispatch(subscriptionSuccess())
    } else {
      dispatch(subscriptionFailure("Unknown error"));
    }
  };
}





function createPaymentMethod(cardNumber, cardExpiry, cardCvc) { 
  return new Promise((resolve, reject) => {
    if (!stripe) {
      return reject("Stripe not loaded");
    }
    stripe.createPaymentMethod({
      type: 'card',
      card: (cardNumber, cardExpiry, cardCvc)
    })
    .then(paymentMethod => {
      if(paymentMethod.error) {
        return reject(paymentMethod.error.message);
      }
      resolve(paymentMethod.paymentMethod);
    })
    .catch(err => {
      reject(err);
    });
  })
}


function fetchCreateSubscriptionWithoutTrial(price, paymentMethodId) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `price=${price}&paymentMethodId=${paymentMethodId}`
  };
  return new Promise((resolve, reject) => {
    fetch(`${constants.baseUrl}/api/v1/subscriptions/createsubscriptionwithouttrial`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          return reject(response.error);
        }
        resolve(response.subscription); 
      })
      .catch(err => {
        reject(err);
      });
  })
}

function fetchCreateSubscriptionWithTrial(price, paymentMethodId) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `price=${price}&paymentMethodId=${paymentMethodId}`
  };
  return new Promise((resolve, reject) => {
    fetch(`${constants.baseUrl}/api/v1/subscriptions/createsubscriptionwithtrial`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          return reject(response.error);
        }
        resolve(response.subscription); 
      })
      .catch(err => {
        reject(err);
      });
  })
}


function retryInvoice(paymentMethodId, invoiceId) {

  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `paymentMethodId=${paymentMethodId}&invoiceId=${invoiceId}`
  };
  return new Promise((resolve, reject) => {
    fetch(`${constants.baseUrl}/api/v1/subscriptions/retryinvoice`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          return reject(response.error);
        }
        resolve(response.invoice); 
      })
      .catch(err => {
        reject(err);
      });
  })
}



export function cancelSubscription(reason) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `reason=${reason}`
  };
  return dispatch => {
    dispatch(subscriptionStart());
    fetch(`${constants.baseUrl}/api/v1/subscriptions/cancelsubscription`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(subscriptionCancelSuccess(response.subscription));
      })
      .catch(err => {
        console.log(err);
        dispatch(subscriptionFailure(`${err}`));
      });
  };
}


export function recoverSubscription() {
  let config = {
    method: "GET",
    headers: { 
      "Authorization": `bearer ${localStorage.getItem("token")}`
    }
  };
  return dispatch => {
    dispatch(subscriptionStart());
    fetch(`${constants.baseUrl}/api/v1/subscriptions/recoversubscription`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(recoverSubscriptionSuccess(response.subscription));
      })
      .catch(err => {
        console.log(err);
        dispatch(subscriptionFailure(`${err}`));
      });
  };
}


export function updateSubscriptionPrice(price) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `price=${price}`
  };

  return (dispatch) => {
    dispatch(subscriptionStart());
    return fetch(`${constants.baseUrl}/api/v1/subscriptions/updatesubscriptionprice`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(setExistingSubscriptionAmount(response.amount))
        dispatch(updateSubscriptionPriceSuccess())
      })
      .catch(err => {
        console.error("getExperiences error: ", err);
        dispatch(subscriptionFailure(`${err}`));
      });
  }
}


export function getAllPaymentMethods() {
  let config = {
    method: "GET",
    headers: { 
      "Authorization": `bearer ${localStorage.getItem("token")}`
    }
  };
  return dispatch => {
    dispatch(subscriptionStart());
    fetch(`${constants.baseUrl}/api/v1/subscriptions/getallpaymentmethods`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(setExistingPaymentMethods(response.paymentMethods))
      })
      .catch(err => {
        console.log(err);
        dispatch(subscriptionFailure("Could not retrieve payment methods"));
      });
  };
}

export function makePaymentMethodDefault(paymentMethodId) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `paymentMethodId=${paymentMethodId}`
  };
  return dispatch => {
    dispatch(subscriptionStart());
    fetch(`${constants.baseUrl}/api/v1/subscriptions/makepaymentmethoddefault`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(setExistingPaymentMethods(response.paymentMethods))
      })
      .catch(err => {
        console.log(err);
        dispatch(subscriptionFailure("Could not make payment method default"));
      });
  };
}

export function deletePaymentMethod(paymentMethodId) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `paymentMethodId=${paymentMethodId}`
  };
  return dispatch => {
    dispatch(subscriptionStart());
    fetch(`${constants.baseUrl}/api/v1/subscriptions/deletepaymentmethod`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(setExistingPaymentMethods(response.paymentMethods))
      })
      .catch(err => {
        console.log(err);
        dispatch(subscriptionFailure("Could not make payment method default"));
      });
  };
}


export function addNewPaymentMethod({
  cardNumber, 
  cardExpiry, 
  cardCvc
}) {
  let paymentMethod;
  let paymentMethodId;
  let clientSecret;
  return async dispatch => {

    dispatch(subscriptionStart());

    try {
      paymentMethod = await createPaymentMethod(cardNumber, cardExpiry, cardCvc);
      paymentMethodId = paymentMethod.id;
    } catch(err) {
      console.log(err);
      return dispatch(subscriptionFailure("Could not create payment method"));
    }

    try {
      clientSecret = await fetchSetupIntentSecret(paymentMethodId);
    } catch(err) {
      console.log(err);
      return dispatch(subscriptionFailure("Could not create payment method"));
    }
    
    if (!paymentMethodId || !clientSecret) {
      console.log(paymentMethod, clientSecret)
      return dispatch(subscriptionFailure("Could not create payment method"));
    }

    let result;
    try {
      result = await stripe.confirmCardSetup(clientSecret,
        { payment_method: paymentMethodId }
      );
    } catch(err) {
      console.log(err);
      return dispatch(subscriptionFailure("Could not create payment method"));
    }

    if (result.error) {
      console.log(result.error);
      dispatch(subscriptionFailure(result.error.message));
    } else {
      dispatch(addPaymentMethodSuccess({
        id: paymentMethodId,
        last4: paymentMethod.card.last4,
        brand: paymentMethod.card.brand,
        isDefault: false
      }));
    }

  };
}


function fetchSetupIntentSecret(paymentMethodId) {

  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `paymentMethodId=${paymentMethodId}`
  };
  return new Promise((resolve, reject) => {
    fetch(`${constants.baseUrl}/api/v1/subscriptions/getsetupintent`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          return reject(response.error);
        }
        resolve(response.clientSecret); 
      })
      .catch(err => {
        reject(err);
      });
  })
}