// yyPilot1

import * as Actions from "../actions/fullscreen";

const initialState = true;

export function fullScreen(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_FULL_SCREEN:
      return true;
    case Actions.CANCEL_FULL_SCREEN:
    	return false;
    default:
      return state;
  }
}