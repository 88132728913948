import React from 'react';
import constants from '../../../constants';
import { endFullScreen } from '../../../lib/fullscreenWrapper';
import screenfull from 'screenfull';
import sendGAEvent from '../../../lib/sendGAEvent';
import sendFBPixelEvent from '../../../lib/sendFBPixelEvent'
import {setUserProperties} from '../../../lib/amplitude'
import { endConnectionReasons } from '../../../actions/endConnectionReason';


class ExperienceTimer extends React.Component {

	constructor() {
		super();

		this.dissapearTimeout = null;
		this.startMoveListenerTimeout = null;
		this.startConnectionTimeout = null;
		this.endConnectionTimeout = null;

		this.showControlsAndHideAfterTime = this.showControlsAndHideAfterTime.bind(this);
		this.setDissapearTimeout = this.setDissapearTimeout.bind(this);
		this.startMoveListener = this.startMoveListener.bind(this);
		this.clearDissapearTimeout = this.clearDissapearTimeout.bind(this);
		this.setStartMoveListenerTimeout = this.setStartMoveListenerTimeout.bind(this);
		this.clearStartMoveListenerTimeout = this.clearStartMoveListenerTimeout.bind(this);
		this.setMousemoveListener = this.setMousemoveListener.bind(this);
		this.clearMousemoveListener = this.clearMousemoveListener.bind(this);

		this.setStartConnectionTimeout = this.setStartConnectionTimeout.bind(this);
		this.clearStartConnectionTimeout = this.clearStartConnectionTimeout.bind(this);
		this.setEndConnectionTimeout = this.setEndConnectionTimeout.bind(this);
		this.clearEndConnectionTimeout = this.clearEndConnectionTimeout.bind(this);
		this.setAfterConnectionTimeout = this.setAfterConnectionTimeout.bind(this);
	}

	componentDidMount() {
		
		// Listener for fullscreen change, to show connection controls
		if (screenfull.enabled) {
		    screenfull.on('change', () => {
		        if (!screenfull.isFullscreen) {
		        	if (this.props.userState === "ready" || this.props.userState === "connected") {
		        		this.showControlsAndHideAfterTime();
		        	}
		        }
		    });
		}
	}

	componentDidUpdate(prevProps){
		// When I receive the remote stream, start connection after delay
		if (!prevProps.peerConnectionReady && this.props.peerConnectionReady) {
			this.setStartConnectionTimeout();
		}

		// When the connection starts, set timeout to end it
		if (prevProps.userState !== "connected" && this.props.userState === "connected") {
			this.setStartMoveListenerTimeout();
			this.setEndConnectionTimeout();
		}

		if (prevProps.userState !== "ready" && this.props.userState === "ready" && this.props.selectedExperienceData) {
			this.setStartMoveListenerTimeout();
		}

		// When the connection ends, clear timeouts and set after connection one
		if (prevProps.userState !== "disconnected" && this.props.userState === "disconnected") {
			this.clearStartConnectionTimeout()
			this.clearStartMoveListenerTimeout();
			this.clearDissapearTimeout();
			this.setAfterConnectionTimeout();
			this.clearEndConnectionTimeout();
			this.clearMousemoveListener();
		}
	}

	componentWillUnmount() {
    this.clearDissapearTimeout();
    this.clearMousemoveListener();
    this.clearStartMoveListenerTimeout();
	}

	setStartMoveListenerTimeout() {
		this.startMoveListenerTimeout = setTimeout(() => {
			this.startMoveListener();
		}, constants.timeControls["showControlsWhenStarting"]);
	}

	clearStartMoveListenerTimeout() {
		if (this.startMoveListenerTimeout) {
			clearTimeout(this.startMoveListenerTimeout);
			this.startMoveListenerTimeout = null;
		}
	}

	startMoveListener() {
		this.setMousemoveListener();
		this.props.hideConnectionControls();
	}

	setMousemoveListener() {
		document.addEventListener('mousemove', this.showControlsAndHideAfterTime);
		document.addEventListener('touchmove', this.showControlsAndHideAfterTime);
		document.addEventListener('touchstart', this.showControlsAndHideAfterTime);
	}

	clearMousemoveListener() {
		document.removeEventListener('mousemove', this.showControlsAndHideAfterTime);
		document.removeEventListener('touchmove', this.showControlsAndHideAfterTime);
		document.removeEventListener('touchstart', this.showControlsAndHideAfterTime);
	}

	showControlsAndHideAfterTime() {
		this.clearDissapearTimeout();
		this.props.showConnectionControls();
		this.setDissapearTimeout();
	}

	setDissapearTimeout() {
		this.dissapearTimeout = setTimeout(() => {
			this.props.hideConnectionControls();
		}, constants.timeControls["showControlsOnMouseMove"]);
	}

	clearDissapearTimeout() {
		if (this.dissapearTimeout) {
			clearTimeout(this.dissapearTimeout);
			this.dissapearTimeout = null;
		}
	}


	setStartConnectionTimeout() {
		let { selectedExperienceData } = this.props;
		this.startConnectionTimeout = setTimeout(() => {
			this.props.setStateConnected();
		}, selectedExperienceData.introDuration);
	}

	clearStartConnectionTimeout() {
		if (this.startConnectionTimeout) {
			clearTimeout(this.startConnectionTimeout);
			this.startConnectionTimeout = null;
		}
	}

	setEndConnectionTimeout() {
		let { completedHumanMinutes, addCompletedHumanMinute, selectedExperienceData } = this.props;
		this.endConnectionTimeout = setTimeout(() => {
			if (this.props.userState === "connected") {
				this.props.setStateDisconnected({ reason: endConnectionReasons.NATURAL });
				if(completedHumanMinutes === 0){
					sendFBPixelEvent('HumanMinute1');
				}
				sendGAEvent('Human Minute', 'Completed', '', completedHumanMinutes + 1);
				setUserProperties({completedHumanMinutes: completedHumanMinutes + 1})
				addCompletedHumanMinute();
			}
		}, selectedExperienceData.connectionDuration);
	}

	clearEndConnectionTimeout() {
		if (this.endConnectionTimeout) {
			clearTimeout(this.endConnectionTimeout);
			this.endConnectionTimeout = null;
		}
	}

	setAfterConnectionTimeout() {
		let { selectedExperienceData } = this.props;
		setTimeout(() => {
			if (this.props.userState === "disconnected") {
				let { endConnectionReason } = this.props;
				// We only go to digesting screen if the connection started. 
				// Should the following include ICE_FAILURE?
				if (
					[
						endConnectionReasons.NATURAL,
						endConnectionReasons.MANUAL_SELF,
						endConnectionReasons.MANUAL_OTHER
					].includes(endConnectionReason)
				) {
					this.props.setStateDigesting();
				} else {
					endFullScreen(this.props.videoWrap)
						.then(() => {
							this.props.setStatePresent();
						})
						.catch((err) => {console.log(err);})
				}



			}
		}, selectedExperienceData.afterConnectionDuration);
	}

	render() {
	  return null;
	}
}



export default ExperienceTimer;
