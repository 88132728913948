import { connect } from 'react-redux';
import PresenceSignalling from './PresenceSignalling';
import {setStatePresent, setStateAbsent} from "../../actions/userState";
import {emitPeopleStatus} from "../../actions/presenceCount";

const mapStateToProps = (state, ownProps) => {
  return {
    userState: state.userState,
    userRole: state.userRole,
    socket: ownProps.socket,
    requestedNotifications: state.connectNotifications.requestedNotifications
  };
};

const mapDispatchtoProps = {
	setStatePresent,
	setStateAbsent,
  emitPeopleStatus
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(PresenceSignalling);