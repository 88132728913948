import React from 'react';
import LayoutWrapper from '../elements/LayoutWrapper';
import textResource from '../../lib/textLoader';

const CommunityGuidelinesPage = () => {

	return (
  	    <LayoutWrapper>
	  	    <h1 className="pb-3">{textResource["community_guidelines.community_guidelines_title"]}</h1>
            <div className="pt-4">
                <p>{textResource["community_guidelines.text1"]}</p>
                <p>{textResource["community_guidelines.text2"]}</p>
                <p><b>1. {textResource["community_guidelines.title1"]}</b></p>
                <p><b>2. {textResource["community_guidelines.title2"]}</b> {textResource["community_guidelines.content2"]}</p>
                <p><b>3. {textResource["community_guidelines.title3"]}</b> {textResource["community_guidelines.content3"]}</p>
                <p><b>4. {textResource["community_guidelines.title4"]}</b> {textResource["community_guidelines.content4"]}</p>
                <p><b>5. {textResource["community_guidelines.title5"]}</b> {textResource["community_guidelines.content5"]}</p>
                <p><b>6. {textResource["community_guidelines.title6"]}</b> {textResource["community_guidelines.content6"]}</p>
                <p><b>7. {textResource["community_guidelines.title7"]}</b> {textResource["community_guidelines.content7"]}</p>
                <p><b>8. {textResource["community_guidelines.title8"]}</b> {textResource["community_guidelines.content8"]}</p>
                <p>{textResource["community_guidelines.content9"]}</p>
            </div>
	    </LayoutWrapper>
	)
}

export default CommunityGuidelinesPage;