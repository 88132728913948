import { connect } from 'react-redux';
import ExperienceFlowManager from './ExperienceFlowManager';
import {
  setStatePresent,
  setStateEnteringRoom,
  setStateFirstTimeGuidance,
  setStateGettingMedia,
  setStateMediaDenied,
  setStatePreparing,
  setStateDisconnected
} from "../../../actions/userState";
import { setCameraFailureReason } from "../../../actions/cameraFailure";
import {
  setRoomConfirmed,
  clearPeerRoom,
  setHaveReceivedThanks,
  sendGAEventForDigestingState
} from "../../../actions/peerRoom";
import {
  getIceServers
} from "../../../actions/iceServers";
import {
  setPeerConnectionReady,
  setPeerConnectionFailed,
  setPeerReady,
  sendSessionData,
  resetAllSessionState
} from "../../../actions/sessionState";
import {
  checkUserIsInMyCircle,
  setPartnerIsInMyCircle,
  setMyCircleAddedByOther
} from "../../../actions/myCircle";
import {
  setFreeExperienceUsed,
  logout
} from "../../../actions/userRole";

// import { dispatch } from 'rxjs/internal/observable/range';


const mapStateToProps = (state, ownProps) => {
  return {
    realtime: state.realtime,
    smallVideo: ownProps.smallVideo,
    bigVideo: ownProps.bigVideo,
    videoWrap: ownProps.videoWrap,
    userState: state.userState,
    userRole: state.userRole,
    endConnectionReason: state.endConnectionReason,
    peerRoom: state.peerRoom,
    iceServers: state.iceServers,
    sessionState: state.sessionState,
    selectedExperienceData: state.selectedExperienceData.data,
    completedHumanMinutes: state.userRole.completedHumanMinutes,
    myCircle: state.myCircle
  };
};

const mapDispatchToProps = {
  setStatePresent,
  setStateGettingMedia,
  setStateMediaDenied,
  setCameraFailureReason,
  setStatePreparing,
  setStateDisconnected,
  setRoomConfirmed,
  setPeerConnectionReady,
  setPeerConnectionFailed,
  setPeerReady,
  resetAllSessionState,
  getIceServers,
  sendSessionData,
  clearPeerRoom,
  setHaveReceivedThanks,
  sendGAEventForDigestingState,
  checkUserIsInMyCircle,
  setPartnerIsInMyCircle,
  setMyCircleAddedByOther,
  setStateFirstTimeGuidance,
  setStateEnteringRoom,
  setFreeExperienceUsed,
  logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExperienceFlowManager);
