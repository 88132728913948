import React from 'react';
import textResource from '../../../lib/textLoader';
import { Button, DropdownItem } from 'reactstrap';
import '../../ActiveExperience/ActiveExperienceStyles.css'
import SignInButtonContainer from '../SignInButtonContainer';
import { connectClick } from '../lib/connectClick';



const ConnectWithinCircleItem = ({userState, userRole, fdTest, hasCancelled, accountSuspended, realtime, setExperienceType, experienceType, setStateWaiting, selectedExperienceData, peerRoom, waitingCount, poolType, setPoolType, setStateFirstTimeGuidance, openNoOneInCircleModal, disabledFromProps}) => {

	if (!userRole.ready) {return null;}

	if (userRole.role === "guest") {
		return <SignInButtonContainer />
	} 

	if (fdTest.testDone && !fdTest.webrtcAvailable) {return null;} 
	
	if ((!["present", "absent"].includes(userState) && peerRoom.experienceType === experienceType && poolType === peerRoom.poolType)) { 
		return null; 
	}

	let disabled=false;
	if (disabledFromProps || hasCancelled || accountSuspended || !realtime ) { 
		disabled =true;
	}

	let waitingText = "";
	if (waitingCount) {
		waitingText = ` (${waitingCount})`
	}

  return (
		<DropdownItem style={{cursor: 'pointer'}} onClick={() => connectClick(userRole, setExperienceType, experienceType, setStateWaiting, selectedExperienceData, poolType, setPoolType, setStateFirstTimeGuidance, openNoOneInCircleModal)} disabled={disabled}>
			{`Someone from my circle${waitingText}`}
		</DropdownItem>
  )
}

export default ConnectWithinCircleItem;