import { connect } from 'react-redux';
import Footer from './Footer';
import { openAuthModal } from "../../actions/uiControls";

const lobbyStates = ['present', 'absent', 'waiting'];

const mapStateToProps = (state, ownProps) => {
  return {
    userRole: state.userRole,
    isInExperience: !lobbyStates.includes(state.userState),
    location: ownProps.location
  };
};

const mapDispatchtoProps = (dispatch) => {
	return {
		openAuthModal: (e) => {
			e.preventDefault();
			dispatch(openAuthModal());
		}
	}
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(Footer);
