import { connect } from 'react-redux';
import ActiveExperience from './ActiveExperience';
import { setVideoTag } from '../../actions/peerRoom';

const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    selectedExperienceData: state.selectedExperienceData.data,
    languageDropDown: state.selectLanguage.languageDropDown,
    peerRoom: state.peerRoom,
    realtime: state.realtime,
    fdTest: state.fdTest

  };
};

const mapDispatchtoProps = {
  setVideoTag
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ActiveExperience);