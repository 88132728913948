export const FD_TEST_POSITIVE = "FD_TEST_POSITIVE";
export const FD_TEST_NEGATIVE = "FD_TEST_NEGATIVE";


export function fdTestPositive() {
  return {
    type: FD_TEST_POSITIVE
  };
}

export function fdTestNegative() {
  return {
    type: FD_TEST_NEGATIVE,
  };
}