import React from "react";
import textResource from '../../../lib/textLoader';
import { connect } from 'react-redux';
import ReportPerson from './ReportPerson';
import { sendUserReport } from "../../../actions/inappropriateBehaviourReport";
import {validateForm} from '../../../lib/validationHelpers';
import serialize from 'form-serialize';
import { sendGAEventForDigestingState } from "../../../actions/peerRoom";
import {sendAmplitudeEvent} from "../../../lib/amplitude"
import constants from "../../../constants";
import { formatEndReasonForUser } from "../../../lib/endReason";

class ReportPersonContainer extends React.Component {
	constructor() {
		super();

    this.state = {
      errors: {}
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
	}

  componentDidUpdate(prevProps){
    if (!prevProps.inappropriateBehaviourReport.error && this.props.inappropriateBehaviourReport.error) {
      this.setState({
        errors: {
          fromServer: "Your report could not be sent."
        },
      })      
    }
    if (!prevProps.inappropriateBehaviourReport.sent && this.props.inappropriateBehaviourReport.sent) {
      this.props.onCancel(); 
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target
    const data = serialize(form, {hash: true})
    let updatedData = {
      selectedOptions: []
    }
    Object.keys(data).forEach(key => {
      let currentValue = constants.notFollowingGuidelines[key] || constants.inappropriateBehaviour[key]
      if(currentValue) {
        updatedData.selectedOptions.push(currentValue)
      }else {
        updatedData[key] = data[key]
      }
    })
    let errors;
    if(updatedData.other || !updatedData.selectedOptions.length) {
      errors = validateForm(updatedData, "userReport");
    }
    if (errors) {
      this.setState({errors})
    } else {
      this.formSuccess(form, updatedData)
    }
  }

  formSuccess(form, data) {
    let {roomID,position,experienceType,poolType,noRepeatMatchmakerOption,groupSpace} = this.props.peerRoom;
    let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
    this.props.sendGAEventForDigestingState('Human Minute', 'Send report user', data.selectedOptions.join(), this.props.completedHumanMinutes);
    sendAmplitudeEvent('submit report', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'end reason': formatEndReasonForUser(this.props.endConnectionReason), 'selected options': data.selectedOptions.join()});
    this.props.sendUserReport(data.reportExplanation, roomID, position, data.selectedOptions, noRepeat);
  }

  render() {
    return <ReportPerson 
      onSubmit={this.onSubmit}
      onCancel={this.props.onCancel}
      inappropriateBehaviourReport={this.props.inappropriateBehaviourReport}
      {...this.state}
    />;
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    inappropriateBehaviourReport: state.inappropriateBehaviourReport,
    peerRoom: state.peerRoom,
    onCancel: ownProps.onCancel,
    completedHumanMinutes: state.userRole.completedHumanMinutes,
    endConnectionReason: state.endConnectionReason
  };
};

const mapDispatchtoProps = {
  sendUserReport,
  sendGAEventForDigestingState
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ReportPersonContainer);