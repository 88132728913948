import React from "react";
import moment from "moment-timezone";
import WhatAreGatheringsTooltip from "./WhatAreGatheringsTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTimeDiff } from "./utils";
import './GatheringInfoStyles.css';

class NextRegularGathering extends React.Component {
  dateDiffIntervalId = null;
  localTimezone = moment.tz.guess();

  state = {
    timeDiff: {
      unitCount: 0,
      unit: "seconds",
      humanReadableString: ""
    }
  };

  componentDidMount() {
    if (this.props.nextRegularGathering) {
      this.setTimeDiffInterval();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.nextRegularGathering &&
      prevProps.nextRegularGathering !== this.props.nextRegularGathering
    ) {
      this.setTimeDiffInterval();
    }
  }

  componentWillUnmount() {
    this.dateDiffIntervalId && window.clearInterval(this.dateDiffIntervalId);
  }

  setTimeDiffInterval = () => {
    const nextRegularGatheringStartTime = this.props.nextRegularGathering
      .startTimeMillSec;
    this.setTimeDiff(nextRegularGatheringStartTime);

    this.dateDiffIntervalId && window.clearInterval(this.dateDiffIntervalId);

    this.dateDiffIntervalId = window.setInterval(() => {
      this.setTimeDiff(nextRegularGatheringStartTime);
    }, 60000);
  };

  setTimeDiff = endDate => {
    const timeDiff = getTimeDiff({
      startDate: Date.now(),
      endDate
    });

    this.setState({ timeDiff });
  };

  render() {

    const { nextRegularGathering, currentGathering } = this.props;
    const { timeDiff } = this.state;

    const nextRegularMoment = nextRegularGathering
      ? moment.tz(nextRegularGathering.startTimeMillSec, this.localTimezone)
      : null;


    return (
      <div className="pb-2 mb-sm-4 pb-sm-2 next-gatherings carousel-gathering" >
        {nextRegularMoment && (
          <div>
            <h5 className="every-day">
              Gatherings every day at {nextRegularMoment.format("LT [(]z[)]")}
                <FontAwesomeIcon
                icon="question-circle"
                id="WhatAreGatheringsTrigger"
                />
                &nbsp;
            </h5>
            {
              !currentGathering &&
              <h5 className="next-at pt-4">
                &nbsp;
                {timeDiff.unitCount >= 0 &&
                  timeDiff.humanReadableString &&
                  `Next ${timeDiff.humanReadableString}`}
              </h5>
            }
          </div>
        )}
        <WhatAreGatheringsTooltip />
      </div>
    );
  }
}

export default NextRegularGathering;