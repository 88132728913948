import { connect } from 'react-redux';
import EndConnectionButton from './EndConnectionButton';
import { endConnectionReasons } from "../../actions/endConnectionReason";
import { setStateDisconnected } from "../../actions/userState";


const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    show: state.uiControls.connectionControls
  };
};

const mapDispatchtoProps = (dispatch) => {
	return {
		onClickEndConnection: (userState) => {
      if (userState === "ready") {
        dispatch(setStateDisconnected({ reason: endConnectionReasons.CANCEL_SELF }));
      } else {
        dispatch(setStateDisconnected({ reason: endConnectionReasons.MANUAL_SELF }));
      }			
		}
	};
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(EndConnectionButton);
