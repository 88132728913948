import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import ValidationErrorMessage from './ValidationErrorMessage';
import ErrorMessage from './ErrorMessage';
import Spinner from '../elements/BlackSpinner';
import textResource from '../../lib/textLoader';

export default class SocialLoginForm extends React.Component {
  state = {
    email: ''
  }

  onChangeEmail = (e) => {
    if(!this.props.alreadyRegisteredWithEmail) {
      this.setState({ email: e.target.value})
    }
  }
  render() {
    let { onSubmit, errors, socialLoginData, alreadyRegisteredWithEmail, userRole } = this.props;

    return (
      <Form id="FormSocialLogin" onSubmit={onSubmit}>
        <ErrorMessage errors={errors} />

        <FormGroup className="pt-3">
            {!alreadyRegisteredWithEmail ? <p>{textResource["social_login_form_does_not_email.text"]}</p>:
            <p>{textResource["social_login_form_already_registered_email.text"]} <b>{this.state.email}</b> {textResource["social_login_form_enter_your_password.text"]}</p>}
        </FormGroup>
        {!alreadyRegisteredWithEmail && <FormGroup className="pt-3 pb-3">
          <p>{textResource["social_login_form.name"]}</p>
          <Input
            type="text" 
            name="name" 
            id="socialLoginName"
            defaultValue={socialLoginData && socialLoginData.name}
            disabled
          />
        </FormGroup>}
        <FormGroup className="pb-3">
          <p>{textResource["social_login_form.email"]}</p>
          <Input 
            invalid={!!errors.email}
            type="text" 
            name="email" 
            id="socialLoginEmail"
            defaultValue={socialLoginData && socialLoginData.email}
            onChange={this.onChangeEmail}
          />
          {!alreadyRegisteredWithEmail &&<p className="pt-2">{textResource["social_login_form_email_hint_message"]}</p>}
          <ValidationErrorMessage message={errors.email} />
        </FormGroup>
        {alreadyRegisteredWithEmail &&<FormGroup className="pb-3">
          <p>{textResource["social_login_form.password"]}</p>
          <Input 
            invalid={!!errors.password}
            type="password" 
            name="password" 
            id="password"
          />
          <ValidationErrorMessage message={errors.password} />
        </FormGroup>}

        {
          userRole.isFetching ?
          (<div className="authSpinner">
            <Spinner />
          </div>) :
          <Button id="BtnRegister" className="mb-5" color="primary">{textResource["social_login_form.continue_btn"]}</Button>
        }
        
      </Form>
    );
  }
}