import React from "react";
import constants from '../../constants';
import sendGAEvent from '../../lib/sendGAEvent';
import {sendAmplitudeEvent} from '../../lib/amplitude';
import {buildSignalingService} from '../../lib/webrtc/SignalingService';
import { Redirect } from 'react-router-dom';


export default class Matchmaker extends React.Component {
	constructor() {
		super();

		this.initiatePairingUser = this.initiatePairingUser.bind(this);
		this.relauchPairingUser = this.relauchPairingUser.bind(this);
		this.cancelPairingUser = this.cancelPairingUser.bind(this);
		this.startPairingTimeout = this.startPairingTimeout.bind(this);
		this.clearPairingTimeout = this.clearPairingTimeout.bind(this);
	
		this.onHelloMessage = this.onHelloMessage.bind(this);
		this.setNoAnswerTimeout = this.setNoAnswerTimeout.bind(this);
		this.clearNoAnswerTimeout = this.clearNoAnswerTimeout.bind(this);

		this.connectTimeout = null;
		this.clickConnectTime = 0;
		this.helloChannel = null;
		this.noAnswerTimeout = null;
		this.SignalingService = null;
	}

	componentDidMount() {
		console.log(this.props);
	}

  componentWillUnmount() {
  	if (this.pairingTimeout) {
  		this.clearPairingTimeout();
  	}   
	}

	componentDidUpdate(prevProps){

		if (
			!prevProps.peerRoom.roomID && this.props.peerRoom.roomID
		) {
			let { peerRoom } = this.props;

			// this.joinHelloChannel();
			this.SignalingService = buildSignalingService(this.props.realtime, peerRoom.roomID);
			this.SignalingService.setHelloListener(this.onHelloMessage);

			if (peerRoom.position === 2) {
				this.SignalingService.sendHelloMessage();
				this.setNoAnswerTimeout();
			}

		}

		// If I don't have a roomID and get a peerRoom.error, it happened on a pairUser request

		if (
			!this.props.peerRoom.roomID && !prevProps.peerRoom.error && this.props.peerRoom.error
		) {
			this.props.setStatePresent();
		}

		// If I get an error while trying to get the selected experience data, I cannot go ahead with the experience
		if (!prevProps.selectedExperienceData.error && this.props.selectedExperienceData.error) {
			this.props.setStatePresent();
		}


		if(
			prevProps.userState === "waiting" &&
			((this.props.userState === "present" && !this.props.peerRoom.error) ||
				(this.props.userState === "firstTimeGuidance") ||
			(this.props.userState === "waiting" && (prevProps.peerRoom.experienceType !== this.props.peerRoom.experienceType || prevProps.peerRoom.poolType !== this.props.peerRoom.poolType) ))
		){
			
			this.cancelPairingUser(prevProps.peerRoom);
		}

		if (
			prevProps.userState !== "waiting" && this.props.userState === "waiting" || 
			prevProps.userState === "waiting" && this.props.userState === "waiting" && 
			(prevProps.peerRoom.experienceType !== this.props.peerRoom.experienceType || prevProps.peerRoom.poolType !== this.props.peerRoom.poolType)
		) {

			this.initiatePairingUser();
		}


		if (!prevProps.peerRoom.confirmed && this.props.peerRoom.confirmed) {

			let {experienceType, noRepeatMatchmakerOption, poolType, groupSpace, position} = this.props.peerRoom;
			let noRepeat = noRepeatMatchmakerOption[experienceType] || false;

			if (experienceType !== "oneminute") {
				this.props.getSelectedExperienceData(experienceType, position)
			}else{
				this.props.clearSelectedExperienceData()
			}

  		let timeWaited = 0;
  		if (this.clickConnectTime) {
  			timeWaited = Math.ceil((Date.now() - this.clickConnectTime)/1000);
  		}

			sendAmplitudeEvent('found match', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'wait time': timeWaited });
		
		}

		if (
			(this.props.userState === "waiting" || (["present", "absent"].includes(this.props.userState) && ["present", "absent"].includes(prevProps.userState) && this.props.peerRoom.activeExperienceInvitation)) &&
			this.props.peerRoom.confirmed &&
			this.props.selectedExperienceData.data.type === this.props.peerRoom.experienceType &&
			(this.props.selectedExperienceData.data.type === "oneminute" ||
			prevProps.selectedExperienceData.isFetching && !this.props.selectedExperienceData.isFetching)
		) {

			// let { userRole, selectedExperienceData } = this.props;
			// let isAccepted = userRole.HMGuidance1Accepted ? userRole.HMGuidance1Accepted : userRole.acceptedExperiencesGuidance[newPeerRoom.experienceType]
				

			// if(selectedExperienceData.data.hasGuidanceStage && !isAccepted) {
			// 	this.props.setStateFirstTimeGuidance()
			// }else if (selectedExperienceData.data.hasEnteringRoomStage) {
				this.props.setStateEnteringRoom()
			// }else{
			// 	this.props.setStateGettingMedia();
			// }
		}
	}

	initiatePairingUser() {
		this.clickConnectTime = Date.now();

		let groupSpace = this.props.match.params.groupid || "open";
		this.props.setGroupSpace(groupSpace);

		let connectClickOriginUrl = this.props.location.pathname;
		this.props.setConnectClickOriginUrl(connectClickOriginUrl);

		let {experienceType, noRepeatMatchmakerOption, poolType} = this.props.peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;

		this.props.pairUser({groupSpace, experienceType, noRepeat, poolType});
		this.startPairingTimeout();

		let {connectNotifications} = this.props;
		let fromNotification = !!(
			connectNotifications.lastNotificationType === experienceType &&
			((Date.now() - connectNotifications.lastNotificationShown) < 30000)
		)

		sendAmplitudeEvent('click connect', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'from notification': fromNotification})
	}

	relauchPairingUser() {
		let groupSpace = this.props.peerRoom.groupSpace;
		let {experienceType, noRepeatMatchmakerOption, poolType} = this.props.peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
		this.props.pairUser({groupSpace, experienceType, noRepeat, poolType});
	}

	cancelPairingUser(oldPeerRoom) {
		this.SignalingService.closeChannel();
		this.SignalingService = null;
		this.clearPairingTimeout();
		
		let {experienceType, poolType, noRepeatMatchmakerOption, groupSpace} = oldPeerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;

		this.props.cancelPairUser({groupSpace, experienceType, poolType, noRepeat});

		this.props.setTriedConnectTrue();

		let timeWaited = 0;
		if (this.clickConnectTime) {
			timeWaited = Math.floor((Date.now() - this.clickConnectTime)/1000);
		}
		sendAmplitudeEvent('click cancel', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'wait time': timeWaited})
		sendGAEvent('Human Minute', 'Cancel before paired', '', timeWaited);

		this.clickConnectTime = 0;

	}

	startPairingTimeout() {
		this.connectTimeout = setTimeout(() => {
			this.props.setStatePresent();
			this.props.showMatchmakerTimeoutAlert();
		}, constants.timeControls["pairingTimeout"])		
	}

	clearPairingTimeout() {
    if (this.connectTimeout) {
			clearTimeout(this.connectTimeout);
			this.connectTimeout = null;
		}
	}


	onHelloMessage() {
		this.clearPairingTimeout();
		let { peerRoom } = this.props;

		if (peerRoom.position === 1) {
			this.SignalingService.sendHelloMessage();
		} else {
			this.clearNoAnswerTimeout();
		}
		
		this.props.setRoomConfirmed();
	}

	setNoAnswerTimeout() {
		this.noAnswerTimeout = setTimeout(() => {
			this.SignalingService.closeChannel();
			this.SignalingService = null;
			if (this.props.userState === "waiting") {
				this.relauchPairingUser();
			}
			
		}, constants.timeControls["peerNoAnswerTimeout"]);
	}

	clearNoAnswerTimeout() {
		if (this.noAnswerTimeout) {
			clearTimeout(this.noAnswerTimeout);
			this.noAnswerTimeout = null;
		}
	}

  render() {

    if(this.props.location.pathname !== "/experience" && !["present", "absent", "waiting", "firstTimeGuidance"].includes(this.props.userState)) {
      return <Redirect to='/experience' />
    }
    return null;
  }
}