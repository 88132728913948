import * as Actions from "../actions/subscription";


const initialState = {
	subscriptionPrice: 0,
	subscriptionPaymentSucceded: false,
	isFetching: false,
	error: null,
	paymentMethods: [],
	cancelSubscriptionSuccess: false,
	updateSubscriptionPriceSuccess: false,
	getAllPaymentMethodsSuccess: false,
	makePaymentMethodDefaultSuccess: false,
	deletePaymentMethodSuccess: false,
	addPaymentMethodSuccess: false,
	recoverSubscriptionSuccess: false,
};

export function subscriptionState(state = initialState, action) {
  switch (action.type) {

		case Actions.SET_SUBSCRIPTION_PRICE:
			return {
				...state,
			  subscriptionPrice: action.price
			}
		case Actions.SUBSCRIPTION_START:
			return {
				...state,
			  subscriptionPaymentSucceded: false,
			  cancelSubscriptionSuccess: false,
				updateSubscriptionPriceSuccess: false,
				getAllPaymentMethodsSuccess: false,
				makePaymentMethodDefaultSuccess: false,
				deletePaymentMethodSuccess: false,
				addPaymentMethodSuccess: false,
				recoverSubscriptionSuccess: false,
				isFetching: true,
				error: null
			}
		case Actions.SUBSCRIPTION_SUCCESS:
			return {
				...state,
			  subscriptionPaymentSucceded: true,
				isFetching: false,
				error: null
			}
		case Actions.SUBSCRIPTION_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.err
			}
		case Actions.CLEAR_SUBSCRIPTION:
			return {
			  subscriptionPaymentSucceded: false,
				isFetching: false,
				error: null,
				subscriptionPrice: 0,
				cancelSubscriptionSuccess: false,
				updateSubscriptionPriceSuccess: false,
				getAllPaymentMethodsSuccess: false,
				makePaymentMethodDefaultSuccess: false,
				deletePaymentMethodSuccess: false,
				addPaymentMethodSuccess: false,
				recoverSubscriptionSuccess: false,
				paymentMethods: []
			}
		case Actions.SET_EXISTING_PAYMENT_METHODS:
			return {
				...state,
				paymentMethods: action.data,
				isFetching: false,
				error: null
			}
		case Actions.ADD_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				addPaymentMethodSuccess: true,
				paymentMethods: [
					...state.paymentMethods,
					action.data
				],
				isFetching: false,
				error: null
			}
		case Actions.SUBSCRIPTION_CANCEL_SUCCESS:
			return {
				...state,
			  cancelSubscriptionSuccess: true,
				isFetching: false,
				error: null
			}
		case Actions.RECOVER_SUBSCRIPTION_SUCCESS:
			return {
				...state,
			  recoverSubscriptionSuccess: true,
				isFetching: false,
				error: null
			}
		case Actions.UPDATE_SUBSCRIPTION_PRICE_SUCCESS:
			return {
				...state,
			  updateSubscriptionPriceSuccess: true,
				isFetching: false,
				error: null
			}
    default:
      return state;
  }
}