import React, { Component } from 'react';
import textResource from '../../lib/textLoader';
import {Alert} from 'reactstrap'


class LobbyAlerts extends Component {

	constructor() {
		super();

		this.clearAnyAlerts = this.clearAnyAlerts.bind(this);
	}

	componentWillUnmount() {
		this.clearAnyAlerts();
	}

	componentDidUpdate(prevProps){
		let lobbyStates = ["present", "absent"];
		let oldState = prevProps.userState;
		let newState = this.props.userState;

		if (lobbyStates.includes(oldState) && !lobbyStates.includes(newState)) {
			this.clearAnyAlerts();
		}

		if (oldState === "digesting" && newState !== "digesting") {
			this.clearAnyAlerts();
		}

		if (prevProps.email && !this.props.email) {
			this.clearAnyAlerts();
		}

		if (!prevProps.authModalOpen && this.props.authModalOpen) {
			this.clearAnyAlerts();
		}
	}

	clearAnyAlerts() {
		if (this.props.registrationConfirmation) {
			this.props.hideRegistrationConfirmation();
		}
		if (this.props.accountDeleted) {
			this.props.clearChanges();
		}
		if (this.props.registeredToEmails) {
			this.props.clearChanges();
		}
		if (this.props.passwordRecoveryRequested) {
			this.props.clearChanges();
		}
		if (this.props.passwordResetSuccessful) {
			this.props.clearChanges();
		}
		if (this.props.validateRecoveryHashReady && !this.props.validateRecoveryHashSuccess) {
			this.props.clearChanges();
		}
		if (this.props.matchmakerError) {
			this.props.clearPeerRoom();
		}
		if (this.props.matchmakerTimeout) {
			this.props.hideMatchmakerTimeoutAlert();
		}
		if (this.props.emailVerification.processed || this.props.emailVerification.resentEmail) {
			this.props.clearVerification();
		}
		if (this.props.inappropriateBehaviourReport.sent) {
			this.props.clearUserReport();
		}
		if (this.props.selectedExperienceData.error) {
			this.props.clearSelectedExperienceData();
		}
	}

  render() {
		
		if (!["present", "absent", "digesting"].includes(this.props.userState)) { return null; }

    return (
    	<div>
		    {
		    	this.props.registrationConfirmation 
		    	? (<Alert color="success">
				      {textResource["lobby.messages.registration_confirmation"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	this.props.accountSuspended 
		    	? (<Alert color="danger">
				      Your account has been suspended due to reports from users.<br/>
				      We are investigating this issue and we will be in contact with you.<br/>
				      If you believe this is a mistake, please write to us at support@human.online.
				    </Alert>)
		    	: null
		    }
		    {
		    	this.props.accountDeleted 
		    	? (<Alert color="success">
				      {textResource["lobby.messages.account_deleted"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	this.props.registeredToEmails && this.props.howSolicited === "lobbyButton"
		    	? (<Alert color="success">
				      {textResource["lobby.messages.registered_to_emails"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	this.props.passwordRecoveryRequested 
		    	? (<Alert color="success">
				      {textResource["lobby.messages.recovery_email_requested"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	this.props.passwordResetSuccessful 
		    	? (<Alert color="success">
				      {textResource["lobby.messages.password_reset_successful"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	(this.props.validateRecoveryHashReady && !this.props.validateRecoveryHashSuccess) 
		    	? (<Alert color="danger">
				      {textResource["lobby.messages.recovery_hash_invalid"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	this.props.matchmakerError 
		    	? (<Alert color="danger">
				      {textResource["lobby.messages.matchmaker_error"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	this.props.selectedExperienceData.error
		    	? (<Alert color="danger">
				      Could not get the selected experience data. This might be an internet error on your side or an error in our server.
				    </Alert>)
		    	: null
		    }
		    {
		    	(this.props.matchmakerTimeout && !this.props.matchmakerError)
		    	? (<Alert color="primary">
				      {
				      	this.props.currentGathering ?
				      	textResource["lobby.messages.matchmaker_timeout_gathering"] :
				      	textResource["lobby.messages.matchmaker_timeout"]
				      }
				    </Alert>)
		    	: null
		    }
		    {
		    	(this.props.userRole !== "guest" && this.props.fdTest.testDone && !this.props.fdTest.webrtcAvailable)
		    	? (<Alert color="danger">
				      {textResource["device_browser_not_supported.message"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	(this.props.emailVerification.verified)
		    	? (<Alert color="success">
				      {textResource["lobby.messages.email_verified"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	(this.props.emailVerification.processed && !this.props.emailVerification.verified)
		    	? (<Alert color="danger">
				      {textResource["lobby.messages.hash_not_valid"]}
				    </Alert>)
		    	: null
		    }
		    {
		    	(this.props.emailVerification.resentEmail)
		    	? (<Alert color="success">
				      {textResource["check_email.email_resent"]}
				    </Alert>)
		    	: null
			}
		  </div>
    );
  }
}

export default LobbyAlerts;