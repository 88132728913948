import React from 'react';
import { DropdownItem } from 'reactstrap';


const FullscreenSettingItem = ({userRole, fullScreen, setFullScreen, cancelFullScreen}) => {

  let onClick = fullScreen ? cancelFullScreen : setFullScreen;
  let text = fullScreen ? "Turn full-screen off" : "Turn full-screen on";

  if (userRole.role === "admin") {
    return (
      <DropdownItem id="BtnFS" onClick={onClick} style={{cursor: 'pointer'}}>
      	{text}
      </DropdownItem>
    )
  } else {
    return null;
  }
}

export default FullscreenSettingItem;