import React from 'react';
import './AccountPagesStyles.css';
import textResource from '../../lib/textLoader';
import { Button, Form, FormGroup, FormText, Label, Input, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import ValidationErrorMessage from '../Authentication/ValidationErrorMessage';
import ErrorMessage from '../Authentication/ErrorMessage';
import LayoutWrapper from '../elements/LayoutWrapper';


const ChangePassword = ({ onSubmit, errors, userRole }) => {
	
	return (
		<LayoutWrapper>
		  	<h1 className="pb-3 text-capitalize">{textResource["change_password.title"]}</h1>
		    <Form className="pt-3" id="FormChangePassword" onSubmit={onSubmit}>
		      <ErrorMessage errors={errors} />
			
		      {userRole.isPasswordExist &&<FormGroup className="mt-3" row>
		        <Label for="oldPassword" xs={12}>
		          {textResource["change_password.current"]}
		        </Label>
		        <Col xs={12}>
			        <Input 
			          invalid={!!errors.oldPassword}
			          type="password" 
			          name="oldPassword" 
			          id="oldPassword" 
			        />
			        <ValidationErrorMessage message={errors.oldPassword} />
			      </Col>		        
		      </FormGroup>}
		      <FormGroup  className="mt-3" row>
		        <Label for="newPassword" xs={12}>
		          {textResource["change_password.new"]}
		        </Label>
		        <Col  xs={12}>
	            
			        <Input 
			          invalid={!!errors.newPassword}
			          type="password" 
			          name="newPassword" 
			          id="newPassword" 
			        />
					<FormText color="muted" style={{"textAlign": "left"}}>
	              {textResource["change_password.password_guidance"]}
	            </FormText>
			        <ValidationErrorMessage message={errors.newPassword} />
			      </Col>		        
		      </FormGroup>
		      <Row className="mt-5">
				{/* <Col sm={5} xs={12}></Col> */}
				<Col  lg={{ size: 5 }}  sm={{ size: 7 }} xs={{ size: 12 }} >
						<Button id="BtnChangePassword" color="primary" className="mb-1" block>
							{textResource["change_password.submit"]}
						</Button>
					</Col>
				<Col lg={{ size: 4 }}  sm={{ size: 5 }} xs={{ size: 12 }}>
							<Link to="/account/profile" style={{ textDecoration: "none", color: "black" }}>
								<Button color="secondary" block>
									{textResource["change_password.cancel"]}
								</Button>
							</Link>
					</Col>
		      </Row>
		    </Form>
		</LayoutWrapper>
	)
}

export default ChangePassword;
