import React from 'react';
import '../ActiveExperienceStyles.css';

class ExperienceTextInstructions extends React.Component {

	render() {
		if (!["ready", "connected"].includes(this.props.userState)) {return null;}
    if (!this.props.showTextInstructionMessage) {return null;}
        
		return (
			<div className="audio-message-text">
				<p>{this.props.showTextInstructionMessage}</p>
			</div>
		)
	}
}

export default ExperienceTextInstructions;