import React from 'react';
import textResource from '../../lib/textLoader';
import { Container, Button, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import ValidationErrorMessage from '../Authentication/ValidationErrorMessage';
import ErrorMessage from '../Authentication/ErrorMessage';
import LobbyAlert from '../elements/LobbyAlert';
import '../AppStyles.css';



const InvitationForm = ({ onSubmit, errors, invitationSent, clearInvitation, userRole }) => {

  return (
    <Container className="invitationEmailContainer">
      <Row>
        <Col
          sm={12}
          className="mt-4 mb-3"
        >
          <h5 className="mobfont16">{textResource["invitation.text"]}</h5>
        </Col>
        <Col
          sm={12}
          md={{
            size: 8,
            offset: 2
          }}
          lg={{
            size: 6,
            offset: 3
          }}
        >
          <Form inline onSubmit={onSubmit} className="mb-5 text-left">
            {errors.fromServer && <ErrorMessage errors={errors} />}
            <div className="w-100">
            <LobbyAlert
              showCondition={invitationSent}
              clearFunction={clearInvitation}
              alertColor="success"
              alertText={textResource["lobby.messages.invitation_sent"]}
            />
</div>
            <FormGroup className="emailContainer">
              {/* <Label for="email" className="mr-3 mb-3">
                {textResource["invitation.email"]}
              </Label> */}
              <Input
                invalid={!!errors.email}
                type="text"
                name="email"
                className="mr-3 mb-3"
                placeholder="Email Address"
              />
            </FormGroup>
            <FormGroup className="invitationContainer"> 
            
              <Button
                color="secondary"
                className="mb-3"
              >
                {textResource["invitation.submit"]}
              </Button>
              <ValidationErrorMessage message={errors.email} />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>

  )
}

export default InvitationForm;