import React from "react";
import textResource from '../../lib/textLoader';
import { connect } from 'react-redux';
import InvitationForm from './InvitationForm';
import { sendInvitation, clearInvitation } from "../../actions/invitation";
import {validateForm} from '../../lib/validationHelpers';
import serialize from 'form-serialize';

class InvitationFormContainer extends React.Component {
	constructor() {
		super();

    this.state = {
      errors: {}
    }

    this.onSubmit = this.onSubmit.bind(this);

	}

  componentDidUpdate(prevProps){
    if (!prevProps.invitation.error && this.props.invitation.error) {
      this.setState({
        errors: {
          fromServer: textResource["invitation.messages.server_error"]
        },
      })      
    }
    
    if (!prevProps.invitation.limit && this.props.invitation.limit) {
      this.setState({
        errors: {
          fromServer: textResource["invitation.messages.reached_limit"]
        },
      })      
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target
    const data = serialize(form, {hash: true})
    Object.keys(data).forEach((key) => {
      data[key] = data[key].trim();
    })
    const errors = validateForm(data, "invitation");

    if (errors) {
      this.setState({errors})
    } else {
      this.formSuccess(form, data)
    }
  }

  formSuccess(form, data) {
    form.reset()
    this.props.sendInvitation(data.email);
    this.setState({
      errors: {},
    })
  }

  render() {
    if (this.props.accountSuspended) { return null; }

    return <InvitationForm 
      onSubmit={this.onSubmit}
      invitationSent={this.props.invitation.sent}
      clearInvitation={clearInvitation}
      userRole={this.props.userRole}
      {...this.state}  
    />;
  }
}

// yyPilot1

const mapStateToProps = (state) => {
  return {
    invitation: state.invitation,
    accountSuspended: state.userRole.accountSuspended,
    userRole: state.userRole
  };
};

const mapDispatchtoProps = {
  sendInvitation
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(InvitationFormContainer);