import React from 'react';
import textResource from '../../../lib/textLoader';
import '../ExperienceModalStyles.css';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Badge, Button, Modal, ModalHeader, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import CancelButtonContainer from '../../ConnectionControls/CancelButtonContainer';
import ConnectButtonContainer from '../../ConnectionControls/ConnectButtonContainer';
import MicroPhoneIcon from '../../../resources/images/iconfinder_microphone_mic_record_micro_2848306.svg'
import SpeakerIcon from '../../../resources/images/iconfinder_devices_headphone_1287555.svg'
import NoMicroPhoneIcon from '../../../resources/images/iconfinder_NEGATIVE_microphone_mic_record_micro_2848306.svg'
import NoSpeakerIcon from '../../../resources/images/iconfinder_NEGATIVE_devices_headphone_1287555.png'
import ClockIcon from '../../../resources/images/iconfinder_time_reload_clock_2742414.svg'
import GuidedIcon from '../../../resources/images/iconfinder_user-man-colletion_293593.png'
import avatar from "../../../resources/images/190306_humanonline_logo_symbol_F_FINAL_01_small.png";

import CrossImg from '../../../resources/images/cross-black.png'
import ExperienceVideo from '../ExperienceVideo';
import Spinner from "../../elements/BlackSpinner";

import HumanMinuteAreaContainer from '../../Lobby/HumanMinuteArea/HumanMinuteAreaContainer';

import {sendAmplitudeEvent} from "../../../lib/amplitude"


class ExperienceInvitationPage extends React.Component {

	constructor() {
		super();

		this.onClickConnectInvitePage = this.onClickConnectInvitePage.bind(this);
		this.onClickCancelInvitePage = this.onClickCancelInvitePage.bind(this);
	}

	componentWillUnmount() {
		if (!this.props.peerRoom.confirmed && this.props.userRole.role === "guest") {
			this.props.logout()
		}		
	}

	onClickConnectInvitePage() {
		this.props.clickConnectExperienceInvite();
		this.props.matchmakerSuccess({
			roomID: this.roomID,
			position: 2
		});
    if (!this.props.experienceInvitation.clickedCancel) {
        sendAmplitudeEvent('click connect on invite page', {'experience type': this.props.experienceInvitation.experience.type});
    }
	}

  onClickCancelInvitePage() {
      if (!this.props.experienceInvitation.clickedCancel) {
          sendAmplitudeEvent('click cancel on invite page', {'experience type': this.props.experienceInvitation.experience.type});
      }
      this.props.clickCancelExperienceInvite();
  }
	

	componentDidUpdate(prevProps) {
		if (!prevProps.userRole.ready && this.props.userRole.ready) {
			this.roomID = `peerchannel:${this.props.match.params.roomid}`;
			this.props.retrieveExperienceInvitation(this.roomID)			
		}

		if (!prevProps.userRole.email && this.props.userRole.email) {
			this.props.instantiateRealtime();
		}

		if (!Object.keys(prevProps.experienceInvitation.experience).length && Object.keys(this.props.experienceInvitation.experience).length) {
			this.props.setExperienceType(this.props.experienceInvitation.experience.type)
			this.props.setPoolType("personalInvite");
			sendAmplitudeEvent('open experience invite', {'experience type': this.props.experienceInvitation.experience.type, "invite active": true});
		}

		if (this.props.experienceInvitation.error && !prevProps.experienceInvitation.error) {
			sendAmplitudeEvent('open experience invite', {'experience type': "", "invite active": false});
		}

		if (!prevProps.peerRoom.confirmed && this.props.peerRoom.confirmed) {
			this.props.storeSessionFromInvitation(this.roomID);
		}

	}

  render() {
  	let { userState, peerRoom, userRole, experienceInvitation } = this.props;
  	let {experience} = experienceInvitation;

  	if (experienceInvitation.error) {
  		return <Alert color="danger">This experience invitation is no longer active</Alert>
  	}

   	if (experienceInvitation.isFetching) {
  		return <Spinner />
  	}

    if (!experience || !Object.keys(experience).length) {
      return null;
    }

    if (experience.type === "oneminute") {
    	return (
    		<div className="mt-4">
	      	{ experienceInvitation.clickedCancel &&
	      		<Alert color="primary">
					      Your host might have left the experience. You might need to ask them to invite you again.
					  </Alert>
	      	}
    			<HumanMinuteAreaContainer fromInvite={true} />
    		</div>
    	)
    }

		let disabled = this.props.realtime ? false : true;

    return (
    <div>
	    <Row className="mt-4">
	      <Col xs={{ size: 12, offset: 0 }} sm={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }}>
	      	{ experienceInvitation.clickedCancel &&
	      		<Alert color="primary">
					      Your host might have left the experience. You might need to ask them to invite you again.
					  </Alert>
	      	}

          <div className="topDedicatedExperiencePageContainer" style={{
            background: `url(${experience.backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: experience.backgroundColor
          }}>
	          <div className="durationBadgeInModal">{parseInt(experience.totalDuration/(1000 * 60))}&nbsp;min</div>
	          <div>
	            <p className="experienceTitle">{experience.name}</p>
	            <p className="author">by {experience.author}</p>
	          </div>
	          <div className="connectButtonsArea">
              <div>
                {
                  experienceInvitation.clickedConnect ?
                  <Button size="lg" color="secondary" onClick={this.onClickCancelInvitePage}>
                      Cancel
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Spinner size="1.5rem"/>    
                  </Button> :
	              	<Button id="BtnConnect" size="lg" color="default" disabled={disabled} onClick={this.onClickConnectInvitePage}>
										Connect
									</Button>
                }                                                   
              </div>

	          </div>
	        </div>

	        { experience.videoUrl &&
	        	<div className="ml-4 mr-4 mt-5">
  	        	<h4 className="experienceVideoTitle">{experience.videoTitle}</h4>
  	        	<ExperienceVideo videoUrl={experience.videoUrl}/>
  	        </div>
  	      }

  	      <div className="ml-4 mr-4 mt-3 experience-requirements" style={{background: "#f8f9fa", textAlign: "left"}}>
		        <Row className="mb-1">
		        	<Col xs={6}>
		            <div style={{paddingLeft: "1rem", paddingTop: "0.8rem", paddingBottom: "0"}}>
		              <div>
		                <img src={ClockIcon} className="clockImg"/>
		                {parseInt(experience.totalDuration/(1000 * 60))} minutes
		              </div>
		            </div>
		          </Col>
          		{
                experience.guidedIcon ?
			        	<Col xs={6}>
			            <div style={{paddingLeft: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem"}}>
			                <div>
			                  <img src={GuidedIcon} className="guidedImg"/>
			                  Guided
			                </div>                  
			            </div>
			          </Col> :
			          null
			        }
		        	<Col xs={6}>
		            <div style={{paddingLeft: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem"}}>
		              {
		                experience.speakerIcon ?
		                <div>
		                  <img src={SpeakerIcon} className="speakerImg"/>
		                  Speaker needed
		                </div> :
		                <div>
		                  <img src={NoSpeakerIcon} className="speakerImg"/>
		                  No speaker needed
		                </div>                   
		              }
		            </div>
		          </Col>
		          <Col xs={6}>
		            <div style={{paddingLeft: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem"}}>
		              {
		                experience.microphoneIcon ?
		                <div>
		                  <img src={MicroPhoneIcon} className="microphoneImg"/>
		                  Mic needed 
		                </div> :
		                <div>
		                  <img src={NoMicroPhoneIcon} className="microphoneImg"/>
		                  No mic needed 
		                </div>              
		              }  
		            </div>
		          </Col>
		        </Row>
		      </div>
	        <div className="ml-4 mr-4 mt-4 pb-4 experienceDescriptionModal" style={{textAlign: "left"}}>
	          {experience.description}
	        </div>
	        <div className="ml-4 mr-4 pb-4">
	        	{
	        		experience.author === "Human Online" ?
	            <div className="facilitatorCard">
	              <img src={experience.facilitatorImg || avatar} className="facilitatorImg"/>
	              <h4 className="facilitatorName">{experience.author}</h4>
	              <p className="facilitatorRole">Facilitator</p>
	            </div> :
		          <Link to={`/facilitator/${experience.author.replace(" ", "-")}`} >
		            <div className="facilitatorCard">
		              <img src={experience.facilitatorImg || avatar} className="facilitatorImg"/>
		              <h4 className="facilitatorName">{`${experience.author} ${experience.facilitatorTitle}`}</h4>
		              <p className="facilitatorRole">Facilitator</p>
		              <p className="facilitatorArrow">></p>
		            </div>
		          </Link>
	        	}
	        </div>
	      </Col>
	    </Row>
    </div>
    );
  }
}

export default ExperienceInvitationPage;