import React, {Component} from 'react';
import { connect } from 'react-redux';
import serialize from 'form-serialize';
import AdditionalInfoForm from './AdditionalInfoForm';
import { addRegistrationInfo } from "../../actions/userRole";

class AdditionalInfoFormContainer extends Component {
  constructor() {
    super()
    // this.state = {
    //   success: false,
    //   errors: {},
    // }

		this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const data = serialize(form, {hash: true});
    // const errors = validateForm(data);

    // if (errors) {
    //   this.setState({errors});
    // } else {
      this.formSuccess(form, data);
    // }
  }

  formSuccess(form, data) {
    form.reset();
    let { motivation, reference, explanation } = data;
    let email = this.props.userRole.email;
    this.props.addRegistrationInfo(email, motivation, reference, explanation);
  }

  render() {
    return (
      <AdditionalInfoForm
        onSubmit={this.onSubmit}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

const mapDispatchtoProps = {
	addRegistrationInfo
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(AdditionalInfoFormContainer);