import * as Actions from "../actions/accountChanges";

const initialState = {
  passwordChanged: false,
  accountDeleted: false,
  registeredToEmails: false,
  howSolicited: "",
  passwordRecoveryRequested: false,
  validateRecoveryHashReady: false,
  validateRecoveryHashSuccess: false,
	passwordResetSuccessful: false,
  isFetching: false,
  error: null
};

export function accountChanges(state = initialState, action) {
  switch (action.type) {
    case Actions.CHANGE_START:
      return {
			  passwordChanged: false,
			  accountDeleted: false,
			  registeredToEmails: false,
			  howSolicited: "",
			  passwordRecoveryRequested: false,
			  validateRecoveryHashReady: false,
  			validateRecoveryHashSuccess: false,
			  passwordResetSuccessful: false,
			  isFetching: true,
			  error: null
			};
    case Actions.CLEAR_CHANGES:
      return {
			  passwordChanged: false,
			  accountDeleted: false,
			  registeredToEmails: false,
			  howSolicited: "",
			  passwordRecoveryRequested: false,
			  validateRecoveryHashReady: false,
  			validateRecoveryHashSuccess: false,
			  passwordResetSuccessful: false,
			  isFetching: false,
			  error: null
			};
    case Actions.CHANGE_FAILURE:
      return {
			  passwordChanged: false,
			  accountDeleted: false,
			  registeredToEmails: false,
			  howSolicited: "",
			  passwordRecoveryRequested: false,
			  validateRecoveryHashReady: false,
  			validateRecoveryHashSuccess: false,
			  passwordResetSuccessful: false,
			  isFetching: false,
			  error: action.error
			};
    case Actions.CHANGE_PASSWORD_SUCCESS:
      return {
      	...state,
			  passwordChanged: true,
			  isFetching: false,
			  error: null
			};
    case Actions.REGISTER_EMAILS_SUCCESS:
      return {
      	...state,
			  registeredToEmails: true,
			  howSolicited: action.howSolicited,
			  isFetching: false,
			  error: null
			};
    case Actions.DELETE_ACCOUNT_SUCCESS:
      return {
			  ...state,
			  accountDeleted: true,
			  isFetching: false,
			  error: null
			};
    case Actions.PASSWORD_RECOVERY_REQUEST_SUCCESS:
      return {
			  ...state,
			  passwordRecoveryRequested: true,
			  isFetching: false,
			  error: null
			};
    case Actions.VALIDATE_RECOVERY_HASH_SUCCESS:
      return {
			  ...state,
			  validateRecoveryHashReady: true,
  			validateRecoveryHashSuccess: true,
			  isFetching: false,
			  error: null
			};
    case Actions.VALIDATE_RECOVERY_HASH_FAILURE:
      return {
			  ...state,
			  validateRecoveryHashReady: true,
			  isFetching: false,
			  error: null
			};
    case Actions.PASSWORD_RESET_SUCCESS:
      return {
			  ...state,
			  passwordResetSuccessful: true,
			  isFetching: false,
			  error: null
			};
    default:
      return state;
  }
}