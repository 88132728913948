import React from 'react';
import './TestimonialStyles.css';
import { Col, Row } from 'reactstrap';
import TestimonialLogo from "../../../resources/images/qotes.png"
import textResource from '../../../lib/textLoader';

const Testimonial = ({ userRole }) => {
  if (!userRole.ready || userRole.email) { return null; }

  return (
    <Row className="connectintrobox">
      <Col className="testimonialsbox" xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }} >
        <div>
          <div className="visitorBox">
            <img src={TestimonialLogo} />
            <p className="visitorText">{textResource["testimonial.text"]}</p>
            <p className="visitorCountry">{textResource["testimonial.author"]}</p>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Testimonial;
