import React from 'react';
import adapter from 'webrtc-adapter';
import constants from '../../../constants';
import { Redirect } from 'react-router-dom';

import ExperienceTimerContainer from './ExperienceTimerContainer';
import VideoManagerContainer from './VideoManagerContainer';

import {getSignalingService} from '../../../lib/webrtc/SignalingService';
import buildWebrtcService from '../../../lib/webrtc/WebRTCService';
import sendGAEvent from '../../../lib/sendGAEvent';
import {sendAmplitudeEvent} from '../../../lib/amplitude';
import {formatEndReasonForUser} from '../../../lib/endReason';
import { endConnectionReasons } from '../../../actions/endConnectionReason';
import AudioManagerContainer from './AudioManagerContainer';
import TextInstructionsManagerContainer from './TextInstructionsManagerContainer';

export default class ExperienceFlowManager extends React.Component {

	constructor() {
		super();

		this.nogo = false;
		this.noAnswerTimeout = null;
		this.noReadyTimeout = null;

		this.state = {
			localStream: null,
			remoteStream: null,
			redirectCameraFailure: false
		}

		this.getTracks = this.getTracks.bind(this);
		this.onGetUserMediaError = this.onGetUserMediaError.bind(this);
		this.setRemoteStream = this.setRemoteStream.bind(this);
		this.setPeerConnectionReady = this.setPeerConnectionReady.bind(this);
		this.setPeerConnectionFailed = this.setPeerConnectionFailed.bind(this);
		this.sendOfferIfImCaller = this.sendOfferIfImCaller.bind(this);

		this.subscribeToAblyEvents = this.subscribeToAblyEvents.bind(this);
		this.onThankyouMessage = this.onThankyouMessage.bind(this);
		this.onAddedCircleMessage = this.onAddedCircleMessage.bind(this);
		this.onCloseMessage = this.onCloseMessage.bind(this);
		this.onReadyMessage = this.onReadyMessage.bind(this);

		this.setNoReadyTimeout = this.setNoReadyTimeout.bind(this);
		this.clearNoReadyTimeout = this.clearNoReadyTimeout.bind(this);

		this.disconnectSettingAppropriateReason = this.disconnectSettingAppropriateReason.bind(this);
		this.closeMessageOnUnloadIfAppropriate = this.closeMessageOnUnloadIfAppropriate.bind(this);
		this.sendCloseMessageIfNeeded = this.sendCloseMessageIfNeeded.bind(this);
		this.fixFailedCancel = this.fixFailedCancel.bind(this);
		this.sendSessionDataIfAppropriate = this.sendSessionDataIfAppropriate.bind(this);
	}

	componentDidMount() {
		var iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
		var eventName = iOS ? 'pagehide' : 'beforeunload';

    window.addEventListener(eventName, (event) => {
			this.closeMessageOnUnloadIfAppropriate();
    });

		this.SignalingService = getSignalingService();
		this.subscribeToAblyEvents();
		this.props.getIceServers();
	}

	componentDidUpdate(prevProps){
		let oldState = prevProps.userState;
		let newState = this.props.userState;
		let oldPeerRoom = prevProps.peerRoom;
		let newPeerRoom = this.props.peerRoom;


		//////////////////////////////////////////////////////
		// Received hello message so room is confirmed
		//////////////////////////////////////////////////////
		if (!oldPeerRoom.confirmed && newPeerRoom.confirmed) {
			// if (newPeerRoom.cancelled) {
			// 	return this.fixFailedCancel();
			// }
			// console.log("all ready room ", newPeerRoom.roomID);
			// this.props.getIceServers();
		}


		//////////////////////////////////////////////////////
		// Received cancel failed and room was already confirmed
		//////////////////////////////////////////////////////
		if (!oldPeerRoom.cancelled && newPeerRoom.cancelled) {
			// if (newPeerRoom.confirmed) {
			// 	return this.fixFailedCancel();
			// }
		}

		//////////////////////////////////////////////////////
		// Received ice servers
		//////////////////////////////////////////////////////
		if (!prevProps.iceServers.servers.length && this.props.iceServers.servers.length) {
			if (newPeerRoom.cancelled || this.nogo) { return; }

			this.WebRTCService = buildWebrtcService(this.props.iceServers.servers, this.SignalingService, this.setRemoteStream, this.setPeerConnectionReady, this.setPeerConnectionFailed);

		}

		//////////////////////////////////////////////////////
		// When room is confirmed and I have a WebRTCService ready, ask user for media
		//////////////////////////////////////////////////////
		if (oldState !== "gettingMedia" && newState === "gettingMedia") {

			if (newPeerRoom.cancelled || this.nogo) { return this.props.setStateDisconnected({ reason: endConnectionReasons.CANCEL_SELF }); }

			this.getTracks()
			.then(() => {
				if (newPeerRoom.cancelled || this.nogo) { return this.props.setStateDisconnected({ reason: endConnectionReasons.CANCEL_SELF }); }

				this.WebRTCService.addTracks(this.state.localStream);
				this.props.setStatePreparing();
			})
		}

		//////////////////////////////////////////////////////
		// Request for user media failed or was denied
		//////////////////////////////////////////////////////
		if (oldState !== "mediaDenied" && newState === "mediaDenied") {
			this.nogo = true;
			this.SignalingService.sendCloseMessage();
			if (this.WebRTCService) {
				this.WebRTCService.close();
				this.WebRTCService = null;
			}
			this.setState({ redirectCameraFailure: true });
		}

		//////////////////////////////////////////////////////
		// When we enter preparing stage, set timer to close if peer isn't ready in 60 sec
		//////////////////////////////////////////////////////
		if (oldState !== "preparing" && newState === "preparing") {
			this.setNoReadyTimeout();
		}

		//////////////////////////////////////////////////////
		// User has clicked "start"
		//////////////////////////////////////////////////////
		if (oldState === "preparing" && newState === "ready") {
	  	this.SignalingService.sendReadyMessage();

	  	if (this.props.sessionState.peerReady) {
	  		this.sendOfferIfImCaller();
	  	}
		}

		//////////////////////////////////////////////////////
		// Received ready message
		//////////////////////////////////////////////////////
		if (!prevProps.sessionState.peerReady && this.props.sessionState.peerReady) {
			this.clearNoReadyTimeout();

	  	if (newState === "ready") {
	  		this.sendOfferIfImCaller();
	  	}
		}

		//////////////////////////////////////////////////////
		// Ice connection failed => set end reason and disconnect
		//////////////////////////////////////////////////////
		if (!prevProps.sessionState.peerConnectionFailed && this.props.sessionState.peerConnectionFailed) {
			// This "if" fixes bug where screen goes randomly to "connection failed"
			// Still need deeper, root cause fix
			if (["connected", "ready", "preparing", "gettingMedia"].includes(newState)) {
				this.props.setStateDisconnected({ reason: endConnectionReasons.ICE_FAILURE });
			}			
		}


		//////////////////////////////////////////////////////
		// User was on active connection or preparing / ready, and now has disconnected
		//////////////////////////////////////////////////////
		if (oldState !== "disconnected" && newState === "disconnected") {
			this.nogo = true;
			if (this.WebRTCService) {
				this.WebRTCService.close(this.state.localStream, this.state.remoteStream);
				this.WebRTCService = null;
			}
			this.clearNoReadyTimeout();
			// Need to put in newProps as parameter or these functions get called with this.props instead
			this.sendCloseMessageIfNeeded(this.props.endConnectionReason);
			this.sendSessionDataIfAppropriate(this.props.endConnectionReason);
		}

		if (oldState === "connected" && newState === "disconnected") {
			if (newPeerRoom.poolType === "myCircle") {
				this.props.setPartnerIsInMyCircle({
					alreadyInMyCircleAddedByMe: true,
        	alreadyInMyCircleMatched: true
				});
			} else {
				if (this.props.userRole.email !== "experience-guest") {
					this.props.checkUserIsInMyCircle(newPeerRoom.roomID, newPeerRoom.position)
				}		
			}

			if ((this.props.peerRoom.experienceType !== "oneminute") && ["natural","manual_self"].includes(this.props.endConnectionReason)) {
				this.props.setFreeExperienceUsed();
			}
    }

		if(newState === "digesting" && newPeerRoom.haveGivenThanks && (oldPeerRoom.haveGivenThanks !== newPeerRoom.haveGivenThanks)) {
			this.SignalingService.sendThankyouMessage();
		}

		if(newState === "digesting" &&
			!prevProps.myCircle.alreadyInMyCircleMatched && !this.props.myCircle.alreadyInMyCircleMatched &&
			this.props.myCircle.alreadyInMyCircleAddedByMe &&
			(!prevProps.myCircle.alreadyInMyCircleAddedByMe || !prevProps.myCircle.addedByOther && this.props.myCircle.addedByOther)
			) {
			this.SignalingService.sendAddedCircleMessage();
		}

	}


	componentWillUnmount() {
		this.closeMessageOnUnloadIfAppropriate();
		if (this.SignalingService) {
			this.SignalingService.closeChannel();
		}
		this.props.resetAllSessionState();
		if (this.props.userRole.email === "experience-guest") {
			this.props.logout()
		}		
	}

	setRemoteStream(stream) {
	  if (this.remoteStream !== stream) {

	  	this.setState({
	  		remoteStream: stream
	  	});

	  	console.log('received remote stream');
	  }
	}

	setPeerConnectionReady() {
	  if (!this.props.sessionState.peerConnectionReady) {
	  	this.props.setPeerConnectionReady();
	  }
	}

	setPeerConnectionFailed() {
	  if (!this.props.sessionState.peerConnectionFailed) {
	  	this.props.setPeerConnectionFailed();
	  }
	}

	closeMessageOnUnloadIfAppropriate() {
		if (this.props.peerRoom.roomID && (this.props.userState !== "disconnected")) {
			this.SignalingService.sendCloseMessage();
		}
	}

	disconnectSettingAppropriateReason(selfInitiated, byTimeout = false) {
		if (["disconnected", "digesting"].includes(this.props.userState)) { return; }

		let reason;

		if (byTimeout) {
			reason = selfInitiated ? endConnectionReasons.TIMEOUT_OTHER : endConnectionReasons.TIMEOUT_SELF;
		} else if (this.props.userState === "connected") {
			reason = selfInitiated ? endConnectionReasons.MANUAL_SELF : endConnectionReasons.MANUAL_OTHER;
		} else {
			reason = selfInitiated ? endConnectionReasons.CANCEL_SELF : endConnectionReasons.CANCEL_OTHER;
		}
  	this.props.setStateDisconnected({ reason });
	}


	sendCloseMessageIfNeeded(endConnectionReason) {
		if ([endConnectionReasons.CANCEL_SELF, endConnectionReasons.MANUAL_SELF].includes(endConnectionReason)) {
			this.SignalingService.sendCloseMessage();
		}
	}

	setNoReadyTimeout() {
		this.noReadyTimeout = setTimeout(() => {
			if (!this.props.sessionState.peerReady) {
				this.SignalingService.sendCloseMessage(true);
				this.disconnectSettingAppropriateReason(true, true);
			}
		}, constants.timeControls["peerNoReadyTimeout"]);
	}

	clearNoReadyTimeout() {
		if (this.noReadyTimeout) {
			clearTimeout(this.noReadyTimeout);
			this.noReadyTimeout = null;
		}
	}

	sendOfferIfImCaller() {
		if (this.props.peerRoom.position === 1) {
			// This is the only place where an actual offer-answer process starts
			this.WebRTCService.createOffer()
		}
	}

	subscribeToAblyEvents() {
		this.SignalingService.setThankyouListener(this.onThankyouMessage);
		this.SignalingService.setAddedCircleListener(this.onAddedCircleMessage);
		this.SignalingService.setReadyListener(this.onReadyMessage);
		this.SignalingService.setCloseListener(this.onCloseMessage);
	}

	onCloseMessage(byTimeout) {
		this.disconnectSettingAppropriateReason(false, byTimeout);
	}

	onReadyMessage() {
		this.props.setPeerReady();
	}

	onThankyouMessage() {
		this.props.setHaveReceivedThanks();
		this.props.sendGAEventForDigestingState('Human Minute', 'Thanked by partner', '', this.props.completedHumanMinutes);
		let { experienceType, groupSpace, noRepeatMatchmakerOption, poolType } = this.props.peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
		let {endConnectionReason} = this.props;
		sendAmplitudeEvent('receive thanks', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'end reason': formatEndReasonForUser(endConnectionReason)});		
	}

	onAddedCircleMessage() {
		this.props.setMyCircleAddedByOther();
	}

	fixFailedCancel() {
		// This happens when user cancelled but was already paired
		this.SignalingService.sendCloseMessage();
		this.props.clearPeerRoom();

	}	

	getTracks() {
	  return new Promise((resolve, reject) => {
			let audio = false
			if(this.props.selectedExperienceData && (this.props.selectedExperienceData.userAudioFromStart || (this.props.selectedExperienceData.userAudioChanges && this.props.selectedExperienceData.userAudioChanges.length))) {
				audio = true
			}
		  navigator.mediaDevices.getUserMedia({
		    audio,
				video: true
		  })
		  .then((stream) => {
				if(audio) {
					stream.getAudioTracks()[0].enabled = false;
					// stream.mute('audio')
				}
		  	this.setState({
		  		localStream: stream
		  	});
		  	return resolve();
		  })
		  .catch(this.onGetUserMediaError);
	  })

	}

	onGetUserMediaError(error) {
  	this.props.setCameraFailureReason(error.name);
  	this.props.setStateMediaDenied();
	  console.log('getUserMedia() error: ' + error.name);
	}

	sendSessionDataIfAppropriate(endConnectionReason) {
		let { peerRoom, sessionState } = this.props;
		let { roomID, position, experienceType, groupSpace, noRepeatMatchmakerOption, poolType } = peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
		let { startTime } = sessionState;
		let duration = startTime ? Math.floor((Date.now() - startTime)/1000) : 0;

		if (endConnectionReason === endConnectionReasons.MANUAL_SELF) {
			sendGAEvent('Human Minute', 'End connection', '', duration);
		}
		sendAmplitudeEvent('experience ended', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, duration, 'end reason': formatEndReasonForUser(endConnectionReason)});

		if (peerRoom.activeExperienceInvitation && position === 2) {
			return;
		}

		let otherEnded = [endConnectionReasons.MANUAL_OTHER, endConnectionReasons.ICE_FAILURE, endConnectionReasons.CANCEL_OTHER].includes(endConnectionReason);
		if (otherEnded || (endConnectionReason === endConnectionReasons.NATURAL && position === 1) || (peerRoom.activeExperienceInvitation && position === 1)) {
			let otherPosition = position === 1 ? "2" : "1"
			let endReason = endConnectionReason.replace('_other', otherPosition);
			endReason = endReason.replace('_self', "1");
			this.props.sendSessionData(position, roomID, duration, endReason, experienceType)
		}
	}

  render() {
    return (
    	<div>
    		<ExperienceTimerContainer
    			videoWrap={this.props.videoWrap}
    		/>
    		<VideoManagerContainer
    			localStream={this.state.localStream}
    			remoteStream={this.state.remoteStream}
    			smallVideo={this.props.smallVideo}
    			bigVideo={this.props.bigVideo}
    			videoWrap={this.props.videoWrap}
    		/>
				<AudioManagerContainer 
					smallVideo={this.props.smallVideo}
				/>
				<TextInstructionsManagerContainer />
    		{this.state.redirectCameraFailure ? <Redirect to="/camerafailure" /> : null}
    	</div>
    );
  }
}
