import React from 'react';
import { Button } from 'reactstrap';



const AuthModalButton = ({openAuthModal, text, ...rest}) => {

  return (
    <Button className="BtnAuthModal" color="default" onClick={openAuthModal} {...rest}>
      {text}
    </Button>
  )
}

export default AuthModalButton;