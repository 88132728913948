import * as Actions from "../actions/experienceModal";

const initialState = {
	isOpen: false,
  experience: {}
};

export function experienceModal(state = initialState, action) {
  switch (action.type) {
    case Actions.OPEN_EXPERIENCE_MODAL:
      return {
				isOpen: true,
			  experience: action.data
			};
		case Actions.CLOSE_EXPERIENCE_MODAL:
      return {
				isOpen: false,
			  experience: {}
			};
    default:
      return state;
  }
}