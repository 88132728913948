import React from "react";
import textResource from '../../lib/textLoader';
import { connect } from 'react-redux';
import DeleteAccount from './DeleteAccount';
import { deleteAccount } from "../../actions/accountChanges";
import {validateForm} from '../../lib/validationHelpers';
import serialize from 'form-serialize';

class DeleteAccountContainer extends React.Component {
	constructor() {
		super();

    this.state = {
      errors: {}
    }
		this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
	}

  componentDidUpdate(prevProps){
    if (!prevProps.accountChanges.error && this.props.accountChanges.error) {
      this.setState({
        errors: {
          fromServer: textResource["delete_account.messages.password_wrong"]
        },
      })      
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target
    const data = serialize(form, {hash: true})
    const errors = validateForm(data, "deleteAccount");

    if (errors) {
      this.setState({errors})
    } else {
      this.formSuccess(form, data)
    }
  }

  formSuccess(form, data) {
    // form.reset()
    this.props.deleteAccount(this.props.email, data.password);
    // this.setState({
    //   success: true,
    //   errors: {},
    // })
  }

  render() {
    return <DeleteAccount 
      onSubmit={this.onSubmit}
      userRole={this.props.userRole}
      {...this.state}
    />;
  }
}

// yyPilot1

const mapStateToProps = (state) => {
  return {
    email: state.userRole.email,
    accountChanges: state.accountChanges,
    userRole: state.userRole
  };
};

const mapDispatchtoProps = {
		deleteAccount
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(DeleteAccountContainer);