import React from "react";
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { loginUser } from "../../actions/userRole";
import { closeAuthModal, setAuthData } from "../../actions/uiControls";
import {validateForm} from '../../lib/validationHelpers';
import serialize from 'form-serialize';
import sendGAEvent from '../../lib/sendGAEvent';


class LoginFormContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      success: false
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
  }

  componentDidUpdate(prevProps){
    if (!prevProps.userRole.error && this.props.userRole.error) {
      this.setState({
        errors: {
          fromServer: "Email or password incorrect"
        },
      })      
    }
    if(!prevProps.userRole.fbPasswordExistError && this.props.userRole.fbPasswordExistError) {
      this.setState({
        errors: {
          facebookError: true
        },
      })
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target
    const data = serialize(form, {hash: true})
    Object.keys(data).forEach((key) => {
      data[key] = data[key].trim();
    })
    const errors = validateForm(data, "login");

    if (errors) {
      this.setState({errors})
      sendGAEvent(this.props.authEventsCategory, 'Sign in', gaEventLabel(errors));
    } else {
      this.formSuccess(form, data)
    }
  }

  formSuccess(form, data) {
    // form.reset()
    this.props.loginUser(data.email, data.password);
    // this.setState({
    //   success: true,
    //   errors: {},
    // })
  }

  render() {
    return <LoginForm onSubmit={this.onSubmit} closeAuthModal={this.props.closeAuthModal} userRole={this.props.userRole} authData={this.props.authData} setAuthData={this.props.setAuthData} {...this.state}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    authModalForm: state.uiControls.authModalForm,
    authData: state.uiControls.authData,
    authEventsCategory: state.uiControls.authEventsCategory
  };
};

const mapDispatchtoProps = {
	loginUser,
  closeAuthModal,
  setAuthData
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(LoginFormContainer);


function gaEventLabel(errors) {
 if (errors.email && errors.email[0] === "Email can't be blank") {
  return "Fail email blank";
 }

 if (errors.password && errors.password[0] === "Password can't be blank") {
  return "Fail password blank";
 }

 if (errors.email && errors.email[0] === "Email is not a valid email") {
  return "Fail email invalid";
 }

 if (errors.password && errors.password[0] === "Password is too short (minimum is 8 characters)") {
  return "Fail password too short";
 }

 return "Fail";
}


