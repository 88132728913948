// import moment from 'moment-timezone';

// let baseUrl = process.env.NODE_ENV === "production"
// ? "https://hm-app-server.herokuapp.com"
// : "http://localhost:3000"

let baseUrl = "http://localhost:3002";
let realtimeUrl = "http://localhost:3001";
let facebookId = "490754811615322";
let notificationSoundUrl = "https://human-online.s3.eu-west-2.amazonaws.com/ho-notification-sound.wav";
let stripePublishableKey = 'pk_test_pRh6T12XWGX0qA5uGCZy1e5c00MD4nBOFU';
let amplitudeKey = '874f45dce6a86a6163971c6bcca04f18'


const hostname = window.location.hostname;

if (hostname === "www.human.online") {
  baseUrl = "https://api-production.human.online";
  realtimeUrl = "https://realtime-production.human.online";
  stripePublishableKey = 'pk_live_iFRhvtq5LCB5vtX5wsoEWQPw00KBADaZmS';
  amplitudeKey = "00a4dca58fcf4bbad8b415470f0f523e"
} 

if (hostname === "client-techtest.human.online") {
  baseUrl = "https://api-techtest.human.online";
  realtimeUrl = "https://realtime-techtest.human.online";
} 

if (hostname === "client-staging.human.online") {
  baseUrl = "https://api-staging.human.online";
  realtimeUrl = "https://realtime-staging.human.online";
  amplitudeKey = "25b4318e9cf953007b054806f976aca8"
} 

if (hostname === "a.human.online") {
  baseUrl = "https://api-a.human.online";
  realtimeUrl = "https://realtime-a.human.online";
  amplitudeKey = "a46ab4ebd33d7f0fb56e08978082c88a";
}


const constants = {
  timeControls: {
    presenceTimeout: 300000,
    pairingTimeout: 60000,
    showControlsWhenStarting: 10000,
    showControlsOnMouseMove: 5000,
    peerNoAnswerTimeout: 5000,
    peerNoReadyTimeout: 60000
  },
  gatherings: {
    // The seek feedback time has to be slightly longer than the recently finished time
    seekFeedbackForLengthInTime: 610000,
    gatheringRecentlyFinishedTime: 600000,
    daily: {
      // to move daily time relative to current time for testing:
      // time: moment().add(12, 'hours').subtract(16, 'minutes').format('H:mm'),
      time: "20:00",
      lengthInTime: 900000,
      timezone: "Europe/Berlin"
    }
  },
  languages: [
    {
        language: 'English',
        code: 'en',
        title: 'English'
    },
    {
        language: 'French - Francais',
        code: 'fr',
        title: 'French'
    },
    {
        language: 'German - Deutsch',
        code: 'de',
        title: 'German'
    },
    {
        language: 'Italian - Italiano',
        code: 'it',
        title: 'Italian'
    },
    {
        language: 'Portuguese - Portugues',
        code: 'pt',
        title: 'Portuguese'
    },
    {
        language: 'Spanish - Espanol',
        code: 'es',
        title: 'Spanish'
    }
  ],
  notFollowingGuidelines: {
    'gestures': 'Many gestures',
    'writtensigns': 'Written signs',
    'notvisibleperson': 'No person was visible',
    'cancelledconnection': 'They cancelled the connection'
  },
  inappropriateBehaviour: {
    'joking': 'Joking or fooling around',
    'notfullyclothed': 'Not fully clothed',
    'sexual': 'Sexually explicit',
    'offensive': 'Offensive behaviour'
  },
  baseUrl,
  realtimeUrl,
  facebookId,
  notificationSoundUrl,
  stripePublishableKey,
  amplitudeKey
};

export default constants;
