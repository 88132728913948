import * as Actions from "../actions/cameraFailure";

const initialState = {
  reason: "",
  dataSent: false,
  isFetching: false,
  error: null
};

export function cameraFailure(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_CAMERA_FAILURE_REASON:
      return {
			  reason: action.data,
			  dataSent: false,
			  isFetching: false,
			  error: null
			};
    case Actions.SEND_FAILURE_DATA_START:
      return {
			  ...state,
			  dataSent: false,
			  isFetching: true,
			  error: null
			};
    case Actions.SEND_FAILURE_DATA_SUCCESS:
      return {
			  ...state,
			  dataSent: true,
			  isFetching: false,
			  error: null
      };
    case Actions.SEND_FAILURE_DATA_FAILURE:
      return {
			  ...state,
			  dataSent: false,
			  isFetching: false,
			  error: action.error
      };
    case Actions.CLEAR_FAILURE_DATA:
      return {
			  reason: "",
			  dataSent: false,
			  isFetching: false,
			  error: null
      };
    default:
      return state;
  }
}