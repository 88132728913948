import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './custom.scss';
import './index.css';
import AppContainer from './components/AppContainer';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import './polyfills/include.js';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import humanMinuteReducer from "./reducers";
import * as Sentry from '@sentry/browser';

const hostname = window.location.hostname;
if (hostname === "www.human.online") {
	Sentry.init({dsn: "https://f3f580f2b74647a5ae41dcad69db5fc5@o413269.ingest.sentry.io/5298086"});
}




const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  humanMinuteReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
	<Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
unregister();
