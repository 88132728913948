import { connect } from 'react-redux';
import TopNavbar from './TopNavbar';
import { openAuthModal, setAuthModalTypeLogin, setAuthEventsCategory } from "../../actions/uiControls";
import { clearSocialLoginButtons } from "../../actions/socialLogin";
import sendGAEvent from '../../lib/sendGAEvent';


const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    userState: state.userState
  };
};

const mapDispatchtoProps = (dispatch) => {
	return {
		openAuthModal: () => {
      dispatch(setAuthModalTypeLogin());
      dispatch(clearSocialLoginButtons());
			dispatch(openAuthModal());
      dispatch(setAuthEventsCategory('Authentication-btnSignin'));
      sendGAEvent('Authentication-btnSignin', 'Open', '');
		},
	}
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(TopNavbar);