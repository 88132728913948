import { combineReducers } from "redux";
import { userState } from './userState';
import { userRole } from './userRole';
import { presenceCount } from './presenceCount';
import { fullScreen } from './fullScreen';
import { endConnectionReason } from './endConnectionReason';
import { peerRoom } from './peerRoom';
import { iceServers } from './iceServers';
import { realtime } from './realtime';
import { uiControls } from './uiControls';
import { accountChanges } from './accountChanges';
import { sessionState } from './sessionState';
import { feedback } from './feedback';
import { fdTest } from './fdTest';
import { emailVerification } from './emailVerification';
import { cameraFailure } from './cameraFailure';
import { invitation } from './invitation';
import { gatheringsInfo } from './gatheringsInfo';
import { connectNotifications } from './connectNotifications';
import { inappropriateBehaviourReport } from './inappropriateBehaviourReport';
import { registeringFromInvitation } from './registeringFromInvitation';
import { paymentsState } from './paymentsState';
import { experiences } from './experiences';
import { facilitator } from './facilitator';
import { socialLogin } from './socialLogin';
import { selectLanguage } from './selectLanguage';
import { selectedExperienceData } from './selectedExperience';
import { subscriptionState } from './subscriptionState';
import { myCircle } from './myCircle';
import { experienceModal } from './experienceModal';
import { experiencesWithPeopleWaiting } from './experiencesWithPeopleWaiting';
import { experienceInvitation } from './experienceInvitation';


// yyPilot1

export default combineReducers({
  userState,
  userRole,
  presenceCount,
  fullScreen,
  endConnectionReason,
  peerRoom,
  iceServers,
  realtime,
  uiControls,
  accountChanges,
  sessionState,
  feedback,
  fdTest,
  emailVerification,
  cameraFailure,
  invitation,
  gatheringsInfo,
  connectNotifications,
  inappropriateBehaviourReport,
  registeringFromInvitation,
  paymentsState,
  experiences,
  facilitator,
  socialLogin,
  selectLanguage,
  selectedExperienceData,
  subscriptionState,
  myCircle,
  experienceModal,
  experiencesWithPeopleWaiting,
  experienceInvitation
});