import { connect } from 'react-redux';
import LobbyAlerts from './LobbyAlerts';
import { hideRegistrationConfirmation, hideMatchmakerTimeoutAlert } from "../../actions/uiControls";
import { clearChanges } from "../../actions/accountChanges";
import { clearPeerRoom } from "../../actions/peerRoom";
import { clearVerification } from "../../actions/emailVerification";
import { clearInvitation } from "../../actions/invitation";
import { clearUserReport } from "../../actions/inappropriateBehaviourReport";
import { clearSelectedExperienceData } from "../../actions/selectedExperience";


const mapStateToProps = (state, ownProps) => {
  return {
    registrationConfirmation: state.uiControls.registrationConfirmation,
    accountDeleted: state.accountChanges.accountDeleted,
    registeredToEmails: state.accountChanges.registeredToEmails,
    howSolicited: state.accountChanges.howSolicited,
    matchmakerError: state.peerRoom.error,
    matchmakerTimeout: state.uiControls.matchmakerTimeout,
    userState: state.userState,
    fdTest: state.fdTest,
    emailVerification: state.emailVerification,
    email: state.userRole.email,
    userRole: state.userRole.role,
    accountSuspended: state.userRole.accountSuspended,
    invitation: state.invitation,
    gatheringsInfo: state.gatheringsInfo,
    passwordRecoveryRequested: state.accountChanges.passwordRecoveryRequested,
    validateRecoveryHashReady: state.accountChanges.validateRecoveryHashReady,
    validateRecoveryHashSuccess: state.accountChanges.validateRecoveryHashSuccess,
    passwordResetSuccessful: state.accountChanges.passwordResetSuccessful,
    authModalOpen: state.uiControls.authModalOpen,
    inappropriateBehaviourReport: state.inappropriateBehaviourReport,
    currentGathering: ownProps.currentGathering,
    selectedExperienceData: state.selectedExperienceData
  };
};

const mapDispatchtoProps = {
	hideRegistrationConfirmation,
	hideMatchmakerTimeoutAlert,
	clearChanges,
	clearPeerRoom,
	clearVerification,
	clearInvitation,
    clearUserReport,
    clearSelectedExperienceData
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(LobbyAlerts);