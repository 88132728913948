import { connect } from 'react-redux';
import ConnectVideo from './ConnectVideo';


const mapStateToProps = (state) => {
    return {
        userRole: state.userRole
    };
};



export default connect(
    mapStateToProps,
    null
)(ConnectVideo);