import React from 'react';
import textResource from '../../lib/textLoader';
import './NoOneInCircleModalStyles.css';
import { Row, Col, Button, Modal } from 'reactstrap';
import CrossImg from '../../resources/images/cross-black.png'


class NoOneInCircleModal extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.props.noOneInCircleModalOpen) {
      this.props.closeNoOneInCircleModal();
    } else {
      this.props.openNoOneInCircleModal();
    }
  } 

  render() {
  	let { userState, userRole, noOneInCircleModalOpen } = this.props;


    return (
    <Modal isOpen={noOneInCircleModalOpen} toggle={this.toggle} contentClassName="my-modal-classname">
	    <Row>
	      <Col xs="12" >

	        <div className="topNoOneInCircleModalContainer">

	          <div className="closeModalX" onClick={this.toggle}>
	          	<img src={CrossImg}/>
	          </div>
	          <div className="noOneInCircleTitle">
	            <p>There is no one in your circle</p>
	          </div>
	          <div className="noOneInCircleText">
	            <p>Add partners to Your Circle by connecting through one of the experiences that are open to connect with "Anyone". At the end of each connection, you can choose to add that person to your circle.</p>
	            <p>Once you have other members added to Your Circle you can then connect with them using any of the Human Online experiences.</p>
	          </div>
	          <div className="buttonArea">
								<Button color="default" onClick={this.toggle}>
									Back
								</Button>            

	          </div>
	        </div>


	      </Col>
	    </Row>
    </Modal>
    );
  }
}

export default NoOneInCircleModal;