import * as Actions from "../actions/selectedExperience";


const initialState = {
  isFetching: false,
  error: null,
  data: {
    name: "Human Minute",
    description: "One minute in silence",
    type: "oneminute",
    introDuration: 4000,
    connectionDuration: 60000,
    afterConnectionDuration: 5000,
    introAudioStart: null,
    introAudioURL: null,
    mainAudioStart: null,
    mainAudioURL: null,
    afterConnectionAudioStart: null,
    afterConnectionAudioURL: null,
    userAudioFromStart: false,
    userAudioChanges: [],
    textInstructions: []    
  }
};

export function selectedExperienceData(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_SELECTED_EXPERIENCE_DATA_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case Actions.GET_SELECTED_EXPERIENCE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case Actions.GET_SELECTED_EXPERIENCE_DATA_SUCCESS:
      return {
        isFetching: false,
        error: null,        
        data: action.data
      };    
    case Actions.CLEAR_SELECTED_EXPERIENCE:
      return initialState; 
    default:
      return state;
  }
}