import * as Actions from "../actions/experiencesWithPeopleWaiting";

const initialState = [];

export function experiencesWithPeopleWaiting(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_EXPERIENCES_WITH_PEOPLE_WAITING:
      return action.data;
    default:
      return state;
  }
}