import React from "react";
import textResource from '../../lib/textLoader';
import { connect } from 'react-redux';
import Feedback from './Feedback';
import { sendFeedback, clearFeedback } from "../../actions/feedback";
import {validateForm} from '../../lib/validationHelpers';
import serialize from 'form-serialize';

class FeedbackContainer extends React.Component {
	constructor() {
		super();

    this.state = {
      errors: {},
      checked: false,
      form: null
    }
		this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
	}

  componentDidUpdate(prevProps){
    if (!prevProps.feedback.error && this.props.feedback.error) {
      this.setState({
        errors: {
          fromServer: textResource["feedback.messages.server_error"]
        },
      })      
    }
    if (!prevProps.feedback.sent && this.props.feedback.sent) {
      if (this.state.form) {
        this.state.form.reset();
        this.setState({
          form: null
        })
      }
    }   
  }

  componentWillUnmount() {
    if (this.props.feedback.sent) {
      this.props.clearFeedback();
    }
  }

  onChangeCheckBox(e) {
    this.setState({
      checked: !this.state.checked
    })
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target;
    this.setState({
      form
    })
    const data = serialize(form, {hash: true})
    // const errors = validateForm(data, "feedback");
    let errors = {};
    if (!data.content && !data.circleAnswer && !data.experiencesAnswer && !data.membershipAnswer) {
      this.setState({
        errors: {
          fromServer: "Please answer at least one field"
        }
      })
    } else {
      this.formSuccess(form, data)
    }

  }

  formSuccess(form, data) {
    let anon = data.anon || "";
    this.props.sendFeedback(data.content, anon, data.circleAnswer, data.experiencesAnswer, data.membershipAnswer);
    this.setState({
      errors: {},
    })
  }

  render() {
    return <Feedback 
      onSubmit={this.onSubmit}
      onChangeCheckBox={this.onChangeCheckBox}
      email={this.props.email}
      feedback={this.props.feedback}
      {...this.state}
    />;
  }
}

// yyPilot1

const mapStateToProps = (state) => {
  return {
    feedback: state.feedback,
    email: state.userRole.email
  };
};

const mapDispatchtoProps = {
	sendFeedback,
  clearFeedback
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(FeedbackContainer);