import React from "react";
import { connect } from 'react-redux';
import SocialLoginForm from './SocialLoginForm';
import { socialUserLogin } from "../../actions/userRole";
import {validateForm} from '../../lib/validationHelpers';
import serialize from 'form-serialize';
import sendGAEvent from '../../lib/sendGAEvent';
import { clearAlreadyRegisterWithEmail } from "../../actions/uiControls";

class SocialLoginFormContainer extends React.Component {
	constructor() {
		super();
    this.state = {
      errors: {},
      success: false
    }
		this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
  }

  componentDidUpdate(prevProps){
  if (!prevProps.userRole.error && this.props.userRole.error) {
      this.setState({
        errors: {
          fromServer: "Account could not be created"
        },
      })      
    }
  }

  componentWillUnmount() {
    this.props.clearAlreadyRegisterWithEmail()
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target
    const data = serialize(form, {hash: true});
    Object.keys(data).forEach((key) => {
      data[key] = data[key].trim();
    })
    let errors;
    if(this.props.alreadyRegisteredWithEmail) {
      errors = validateForm(data, "socialLoginAlreadyRegisterd")
    } else{
      errors = validateForm(data, "socialLogin");
    }
    if (errors) {
      this.setState({errors})
      sendGAEvent('Authentication', 'Create account', gaEventLabel(errors));
    } else {
      this.formSuccess(form, data)
    }
  }

  formSuccess(form, data) {
    let {userRole,invitationHash,socialLoginData} = this.props;
    this.props.socialUserLogin(data.email, socialLoginData.name, socialLoginData.id, userRole.referrerUrl, userRole.currentUrl,invitationHash, socialLoginData.accessToken, data.password);
  }

  render() {
    return <SocialLoginForm 
      onSubmit={this.onSubmit}
      socialLoginData={this.props.socialLoginData}
      userRole={this.props.userRole}
      alreadyRegisteredWithEmail={this.props.alreadyRegisteredWithEmail}
      {...this.state}
    />;
  }
}

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    invitationHash: state.registeringFromInvitation.invitationHash,
    socialLoginData: state.socialLogin.facebookData,
    alreadyRegisteredWithEmail: state.uiControls.alreadyRegisteredWithEmail
  };
};

const mapDispatchtoProps = {
    socialUserLogin,
    clearAlreadyRegisterWithEmail
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(SocialLoginFormContainer);


function gaEventLabel(errors) {
 if (errors.email && errors.email[0] === "Email can't be blank") {
  return "Fail email blank";
 }

 if (errors.email && errors.email[0] === "Email is not a valid email") {
  return "Fail email invalid";
 }

 if (errors.password && errors.password[0] === "Password can't be blank") {
  return "Fail password blank";
 }

 if (errors.password && errors.password[0] === "Password is too short (minimum is 8 characters)") {
  return "Fail password too short";
 }

 if (errors.name && errors.name[0] === "Name can't be blank") {
  return "Fail name blank";
 }

 if (errors.termsAndPrivacy && errors.termsAndPrivacy[0] === "Terms and privacy need to be accepted to create an account") {
  return "Fail agree terms";
 }

 return "Fail";
}