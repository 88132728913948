import { connect } from 'react-redux';
import DigestingContent from './DigestingContent';
import { setStatePresent } from "../../../actions/userState";
import { endFullScreen } from '../../../lib/fullscreenWrapper';
import { thanksUser, sendGAEventForDigestingState } from '../../../actions/peerRoom';
import { addUserToMyCircle, removeUserFromMyCircle, addToMyCircleCount } from '../../../actions/myCircle';
import { openAuthModal } from "../../../actions/uiControls";
import {
  logout
} from "../../../actions/userRole";


const mapStateToProps = (state, ownProps) => {
  return {
    inappropriateBehaviourReport: state.inappropriateBehaviourReport,
    videoWrap: ownProps.videoWrap,
    peerRoom: state.peerRoom,
    completedHumanMinutes: state.userRole.completedHumanMinutes,
    endConnectionReason: state.endConnectionReason,
    myCircle: state.myCircle,
    userRole: state.userRole
  };
};


const mapDispatchtoProps = (dispatch) => {
  return {
    finishExperience: (videoWrap) => {
      videoWrap.classList.remove("digesting-screen-full");
      endFullScreen(videoWrap)
        .then(() => {
          dispatch(setStatePresent());
        })
        .catch((err) => {console.log(err);})      
    },
    thankyou: (roomID,position) => {
      dispatch(thanksUser(roomID,position));
    },
    sendGAEventForDigestingState: (category, action, label, value) => {
      dispatch(sendGAEventForDigestingState(category, action, label, value));
    },
    addUserToMyCircle: (roomID,position) => {
      dispatch(addUserToMyCircle(roomID,position))
    },
    removeUserFromMyCircle: (roomID,position) => {
      dispatch(removeUserFromMyCircle(roomID,position))
    },
    addToMyCircleCount: () => {
      dispatch(addToMyCircleCount())
    },
    openAuthModal: () => {
      dispatch(openAuthModal())
    },
    logout: () => {
      dispatch(logout())
    }    
  };
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(DigestingContent);