import { connect } from 'react-redux';
import ReadyContent from './ReadyContent';


const mapStateToProps = (state) => {
  return {
    peerReady: state.sessionState.peerReady,
    userState: state.userState,
    selectedExperienceData: state.selectedExperienceData.data,
  };
};


export default connect(
  mapStateToProps,
  null
)(ReadyContent);