import constants from "../constants";

export const GET_SERVERS_START = "GET_SERVERS_START";
export const GET_SERVERS_FAILURE = "GET_SERVERS_FAILURE";
export const GET_SERVERS_SUCCESS = "GET_SERVERS_SUCCESS";


export function getServersStart() {
  return {
    type: GET_SERVERS_START
  };
}

export function getServersSuccess(data) {
  return {
    type: GET_SERVERS_SUCCESS,
    data
  };
}

export function getServersFailure(error) {
  return {
    type: GET_SERVERS_FAILURE,
    error
  };
}



export function getIceServers() {
  let config = {
    method: "GET",
    headers: {
      Authorization: `bearer ${localStorage.getItem("token")}`
    }
  };
  return dispatch => {
  	dispatch(getServersStart());
    fetch(`${constants.baseUrl}/api/v1/iceservers`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        dispatch(getServersSuccess(response));
      })
      .catch(err => {
        dispatch(getServersFailure(err));
        console.log("iceServers error: ", err);
      });
  };
}