import React from 'react'
import {Alert} from 'reactstrap'
import { Link } from 'react-router-dom';
import {isEmpty} from '../../lib/validationHelpers'


const FacebookErrorMessage = ({errors,closeAuthModal}) => {
  if (!errors || isEmpty(errors)) {
    return null
  }

  if (errors.facebookError) {
  	return (
	    <Alert color="danger">
	      You created your account via Facebook. To login via email you will need to set a password.<Link to="/requestrecoverpassword" style={{textDecoration: "underline", fontSize: '1rem'}} onClick={closeAuthModal}>Click here to continue</Link>
	    </Alert>
  	)
  }

  return (
    <Alert color="danger">
      Please correct the fields in red.
    </Alert>
  )
}


FacebookErrorMessage.defaultProps = {
  errors: {},
}

export default FacebookErrorMessage