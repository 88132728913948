import React from "react";
import favicon from "../../resources/images/favicon.png";
import constants from "../../constants";
import {sendAmplitudeEvent} from "../../lib/amplitude"

class ConnectNotifications extends React.Component {

  constructor() {
    super();

    this.showNotificationIfAppropriate = this.showNotificationIfAppropriate.bind(this);

    this.getMyCurrentGroupID = this.getMyCurrentGroupID.bind(this);
    this.audio = new Audio(constants.notificationSoundUrl)
  }


  componentDidUpdate(prevProps){
 
    if (this.props.connectNotifications.requestedNotifications.length !== prevProps.connectNotifications.requestedNotifications.length) {
      localStorage.setItem('requestedNotifications', this.props.connectNotifications.requestedNotifications.join());
    }

    if (this.props.connectNotifications.requestedNotifications.length) {
      this.props.connectNotifications.requestedNotifications.forEach((experienceType) => {
        if (this.props.presenceCount[`${experienceType}:anyone`] && this.props.presenceCount[`${experienceType}:anyone`] > prevProps.presenceCount[`${experienceType}:anyone`]) {
          this.showNotificationIfAppropriate(experienceType, "anyone");
        }
        if (this.props.presenceCount[`${experienceType}:myCircle`] && this.props.presenceCount[`${experienceType}:myCircle`] > prevProps.presenceCount[`${experienceType}:myCircle`]) {
          this.showNotificationIfAppropriate(experienceType, "myCircle");
        }
      })
    }



    if ((prevProps.userRole.role !== "guest") && (this.props.userRole.role === "guest")) {
      // If I log out, notifications should be turned off
      this.props.clearAllRequestedNotifications();
    }
  }

  getMyCurrentGroupID() {
    let params = this.props.history.location.pathname.split('/')
    if (params.length >= 2 && params[1] === "groupspace") {
      return params[2]
    }
    return "";
  }

  showNotificationIfAppropriate(experienceType, poolType) {
    if (!["present", "absent"].includes(this.props.userState) || this.props.currentGatheringId) {
      return;
    }
    if ((Date.now() - this.props.connectNotifications.lastNotificationShown) > 60000) {
      showNotification(this.audio, experienceType, poolType);
      this.props.setLastNotificationShown({
        time: Date.now(),
        type: experienceType
      });
      sendAmplitudeEvent('notification shown', {'experience type': experienceType});
    }
  }


  render() {
    return null;
  }
}

function showNotification(audio, experienceType, poolType) {
  var promise = audio.play();

  if (promise !== undefined) {
      promise.then(() => {
        console.log('sound completed')

      }).catch((err) => {
        console.log('notification sound error',err)
      });
  }
  let circleText = "";
  if (poolType === "myCircle") {
    circleText = "from your circle ";
  }
  var leadText = `Someone ${circleText}is ready for ${experienceType}`;
  var body = "Click here to switch to Human Online.";
  var notification = new Notification(leadText, {
    body,
    icon: favicon,
    silent: true
  });
  notification.onclick = function (event) {
    event.preventDefault(); // prevent the browser from focusing the Notification's tab
    window.focus();
  };
}

export default ConnectNotifications;
