import { connect } from 'react-redux';
import AuthModalButton from './AuthModalButton';
import { openAuthModal } from "../../actions/uiControls";
import sendGAEvent from '../../lib/sendGAEvent';


const mapStateToProps = (state, ownProps) => {
  return {
  	text: ownProps.text
  };
};

const mapDispatchtoProps = (dispatch) => {
	return {
		openAuthModal: () => {
			dispatch(openAuthModal());
			sendGAEvent('Authentication', 'Open', 'Create account');
		},
	}
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(AuthModalButton);