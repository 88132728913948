import * as Actions from "../actions/sessionState";
import * as UserStateActions from "../actions/userState";

const initialState = {
  startTime: 0,
  peerReady: false,
  peerConnectionReady: false,
  peerConnectionFailed: false,
  sessionDataSent: false
};

export function sessionState(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_PEER_READY:
      return {
        ...state,
      	peerReady: true,
			};
    case Actions.SET_PEER_CONNECTION_READY:
      return {
        ...state,
      	peerConnectionReady: true,
      };
    case Actions.SET_PEER_CONNECTION_FAILED:
      return {
        ...state,
        peerConnectionFailed: true,
      };
    case Actions.SET_SESSION_DATA_SENT:
      return {
        ...state,
        sessionDataSent: true,
      };
    case UserStateActions.SET_STATE_CONNECTED:
      return {
        ...state,
        startTime: Date.now(),
      };
    case Actions.RESET_ALL_SESSION_STATE:
      return {
        startTime: 0,
        peerReady: false,
        peerConnectionReady: false,
        peerConnectionFailed: false,
        sessionDataSent: false
      };
    default:
      return state;
  }
}