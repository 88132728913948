import React from 'react';
import textResource from '../../../lib/textLoader';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ConnectWithinCircleItemContainer from './ConnectWithinCircleItemContainer';
import {sendAmplitudeEvent} from "../../../lib/amplitude"


const ConnectWithDropdown = ({userRole, experienceType, setExperienceType, createExperienceInvitation, setPoolType}) => {

	let text = "Connect with:";
	let {subscription} = userRole;

	if (experienceType !== "oneminute" && !subscription.activeSubscription && !subscription.freeExperienceUsed) {
		text = "Try one experience free with:";
	}

  return (
    <div>
      <UncontrolledDropdown>
        <DropdownToggle color="default" caret>
          {text}&nbsp;&nbsp;&nbsp;
        </DropdownToggle>
        <DropdownMenu right style={{width: "100%"}}>
          <ConnectWithinCircleItemContainer experienceType={experienceType} poolType="myCircle"/>
          <DropdownItem 
            onClick={() => {
              setExperienceType(experienceType);
              setPoolType("personalInvite");
              createExperienceInvitation(experienceType);
              sendAmplitudeEvent('create experience invite', {'experience type': experienceType});
            }
          }>
            Send a personal invite
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

export default ConnectWithDropdown;