import React from 'react';
import { startFullScreen } from '../../../lib/fullscreenWrapper';
import { startVideo, startSelfVideo, stopVideo } from '../../../lib/webrtc/helpers';

export default class VideoManager extends React.Component {

	componentDidUpdate(prevProps){
		let oldState = prevProps.userState;
		let newState = this.props.userState;

		//////////////////////////////////////////////////////
		// User was on active connection, has now ended
		//////////////////////////////////////////////////////
		if (oldState !== "disconnected" && newState === "disconnected") {
			stopVideo(this.props.bigVideo);
			stopVideo(this.props.smallVideo);
		}

		//////////////////////////////////////////////////////
		// Connection starting
		//////////////////////////////////////////////////////
		if (oldState !== "connected" && newState === "connected") {
			startVideo(this.props.bigVideo, this.props.remoteStream);
		}

		//////////////////////////////////////////////////////
		// User has clicked "start"
		//////////////////////////////////////////////////////
		if (oldState === "preparing" && newState === "ready") {
			stopVideo(this.props.bigVideo);
			startSelfVideo(this.props.smallVideo, this.props.localStream);

			if (prevProps.fullScreen) {
				startFullScreen(this.props.videoWrap)
	    }
		}

		//////////////////////////////////////////////////////
		// When media is ready, play it on big video
		//////////////////////////////////////////////////////
		if (oldState === "gettingMedia" && newState === "preparing") {
				
			startSelfVideo(this.props.bigVideo, this.props.localStream);
		}	
	}

  render() {
    return null;
  }
}