import React from 'react';
import './HumanMinuteAreaStyles.css';
import CancelButtonContainer from '../../ConnectionControls/CancelButtonContainer';
import NotificationsSwitchContainer from './NotificationsSwitchContainer';
import NoRepeatSwitchContainer from './NoRepeatSwitchContainer';
import Icon2Img from '../../../resources/images/globeIcon.svg'
import Icon1Img from '../../../resources/images/hand-holding-heart-solid.svg'
import { Col, Row } from 'reactstrap';
import SocialLoginContainer from '../../Authentication/SocialLoginContainer';
import textResource from '../../../lib/textLoader';
import PresentStateContainer from './PresentStateContainer';
import WaitingStateContainer from './WaitingStateContainer';
import GuidelinesContainer from '../../ActiveExperience/GuidanceAndEnteringRoom/GuidelinesContainer';
import SelectLanguageContainer from '../../ActiveExperience/GuidanceAndEnteringRoom/SelectLanguageContainer';

class HumanMinuteArea extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      videoWrapHeight: "380px"
    }

    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize() {
    if (this.videoWrap) {
      this.setState({ videoWrapHeight: `${this.videoWrap.offsetWidth / 1.79}px` })
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

    }
  }

  renderVideoWrapClassName() {
    let { userState, experienceType, socialLogin } = this.props;
    let stateForStyles = userState;
    if (userState === "waiting" && experienceType !== "oneminute") {
      stateForStyles = "present";
    }
    let hmWrapSocial = "";
    if (socialLogin.socialLoginButtons) {
      hmWrapSocial = "hm-area-social";
    }
    return `hm-area-wrap ${stateForStyles} ${hmWrapSocial}`
  }

  renderVideoWrapStyle() {
    if(this.props.userState !== "firstTimeGuidance") {
      return { height: this.state.videoWrapHeight }
    }
    return {minHeight: this.state.videoWrapHeight}
  }

  renderVideoAreaStyle() {
    if(this.props.languageDropDown) {
      return {overflow: 'visible'}
    }
    return {}
  }

  shareOneMinuteClassname(fromInvite) {
    if (!fromInvite) {
      return "share-one-minute pl-1 pr-1 pb-5"
    } else {
      return "share-one-minute share-one-minute--from-invite pl-2 pr-2 pb-5"
    }
  }

  exploreHMClassname(fromInvite) {
    return fromInvite ? "exploreHM exploreHM--from-invite" : "exploreHM";
  }

  render() {
    let { userState, userRole, socialLogin, experienceType, fromInvite } = this.props;
    let stateForStyles = userState;
    if (userState === "waiting" && experienceType !== "oneminute") {
      stateForStyles = "present";
    }

    return (
      <div className="humanMinuteAreaContainer" style={userRole.email ? {background: 'none', padding: 0} : {background: "white", color: "#212121"}}>
          {userRole.ready && 
            <div>
            {
              !!(userRole.email && !fromInvite) ?
              <h2 className="hm-title" style={{paddingBottom: "3rem"}}>Human Minute</h2> :
              <h2 className={this.shareOneMinuteClassname(fromInvite)}>{textResource["video_area.share_one_minute_silence"]}</h2>
            }
          </div>}
          <div style={this.renderVideoAreaStyle()}>
            <div className={this.renderVideoWrapClassName()} id="hm-area-wrap" style={this.renderVideoWrapStyle()} ref={element => (this.videoWrap = element)} >

              {(stateForStyles === 'firstTimeGuidance') &&
              <div className="selectLanguageContainer">
                <SelectLanguageContainer />
              </div>}

              {(stateForStyles === 'firstTimeGuidance') &&
              <div className="guidanceContainer" style={{ height: this.state.videoWrapHeight }}>
                <GuidelinesContainer />
              </div>}

              <div className="video-background" style={{ height: this.state.videoWrapHeight }}>
                <div className="my-video-image"></div>
                <div className="video-background-image"></div>
              </div>

              <div className='main-video'></div>

              {socialLogin.socialLoginButtons &&
                <SocialLoginContainer />
              }

              {["present", "absent"].includes(stateForStyles) && !socialLogin.socialLoginButtons &&
                <PresentStateContainer />
              }

              {(stateForStyles === 'waiting') &&
                <WaitingStateContainer />
              }

              {(stateForStyles === 'waiting') &&
                <div 
                  id="CancelButton"
                  style={{zIndex: 2}}
                >
                  <CancelButtonContainer />
                </div>
              }

            </div>
            
          </div>
          {
            !!(userRole.email && !fromInvite) &&
            <div className="pt-3 notifications-checkbox">
              <NotificationsSwitchContainer experienceType="oneminute"/>
              <NoRepeatSwitchContainer experienceType="oneminute"/>
            </div>
          }
          {userRole.ready && (!userRole.email || fromInvite) && <div className="videoVisitors">
              <Row>
                <Col sm={6}>
                  <div className={this.exploreHMClassname(fromInvite)}>
                    <img src={Icon1Img} />
                    <h5>{textResource["video_area.relate_to_another.title"]}</h5>
                    <p>{textResource["video_area.relate_to_another.content"]}</p>
                  </div>
                </Col>
                <Col sm={6}>
                <div className={this.exploreHMClassname(fromInvite)}>
                  <img src={Icon2Img} />
                  <h5>{textResource["video_area.beyond_barriers.title"]}</h5>
                  <p>{textResource["video_area.beyond_barriers.content"]}</p>
                  </div>
                </Col>
              </Row>
          </div>}
      </div>
    )
  }
}

export default HumanMinuteArea;
