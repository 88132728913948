import constants from '../constants';
import { authSuccess } from './userRole';

export const RETRIEVE_EXPERIENCE_INVITATION_START = "RETRIEVE_EXPERIENCE_INVITATION_START";
export const RETRIEVE_EXPERIENCE_INVITATION_FAILURE = "RETRIEVE_EXPERIENCE_INVITATION_FAILURE";
export const RETRIEVE_EXPERIENCE_INVITATION_SUCCESS = "RETRIEVE_EXPERIENCE_INVITATION_SUCCESS";
export const CLICKED_CONNECT_EXPERIENCE_INVITE = "CLICKED_CONNECT_EXPERIENCE_INVITE";
export const CLICKED_CANCEL_EXPERIENCE_INVITE = "CLICKED_CANCEL_EXPERIENCE_INVITE";


export function retrieveExperienceInvitationStart(data) {
  return {
    type: RETRIEVE_EXPERIENCE_INVITATION_START,
    data
  };
}

export function retrieveExperienceInvitationSuccess(data) {
  return {
    type: RETRIEVE_EXPERIENCE_INVITATION_SUCCESS,
    data
  };
}

export function retrieveExperienceInvitationFailure(error) {
  return {
    type: RETRIEVE_EXPERIENCE_INVITATION_FAILURE,
    error
  };
}

export function clickConnectExperienceInvite() {
  return {
    type: CLICKED_CONNECT_EXPERIENCE_INVITE
  };
}

export function clickCancelExperienceInvite() {
  return {
    type: CLICKED_CANCEL_EXPERIENCE_INVITE
  };
}


export function retrieveExperienceInvitation(roomID) {
  const config = {
    method: "POST",
    headers: {
      "Authorization": `bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `roomID=${roomID}`
  };

  return (dispatch) => {
    dispatch(retrieveExperienceInvitationStart(roomID))
    return fetch(`${constants.baseUrl}/api/v1/experiences/retrieve-invitation`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        if (response.user && response.user.token) {
          localStorage.setItem("token", response.user.token);
          dispatch(authSuccess(response.user));
        }
        dispatch(retrieveExperienceInvitationSuccess(response))
      })
      .catch(err => {
        console.error("Retrieve experience invitation error: ", err);
        dispatch(retrieveExperienceInvitationFailure(`${err}`))
      });
  }
}

export function storeSessionFromInvitation(roomID) {
  const config = {
    method: "POST",
    headers: {
      "Authorization": `bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `roomID=${roomID}`
  };

  return (dispatch) => {
    return fetch(`${constants.baseUrl}/api/v1/experiences/store-invitation-session`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        console.log("session stored successfully");
      })
      .catch(err => {
        console.error("Store session from invitation error: ", err);
      });
  }
}


export function closeExperienceInvitation(roomID) {
  const config = {
    method: "POST",
    headers: {
      "Authorization": `bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `roomID=${roomID}`
  };

  return (dispatch) => {
    return fetch(`${constants.baseUrl}/api/v1/experiences/cancel-invitation`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
      })
      .catch(err => {
        console.error("getExperiences error: ", err);
      });
  }
}
