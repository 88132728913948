import { connect } from 'react-redux';
import NewsBanner from './NewsBanner';


const mapStateToProps = (state) => {
  return {
    userState: state.userState
  };
};


export default connect(
  mapStateToProps,
  null
)(NewsBanner);