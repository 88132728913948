import * as Actions from "../actions/inappropriateBehaviourReport";

const initialState = {
  sent: false,
  isFetching: false,
  error: null
};

export function inappropriateBehaviourReport(state = initialState, action) {
  switch (action.type) {
    case Actions.USER_REPORT_START:
      return {
        sent: false,
			  isFetching: true,
			  error: null
			};
    case Actions.USER_REPORT_SUCCESS:
      return {
        sent: true,
  			isFetching: false,
        error: null
      };
    case Actions.USER_REPORT_FAILURE:
      return {
        sent: false,
  			isFetching: false,
        error: action.error
      };
    case Actions.CLEAR_USER_REPORT:
      return {
			  sent: false,
			  isFetching: false,
			  error: null
      };
    default:
      return state;
  }
}