import { connect } from 'react-redux';
import AuthModal from './AuthModal';
import { 
	openAuthModal, 
	closeAuthModal,
	setAuthModalTypeLogin,
  setAuthModalTypeRegister,
  clearAuthModalForm,
  clearLoginWithEmail,
  clearAuthData
} from "../../actions/uiControls";
import { clearFacebookError } from "../../actions/socialLogin"


const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    userState: state.userState,
    authModalOpen: state.uiControls.authModalOpen,
    authModalType: state.uiControls.authModalType,
    emailFromLink: state.uiControls.emailFromLink,
    authModalForm: state.uiControls.authModalForm,
    loginWithEmail: state.uiControls.loginWithEmail,
    socialLogin: state.socialLogin,
    authEventsCategory: state.uiControls.authEventsCategory
  };
};

const mapDispatchtoProps = {
	openAuthModal,
	closeAuthModal,
	setAuthModalTypeLogin,
  setAuthModalTypeRegister,
  clearAuthModalForm,
  clearLoginWithEmail,
  clearAuthData,
  clearFacebookError
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(AuthModal);