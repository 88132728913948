import { connect } from 'react-redux';
import NotificationsSwitch from './NotificationsSwitch';
import sendGAEvent from '../../../lib/sendGAEvent';

import { 
  setRequestedNotifications,
  clearRequestedNotifications
} from "../../../actions/connectNotifications";
import { setUserProperties } from '../../../lib/amplitude';


const mapStateToProps = (state, ownProps) => {
  return {
    userState: state.userState,
    userRole: state.userRole,
    experienceType: ownProps.experienceType,
    isRequested: state.connectNotifications.requestedNotifications.includes(ownProps.experienceType)
  };
};

const mapDispatchtoProps = (dispatch) => {
	return {
    setRequestedNotifications: (experienceType) => {
      checkOrRequestNotificationsPermission()
      .then(() => {
        dispatch(setRequestedNotifications(experienceType));
        // setUserProperties({"notifications on": true})
      })
      .catch((reason) => {
        if (reason === "browser") {
          alert("This browser does not support system notifications");
        } else if (reason === "permission") {
          alert("You need to allow notifications to use this feature");
        }
      })
      
    },
    clearRequestedNotifications: (experienceType) => {
      dispatch(clearRequestedNotifications(experienceType));
      // setUserProperties({"notifications on": false})
    },
	};
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(NotificationsSwitch);



function checkOrRequestNotificationsPermission() {
  return new Promise((resolve, reject) => {
    if (!("Notification" in window)) {
      return reject("browser");
    }

    if (Notification.permission === "granted") {
      return resolve();
    }

    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        resolve();
      } else {
        reject("permission");
      }
    })
  })
}