import { connect } from 'react-redux';
import AudioManager from './AudioManager';
import { 
	setUserAudio, 
	clearUserAudio,
	showUserAudioMessage,
	clearUserAudioMessage 
} from "../../../actions/uiControls";

const mapStateToProps = (state,ownProps) => {
  return {
    userState: state.userState,
    peerRoom: state.peerRoom,
    peerReady: state.sessionState.peerReady,
    smallVideo: ownProps.smallVideo,
    selectedExperienceData: state.selectedExperienceData.data,
    userAudio: state.uiControls.userAudio,
    endConnectionReason: state.endConnectionReason
  };
};

const mapDispatchtoProps = {
	setUserAudio,
	clearUserAudio,
	showUserAudioMessage,
	clearUserAudioMessage
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(AudioManager);