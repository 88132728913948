import { connect } from 'react-redux';
import ExperiencesHorizontalScroll from './ExperiencesHorizontalScroll';


const mapStateToProps = (state,ownProps) => {
    return {
        experienceCategory: ownProps.experienceCategory
    };
};


export default connect(
    mapStateToProps,
    null
)(ExperiencesHorizontalScroll);