import React from 'react';
import './ConnectVideoStyles.css';
import ConnectVideoImg from '../../../resources/images/image-36.png';
import PlayIcon from '../../../resources/images/Shape.png';
import VimeoPlayer from '@vimeo/player';
import sendGAEvent from '../../../lib/sendGAEvent';

class VideoHowTo extends React.Component{
	constructor() {
		super()
		this.state = {
			videoPlay: false
		}
    }
    onStartVideo = () => {
		this.setState({ videoPlay: true })
		setTimeout(() => {
			const videoIframe = this.refs.videoStage.querySelector('iframe')
			if (videoIframe) { 
				const player = new VimeoPlayer(videoIframe);
				player.ready().then(() => {
                    player.on('play', data => this.onVideoEvent(data,'play'));
                    player.on('pause', data => this.onVideoEvent(data,'pause'));
					player.on('ended', data => this.onVideoEvent(data,'ended'));
				});
			}
		}, 500);
    }
    onVideoEvent = (data,event) => {
		let action = "Play VideoHowTo";
		if(event === 'ended') {
            action = "Finished VideoHowTo"
            this.setState({ videoPlay: false })
        }
        if(event === 'pause') {
			action = "Cancel VideoHowTo"
		}
		sendGAEvent('Human Minute', action, this.props.label, data.seconds)
	}
	render() {
		return (
            <div className="mt-4 mb-1 connectVideo" ref="videoStage">
                {!this.state.videoPlay ?
                <div>
                    <img className="connectImage" src={ConnectVideoImg}/>
                    <img className="playIcon" src={PlayIcon} onClick={this.onStartVideo}
                    	style={{cursor: "pointer"}}
                    />
                </div>:
                <iframe src="https://player.vimeo.com/video/411370196?autoplay=1" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>}
            </div>
		)
	}
}

export default VideoHowTo;