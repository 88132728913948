import * as Actions from "../actions/paymentActions";


const initialState = {
  paymentType: "",
  amount: 0,
	client_secret: "",
	paymentId: "",
  paymentSucceded: false,
  isFetchingFromServer: false,
  errorFromServer: null,
  isFetchingFromStripe: false,
	errorFromStripe: null,
	countriesList: [],
	paymentRecordId: ""
};

export function paymentsState(state = initialState, action) {
  switch (action.type) {

    case Actions.GET_PAYMENT_INTENT_START:
      return {
			  paymentType: action.data.paymentType,
			  amount: action.data.amount,
				client_secret: "",
				paymentId: "",
			  paymentSucceded: false,
			  isFetchingFromServer: true,
			  errorFromServer: null,
			  isFetchingFromStripe: false,
			  errorFromStripe: null
      };
    case Actions.GET_PAYMENT_INTENT_SUCCESS:
      return {
			  ...state,
				client_secret: action.data.client_secret,
				paymentId: action.data.paymentId,
			  isFetchingFromServer: false,
			  errorFromServer: null,
			  isFetchingFromStripe: false,
			  errorFromStripe: null
      };
    case Actions.GET_PAYMENT_INTENT_FAILURE:
      return {
			  ...state,
			  isFetchingFromServer: false,
			  errorFromServer: action.error,
			  isFetchingFromStripe: false,
			  errorFromStripe: null
      };

    case Actions.EXECUTE_PAYMENT_START:
      return {
			  ...state,
			  isFetchingFromServer: false,
			  errorFromServer: null,
			  isFetchingFromStripe: true,
			  errorFromStripe: null
      };
    case Actions.EXECUTE_PAYMENT_SUCCESS:
      return {
			  ...state,
			  paymentSucceded: true,
			  isFetchingFromServer: false,
			  errorFromServer: null,
			  isFetchingFromStripe: false,
				errorFromStripe: null,
				client_secret: "",
				paymentId: "",
				amount: 0,
      };
		case Actions.EXECUTE_PAYMENT_FAILURE:
      return {
			  ...state,
			  isFetchingFromServer: false,
			  errorFromServer: null,
			  isFetchingFromStripe: false,
			  errorFromStripe: action.error
			};
		case Actions.CLEAR_PAYMENT_CLIENT_SECRET:
      return {
			  ...state,
			  client_secret: ""
			};
		case Actions.GET_ALL_COUNTRIES_LIST:
      return {
			  ...state,
			  countriesList: action.data
			};
		case Actions.SET_PAYMENT_RECORD_ID:
      return {
			  ...state,
			  paymentRecordId: action.id
			};

    default:
      return state;
  }
}
