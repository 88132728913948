import React from 'react';
import '../ActiveExperienceStyles.css';
import textResource from '../../../lib/textLoader';
import IconCamera from '../../../resources/images/icnCamera.png'


class GettingMediaContent extends React.Component {

	constructor() {
		super();

		this.showTimeout = null;

		this.clearShowTimeout = this.clearShowTimeout.bind(this);
		this.setShowTimeout = this.setShowTimeout.bind(this);


		this.state = {
			show: false
		}
	}

	componentDidMount() {
		this.setShowTimeout();
	}

	componentWillUnmount() {
		this.clearShowTimeout();
	}

	setShowTimeout() {
		this.showTimeout = setTimeout(() => {
			this.setState({
				show: true
			});			
		} , 1000);
	}

	clearShowTimeout() {
		if (this.showTimeout) {
			clearTimeout(this.showTimeout);
		}		
	}


	render() {
		return (
			<div>
				{this.state.show ? 
					<div>
						<p className="heading-text">{textResource["getting_media.someone_waiting"]}</p>
						<div className="permissionContainer">
							<img src={IconCamera} />
							<div className="permission-text">
								<p>{textResource["getting_media.click"]} <b>{textResource["getting_media.allow"]}</b> {textResource["getting_media.pop_up"]}</p>
								<p>{textResource["getting_media.camera_access"]}</p>
							</div>
						</div>
					</div>
				: null}
			</div>
		)		
	}
}

export default GettingMediaContent;