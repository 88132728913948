import * as Actions from "../actions/userState";

const initialState = "present";

export function userState(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_STATE_PRESENT:
      return "present";
    case Actions.SET_STATE_ABSENT:
      return "absent";
    case Actions.SET_STATE_WAITING:
      return "waiting";
    case Actions.SET_STATE_FIRST_TIME_GUIDANCE:
      return "firstTimeGuidance";
    case Actions.SET_STATE_ENTERING_ROOM:
      return "enteringRoom";
    case Actions.SET_STATE_GETTING_MEDIA:
      return "gettingMedia";
    case Actions.SET_STATE_MEDIA_DENIED:
      return "mediaDenied"; 
    case Actions.SET_STATE_PREPARING:
      return "preparing";
    case Actions.SET_STATE_READY:
      return "ready";
    case Actions.SET_STATE_CONNECTED:
      return "connected";
    case Actions.SET_STATE_DISCONNECTED:
      return "disconnected";
    case Actions.SET_STATE_DIGESTING:
      return "digesting";
    default:
      return state;
  }
}