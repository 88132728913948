import screenfull from 'screenfull';

export const startFullScreen = (videoTag) => {

	return new Promise((resolve, reject) => {
		if (screenfull.enabled) {
	    screenfull.request(videoTag)
		  	.then(() => {
		  		resolve()
		  	})
		    .catch((err) => {
		    	console.log(err);
		    })
	  } else {
		  	resolve();
		  }
		})
}

export const endFullScreen = (videoTag) => {

	return new Promise((resolve, reject) => {
	  if (screenfull.isFullscreen) {
	  	screenfull.exit()
		  	.then(() => {
		  		resolve()
		  	})
		  	.catch((err) => {
		  		reject(err);
		  	})					
	  } else {
	  	resolve();
	  }
	})
	
}