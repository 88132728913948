import constants from "../constants";
export const CLEAR_ENTERING_ROOM_STATE = "CLEAR_ENTERING_ROOM_STATE";

export function sendSurveyRequest(experiment, response) {

    let config = {
      method: "POST",
      headers: { 
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `bearer ${localStorage.getItem("token")}`
    	},
      body: `experiment=${experiment}&response=${response}`
    };
    return async (dispatch) => {
      fetch(`${constants.baseUrl}/api/v1/surveyanswer`, config)
        .then(res => {
          return res.json();
        })
        .then(response => {
          if (response.error) {
            throw new Error(`Error: ${response.error}`);
          }
        })
        .catch(err => {
          console.log(err);
        });
    };
  }