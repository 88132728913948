import { connect } from 'react-redux';
import TargetGroupValuePropositions from './TargetGroupValuePropositions';
import { openAuthModal, setAuthEventsCategory } from '../../../actions/uiControls';
import { clearSocialLoginButtons } from '../../../actions/socialLogin';

const mapStateToProps = (state, ownProps) => {
    return {
        userRole: state.userRole
    };
};

const mapDispatchtoProps = {
    openAuthModal,
    clearSocialLoginButtons,
    setAuthEventsCategory
};

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(TargetGroupValuePropositions);