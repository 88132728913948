import { connect } from 'react-redux';
import NextSpecialGathering from './NextSpecialGathering';
import constants from '../../../constants';


const mapStateToProps = (state, ownProps) => {

  return {
    userRole: state.userRole,
    nextRegularGathering: ownProps.nextRegularGathering,
    nextSpecialGathering: ownProps.nextSpecialGathering,
    currentGathering: ownProps.currentGathering
  }
}


export default connect(mapStateToProps)(NextSpecialGathering);