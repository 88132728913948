import { connect } from 'react-redux';
import VerifyScreen from './VerifyScreen';
import { withRouter } from 'react-router-dom';
import { sendVerification } from "../../../actions/emailVerification";
import { loginFromToken } from "../../../actions/userRole";


const mapStateToProps = (state) => {
  return {
    emailVerification: state.emailVerification,
  };
};

const mapDispatchtoProps = {
	sendVerification,
	loginFromToken
};


export default withRouter(connect(
  mapStateToProps,
  mapDispatchtoProps
)(VerifyScreen));