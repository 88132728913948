import React from 'react';
import textResource from '../../../lib/textLoader';
import { FormGroup, Label, Input, CustomInput, UncontrolledTooltip } from 'reactstrap';



class NotificationsCheckbox extends React.Component {

  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    if (this.props.isRequested) {
      this.props.clearRequestedNotifications(this.props.experienceType);
    } else {
      this.props.setRequestedNotifications(this.props.experienceType);
    }
  }

  render() {
    let { isRequested, userState, userRole } = this.props;

    if (!["present", "absent"].includes(userState)) { return null; }
    if (!userRole.email) { return null; }
    let disabled = !!(userRole.role === "guest");
    let color = disabled ? "#545454" : "white";
    let style = { color };

  // <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="Turn on this custom switch" />

  // <Input
  //  name="CheckboxRequestNotifications"
  //  type="checkbox"
  //  className="checkbox"
  //  disabled={disabled}
  //  checked={isRequested}
  //  onChange={isRequested ? clearisRequested : setisRequested}
  ///>

    return (
      <div className="switchContainer">
          <div className="switchLabel">
            <p>
              Notify me when someone wants to connect
            </p>
          </div>
          <div className="switchMain">
            <CustomInput 
              id={`NotificationsCheckbox:${this.props.experienceType}`}
              type="switch" 
              bsSize="lg"
              name="SwitchRequestNotifications" 
              disabled={disabled}
              checked={isRequested}
              onChange={this.onChange}
              />
          </div>
      </div>
    )

  }
}


export default NotificationsCheckbox;