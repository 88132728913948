import React from 'react';
import './Spinner.css';
import './BlackSpinner.css';

const BlackSpinner = ({size}) => (
  <div className="spinner" style={{width: size || "2.5rem", height: size || "2.5rem"}}>
    <div className="spinner-circle1 spinner-circle spinner-black"></div>
    <div className="spinner-circle2 spinner-circle spinner-black"></div>
    <div className="spinner-circle3 spinner-circle spinner-black"></div>
    <div className="spinner-circle4 spinner-circle spinner-black"></div>
    <div className="spinner-circle5 spinner-circle spinner-black"></div>
    <div className="spinner-circle6 spinner-circle spinner-black"></div>
    <div className="spinner-circle7 spinner-circle spinner-black"></div>
    <div className="spinner-circle8 spinner-circle spinner-black"></div>
    <div className="spinner-circle9 spinner-circle spinner-black"></div>
    <div className="spinner-circle10 spinner-circle spinner-black"></div>
    <div className="spinner-circle11 spinner-circle spinner-black"></div>
    <div className="spinner-circle12 spinner-circle spinner-black"></div>
  </div>
);

export default BlackSpinner;