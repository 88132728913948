import React from 'react';
import './ActiveExperienceStyles.css';
import { Redirect } from 'react-router-dom';
import silhouette from "../../resources/images/silhouetteNew.svg";
import ProgressBarContainer from "./Connected/ProgressBarContainer";
import AfterConnectionContentContainer from './Disconnected/AfterConnectionContentContainer';
import PreparingContentContainer from './Preparing/PreparingContentContainer';
import ReadyContentContainer from './Preparing/ReadyContentContainer';
import DigestingContentContainer from './Digesting/DigestingContentContainer';
import GettingMediaContent from './Preparing/GettingMediaContent';

import StartButtonContainer from '../ConnectionControls/StartButtonContainer';
import CancelButtonContainer from '../ConnectionControls/CancelButtonContainer';
import EndConnectionButtonContainer from '../ConnectionControls/EndConnectionButtonContainer';

import textResource from '../../lib/textLoader';
import GuidelinesContainer from './GuidanceAndEnteringRoom/GuidelinesContainer';
import SelectLanguageContainer from './GuidanceAndEnteringRoom/SelectLanguageContainer';
import EnteringRoomStateContainer from './GuidanceAndEnteringRoom/EnteringRoomStateContainer';

import AudioOpenIndicatorContainer from './Connected/AudioOpenIndicatorContainer';
import ExperienceTextInstructionsContainer from './Connected/ExperienceTextInstructionsContainer';

import ExperienceFlowManagerContainer from './ExperienceManager/ExperienceFlowManagerContainer';


function scrollTrick() {
  window.scrollTo(0,1);
}

const digestingScreen = (userState, videoWrap) => {
  if (userState === "digesting") {
    return <DigestingContentContainer videoWrap={videoWrap} />;
  } else {
    return null;
  }
}

const silhouetteBigIfAppropriate = (userState) => {
  if (userState === "preparing") {
    return <img className="silhouette" alt="" src={silhouette} />
  } else {
    return null;
  }
}

const silhouetteSmallIfAppropriate = (userState) => {
  if (userState === "ready") {
    return <img className="silhouette" alt="" src={silhouette} />
  } else {
    return null;
  }
}

const progressBarIfAppropiate = (userState) => {
  if (userState === "connected") {
    return (
      <div id="progressbar">
        <ProgressBarContainer />
      </div>
    )
  } else {
    return null;
  }
}

class ActiveExperience extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      videoWrapHeight: "380px",
      fullScreenVideo: "",
      refsLoaded: false
    }

    this.resize = this.resize.bind(this);
    this.loadWebRTC = this.loadWebRTC.bind(this);
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
    this.setState({refsLoaded: true});
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  componentDidUpdate(prevProps){
    let oldState = prevProps.userState;
    let newState = this.props.userState;
    let targetStates = ["ready", "connected"];
    // When I move to ready state, show small video
    if(oldState !== newState){
      this.resize()
    }
    if (oldState !== "ready" && newState === "ready") {
      let smallVideoDiv = document.getElementById("smallVideoDiv");
      smallVideoDiv.classList.remove("invisible");
    }
    if(oldState !== "gettingMedia" && newState === "gettingMedia" ){
      const cookieControlButton = document.getElementById("ccc-icon");
      if (cookieControlButton) {
        cookieControlButton.classList.add("invisible");
      }
      this.resize()
      // This event is a trick and it's only ok on these video states where there is nothing to scroll
      window.addEventListener('resize', scrollTrick);
    }

    // When coming out of ready or connected state, hide small video
    if (targetStates.includes(oldState) && !targetStates.includes(newState)) {
      let smallVideoDiv = document.getElementById("smallVideoDiv");
      smallVideoDiv.classList.add("invisible");
    }

    // When coming to digesting screen, make black take all screen
    if (oldState !== "ready" && newState === "ready") {
      this.setState({ fullScreenVideo: "digesting-screen-full" })
    }

    // When coming back to present, make box normal size
    if (oldState !== "present" && newState === "present") {
      this.setState({ fullScreenVideo: "" })
      window.removeEventListener('resize', scrollTrick);
      setTimeout(() => this.resize(), 500)

      const cookieControlButton = document.getElementById("ccc-icon");
      if (cookieControlButton) {
        cookieControlButton.classList.remove("invisible");
      }
    }

  }

  resize() {
    if (this.videoWrap) {
      this.setState({ videoWrapHeight: `${this.videoWrap.offsetWidth / 1.79}px` })
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

    }
  }

  renderVideoWrapClassName() {
    let { fullScreenVideo } = this.state;
    let { userState } = this.props;

    return `video-wrap ${userState} ${fullScreenVideo}`
  }

  renderVideoWrapStyle() {
    if(!this.props.userState === "firstTimeGuidance") {
      return { height: this.state.videoWrapHeight }
    }
    return {minHeight: this.state.videoWrapHeight}
  }

  renderVideoAreaStyle() {
    if(this.props.languageDropDown) {
      return {overflow: 'visible'}
    }
    return {}
  }

  loadWebRTC() {
    if (!this.state.refsLoaded || !this.props.realtime || (this.props.fdTest.testDone && !this.props.fdTest.webrtcAvailable)) {
      console.log("not rendering", !this.state.refsLoaded, !this.props.realtime, this.props.fdTest.testDone, !this.props.fdTest.webrtcAvailable)
      return null;
    } else {
      return (
        <ExperienceFlowManagerContainer
          smallVideo={this.smallVideo}
          bigVideo={this.bigVideo}
          videoWrap={this.videoWrap}
        />
      )
    }
  }

  render() {
    let { userState, peerRoom } = this.props;
    if (["present", "absent", "waiting", "firstTimeGuidance"].includes(userState)) { 
      return <Redirect to={peerRoom.connectClickOriginUrl} /> 
    }

    return (
      <div className="videoConatainer" style={{background: 'none', padding: 0}}>
          <div className="overflow-hidden" id="mainVideoAreaContainer" style={this.renderVideoAreaStyle()}>
            <div className={this.renderVideoWrapClassName()} id="video-wrap" style={this.renderVideoWrapStyle()} ref={element => (this.videoWrap = element)}>

              {userState === 'enteringRoom' &&
              <div className="selectLanguageContainer">
                <SelectLanguageContainer />
              </div>}

              {userState === 'enteringRoom' &&
                <EnteringRoomStateContainer />
              }

              <div className='main-video'>
                <video
                  id="mainVideoTag"
                  autoPlay
                  playsInline
                  ref={element => (this.bigVideo = element)}
                />
                {silhouetteBigIfAppropriate(userState)}
              </div>

              <div id="smallVideoDiv" className="small-video invisible">
                <video
                  id="smallVideoTag"
                  autoPlay
                  playsInline
                  ref={element => (this.smallVideo = element)}
                  muted
                />
                {silhouetteSmallIfAppropriate(userState)}
              </div>

              {progressBarIfAppropiate(userState)}


              {digestingScreen(userState, this.videoWrap)}

              {(userState === "gettingMedia") &&
                <div className="getting-media-text">
                  <GettingMediaContent />
                </div>
              }

              {(userState === "preparing") &&
                <div id="StartButton">
                  <StartButtonContainer />
                </div>
              }

              {(["enteringRoom", "gettingMedia", "preparing"].includes(userState) ) &&
                <div id="CancelButton">
                  <CancelButtonContainer />
                </div>
              }
              
              {(userState === "connected" || (userState === "ready" && this.props.selectedExperienceData.type !== "oneminute")) &&
                <div id="EndConnectionButton">
                  <EndConnectionButtonContainer />
                </div>
              }

              {(userState === "ready") &&
                <div className="ready-state-text">
                  <ReadyContentContainer/>
                </div>
              }

              {(userState === "connected") &&
                  <AudioOpenIndicatorContainer/>
              }

              {(["ready", "connected"].includes(userState)) &&
                  <ExperienceTextInstructionsContainer/>
              }

              {userState === "preparing" && <PreparingContentContainer/>}
              
              {(userState === "disconnected") &&
                <div className="disconnected-state-text">
                  {userState === "disconnected" && <AfterConnectionContentContainer />}
                </div>
              }

              {this.loadWebRTC()}

            </div>
            
          </div>
      </div>
    )
  }
}

export default ActiveExperience;
