import { connect } from 'react-redux';
import ConnectButton from './ConnectButton';
import {setStateWaiting, setStateFirstTimeGuidance} from "../../actions/userState";
import { setExperienceType, setPoolType } from "../../actions/peerRoom";
import { openNoOneInCircleModal } from "../../actions/uiControls";


const mapStateToProps = (state, ownProps) => {
  return {
    userState: state.userState,
    accountSuspended: state.userRole.accountSuspended,
    peerRoom: state.peerRoom,
    userRole: state.userRole,
    fdTest: state.fdTest,
    realtime: state.realtime,
    experienceType: ownProps.experienceType,
    selectedExperienceData: state.selectedExperienceData.data,
    waitingCount: state.presenceCount[`${ownProps.experienceType}:${ownProps.poolType}`],
    poolType: ownProps.poolType,
    disabledFromProps: ownProps.disabled
  };
};

const mapDispatchtoProps = {
  setExperienceType,
  setStateWaiting,
  setPoolType,
  setStateFirstTimeGuidance,
  openNoOneInCircleModal
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ConnectButton);