import { connect } from 'react-redux';
import CameraFailure from './CameraFailure';
import { sendFailureData } from "../../actions/cameraFailure";


const mapStateToProps = (state) => {
  return {
    cameraFailureReason: state.cameraFailure.reason,
  };
};

const mapDispatchtoProps = {
	sendFailureData
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(CameraFailure);