import React from "react";
import textResource from '../../../lib/textLoader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SetNewPassword from './SetNewPassword';
import { setNewRecoveredPassword, validateRecoveryHash } from "../../../actions/accountChanges";
import {logout} from "../../../actions/userRole";
import {validateForm} from '../../../lib/validationHelpers';
import serialize from 'form-serialize';

class SetNewPasswordContainer extends React.Component {
	constructor() {
		super();

    this.state = {
      errors: {}
    }
		this.onSubmit = this.onSubmit.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
	}

  componentDidUpdate(prevProps){
    if (!prevProps.accountChanges.error && this.props.accountChanges.error) {
      this.setState({
        errors: {
          fromServer: "The request could not be made. Please try again later."
        },
      })      
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const form = e.target
    const data = serialize(form, {hash: true})
    const errors = validateForm(data, "setNewPassword");

    if (errors) {
      this.setState({errors})
    } else {
      this.formSuccess(form, data)
    }
  }

  formSuccess(form, data) {
    // form.reset()
    let hash = this.props.match.params.hash;
    this.props.setNewRecoveredPassword(data.password, hash);
    // this.setState({
    //   success: true,
    //   errors: {},
    // })
  }

  render() {
    return <SetNewPassword onSubmit={this.onSubmit} accountChanges={this.props.accountChanges} validateRecoveryHash={this.props.validateRecoveryHash} logout={this.props.logout} match={this.props.match} {...this.state}/>;
  }
}

// yyPilot1

const mapStateToProps = (state) => {
  return {
    accountChanges: state.accountChanges
  };
};

const mapDispatchtoProps = {
	setNewRecoveredPassword,
  validateRecoveryHash,
  logout
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchtoProps
)(SetNewPasswordContainer));