import React from 'react';
import { Col, Row, Container } from 'reactstrap';


const LayoutWrapper = ({ children }) => {

	return (
		
		<div className="contentpages" style={{ textAlign: "left" }}>
			<Row>
				<Col className="mt-4 pt-4 pb-5" xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} >
					{children}
				</Col>
			</Row>
		</div>
	)
}

export default LayoutWrapper;