import { connect } from 'react-redux';
import ExperienceTimer from './ExperienceTimer';
import { showConnectionControls, hideConnectionControls } from "../../../actions/uiControls";
import {
  setStateConnected, 
  setStateDisconnected,
  setStateDigesting,
  setStatePresent
} from "../../../actions/userState";
import { addCompletedHumanMinute } from '../../../actions/userRole';


const mapStateToProps = (state, ownProps) => {
  return {
    userState: state.userState,
    peerConnectionReady: state.sessionState.peerConnectionReady,
    roomID: state.peerRoom.roomID,
    videoWrap: ownProps.videoWrap,
    endConnectionReason: state.endConnectionReason,
    completedHumanMinutes: state.userRole.completedHumanMinutes,
    selectedExperienceData: state.selectedExperienceData.data
  };
};

const mapDispatchtoProps = {
	showConnectionControls,
	hideConnectionControls,
  setStateConnected,
  setStateDisconnected,
  setStateDigesting,
  setStatePresent,
  addCompletedHumanMinute
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ExperienceTimer);