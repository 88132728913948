import constants from "../constants";

export const GET_PAYMENT_INTENT_START = "GET_PAYMENT_INTENT_START";
export const GET_PAYMENT_INTENT_FAILURE = "GET_PAYMENT_INTENT_FAILURE";
export const GET_PAYMENT_INTENT_SUCCESS = "GET_PAYMENT_INTENT_SUCCESS";
export const CLEAR_PAYMENT_CLIENT_SECRET = "CLEAR_PAYMENT_CLIENT_SECRET";

export const EXECUTE_PAYMENT_START = "EXECUTE_PAYMENT_START";
export const EXECUTE_PAYMENT_FAILURE = "EXECUTE_PAYMENT_FAILURE";
export const EXECUTE_PAYMENT_SUCCESS = "EXECUTE_PAYMENT_SUCCESS";
export const GET_ALL_COUNTRIES_LIST = "GET_ALL_COUNTRIES_LIST";
export const SET_PAYMENT_RECORD_ID = "SET_PAYMENT_RECORD_ID";



export function getPaymentIntentStart(data) {
  return {
    type: GET_PAYMENT_INTENT_START,
    data
  };
}

export function getPaymentIntentSuccess(data) {
  return {
    type: GET_PAYMENT_INTENT_SUCCESS,
    data
  };
}

export function getPaymentIntentFailure(error) {
  return {
    type: GET_PAYMENT_INTENT_FAILURE,
    error
  };
}


export function executePaymentStart() {
  return {
    type: EXECUTE_PAYMENT_START
  };
}

export function executePaymentSuccess() {
  return {
    type: EXECUTE_PAYMENT_SUCCESS
  };
}

export function executePaymentFailure(error) {
  return {
    type: EXECUTE_PAYMENT_FAILURE,
    error
  };
}

export function clearPaymentClientSecret() {
  return {
    type: CLEAR_PAYMENT_CLIENT_SECRET
  };
}

export function getAllCountriesList(data) {
  return {
    type: GET_ALL_COUNTRIES_LIST,
    data
  };
}

export function setPaymentRecordId(id) {
  return {
    type: SET_PAYMENT_RECORD_ID,
    id
  };
}


export function getPaymentIntent(paymentType, amount) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `paymentType=${paymentType}&amount=${amount}`
  };
  return dispatch => {
    dispatch(getPaymentIntentStart({paymentType, amount}));
    fetch(`${constants.baseUrl}/api/v1/payments/paymentintentsecret`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
       
        dispatch(getPaymentIntentSuccess(response));
      })
      .catch(err => {
        console.log(err);
        dispatch(getPaymentIntentFailure(err));
      });
  };
}

export function updatePaymentRecord(paymentId, email, firstName, lastName, postalCode, country='') {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `bearer ${localStorage.getItem("token")}`
    },
    body: `paymentId=${paymentId}&email=${email}&firstName=${firstName}&lastName=${lastName}&postalCode=${postalCode}&country=${country}`
  };
  return dispatch => {
    fetch(`${constants.baseUrl}/api/v1/payments/updatepaymentrecord`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function executePayment(stripe, clientSecret, cardNumber, cardExpiry, cardCvc, data) {
  return (dispatch, getState) => {
    let paymentId = getState().paymentsState.paymentId;
    dispatch(executePaymentStart());
    dispatch(updatePaymentRecord(paymentId, data.email, data.first_name, data.last_name, data.postal_code, data.country))
    if (!stripe) {return console.log("no fakin stripe");}
		stripe.confirmCardPayment(clientSecret, {
		  payment_method: {card: (cardNumber, cardExpiry, cardCvc)}
		})
		.then((result) => {
		  if (result.error) {
		    dispatch(executePaymentFailure(result.error.message));
		  }
      console.log(result.paymentIntent.status)
	    // The payment has been processed!
	    if (result.paymentIntent.status === 'succeeded') {
        dispatch(setPaymentRecordId(paymentId))
        dispatch(executePaymentSuccess());
	    } else {
        // if the status is not succeeded, I need to check what went wrong
      }

		})
    .catch(err => {
    	// Show error to customer (e.g., insufficient funds)
      console.log(err);
      dispatch(executePaymentFailure(err));
    });
  };
}

export function getAllCountries() {
  let config = {
    method: "GET",
  };
  return dispatch => {
    fetch(`https://restcountries.eu/rest/v1`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        dispatch(getAllCountriesList(response))
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function emailDonorNewsletter(paymentId) {
  let config = {
    method: "POST",
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `paymentId=${paymentId}`
  };
  return dispatch => {
    fetch(`${constants.baseUrl}/api/v1/payments/emaildonornewsletter`, config)
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
}

