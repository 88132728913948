import React from 'react';
import textResource from '../../lib/textLoader';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import ParagraphCreator from '../elements/ParagraphCreator';

export default class LoginForm extends React.Component {
  render() {
    let { onSubmit } = this.props;
    return (
      <Form id="FormAdditionalInfo" onSubmit={onSubmit}>
        <FormGroup>
          <Label for="motivation">
            {textResource["waitlist.thank_you"]}
          </Label>
          <Input 
          	type="textarea" 
          	name="motivation" 
          	id="motivation"
          />
        </FormGroup>
				<FormGroup tag="fieldset">
          <Label>
            {textResource["waitlist.how_discover"]}
          </Label>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="reference" value="friend" selected />{' '}
              {textResource["waitlist.how_discover.friend"]}
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="reference" value="website" />{' '}
              {textResource["waitlist.how_discover.website"]}
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="reference" value="facebook" />{' '}
              {textResource["waitlist.how_discover.fb"]}
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="reference" value="other" />{' '}
              {textResource["waitlist.how_discover.other"]}
            </Label>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label for="explanation" hidden>Explanation</Label>
          <Input 
            type="text" 
            name="explanation" 
            id="explanation" 
          />
        </FormGroup>
        <Button id="BtnAddInfo" color="primary" block>
          {textResource["waitlist.send_info_btn"]}
        </Button>
      </Form>
    );
  }
}