import { connect } from 'react-redux';
import ConnectNotifications from './ConnectNotifications';

import { 
  setLastNotificationShown,
  clearAllRequestedNotifications
} from "../../actions/connectNotifications";


const mapStateToProps = (state) => {
  return {
    realtime: state.realtime,
    userState: state.userState,
    userRole: state.userRole,
    peerRoom: state.peerRoom,
    connectNotifications: state.connectNotifications,
    currentGatheringId: state.gatheringsInfo.currentGatheringId,
    presenceCount: state.presenceCount
  };
};

const mapDispatchtoProps = {
  setLastNotificationShown,
  clearAllRequestedNotifications
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ConnectNotifications);