import React from 'react';
import textResource from '../../../lib/textLoader';
import Spinner from '../../elements/Spinner';

const message = (reason) => {
	switch (reason) {
		case "natural":
			return <h4>{textResource["end_connection.natural"]}</h4>;
		case "manual_other":
			return <h4>{textResource["end_connection.manual_other"]}</h4>;
		case "manual_self":
			return null;			
		case "cancel_other":
			return <h4>{textResource["end_connection.cancel_other"]}</h4>;
		case "timeout_other":
			return <h4>{textResource["end_connection.cancel_other"]}</h4>;
		case "timeout_self":
			return <h4>{textResource["end_connection.timeout_self"]}</h4>;
		case "cancel_self":
			return null;
		case "ice_failure":
			return <h4>{textResource["end_connection.connection_failed"]}</h4>;
		default:
			return null;
	}
}

const AfterConnectionContent = ({endConnectionReason}) => {
	return (
		<div>
			{message(endConnectionReason)}
			<Spinner />
		</div>
	)

}

export default AfterConnectionContent;