import React from 'react';
import './AccountPagesStyles.css';
import textResource from '../../lib/textLoader';
import { Button, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import ValidationErrorMessage from '../Authentication/ValidationErrorMessage';
import ErrorMessage from '../Authentication/ErrorMessage';
import LayoutWrapper from '../elements/LayoutWrapper';


const DeleteAccount = ({ onSubmit, errors, userRole }) => {

	{/*
	if (userRole.subscription.activeSubscription && !userRole.subscription.subscriptionCancelsAt) {
		return (
			<LayoutWrapper>
				<h1 className="pb-3">{textResource["delete_account.title"]}</h1>
		  	<br/>
		  	<p>You have an active paid membership. If you are sure you want to delete your account (which deletes all your data), please cancel your paid membership first.</p>
	      <Row>
	      	<Col lg={{ size: 5 }}  sm={{ size: 7 }} xs={{ size: 12 }}>
					  <Link to="/cancelmembership">
					  	<Button color="default" block>
						  	Go to cancel membership
						  </Button>
			    	</Link>
	      	</Col>
	      </Row>
	    </LayoutWrapper>
		)
	}
	*/}

	return (
		<LayoutWrapper>
		  	<h1 className="pb-3">{textResource["delete_account.title"]}</h1>
		  	<br/>
		  	<p>{textResource["delete_account.are_you_sure"]}</p>
		    <Form id="FormDeleteAccount" onSubmit={onSubmit}>
		      <ErrorMessage errors={errors} />

		      <FormGroup row>
		        <Label for="password" xs={12}>
		          {textResource["delete_account.password"]}
		        </Label>
		        <Col xs={12}>
			        <Input 
			          invalid={!!errors.password}
			          type="password" 
			          name="password" 
			          id="password" 
			        />
			        <ValidationErrorMessage message={errors.password} />
			      </Col>		        
		      </FormGroup>
		      <br/>
		      <Row>
		      	<Col lg={{ size: 5 }}  sm={{ size: 12 }} xs={{ size: 12 }}>
				      <Button id="BtnDeleteAccount" color="primary" className="mb-1" block>
				        {textResource["delete_account.submit"]}
				      </Button>
		      	</Col>
		      	<Col lg={{ size: 7 }}  sm={{ size: 12 }} xs={{ size: 12 }}>
						  <Link to="/account/profile">
						  <Button color="secondary" block>
							  {textResource["delete_account.cancel"]}
							  </Button>
				    	</Link>
		      	</Col>
		      </Row>
		    </Form>
		</LayoutWrapper>
	)
}

export default DeleteAccount;