import React from 'react';
import './AboutPageStyles.css';
import textResource from '../../lib/textLoader';
import LayoutWrapper from '../elements/LayoutWrapper';
import { Col, Row, Alert } from 'reactstrap';
import KeepMeInformedButtonContainer from '../Lobby/GatheringsArea/KeepMeInformedButtonContainer';
import AuthModalButtonContainer from '../Authentication/AuthModalButtonContainer';
import InvitationFormContainer from '../Lobby/InvitationFormContainer';
import PartnerContainer from '../Lobby/OurPartners/PartnerContainer';
import RelationalExperiencesImg from "../../resources/images/authentic_connection.png"
import HumanMinuteCardImage from "../../resources/images/HumanMinuteCardImage.png"
import FolhaLogo from "../../resources/images/folhalogo.png"
import FR20MinutesLogo from '../../resources/images/fr20minuteslogo.png'
import EvolveLogo from "../../resources/images/evolvelogo.png"
import WiredLogo from "../../resources/images/wiredlogo.png"

import TeamMemberModal from './TeamMemberModal';

const callToActionEmails = (userRole) => {
	if (!userRole.ready) { return null; }
	if (userRole.email) {
		if (userRole.solicitedEmails) { 
			return (
				<div style={{textAlign: "center"}}>
					<InvitationFormContainer />
				</div>
			) 
		} else {
			return (
				<div style={{textAlign: "center"}}>
					<p className="pt-3">{textResource["about.call_to_action.emails"]}</p>
					<KeepMeInformedButtonContainer
						origin="aboutPageButton"
					/>
				</div>
			)
		}
	} else {
		return callToActionRegister(userRole)
	}
}

const callToActionRegister = (userRole) => {
	if (!userRole.ready) { return null; }
	if (!userRole.email) {
		return (
			<div className="pb-5" style={{textAlign: "center"}}>
				<p >{textResource["about.call_to_action.try_now.text"]}</p>
				<AuthModalButtonContainer text={textResource["about.call_to_action.try_now.btn"]} />
			</div>
		)
	} 
	return null;
}


const AboutPage = ({ userRole, registeredToEmails, howSolicited }) => {

	return (
		<div>
			<div className="topLeadingText">
				<LayoutWrapper>
					<h2 style={{textAlign: "center"}} className="pb-4">A Space To Truly Connect</h2>
					<p className="pb-4">{textResource["about.intro"]}</p>
					<p className="pb-4">{textResource["about.social_enterprise"]}</p>
				</LayoutWrapper>
			</div>
			<div>
        <LayoutWrapper>
      	  <Row className="pb-5 pt-5">
            <Col xs={12} md={6}>
            	<div className="centeredBox">
	            	<div>
		            	<h2 className="pt-4 pb-3">{textResource["about.human_minute.title"]}</h2>
		            	<p className="pb-1">{textResource["about.human_minute.content1"]}</p>
		            	<p className="pb-4">{textResource["about.human_minute.content2"]}</p>
	            	</div>
            	</div>
            </Col>
            <Col xs={12} md={6}>
              <div className="centeredBox">
                <img className="p-3" src={HumanMinuteCardImage} />
              </div>
            </Col>
      	  </Row>
      	  {/*<Row className="pb-5">
            <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
              <div className="centeredBox">
                <img className="p-3" src={RelationalExperiencesImg} />
              </div>
            </Col>
            <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
            	<div className="centeredBox">
	            	<div>
		            	<h2 className="pt-4 pb-3">{textResource["about.experiences.title"]}</h2>
		            	<p className="pb-3">{textResource["about.experiences.content"]}</p>
	            	</div>
            	</div>
            </Col>
      	  </Row>*/}
      	  {callToActionRegister(userRole)}
      	  {/*<p className="pb-3">{textResource["about.experiences.facilitators"]}</p>*/}
        </LayoutWrapper>
      </div>
      <div style={{background: "#F8E9DE"}}>
	      <LayoutWrapper>
			    <h2 style={{textAlign: "center"}} className="pt-4 pb-3">{textResource["about.media.title"]}</h2>
	        <Row>
	            <Col md={3} sm={6} className="pb-2">                        
	                <div className="media-logo" >
	                	<a href="https://www.wired.com/story/human-online-anti-zoom/" target="_blank" >
	                    	<img src={WiredLogo} />
	                    </a>
	                    <p style={{textAlign: "center"}} className="pt-3">In english</p>
	                </div>                        
	            </Col>
	            <Col md={3} sm={6} className="pb-2">                        
	                <div className="media-logo">
	                	<a href="https://www1.folha.uol.com.br/mundo/2020/04/plataforma-poe-estranhos-para-se-olharem-por-um-minuto-pela-internet.shtml" target="_blank" >
	                    	<img src={FolhaLogo} />
	                    </a>
	                    <p style={{textAlign: "center"}} className="pt-3">In portuguese</p>
	                </div>                        
	            </Col>
	            <Col md={3} sm={6} className="pb-2">                        
	                <div className="media-logo">
	                	<a href="https://www.20minutes.fr/high-tech/2754663-20200503-confinement-ca-aide-sentir-mieux-appli-ligne-permet-devisager-inconnu-pendant-minute" target="_blank" >
	                    	<img src={FR20MinutesLogo} />
	                    </a>
	                    <p style={{textAlign: "center"}} className="pt-3">In french</p>
	                </div>                        
	            </Col>
	            <Col md={3} sm={6}>                        
	                <div className="media-logo">
	                	<a href="https://www.evolve-magazin.de/archiv/ausgabe-24-2019/" target="_blank" >
	                    	<img src={EvolveLogo} />
	                    </a>
	                    <p style={{textAlign: "center"}} className="pt-3">In german</p>
	                </div>                        
	            </Col>                    
	        </Row>
				</LayoutWrapper>
			</div>
			<div>
        <LayoutWrapper>
					<h2 style={{textAlign: "center"}} className="pt-4 pb-3">{textResource["about.team.title"]}</h2>

		      <Row className="team-area">
		          <Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="team" name="nicolas"/>                 
		          </Col>
		          <Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="team" name="felipe"/>                 
		          </Col>
		          <Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="team" name="miguel"/>                 
		          </Col>
		          <Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="team" name="esther"/>                 
		          </Col>
		          <Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="team" name="americo"/>                 
		          </Col>
		          <Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="team" name="ming"/>                 
		          </Col>		          		         		          		              
		      </Row>
					{/*<h2 style={{textAlign: "center"}} className="pt-4 pb-3">{textResource["about.facilitators.title"]}</h2>

		      <Row className="team-area">
		      		<Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="facilitator" name="john-thompson"/>
		          </Col>
		      		<Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="facilitator" name="maja-wrzesien"/>
		          </Col>	
		      		<Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="facilitator" name="al-jeffery"/>
		          </Col>
		      		<Col md={4} sm={6} className="pb-5">
		          	<TeamMemberModal type="facilitator" name="arun-thompson"/>
		          </Col>
		      </Row>*/}
        </LayoutWrapper>
      </div>
      <div style={{background: "#F8E9DE"}}>
	      <LayoutWrapper>
					<h2 style={{textAlign: "center"}} className="pt-4 pb-5">{textResource["about.partners.title"]}</h2>
					<PartnerContainer withTitle={false}/>      	
	      </LayoutWrapper>
	    </div>
      <div>
      	<LayoutWrapper>
					{
						registeredToEmails && howSolicited === "aboutPageButton"
							? (<Alert color="success">
								{textResource["lobby.messages.registered_to_emails"]}
							</Alert>)
							: null
					}
					{
						//callToActionEmails(userRole)
					}
      	</LayoutWrapper>
      </div>
		
		</div>
	);

};

export default AboutPage;
