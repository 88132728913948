import { connect } from 'react-redux';
import FullscreenSettingItem from './FullscreenSettingItem';
import { setFullScreen, cancelFullScreen } from "../../../actions/fullscreen";

// yyPilot1

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,  	
    fullScreen: state.fullScreen
  };
};

const mapDispatchtoProps = {
	setFullScreen,
	cancelFullScreen
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(FullscreenSettingItem);