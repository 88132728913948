import * as Actions from "../actions/experiences";

const initialState = {
    experiencesByCategory: [],
    isFetching: false,
    error: null
};

export function experiences(state = initialState, action) {
    switch (action.type) {
        case Actions.GET_EXPERIENCES_START:
            return {
                experiencesByCategory: [],
                isFetching: true,
                error: null
            };
        case Actions.GET_EXPERIENCES_SUCCESS:
            return {
                experiencesByCategory: action.data,
                isFetching: false,
                error: null
            };
        case Actions.GET_EXPERIENCES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}
