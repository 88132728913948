import React from 'react';
import './DigestingContentStyles.css';
import textResource from '../../../lib/textLoader';
import { Button, Form, FormGroup, Input, Col, Row } from 'reactstrap';
import ValidationErrorMessage from '../../Authentication/ValidationErrorMessage';
import ErrorMessage from '../../Authentication/ErrorMessage';
import LayoutWrapperWithoutHome from '../../elements/LayoutWrapperWithoutHome';
import ReportThumbIcon from '../../../resources/images/flag-solid-white.svg'
import ReportRedThumbIcon from '../../../resources/images/flag-solid-red-d71b12.svg'
import constants from '../../../constants';


const ReportPerson = ({ onSubmit, errors, onCancel, inappropriateBehaviourReport }) => {

	return (
		<div>
			<LayoutWrapperWithoutHome>			
				<h3 className="pt-5 pb-3">{textResource["report_inappropriate_behaviour.title"]}</h3>
				<br/>
				<p>{textResource["report_inappropriate_behaviour.content"]}</p>
				<Form id="FormReportPerson" onSubmit={onSubmit}>
					<ErrorMessage errors={errors.fromServer ? errors : {}} />
					<Row className="mt-5">
						<Col md={6}>
							<div className="mb-3">
								<p><b>{textResource["report_inappropriate_behaviour.not_following_guidelines_title"]}</b></p>
								{Object.keys(constants.notFollowingGuidelines).map((key) => {
									return (
										<span key={key}>
											<label className="customCheckbox">
												<input type="checkbox" name={key}/>
												<span></span>
												{constants.notFollowingGuidelines[key]}
											</label><br />
										</span>
									)
								})}
							</div>
						</Col>
						<Col md={6}>
							<div className="mb-3">
								<p><b>{textResource["report_inappropriate_behaviour.inappropriate_behaviour_title"]}</b></p>
								{Object.keys(constants.inappropriateBehaviour).map((key) => {
									return (
										<span key={key}>
											<label className="customCheckbox">
												<input type="checkbox" name={key} />
												<span></span>
												{constants.inappropriateBehaviour[key]}
											</label><br />
										</span>
									)
								})}
								<label className="customCheckbox">
									<input type="checkbox" name="other" />
									<span></span>
									{textResource["report_inappropriate_behaviour.other_checkbox"]}
								</label><br />
							</div>
						</Col>
					</Row>
					<FormGroup>
						<p>{textResource["report_inappropriate_behaviour.tell_us_more"]}</p>
						<Input 
							invalid={!!errors.reportExplanation}
							type="textarea"
							maxLength="10000"
							name="reportExplanation" 
							id="reportExplanation"
						/>
						<ValidationErrorMessage message={errors.reportExplanation} />      
					</FormGroup>
					
					<Row className="mt-5 mb-5">
						<Col xs={{ size: 6 }}>
							<Button 
								id="BtnSendReport" 
								color="secondary" 
								block
								disabled={inappropriateBehaviourReport.isFetching}
							>
							<img src={ReportThumbIcon} className="whiteImg"/>
							<img src={ReportRedThumbIcon} className="redImg"/>
								{
									inappropriateBehaviourReport.isFetching ?
									textResource["report_inappropriate_behaviour.sending_btn"] :
									textResource["report_inappropriate_behaviour.report_btn"]
								}
								
							</Button>
						</Col>
						<Col xs={{ size: 6 }}>
							<Button
								onClick={onCancel}
								color="default" 
								block
							>
								{textResource["report_inappropriate_behaviour.cancel_btn"]}
							</Button>		    	
						</Col>
					</Row>
				</Form>
			</LayoutWrapperWithoutHome>
		</div>
	)
}

export default ReportPerson;