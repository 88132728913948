const sendGAEvent = (category, action, label, value, nonInteraction) => {

	if (!window.ga) {return;}
	if (window.location.pathname === "/testing/testrtc") {return;}

	let sendData = {
		'hitType': 'event',
		'eventCategory': category,
		'eventAction': action,
		'eventLabel': label		
	};

	if (value) {
		sendData.eventValue = value;
	}

	if (nonInteraction) {
		sendData.nonInteraction = true;
	}
	// console.log(sendData);

	console.log(sendData);
	return window.ga('send', sendData);
}


module.exports = sendGAEvent;