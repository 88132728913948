import { connect } from 'react-redux';
import SocialLogin from './SocialLogin';
import { 
	socialUserLogin,
} from "../../actions/userRole";
import { 
  setFacebookData,
  facebookFailure,
  clearFacebookError,
  clearSocialLoginButtons
} from "../../actions/socialLogin";
import { setAuthModalForm, clearAuthModalForm, setLoginWithEmail, openAuthModal, clearAuthData } from '../../actions/uiControls';

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    registeringFromInvitation: state.registeringFromInvitation,
    authModalType: state.uiControls.authModalType,
    authModalForm: state.uiControls.authModalForm,
    socialLogin: state.socialLogin,
    authEventsCategory: state.uiControls.authEventsCategory
  };
};

const mapDispatchtoProps = {
  socialUserLogin,
  setFacebookData,
  setAuthModalForm,
  clearAuthModalForm,
  setLoginWithEmail,
  openAuthModal,
  facebookFailure,
  clearAuthData,
  clearFacebookError,
  clearSocialLoginButtons
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(SocialLogin);