export const OPEN_LANGUAGE_DROPDOWN = "OPEN_LANGUAGE_DROPDOWN";
export const CLOSE_LANGUAGE_DROPDOWN = "CLOSE_LANGUAGE_DROPDOWN";
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";
export const SET_SCREEN_OPEN_TIME = "SET_SCREEN_OPEN_TIME"

export function openLanguageDropdown() {
  return {
    type: OPEN_LANGUAGE_DROPDOWN
  };
}

export function closeLanguageDropDown() {
    return {
      type: CLOSE_LANGUAGE_DROPDOWN
    };
}

export function setSelectedLanguage(data) {
    return {
      type: SET_SELECTED_LANGUAGE,
      data
    };
}

export function setScreenOpenTime(data) {
  return {
    type: SET_SCREEN_OPEN_TIME,
    data
  };
}

