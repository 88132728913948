import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import ValidationErrorMessage from './ValidationErrorMessage';
import ErrorMessage from './ErrorMessage';
import TermsCollapseContainer from './TermsCollapseContainer';
import FacebookErrorMessage from './FacebookErrorMessage';
import Spinner from '../elements/BlackSpinner';
import textResource from '../../lib/textLoader';


export default class RegisterForm extends React.Component {
  constructor(){
    super();
    this.state = {
      emailHintText: false,
      passwordHintText: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(key,event) {
    let { authData } = this.props;
    authData[key] = event.target.value
    this.props.setAuthData(authData)
  }

  render() {
    let { onSubmit, errors, userRole, authData } = this.props;
    return (
      <Form id="FormRegister" onSubmit={onSubmit}>
        {userRole && !userRole.fbPasswordExistError ?
        <ErrorMessage errors={errors} /> :
        <FacebookErrorMessage errors={errors} closeAuthModal={this.props.closeAuthModal}/>}
        <FormGroup>
          <Label for="name">{textResource["register.name"]}</Label>
          <Input 
            invalid={!!errors.name}
            type="text"
            name="name" 
            id="name"
            defaultValue={this.props.registeringFromInvitation.name || authData.name}
            onChange={(event) => this.handleChange('name',event)}
          />
          <ValidationErrorMessage message={errors.name} />
        </FormGroup>
        <FormGroup>
          <Label for="email">{textResource["register.email"]}</Label>
          <Input 
            invalid={!!errors.email}
            type="text" 
            name="email" 
            id="registerEmail"
            defaultValue={this.props.registeringFromInvitation.preapprovedEmail || authData.email}
            onFocus={() => this.setState({ emailHintText: true })}
            onBlur={() => this.setState({ emailHintText: false })}
            onChange={(event) => this.handleChange('email',event)}
          />
          <ValidationErrorMessage message={errors.email} />
          {this.state.emailHintText && <p style={{fontSize: '14px', marginTop: '.5rem', color: 'rgba(121, 77, 79, 0.7)'}}>{textResource["register.email_hint.text"]}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="password">{textResource["register.password"]}</Label>
          <Input 
            type="password" 
            name="password" 
            id="registerPassword"
            invalid={!!errors.password}
            onFocus={() => this.setState({ passwordHintText: true })}
            onBlur={() => this.setState({ passwordHintText: false })}
            onChange={(event) => this.handleChange('password',event)}
            defaultValue={authData.password}
          />
          <ValidationErrorMessage message={errors.password} />
          {this.state.passwordHintText && <p style={{fontSize: '14px', marginTop: '.5rem', color: 'rgba(121, 77, 79, 0.7)'}}>{textResource["register.password_hint.text"]}</p>}
        </FormGroup>
        

        <FormGroup check className="pt-3">
          
          <Input 
            type="checkbox" 
            id="emailListRegistration" 
            name="emailListRegistration"
            checked={this.props.registerEmailsAfterLogin}
            onChange={this.props.onChangeRegisterEmails}
          />
          <Label className="privacyPolicy" for="emailListRegistration" check>{textResource["register.privacy_policy_checkbox.text"]}</Label>
        </FormGroup>

        <br/>
        {/* <TermsCollapseContainer /> */}
        {
          userRole.isFetching ?
          (<div className="authSpinner">
            <Spinner />
          </div>) :
          <Button id="BtnRegister" color="primary" size="lg">{textResource["register.register_btn"]}</Button>
        }
        
      </Form>
    );
  }
}