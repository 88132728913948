import React from 'react';
import './ExperienceCardStyles.css';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Badge, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import textResource from '../../lib/textLoader';
import MicroPhoneIcon from '../../resources/images/microphone.png'
import SpeakerIcon from '../../resources/images/speaker.png'
import InfoIcon from '../../resources/images/info.png'
import NotificationsSwitchContainer from '../Lobby/HumanMinuteArea/NotificationsSwitchContainer';
import NoRepeatSwitchContainer from '../Lobby/HumanMinuteArea/NoRepeatSwitchContainer';

import {sendAmplitudeEvent} from '../../lib/amplitude';
import ConnectOptionsContainer from '../ConnectionControls/ConnectOptions/ConnectOptionsContainer';


const cutDescription = (description) => {
  if (description.length > 120) {
    return `${description.slice(0, 120)}... `;
  } else {
    return description;
  }
}

class ExperienceCard extends React.Component {

  constructor() {
    super();

    this.state = {
      activeTab: "1"
    }

    this.setActiveTab = this.setActiveTab.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onClickMore = this.onClickMore.bind(this);
  }

  setActiveTab(tab) {
    this.setState({
      activeTab: tab
    })
  }

  toggle(tab) {
    if(this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  }

  onClickMore(e) {
    e.preventDefault();
    this.props.openExperienceModal(this.props.experience);
    sendAmplitudeEvent('open experience modal', {'experience type': this.props.experience.type})
  }

	render() {
    let { userState, peerRoom, userRole, experience } = this.props;
        
		return (	

        <div className="experienceCardContainer">
          <div className="durationBadge">{parseInt(experience.totalDuration/(1000 * 60))}&nbsp;min</div>
          <Link to="/" onClick={this.onClickMore}>
            <div className="topExperienceContainer" style={{
              background: `url(${experience.backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundColor: experience.backgroundColor
            }}>
              <div>
                <p className="experienceTitle">{experience.name}</p>
                <p className="author">by {experience.author}</p>
              </div>
            </div>
          </Link>
          <div className="experienceContent">
            <div>
              <Nav tabs style={{border: "none", height: "78px", padding: "26px 0"}}>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === '1' ? "experienceTabAbout experienceTabActive" : "experienceTabAbout"}
                    onClick={() => { this.toggle('1'); }}
                  >
                    ABOUT
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === '2' ? "experienceTabOptions experienceTabActive" : "experienceTabOptions"}
                    onClick={() => { this.toggle('2'); }}
                  >
                    OPTIONS
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab} >
                <TabPane tabId="1" className="experienceTabContent">
                  <p className="experienceDescription">{cutDescription(experience.description)}&nbsp;<Link to="/" onClick={this.onClickMore}>More</Link></p>
                </TabPane>
                <TabPane tabId="2" className="experienceTabContent">
                  <div className="pb-1">
                    <NotificationsSwitchContainer experienceType={experience.type}/>
                  </div>
                  <div>
                    <NoRepeatSwitchContainer experienceType={experience.type}/>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
          <div className="connectButtonsArea">
            <ConnectOptionsContainer experience={experience} />
          </div>
        </div>
		)	
	}
} 




export default ExperienceCard;