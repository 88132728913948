import * as Actions from "../actions/selectLanguage";

const initialState = {
	languageDropDown: false,
  selectedLanguage: 'en',
  selectedFullLanguage: 'English',
  screenOpenTime: null
};

export function selectLanguage(state = initialState, action) {
  switch (action.type) {
    case Actions.OPEN_LANGUAGE_DROPDOWN:
      return {
        ...state,
        languageDropDown: true
			};
		case Actions.CLOSE_LANGUAGE_DROPDOWN:
      return {
        ...state,
        languageDropDown: false
			};
		case Actions.SET_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.data.code,
        selectedFullLanguage: action.data.title,
				languageDropDown: false
      };
    case Actions.SET_SCREEN_OPEN_TIME:
      return {
        ...state,
        screenOpenTime: action.data
			};
    default:
      return state;
  }
}