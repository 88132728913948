import React from 'react';
import textResource from '../lib/textLoader';


const NewsBanner = ({ userState, location }) => {

  return (

    <div className="newsbannerbox" style={{backgroundColor: "#234351"}}>
      <p>The Human Online platform is on a temporary pause.</p>
      <p>If you'd like to get in touch with our team, feel free to reach us at support@human.online.</p>
    </div>

  )


  // let messageText = textResource["message_of_the_day"];
  return null;
  // if (!messageText) { return null; }
  if (location.pathname !== "/") { return null; }
  if (!["present", "absent", "waiting"].includes(userState)) { return null; }

  return (

    <div className="newsbannerbox" style={{backgroundColor: "#234351"}}>
      <p>Sunday August 30 - A special event to be seen and heard. Register <a style={{fontSize: "1rem", color: "white", textDecoration: "underline"}} href="https://www.facebook.com/events/2580279625547093/" target="blank">here</a>!</p>
    </div>

  )

}

export default NewsBanner;