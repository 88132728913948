import constants from "../constants";

let stripe = null;

function getStripe() {
    if (!stripe && window.Stripe) {
        stripe = window.Stripe(constants.stripePublishableKey);
    }
    return stripe;
}


export default getStripe();