import { connect } from 'react-redux';
import Matchmaker from './Matchmaker';
import {
  setStatePresent,
  setStateEnteringRoom,
  setStateFirstTimeGuidance,
  setStateGettingMedia,
} from "../../actions/userState";
import { 
  pairUser, 
  cancelPairUser, 
  setGroupSpace,
  setRoomConfirmed,
  setConnectClickOriginUrl
} from "../../actions/peerRoom";
import { showMatchmakerTimeoutAlert } from "../../actions/uiControls";
import {setTriedConnectTrue} from "../../actions/userRole";
import { 
  getSelectedExperienceData, 
  clearSelectedExperienceData 
} from "../../actions/selectedExperience";



const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    userRole: state.userRole,
    peerRoom: state.peerRoom,
    selectedExperienceData: state.selectedExperienceData,
    realtime: state.realtime,
    connectNotifications: state.connectNotifications,
  };
};

const mapDispatchtoProps = {
	setStatePresent,
  setStateEnteringRoom,
  setStateFirstTimeGuidance,
  setStateGettingMedia,
	pairUser,
	cancelPairUser,
	showMatchmakerTimeoutAlert,
  setTriedConnectTrue,
  setGroupSpace,
  setRoomConfirmed,
  getSelectedExperienceData,
  clearSelectedExperienceData,
  setConnectClickOriginUrl
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(Matchmaker);