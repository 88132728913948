import React from "react";
import { connect } from 'react-redux';
import { setSocialLoginButtons } from "../../actions/socialLogin";
import { openAuthModal, setAuthEventsCategory } from "../../actions/uiControls";
import SignInButton from "./SignInButton";


const mapStateToProps = (state, ownProps) => {
  return {
    userRole: state.userRole,
  };
};

const mapDispatchtoProps = {
  setSocialLoginButtons,
  openAuthModal,
  setAuthEventsCategory
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(SignInButton);