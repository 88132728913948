import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Badge, Button } from 'reactstrap';
import './FacilitatorPageStyles.css';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import {sendAmplitudeEvent} from '../../../lib/amplitude';
import textResource from '../../../lib/textLoader';
import avatar from "../../../resources/images/avatar-grey-46x35px.png";
import ExperiencesHorizontalScrollContainer from '../ExperiencesHorizontalScrollContainer';


class DedicatedExperiencePage extends React.Component {

  constructor() {
    super();

    this.onClickFacilitatorLink = this.onClickFacilitatorLink.bind(this);
  }

  componentDidMount() {
    let name = this.props.match.params.facilitator.replace("-", " ")
    this.props.getFacilitatorData(name);
    sendAmplitudeEvent('open facilitator page', {'facilitator name': name})
  }

  componentWillUnmount() {
    this.props.clearFacilitatorData();
  }

  onClickFacilitatorLink(e) {
    e.preventDefault();
    let { facilitator } = this.props;
    sendAmplitudeEvent('click facilitator link', {'facilitator name': facilitator.name})
    window.open(facilitator.outboundLink, "_blank")
  }

	render() {

    let { userState, peerRoom, userRole, facilitator } = this.props;
    let experienceCategory = {
      categoryName: `Experiences by ${facilitator.name}`,
      experiences: facilitator.experiences
    }

    if (!facilitator.ready) {
      return null;
    }

    if (!facilitator.name) {
      return (
        <Row>
          <Col className="mt-4 pt-4 pb-5" xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }} >
            <h3 className="pb-3">We could not find this facilitator</h3>            
            <Link to="/" >
              <Button color="primary" size="lg">
                Back to lobby
              </Button> 
            </Link>            
          </Col>
        </Row>
      )
    }
        
		return (

		<div>
      <div className="facilitatorPage">
      <Row className="mb-5">
        <Col xs={12} xl={{size: 10, offset: 1}}>
          <Row>
            <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
              <div className="centeredBoxFacilitatorPage">
                <div className="facilitatorPageInfo pt-4">
                  <p className="facilitator-lead pb-2">
                    Facilitator
                  </p>
                  <div className="pb-4">
                    <h3 className="facilitator-name pb-1">
                      {`${facilitator.name} ${facilitator.title}`}
                    </h3>
                    {
                      !!facilitator.pronouns &&
                      <p className="facilitator-bio">
                        {`(${facilitator.pronouns})`}
                      </p>
                    }
                  </div>
                  <p className="facilitator-bio pb-4">
                    {facilitator.bio}
                  </p>
                  {
                    !!facilitator.outboundLink &&
                    <div>
                      <p className="facilitator-bio">
                        {`Want to learn more about ${facilitator.name.split(" ")[0]}?`}
                      </p>
                      <p className="facilitator-bio pb-4">
                        <a href={facilitator.outboundLink} target="_blank" onClick={this.onClickFacilitatorLink}>
                          {facilitator.outboundLink}
                        </a>
                      </p>
                    </div>
                  }
                </div>  
              </div>
            </Col>
            <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
              <div className="centeredBoxFacilitatorPage">
                <img src={facilitator.imageUrl} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      </div>
      <div style={{display: 'block', marginTop: 50}}>
        <ExperiencesHorizontalScrollContainer experienceCategory={experienceCategory}/>
      </div>
	  </div>
		)	
	}
}

export default DedicatedExperiencePage;