import React, { Component } from 'react';
import textResource from '../../../lib/textLoader';
import './GatheringInfoStyles.css';
import sendGAEvent from '../../../lib/sendGAEvent';


class KeepMeInformedButton extends Component {

  constructor() {
    super();

    this.onClickKeepMeInformed = this.onClickKeepMeInformed.bind(this);
  }

  onClickKeepMeInformed(e) {
    e.preventDefault();
    console.log("good")

    if (this.props.userRole.email) {
	  	// The server only accepts a couple of reasons for this, "lobbyButton" is one.
	    this.props.registerToGatheringsEmailList(this.props.origin);
    } else {
      this.props.setRegisterEmailsAfterLogin();
      this.props.openAuthModal();
      this.props.setAuthEventsCategory("Authentication-btnEmailMe")
      sendGAEvent('Authentication-btnEmailMe', 'Open', '');
    }
  }

  render() {
    if (this.props.userRole.solicitedEmails) { return null; }
		
    return (
      <div className="emailBox">
        <a href="/" className="emaillink" id="BtnKeepMeInformed" onClick={this.onClickKeepMeInformed}>{textResource["gathering.email_me"]}</a>
      </div>
    );
  }
}

export default KeepMeInformedButton;