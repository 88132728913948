const sendFBPixelEvent = (eventType) => {

	if (!window.fbq) {return;}
	if (window.location.pathname === "/testing/testrtc") {return;}


	return window.fbq('track', eventType);
}


module.exports = sendFBPixelEvent;