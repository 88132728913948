import moment from 'moment-timezone';
import { setSessionDataSent } from './sessionState'
import { emitAction } from '../lib/socketInit'
import sendGAEvent from '../lib/sendGAEvent';
import { setStatePresent } from './userState';
import constants from '../constants';
export const MATCHMAKER_START = "MATCHMAKER_START";
export const MATCHMAKER_FAILURE = "MATCHMAKER_FAILURE";
export const MATCHMAKER_SUCCESS = "MATCHMAKER_SUCCESS";
export const CLEAR_PEER_ROOM = "CLEAR_PEER_ROOM";
export const SET_ROOM_CANCELLED = "SET_ROOM_CANCELLED";
export const SET_ROOM_CONFIRMED = "SET_ROOM_CONFIRMED";
export const SET_HAVE_RECEIVED_THANKS = "SET_HAVE_RECEIVED_THANKS";
export const SET_HAVE_GIVEN_THANKS = "SET_HAVE_GIVEN_THANKS";
export const SET_EXPERIENCE_TYPE = "SET_EXPERIENCE_TYPE";
export const SET_NO_REPEAT_MATCHMAKER_OPTION = "SET_NO_REPEAT_MATCHMAKER_OPTION";
export const CLEAR_NO_REPEAT_MATCHMAKER_OPTION = "CLEAR_NO_REPEAT_MATCHMAKER_OPTION";
export const SET_POOL_TYPE = "SET_POOL_TYPE";
export const SET_GROUP_SPACE = "SET_GROUP_SPACE";
export const SET_VIDEO_TAG = "SET_VIDEO_TAG";
export const SET_CONNECT_CLICK_ORIGIN_URL = "SET_CONNECT_CLICK_ORIGIN_URL";
export const EXPERIENCE_INVITATION_START = "EXPERIENCE_INVITATION_START";



export function matchmakerStart(data) {
  return {
    type: MATCHMAKER_START,
    data
  };
}

export function matchmakerSuccess(data) {
  return {
    type: MATCHMAKER_SUCCESS,
    data
  };
}

export function matchmakerFailure(error) {
  return {
    type: MATCHMAKER_FAILURE,
    error
  };
}

export function clearPeerRoom() {
  return {
    type: CLEAR_PEER_ROOM
  };
}

export function setRoomCancelled() {
  return {
    type: SET_ROOM_CANCELLED
  };
}

export function setRoomConfirmed() {
  return {
    type: SET_ROOM_CONFIRMED
  };
}

export function setHaveReceivedThanks() {
  return {
    type: SET_HAVE_RECEIVED_THANKS
  };
}

export function setHaveGivenThanks() {
  return {
    type: SET_HAVE_GIVEN_THANKS
  };
}

export function setExperienceType(data) {
  return {
    type: SET_EXPERIENCE_TYPE,
    data
  };
}

export function setNoRepeatMatchmakerOption(data) {
  return {
    type: SET_NO_REPEAT_MATCHMAKER_OPTION,
    data
  };
}

export function setGroupSpace(data) {
  return {
    type: SET_GROUP_SPACE,
    data
  };
}

export function clearNoRepeatMatchmakerOption(data) {
  return {
    type: CLEAR_NO_REPEAT_MATCHMAKER_OPTION,
    data
  };
}

export function setPoolType(poolType) {
  return {
    type: SET_POOL_TYPE,
    poolType
  };
}

export function setVideoTag(data) {
  return {
    type: SET_VIDEO_TAG,
    data
  };
}

export function setConnectClickOriginUrl(data) {
  return {
    type: SET_CONNECT_CLICK_ORIGIN_URL,
    data
  };
}

export function experienceInvitationStart() {
  return {
    type: EXPERIENCE_INVITATION_START
  };
}


export function sendGAEventForDigestingState(category, action, label, value) {
  return (dispatch, getState) => {
    if(getState().selectedExperienceData.data.type === "oneminute") {
      sendGAEvent(category, action, label, value);
    }
  }
}

export function handleMatchmakerSocketEvent(data) {
  return (dispatch, getState) => {
    if(data.type === 'connectRecieved'){
      if(data.roomID){
        dispatch(matchmakerSuccess(data));
      }
    }
    if(data.type === 'connectRecievedError' || data.type === 'cancelRecievedError'){
      dispatch(matchmakerFailure(data))
    }
    if(data.type === 'cancelRecieved' && getState().userState === 'present'){
      dispatch(clearPeerRoom())
    }
    if(data.type === 'updatesessionRecieved'){
      dispatch(setSessionDataSent())
    }
  }
}

export function handleDisconnectSocketEvent() {
  return (dispatch, getState) => {
    if(getState().userState === 'waiting'){
      dispatch(clearPeerRoom())
      dispatch(setStatePresent())
      dispatch(matchmakerFailure('error'))
    }
  }
}


export function pairUser({experienceType, groupSpace, noRepeat, poolType}) {
  let clickTime = Date.now();
  let zone = moment.tz.guess();
  let timeZone = `${moment.tz(zone).format('zz')}`;
  let token = localStorage.getItem("token")
  let { appName, language, appCodeName, userAgent } = navigator
  return (dispatch) => {
    dispatch(matchmakerStart({experienceType}));
    dispatch(emitAction( 'connectClick', {experienceType,groupSpace,clickTime,timeZone,token,appName,language,appCodeName,userAgent,noRepeat,poolType}, {onError: matchmakerFailure.bind(this, true)}))
  };
}

export function cancelPairUser({experienceType, groupSpace, noRepeat, poolType}) {
  let cancelClickTime = Date.now();
  let token = localStorage.getItem("token")
  return dispatch => {
    dispatch(setRoomCancelled());
    dispatch(emitAction( 'cancelClick', {experienceType,groupSpace,noRepeat,poolType,cancelClickTime,token},{onError: clearPeerRoom.bind(this)} ))
  };
}

export function thanksUser(roomID,position) {
  let token = localStorage.getItem("token")
  return (dispatch) => {
    dispatch(setHaveGivenThanks())
    dispatch(emitAction( 'thanksClick', {roomID,position,token} ))
  };
}





export function createExperienceInvitation(type) {
  const config = {
    method: "POST",
    headers: {
      "Authorization": `bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `type=${type}`
  };

  return (dispatch) => {
    dispatch(experienceInvitationStart())
    return fetch(`${constants.baseUrl}/api/v1/experiences/create-invitation`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        dispatch(matchmakerSuccess(response))
      })
      .catch(err => {
        console.error("getExperiences error: ", err);
      });
  }
}


export function cancelExperienceInvitation(roomID) {
  const config = {
    method: "POST",
    headers: {
      "Authorization": `bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `roomID=${roomID}`
  };

  return (dispatch) => {
    dispatch(clearPeerRoom())
    return fetch(`${constants.baseUrl}/api/v1/experiences/cancel-invitation`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
      })
      .catch(err => {
        console.error("getExperiences error: ", err);
      });
  }
}
