import React from 'react';
import textResource from '../../lib/textLoader';
import { Row, Col } from 'reactstrap';
import SafeSpaceLogo from "../../resources/images/safe_space.png"
import JustBeLogo from "../../resources/images/just_be.png"
import AuthenticLogo from "../../resources/images/authentic_connection.png"

const WelcomePotrait = ({ userState, userRole, location, currentGathering }) => {

  if (!["present", "absent", "waiting", "firstTimeGuidance"].includes(userState)) { return null; }
  if (location.pathname !== "/") { return null; }
  if(!userRole.ready){
    return null;
  }

  if (userRole.email) {
    return null;
    if (userRole.role === "guest") { return null; }
    if(currentGathering){
      return null;
    }
    
    return <div className="col-sm-12 welcomecontainer mt-4 pt-2" >
      <h1 className="pb-3"> {textResource["potrait.welcome"] + userRole.name}</h1>
    </div>;
  }

 
  return (
    <div style={{background: "#F8E9DE", color: "#794d4f"}}>
      <div className="bannerbox">
        <img src="https://human-online.s3.eu-west-2.amazonaws.com/banner-portrait-v1-4.png" />
        <div className="bannertext">
          <h1> {textResource["lobby.landing_message_title"]}</h1>
          <p>
            {textResource["lobby.landing_message1"]}&nbsp;{textResource["lobby.landing_message2"]}
          </p>
        </div>
      </div>
      <div className="drawingsGradient">
        <Row>
          <Col sm={{ size: 10, offset: 1 }}>
            <Row className="lineDrawingRow">
              <Col sm={4}>
                <div className="lineDrawingContainer">
                  <img src={SafeSpaceLogo} />
                  <p className="spaceTitle">{textResource["welcome_potrait.safe_space.title"]}</p>
                  <p className="spaceText">{textResource["welcome_potrait.safe_space.content"]}</p>
                </div>  
              </Col>
              <Col sm={4}>
                <div className="lineDrawingContainer">
                  <img src={JustBeLogo} />
                  <p className="spaceTitle">{textResource["welcome_potrait.just_be.title"]}</p>
                  <p className="spaceText">{textResource["welcome_potrait.just_be.content"]}</p>
                </div> 
              </Col>
              <Col sm={4}>
                <div className="lineDrawingContainer">
                  <img src={AuthenticLogo} />
                  <p className="spaceTitle">{textResource["welcome_potrait.authentic_connections.title"]}</p>
                  <p className="spaceText">{textResource["welcome_potrait.authentic_connections.content"]}</p>
                </div>  
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WelcomePotrait;
