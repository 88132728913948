import React from 'react';
import CameraGeneralFailure from './CameraGeneralFailure';
import CameraNotAllowed from './CameraNotAllowed';


class CameraFailure extends React.Component {

	componentDidMount() {
		this.props.sendFailureData(this.props.cameraFailureReason);
	}

	render() {
		return (
			<div>
				{ (this.props.cameraFailureReason === "NotAllowedError") ? <CameraNotAllowed /> : <CameraGeneralFailure /> }
			</div>
		)		
	}


}

export default CameraFailure;