import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';



const EmailTooltip = ({userRole}) => {
	if (!userRole.email) {
		return null;
	}

  return (
    <UncontrolledTooltip placement="auto" target="BtnMenu" style={{wordBreak: "keep-all" }}>
      {userRole.email}
    </UncontrolledTooltip>
  )
}

export default EmailTooltip;