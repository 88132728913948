import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Button,
  Alert
} from 'reactstrap';
import { Link } from 'react-router-dom';
import NextRegularGatheringContainer from './GatheringsArea/NextRegularGatheringContainer';
import NextSpecialGatheringContainer from './GatheringsArea/NextSpecialGatheringContainer';
import './TopCarouselStyles.css';



class TopCarousel extends React.Component {

	constructor() {
		super();

		this.state = {
			activeIndex: 0,
			animating: false
		}

		this.setActiveIndex = this.setActiveIndex.bind(this);
		this.setAnimating = this.setAnimating.bind(this);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
	}

	setActiveIndex(index) {
		this.setState({
			activeIndex: index
		})
	}

	setAnimating(animating) {
		this.setState({
			animating
		})
	}

  next() {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setActiveIndex(nextIndex);
  }

  previous() {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
    this.setActiveIndex(nextIndex);
  }

  goToIndex(newIndex) {
    if (this.state.animating) return;
    this.setActiveIndex(newIndex);
  }


  render() {
  	const slides = this.props.items.map((item) => {
  		return (
  			<CarouselItem
				  onExiting={() => this.setAnimating(true)}
				  onExited={() => this.setAnimating(false)}
				  key={item}
				  tag="div"
				  className="custom-carousel-item"
				>
				  {buildCarouselItemFromType(item, this.props)}
				</CarouselItem>
  		)
  	})


	  return (
	  	<div className="topCarousel">
		    <Carousel
		      activeIndex={this.state.activeIndex}
		      next={this.next}
		      previous={this.previous}
		    >
		      <CarouselIndicators items={this.props.items} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} className="custom-carousel-indicator"/>
		      {slides}

		    </Carousel>
		  </div>
	  );  	
  }

}


function buildCarouselItemFromType(type, props) {
  switch (type) {
      case "nextRegularGathering":
          return (
          	<NextRegularGatheringContainer
  	          currentGathering={props.currentGathering}
  	          nextRegularGathering={props.nextRegularGathering}
  	          nextSpecialGathering={props.nextSpecialGathering}
  				  />
          )      
      case "nextSpecialGathering":
          return (
					  <NextSpecialGatheringContainer
		          currentGathering={props.currentGathering}
		          nextRegularGathering={props.nextRegularGathering}
		          nextSpecialGathering={props.nextSpecialGathering}
					  />
          );
      case "emailList":
          return (
		        <div className="next-gatherings">
		          <h5 className="every-day">
		            Want to stay informed about Human Online?
		          </h5>
		          {
		          	props.registeredToEmails ?
		          	<h5 className="next-at pt-4">
				          Thank you. Email preference saved.
		          	</h5> :
		          	<h5 className="next-at pt-4">
				          <a href="#" onClick={(e) => {
				          	e.preventDefault();
				          	props.registerToGatheringsEmailList("topCarousel");
				          }}>
										Send me occasional emails >
									</a>		          	
		          	</h5>
		          }        
		        </div>
          );      			
      case "contributingMember1":
          return (
		        <div className="next-gatherings">
		          <h5 className="every-day">
		            Explore relational experiences from expert facilitators
		          </h5>
		          <h5 className="next-at pt-4">
								<Link to="/membership">
									Learn more >
								</Link>            
		          </h5>
		        </div>
          );
      case "contributingMember2":
          return (
		        <div className="next-gatherings">
		          <h5 className="every-day">
		            Support real, heartfelt connection between people
		          </h5>
		          <h5 className="next-at pt-4">
								<Link to="/membership">
									Learn more >
								</Link>            
		          </h5>		          
		        </div>
          );
      default:
          return null;
  }
}




export default TopCarousel;