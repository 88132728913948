class SignalingService {

	constructor(realtime, channelName) {

		// Initilize class variables
		this.channel = realtime.channels.get(channelName);

		// Functions that manage signalling

		this.sendHelloMessage = this.sendHelloMessage.bind(this);
		this.sendThankyouMessage = this.sendThankyouMessage.bind(this);
		this.sendAddedCircleMessage = this.sendAddedCircleMessage.bind(this);
		this.sendReadyMessage = this.sendReadyMessage.bind(this);
		this.sendCloseMessage = this.sendCloseMessage.bind(this);
		this.sendSDP = this.sendSDP.bind(this);
		this.sendIceCandidate = this.sendIceCandidate.bind(this);

		this.setHelloListener = this.setHelloListener.bind(this);
		this.setThankyouListener = this.setThankyouListener.bind(this);
		this.setAddedCircleListener = this.setAddedCircleListener.bind(this);
		this.setReadyListener = this.setReadyListener.bind(this);
		this.setCloseListener = this.setCloseListener.bind(this);
		this.setSDPListener = this.setSDPListener.bind(this);
		this.setIceCandidateListener = this.setIceCandidateListener.bind(this);

		this.closeChannel = this.closeChannel.bind(this);
	}

	closeChannel() {
		this.channel.unsubscribe();
		this.channel.detach((err) => {
			if (err) { console.log(err); }
		})
	}

	sendHelloMessage() {
		this.channel.publish('hello', null);
	}

	sendThankyouMessage() {
		this.channel.publish('thankyou', null);
	}

	sendAddedCircleMessage() {
		this.channel.publish('addedcircle', null);
	}

	sendReadyMessage() {
		this.channel.publish('ready', null);
	}

	sendCloseMessage(byTimeout = false) {
		let data = JSON.stringify({ byTimeout });
		this.channel.publish('close', data);
	}

	setHelloListener(onHelloMessage) {
		this.channel.subscribe('hello', () => {
			onHelloMessage();
		})		
	}

	setThankyouListener(onThankyouMessage) {
		this.channel.subscribe('thankyou', () => {
			onThankyouMessage();
		})		
	}

	setAddedCircleListener(onAddedCircleMessage) {
		this.channel.subscribe('addedcircle', () => {
			onAddedCircleMessage();
		})		
	}

	setReadyListener(onReadyMessage) {
		this.channel.subscribe('ready', () => {
			onReadyMessage();
		})		
	}

	setCloseListener(onCloseMessage) {
		this.channel.subscribe('close', (message) => {
			let byTimeout = JSON.parse(message.data).byTimeout;
			onCloseMessage(byTimeout);
		})		
	}

	sendSDP(data) {		
		this.channel.publish('sdp', data);
	}

	sendIceCandidate(data) {
		this.channel.publish('icecandidate', data);
	}


	setSDPListener(onReceiveSDP) {
		this.channel.subscribe('sdp', (message) => {
			let sdp = JSON.parse(message.data).sdp;
			onReceiveSDP(sdp);
		})		
	}

	setIceCandidateListener(onReceiveIceCandidate) {
		this.channel.subscribe('icecandidate', (message) => {
			let candidate = JSON.parse(message.data).candidate;
			onReceiveIceCandidate(candidate);
		})			
	}

}

let SignalingInstance = null;


export function buildSignalingService(realtime, channelName) {

	if (SignalingInstance) {
		SignalingInstance.closeChannel();
		SignalingInstance = null;
	}

	SignalingInstance = new SignalingService(realtime, channelName);

	return {
		sendHelloMessage: SignalingInstance.sendHelloMessage,
		setHelloListener: SignalingInstance.setHelloListener,
		closeChannel: SignalingInstance.closeChannel
	}
}

export function getSignalingService() {

	if (!SignalingInstance) {
		return null;
	}

	return {
		setSDPListener: SignalingInstance.setSDPListener,
		setIceCandidateListener: SignalingInstance.setIceCandidateListener,
		sendThankyouMessage: SignalingInstance.sendThankyouMessage,
		sendAddedCircleMessage: SignalingInstance.sendAddedCircleMessage,
		sendReadyMessage: SignalingInstance.sendReadyMessage,
		sendCloseMessage: SignalingInstance.sendCloseMessage,
		sendSDP: SignalingInstance.sendSDP,
		sendIceCandidate: SignalingInstance.sendIceCandidate,
		setThankyouListener: SignalingInstance.setThankyouListener,
		setAddedCircleListener: SignalingInstance.setAddedCircleListener,
		setReadyListener: SignalingInstance.setReadyListener,
		setCloseListener: SignalingInstance.setCloseListener,
		closeChannel: SignalingInstance.closeChannel,
	}
}
