import { connect } from 'react-redux';
import SocialAuthModal from './SocialAuthModal';
import { 
	openSocialAuthModal, 
	closeSocialAuthModal 
} from "../../actions/uiControls";


const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    userState: state.userState,
    socialAuthModalOpen: state.uiControls.socialAuthModalOpen
  };
};

const mapDispatchtoProps = {
	openSocialAuthModal,
	closeSocialAuthModal
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(SocialAuthModal);