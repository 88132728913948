import constants from "../constants";
import { getSelectedExperienceDataSuccess } from "./selectedExperience";

export const GET_EXPERIENCES_START = "GET_EXPERIENCES_START";
export const GET_EXPERIENCES_SUCCESS = "GET_EXPERIENCES_SUCCESS";
export const GET_EXPERIENCES_FAILURE = "GET_EXPERIENCES_FAILURE";

export function getExperiencesStart() {
    return {
      type: GET_EXPERIENCES_START
    };
  }

export function getExperiencesSuccess(data) {
    return {
        type: GET_EXPERIENCES_SUCCESS,
        data
    };
}

export function getExperiencesFailure(error) {
    return {
        type: GET_EXPERIENCES_FAILURE,
        error
    };
}

export function getExperiences(experienceStatus) {
    const config = {
      method: "POST",
      headers: {
        "Authorization": `bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `experienceStatus=${experienceStatus}`
    };
    
    return (dispatch) => {
      dispatch(getExperiencesStart())
      return fetch(`${constants.baseUrl}/api/v1/experiences`, config)
        .then(res => res.json())
        .then(response => {
          if (response.error) {
            throw new Error(`Error: ${response.error}`);
          }
          dispatch(getExperiencesSuccess(response))
        })
        .catch(err => {
          console.error("getExperiences error: ", err);
          dispatch(getExperiencesFailure(err))
        });
    }
}

export function getExperience(type) {
  const config = {
    method: "POST",
    headers: {
      "Authorization": `bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `type=${type}`
  };

  return (dispatch) => {
    return fetch(`${constants.baseUrl}/api/v1/experiences/getexperience`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        dispatch(getSelectedExperienceDataSuccess(response))
      })
      .catch(err => {
        console.error("getExperiences error: ", err);
      });
  }
}

