import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import sendGAEvent from '../../../lib/sendGAEvent';
import {sendAmplitudeEvent} from '../../../lib/amplitude';
import textResource from '../../../lib/textLoader';
import Spinner from "../../elements/Spinner"
import PauseIcon from "../../elements/PauseIcon"
import { endConnectionReasons } from '../../../actions/endConnectionReason';

var timeout = null;
var interval = null;
let delayTime = 15000;
var oneSecondDelayTimeout = null;

class Guidelines extends React.Component {
	constructor(){
		super();
		this.state = {
			index: 0,
			timeScreenOpen: null,
			buttonActivated: false,
			activateTime: 1,
			pauseLoader: false
		}
	}

	componentDidMount() {
		let { userRole } = this.props;
		this.setState({ timeScreenOpen: new Date() })
		if((userRole.completedHumanMinutes >= 5) && (userRole.thanksReceived >= 5) && (userRole.completedHumanMinutes >= 2*userRole.sessionManuallyEnded) ) {

			delayTime = 5000
		}

		this.setButtonVisibilityTimeout(delayTime)
		this.setButtonVisibilityInterval()
	}

	componentDidUpdate(prevProps) {
		if(!prevProps.languageDropDown && this.props.languageDropDown) {
			clearInterval(interval)
			clearTimeout(timeout)
			this.setState({ pauseLoader: true })
		}
		if(prevProps.languageDropDown && !this.props.languageDropDown) {
			oneSecondDelayTimeout = setTimeout(() => {

				clearTimeout(oneSecondDelayTimeout)
				this.setButtonVisibility()
				this.setState({ pauseLoader: false })
			}, 1000)
		}
	}

	setButtonVisibility = () => {
		if(this.props.languageDropDown){
			this.props.closeLanguageDropDown();
		}
		if((this.state.activateTime * 1000) < delayTime) {
			this.setButtonVisibilityInterval()
			this.setButtonVisibilityTimeout(delayTime - (this.state.activateTime *1000))
		}
	}

	setButtonVisibilityInterval = () => {
		interval = setInterval(() => {
			if((this.state.activateTime * 1000) === delayTime) {

				return clearInterval(interval)
			}
			this.setState({ activateTime: this.state.activateTime + 1 })
		}, 1000);
	}

	setButtonVisibilityTimeout = (time) => {
		timeout = setTimeout(() => {
			clearTimeout(timeout)
			this.setState({ buttonActivated: true })
		}, time);
	}

	componentWillUnmount() {
		clearTimeout(timeout)
		clearTimeout(oneSecondDelayTimeout)
		delayTime = 15000;
	}

	onExpandGuidelines = (index, action) => {
		sendGAEvent('Human Minute', action, this.getSeconds())
		this.setState({ index })
	}

	onFollowGuidance = () => {
		if(!this.state.buttonActivated) {
			sendGAEvent('Human Minute', 'Attempt to skip delay Guidance 1', this.getSeconds())
		}else {
			sendGAEvent('Human Minute', 'Accept Guidance 1', this.getSeconds())
			sendAmplitudeEvent('accept guidance', {'experience type': this.props.experienceType})
			this.props.acceptedGuidance()
			this.props.setStateWaiting()
		}
	}

	onRejectGuidance = () => {
		if(this.state.buttonActivated) {
			sendGAEvent('Human Minute', 'Reject Guidance 1', this.getSeconds())
			this.props.rejectedGuidance()
			this.props.setStatePresent();
		} else {
			sendGAEvent('Human Minute', 'Attempt to skip delay reject Guidance 1', this.getSeconds())
			sendAmplitudeEvent('reject guidance', {'experience type': this.props.experienceType})
		}
	}

	getSeconds() {
		var duration = Math.abs(new Date() - this.state.timeScreenOpen) / 1000;
		return parseInt(duration)
	}

	render() {
		let { index, buttonActivated, activateTime } = this.state;
		let { selectedLanguage, languageDropDown } = this.props;
		
		return(
			<div>
				<div>
					<p className="mt-3 guidelinesText">{textResource[`guidelines.title.${selectedLanguage}`]}</p>
					<div className="guidelines">
						<p><b>{textResource[`guidelines.stay_still.title.${selectedLanguage}`]}</b> - <span style={index === 1 ? {} : {textDecoration: 'underline', cursor: 'pointer'}} onClick={this.onExpandGuidelines.bind(this, 1, 'Expand Be Still')}>{index === 1? textResource[`guidelines.stay_still.content.${selectedLanguage}`] : textResource[`guidelines.why.text.${selectedLanguage}`]} </span></p>
						<p><b>{textResource[`guidelines.avoid_gestures.title.${selectedLanguage}`]}</b> - <span style={index === 2 ? {} : {textDecoration: 'underline', cursor: 'pointer'}} onClick={this.onExpandGuidelines.bind(this, 2, 'Expand No Signs')}>{index === 2? textResource[`guidelines.avoid_gestures.content.${selectedLanguage}`] :textResource[`guidelines.why.text.${selectedLanguage}`]}</span></p>
						<p><b>{textResource[`guidelines.stay_present.title.${selectedLanguage}`]}</b> - <span style={index === 3 ? {} : {textDecoration: 'underline', cursor: 'pointer'}} onClick={this.onExpandGuidelines.bind(this, 3, 'Expand Stay')}>{index === 3? textResource[`guidelines.stay_present.content.${selectedLanguage}`] :textResource[`guidelines.why.text.${selectedLanguage}`]}</span></p>
						<p className="text-center pt-3">{textResource[`guidelines.content.${selectedLanguage}`]}</p>
					</div>
				</div>
				<div className="guidanceButtonContainer pt-4">
					<div className="buttons">
						<Button id="BtnNotFollowGuidelines" size="lg" className={`BtnNotFollowGuidelines mb-4`} color="secondary" onClick={this.onRejectGuidance} style={{opacity: 1/(((delayTime/1000) + 1) - this.state.activateTime)}}>
							{textResource[`guidelines.reject_guidance_btn.${selectedLanguage}`]}
						</Button>
						{!buttonActivated && (!languageDropDown && !this.state.pauseLoader) && 

							<span className="spincontainer mb-3">
								<Spinner />
							</span>
						}
						{(activateTime < 15 && (languageDropDown || this.state.pauseLoader) ) && 
							<div className="spincontainer mb-3 pt-1">
								<PauseIcon />
							</div>
						}
						<Button id="BtnFollowGuidelines" size="lg" className={'BtnFollowGuidelines mb-4'} color="secondary" onClick={this.onFollowGuidance} style={{opacity: 1/(((delayTime/1000) + 1) - this.state.activateTime)}}>
							{textResource[`guidelines.accept_guidance_btn.${selectedLanguage}`]}
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default Guidelines;