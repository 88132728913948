import React from 'react';
import constants from '../../constants';

// The only way you can start being present is by opening the lobby. If you load the website on the About page, you're not present. Also, you cannot activate notifications from outside the lobby.
// Once I've become present, the only ways to stop being present are to close the website or send an absent message. If user has requested notifications, he should never send an absent message.



export default class PresenceSignalling extends React.Component {

	constructor() {
		super();

		this.setStatePresentIfApplies = this.setStatePresentIfApplies.bind(this);
		this.setStateAbsentIfApplies = this.setStateAbsentIfApplies.bind(this);
		this.setPresenceTimeout = this.setPresenceTimeout.bind(this);
		this.setPresenceInterval = this.setPresenceInterval.bind(this);
		this.visibilityListener = this.visibilityListener.bind(this);
		this.activityListener = this.activityListener.bind(this);
	}

	componentDidMount() {
		let {emitPeopleStatus} = this.props;

		// Start presence timeout on mount
		this.setStatePresentIfApplies();
		this.setPresenceTimeout();

		// Restart presence timeout on any click
		document.addEventListener("click", this.activityListener);

		// Update presence if tab visibility changes
		document.addEventListener("visibilitychange", this.visibilityListener);

		emitPeopleStatus('present');
		this.setPresenceInterval()
	}

	componentDidUpdate(prevProps){
		let {emitPeopleStatus} = this.props
		// Send presence messages to the signalling server
		if (prevProps.userState === "absent" && this.props.userState !== "absent") {
			emitPeopleStatus('present')
		} 
		// if (prevProps.userState !== "absent" && this.props.userState === "absent") {
		// 	emitPeopleStatus('absent')
		// }
	}

  componentWillUnmount() {
		let {emitPeopleStatus} = this.props;
    if (this.presenceTimeout) {
			clearTimeout(this.presenceTimeout);
			this.presenceTimeout = null;
			clearInterval(this.presenceInterval);
			this.presenceInterval = null;
    }
    document.removeEventListener("click", this.activityListener);
    document.removeEventListener("visibilitychange", this.visibilityListener);

    // if (!this.props.requestedNotifications) {
    // 	emitPeopleStatus('absent')
    // }    
    this.setStateAbsentIfApplies();
  }

  setPresenceTimeout() {
		this.presenceTimeout = setTimeout(() => {
			this.setStateAbsentIfApplies();
		}, constants.timeControls["presenceTimeout"]);	
  }

  setPresenceInterval() {
		this.presenceInterval = setInterval(() => {
			if (this.props.userState !== "absent" || this.props.requestedNotifications.length) {
				this.props.emitPeopleStatus("present")
			}
		}, 4000);	
  }  

	visibilityListener() {
		if (document.hidden) {
			this.setStateAbsentIfApplies();
		} else {
			this.setStatePresentIfApplies();
		}	
	}

	activityListener() {
		clearTimeout(this.presenceTimeout);
		this.setStatePresentIfApplies();
		this.setPresenceTimeout();		
	}

	setStatePresentIfApplies() {
		let { userState, setStatePresent } = this.props;

		if (userState === "absent") {
			setStatePresent();
		}		
	}

	setStateAbsentIfApplies() {
		let { userState, setStateAbsent, requestedNotifications } = this.props;

		if (userState === "present" && !requestedNotifications.length) {
			setStateAbsent();
		}		
	}


  render() {
    return null;
  }
}