import React from 'react';
import './AccountPagesStyles.css';
import textResource from '../../lib/textLoader';
import { Link } from 'react-router-dom';
import { Button, Alert, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import LayoutWrapper from '../elements/LayoutWrapper';
import VisaImg from "../../resources/images/default_card.png";

class Profile extends React.Component {

	constructor() {
		super();

		this.membershipTitle = this.membershipTitle.bind(this);
		this.stateOfMembershipText = this.stateOfMembershipText.bind(this);
	}

	componentWillUnmount() {
		this.props.clearChanges();
	}

	membershipTitle({
		noEstablishedSubscription,
		subscriptionIncompleteInitialPaymentFailed,
		subscriptionIsInTrialPeriod,
		paymentFailedAfterTrialPeriod,
		recurringPaymentIsPending
	}) {

		let { userRole, subscriptionState } = this.props;
		let {
			subscriptionAmount,
			subscriptionCancelsAt
		} = userRole.subscription;

		if (noEstablishedSubscription) {
			return "Free account";
		}
		if (subscriptionIsInTrialPeriod) {
			return `Contributing member: ${subscriptionAmount} €/month - On trial period`
		}
		if (subscriptionCancelsAt) {
			return `Contributing member: ${subscriptionAmount} €/month - Cancelled`
		}
		return `Contributing member: ${subscriptionAmount} €/month`

	}

	stateOfMembershipText({
		noEstablishedSubscription,
		subscriptionIncompleteInitialPaymentFailed,
		subscriptionIsInTrialPeriod,
		paymentFailedAfterTrialPeriod,
		recurringPaymentIsPending
	}) {
		let { userRole, subscriptionState } = this.props;
		let {
			subscriptionAmount,
			subscriptionCancelsAt,
			trialEnds
		} = userRole.subscription;

		if (noEstablishedSubscription) {
			return null;
		}
		if (subscriptionIncompleteInitialPaymentFailed) {
			return <p>Your membership is pending initial payment.</p>
		}
		if (subscriptionIsInTrialPeriod) {
			if (trialEnds) {
				return <p>{`Your free trial ends on ${new Date(trialEnds).toDateString()}.`}</p>
			}
			return <p>You are currently on a free trial period.</p>
		}
		if (paymentFailedAfterTrialPeriod || recurringPaymentIsPending) {
			return <p>Your membership requires action. We were unable to process your payment.</p>
		}
		if (subscriptionCancelsAt) {
			return <p>
				{`Your membership is cancelled. You can continue to use the Contributing member experiences until ${new Date(subscriptionCancelsAt)}`}
			</p>
		}
		return null;


	}

	render() {

		let { userRole, subscriptionState } = this.props;
		let {
			activeSubscription,
			subscriptionAmount,
			initialPaymentSucceeded,
			trialUsed,
			pendingInvoice,
			subscriptionCancelsAt
		} = userRole.subscription;

		let noEstablishedSubscription = !!(!subscriptionAmount || (trialUsed && !initialPaymentSucceeded));
		let subscriptionIncompleteInitialPaymentFailed = !!(trialUsed && pendingInvoice && !initialPaymentSucceeded);
		let subscriptionIsInTrialPeriod = !!(activeSubscription && !initialPaymentSucceeded);
		let paymentFailedAfterTrialPeriod = !!(!trialUsed && pendingInvoice && !initialPaymentSucceeded);
		let recurringPaymentIsPending = !!(initialPaymentSucceeded && pendingInvoice);
		let subscriptionIsActiveAndGoodStanding = !!(activeSubscription && initialPaymentSucceeded && !pendingInvoice && !subscriptionCancelsAt);

		return (	
			<LayoutWrapper>
			  	<h1 className="pb-3">{textResource["profile.title"]}</h1>
			  	<div>
				    {
				    	this.props.passwordChanged 
				    	? (<Alert color="success">
						      {textResource["profile.password_change_confirmation"]}
						    </Alert>)
				    	: null
				    }
				  </div>
				  <Row>
		        <Col xs={12} md={3}>
		        	<label>{textResource["profile.email"]}</label>
			      </Col>
		        <Col xs={12} md={9}>
		        	<p className="user-email">{userRole.email}</p>
			      </Col>					  	
				  </Row>
				  <hr/>
				  <Row>
		        <Col xs={12} md={3}>
		        	<label>{textResource["profile.name"]}</label>
			      </Col>
		        <Col xs={12} md={9}>
		        	<p>{userRole.name}</p>
			      </Col>					  	
				  </Row>
				  <hr/>
				  <Row>
		        <Col xs={12} md={3}>
		        	<label>{textResource["profile.password_label"]}</label>
			      </Col>
		        <Col xs={12} md={9}>
		        {textResource["profile.password_value"]}
							&nbsp;&nbsp;&nbsp;<Link to="/account/changepassword" className="btn btn-secondary" >
					  			{textResource["profile.password.edit_link"]}
					    </Link>
			      </Col>					  
				  </Row>  	
				  <hr/>
				  <Row>
		        <Col xs={12} md={3}>
		        	<label>My Circle</label>
			      </Col>
		        <Col xs={12} md={9}>
		        	<p>
		        		{`There are ${userRole.myCircleMatched} people in your circle`}
							</p>
			      </Col>					  
				  </Row>  	
				  {/*
				  <hr/>
				  <Row>
						<Col xs={12} md={3}>
							<label>{textResource["profile.subscription"]}</label>
						</Col>
						<Col xs={12} md={9}>

							<p className="user-membership">
								{
									this.membershipTitle({
										noEstablishedSubscription,
										subscriptionIncompleteInitialPaymentFailed,
										subscriptionIsInTrialPeriod,
										paymentFailedAfterTrialPeriod,
										recurringPaymentIsPending
									})
								}
							</p>
							
						</Col>			  	
				  </Row>
				  <Row>
						<Col xs={0} md={3}>
							
						</Col>
						<Col xs={12} md={9}>
							{
								this.stateOfMembershipText({
									noEstablishedSubscription,
									subscriptionIncompleteInitialPaymentFailed,
									subscriptionIsInTrialPeriod,
									paymentFailedAfterTrialPeriod,
									recurringPaymentIsPending
								})
							}								
							<div>
								{
									noEstablishedSubscription &&
									<Row className="pb-3">
										<Col>
											<Link to="/membership" className="btn btn-secondary" style={{width: "15rem"}}>
													{
														subscriptionAmount ? 
														"Complete membership" :
														"Explore membership"
													}
											</Link>
										</Col>
									</Row>
								}
								{
									(paymentFailedAfterTrialPeriod || recurringPaymentIsPending) &&
									<Row className="pb-3">
										<Col>
											<Link to="/membership/make-payment" className="btn btn-secondary" style={{width: "15rem"}}>
													Make payment
											</Link>
										</Col>
									</Row>
								}
								{
									(subscriptionIsInTrialPeriod || subscriptionIsActiveAndGoodStanding) &&
									<Row className="pb-3">
										<Col>
											<Link to="/membership/update" className="btn btn-secondary" style={{width: "15rem"}}>
													Update membership
											</Link>
										</Col>
									</Row>
								}
								{
									(subscriptionIsInTrialPeriod || paymentFailedAfterTrialPeriod || subscriptionIsActiveAndGoodStanding || recurringPaymentIsPending) &&
									<Row className="pb-3">
										<Col>
											<Link to="/membership/payment-methods" className="btn btn-secondary" style={{width: "15rem"}}>
													Manage payment method
											</Link>
										</Col>
									</Row>
								}
								{
									(subscriptionIsInTrialPeriod || paymentFailedAfterTrialPeriod || subscriptionIsActiveAndGoodStanding || recurringPaymentIsPending) &&
									<Row className="pb-3">
										<Col>
											<Link to="/membership/cancel" className="btn btn-secondary" style={{width: "15rem"}}>
													Cancel membership
											</Link>
										</Col>
									</Row>
								}
								{
									(!!subscriptionCancelsAt) &&
									<Row className="pb-3">
										<Col>
											<Link to="/membership/recover" className="btn btn-secondary" style={{width: "15rem"}}>
													Recover membership
											</Link>
										</Col>
									</Row>
								}
							</div>
							
						</Col>	
				  </Row>
				  */}
					<hr/>

				  <div className="mt-5">
					<Link to="/account/deleteaccount">
						<Button color="secondary">
							{textResource["profile.delete_account_btn"]}
						</Button>
					</Link>
				</div>
			</LayoutWrapper>
		)	
	}
}

export default Profile;


