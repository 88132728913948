import React from 'react';
import './ExperienceModalStyles.css';


class ExperienceVideo extends React.Component{

	render() {
		return (
      <div className="mt-4 mb-1 experienceVideo">
          <iframe src={this.props.videoUrl} width="480" height="270" frameBorder="0" allow="fullscreen" allowFullScreen></iframe>
      </div>
		)
	}
}

export default ExperienceVideo;