import { connect } from 'react-redux';
import Lobby from './Lobby';
import {
  openAuthModal,
  setAuthModalForm
} from "../../actions/uiControls";
import {
  checkHash
} from "../../actions/registeringFromInvitation";
import { loginGraphApi } from '../../actions/socialLogin';
import constants from '../../constants';
import { logout } from "../../actions/userRole";

function isCurrentGathering(gathering) {
  const now = Date.now();
  return gathering.startTimeMillSec <= now && gathering.endTimeMillSec > now;
}

function isRecentlyEndedGathering(gathering) {
  const now = Date.now();
  return gathering.endTimeMillSec <= now && gathering.endTimeMillSec > now - constants.gatherings.gatheringRecentlyFinishedTime;
}

function isNextRegularGathering(gathering) {
  return gathering.isRegular && gathering.startTimeMillSec > Date.now();
}

function isNextSpecialGathering(gathering) {
  return !gathering.isRegular && gathering.startTimeMillSec > Date.now();
}

const mapStateToProps = (state) => {
  const { gatherings } = state.gatheringsInfo;
  let currentGathering;
  let mostRecentlyEndedGathering;
  let nextRegularGathering;
  let nextSpecialGathering;

  for (const gathering of gatherings) {
    if (isCurrentGathering(gathering)) {
      currentGathering = currentGathering || gathering;
    } else if (isRecentlyEndedGathering(gathering)) {
      mostRecentlyEndedGathering = gathering;
    } else if (isNextRegularGathering(gathering)) {
      nextRegularGathering = nextRegularGathering || gathering;
    } else if (isNextSpecialGathering(gathering)) {
      nextSpecialGathering = nextSpecialGathering || gathering;
    }
  }
  return {
    userRole: state.userRole,
    userState: state.userState,
    registeringFromInvitation: state.registeringFromInvitation,
    removeToken: state.socialLogin.removeToken,
    peerRoom: state.peerRoom,
    currentGathering,
    mostRecentlyEndedGathering,
    nextRegularGathering,
    nextSpecialGathering,
  };
};


const mapDispatchtoProps = (dispatch) => {
  return {
    checkHash: (invitationHash) => {
      dispatch(checkHash(invitationHash));
    },
    openAuthModal: () => {
      dispatch(openAuthModal());
    },
    setAuthModalForm: () => {
      dispatch(setAuthModalForm());
    },
    loginGraphApi: (token) => {
      dispatch(loginGraphApi(token))
    },
    logout: () => {
      dispatch(logout())
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(Lobby);
