import React, { Component } from 'react';

const textIntoArray = (text) => {
	return text.split("\n");
}

const arrayIntoParagraphs = (textArray) => {
  const paragraphList = textArray.map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    )
  )
  return paragraphList;
}


class ParagraphCreator extends Component {

  render() {
		let text = this.props.text;
		let textArray = textIntoArray(text);
		let paragraphArray = arrayIntoParagraphs(textArray);


    return (
      <div>
      	{paragraphArray}
      </div>
    );
  }
}

export default ParagraphCreator;