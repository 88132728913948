import constants from "../constants";

export const GET_GATHERING_INFO_START = "GET_GATHERING_INFO_START";
export const GET_GATHERING_INFO_FAILURE = "GET_GATHERING_INFO_FAILURE";
export const GET_GATHERING_INFO_SUCCESS = "GET_GATHERING_INFO_SUCCESS";
export const RECALCULATE_DAILY_GATHERINGS = "RECALCULATE_DAILY_GATHERINGS";
export const TRIGGER_RERENDER = "TRIGGER_RERENDER";


export function getGatheringInfoStart() {
  return {
    type: GET_GATHERING_INFO_START
  };
}

export function getGatheringInfoSuccess(data) {
  return {
    type: GET_GATHERING_INFO_SUCCESS,
    data
  };
}

export function getGatheringInfoFailure(error) {
  return {
    type: GET_GATHERING_INFO_FAILURE,
    error
  };
}

export function getGatheringsInfo() {
  const config = {
    method: "GET",
    headers: {
      "Authorization": `bearer ${localStorage.getItem("token")}`
    }
  };

  return (dispatch) => {
    dispatch(getGatheringInfoStart());

    return fetch(`${constants.baseUrl}/api/v1/gatherings`, config)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          throw new Error(`Error: ${response.error}`);
        }
        dispatch(getGatheringInfoSuccess(response));
      })
      .catch(err => {
        dispatch(getGatheringInfoFailure(err));
        console.error("getGatheringInfo error: ", err);
      });
  }
}

export function recalculateDailyGatherings() {
  return {
    type: RECALCULATE_DAILY_GATHERINGS
  };
}

export function triggerRerender() {
  return {
    type: TRIGGER_RERENDER
  };
}
