import React, { Component } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import textResource from '../../lib/textLoader';
import sendGAEvent from '../../lib/sendGAEvent';
import TermsMarkup from '../ContentPages/TermsMarkup';
import PrivacyMarkup from '../ContentPages/PrivacyMarkup';

import ParagraphCreator from '../elements/ParagraphCreator';

class TermsCollapse extends Component {

  render() {
    let { termsPrivacyCollapse } = this.props;
    return (
      <div>
        <Collapse isOpen={!!termsPrivacyCollapse}>
          <Card>
            <CardBody style={{maxHeight: "300px", overflowY: "auto"}}>
            	{ collapseContent(termsPrivacyCollapse) }
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}

function collapseContent(collapseType) {
  if (collapseType === "terms") {
    return termsSection();
  } else if (collapseType === "privacy") {
    return privacySection();
  } else {
    return gatheringSection();
  }
}

function openTermsPage(e) {
  e.preventDefault();
  sendGAEvent('Authentication', 'Open terms page', '');
  window.open('/terms', "_blank")
}

function openPrivacyPage(e) {
  e.preventDefault();
  sendGAEvent('Authentication', 'Open privacy page', '');
  window.open('/privacy', "_blank")
}

function termsSection() {
  return (
    <div>
      <h4 className="pt-3">{textResource["terms_collapse.terms_title"]}</h4>
      <p>(<a href="#" style={{textDecoration: "underline"}} onClick={(e) => openTermsPage(e)}>{textResource["terms_collapse_read_in_new_tab.text"]}</a>)</p>
      <br/>
      <TermsMarkup />
    </div>
  )
}

function privacySection() {
  return (
    <div>
      <h4 className="pt-3">{textResource["terms_collapse.privacy_policy_title"]}</h4>
      <p>(<a href="#" style={{textDecoration: "underline"}} onClick={(e) => openPrivacyPage(e)}>{textResource["terms_collapse_read_in_new_tab.text"]}</a>)</p>
      <br/>
      <PrivacyMarkup />
    </div>
  )
}

function gatheringSection() {
  return (
    <div>
      <h4 className="pt-3">{textResource["gathering.what_is_gathering.title"]}</h4>
      <br/>
      <ParagraphCreator text={textResource["gathering.what_is_gathering.text"]} />
    </div>
  )
}

export default TermsCollapse;