import React from 'react';
import '../ActiveExperienceStyles.css';
import textResource from '../../../lib/textLoader';
import Spinner from '../../elements/Spinner';
import SpeakerIcon from '../../../resources/images/headphones-white.png'


class ReadyContent extends React.Component {
	constructor() {
		super();

		this.showTimeout = null;
		this.showIntroAudioTimeout = null;
		
		this.setShowTimeout = this.setShowTimeout.bind(this);
		this.clearShowTimeout = this.clearShowTimeout.bind(this);
		this.clearShow = this.clearShow.bind(this);
		this.setShowAudioTimeout = this.setShowAudioTimeout.bind(this);
		this.clearShowAudioTimeout = this.clearShowAudioTimeout.bind(this);


		this.state = {
			show: false,
			showAudio: false
		}
	}

	componentDidMount() {

		if (!this.props.peerReady) {
			this.setShowTimeout();
		}

    if (this.props.selectedExperienceData.introAudioStart) {
        if (this.props.peerReady) {
            this.setShowAudioTimeout()
        }          
    }
	}

	componentDidUpdate(prevProps){

		if (!prevProps.peerReady && this.props.peerReady) {
				this.clearShow();
		}

    if (this.props.selectedExperienceData.introAudioStart) {
        if (this.props.peerReady && !prevProps.peerReady) {
            this.setShowAudioTimeout()
        }          
    }
	}

	componentWillUnmount() {
		this.clearShowTimeout();
	}

	setShowTimeout() {
		this.showTimeout = setTimeout(() => {
			this.setState({show: true})	
		}, 5000);
	}

	clearShowTimeout() {
		if (this.showTimeout) {
			clearTimeout(this.showTimeout);			
		}
		this.showTimeout = null;
	}

	clearShow() {
		this.clearShowTimeout();
		this.setState({show: false})	
	}

	setShowAudioTimeout() {
    this.showIntroAudioTimeout = setTimeout(() => {
        this.setState({showAudio: true})	
    }, this.props.selectedExperienceData.introAudioStart)
	}

	clearShowAudioTimeout() {
		if (this.showIntroAudioTimeout) {
			clearTimeout(this.showIntroAudioTimeout);			
		}
		this.showIntroAudioTimeout = null;
	}

	render() {
		return (
			<div>
				{
					!!(this.state.show && !this.state.showAudio && !this.props.peerReady) &&
					<h4 className="pt-3">{textResource["before_call.waiting_for_other"]}</h4>
				}
				{
					!this.state.showAudio &&
					<Spinner />
				}
				{
					this.state.showAudio &&
					<div>
						<img src={SpeakerIcon} style={{width: "5rem", height: "5rem"}}/>
						<h4 className="pt-3">The other person will appear on your screen after this intro.</h4>
					</div>
				}				
			</div>
		)		
	}
}

export default ReadyContent;