import { connect } from 'react-redux';
import NoOneInCircleModal from './NoOneInCircleModal';
import { 
	openNoOneInCircleModal, 
	closeNoOneInCircleModal,
} from "../../actions/uiControls";


const mapStateToProps = (state,ownProps) => {
    return {
        userRole: state.userRole,
        userState: state.userState,
        noOneInCircleModalOpen: state.uiControls.noOneInCircleModalOpen
    };
};

const mapDispatchtoProps = {
    openNoOneInCircleModal,
    closeNoOneInCircleModal
};

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(NoOneInCircleModal);