// yyPilot1

export const SET_FULL_SCREEN = "SET_FULL_SCREEN";
export const CANCEL_FULL_SCREEN = "CANCEL_FULL_SCREEN";

export function setFullScreen() {
  return {
    type: SET_FULL_SCREEN
  };
}

export function cancelFullScreen() {
  return {
    type: CANCEL_FULL_SCREEN
  };
}