import React from 'react';
import textResource from '../../../lib/textLoader';
import ParagraphCreator from '../../elements/ParagraphCreator';
import LayoutWrapper from '../../elements/LayoutWrapper';


class WaitingList extends React.Component {


  render() {

    return (
    	<LayoutWrapper>
		  	<h1 className="pb-3">{textResource["waitlist.verified.title"]}</h1>
		  	<br/>
		  	<ParagraphCreator text={textResource["waitlist.verified.text"]} />
			</LayoutWrapper>
    );
  }
}

export default WaitingList;