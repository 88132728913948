import { connect } from 'react-redux';
import ExperienceTextInstructions from './ExperienceTextInstructions';


const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    showTextInstructionMessage: state.uiControls.showTextInstructionMessage
  };
};


export default connect(
  mapStateToProps,
  null
)(ExperienceTextInstructions);