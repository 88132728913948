import React from 'react';
import './DigestingContentStyles.css';
import textResource from '../../../lib/textLoader';
import { Button, Alert, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReportPersonContainer from './ReportPersonContainer';
import ThankyouLogo from '../../../resources/images/thankyou.png';
import MyCircleImage from '../../../resources/images/circle-hand-drawn.png'
import MoveOnArrow from '../../../resources/images/move-on-arrow.png';
import ReportThumbIcon from '../../../resources/images/flag-solid-white.svg'
import ReportRedThumbIcon from '../../../resources/images/flag-solid-red-d71b12.svg'
import {sendAmplitudeEvent} from "../../../lib/amplitude"
import { formatEndReasonForUser } from '../../../lib/endReason';
import Spinner from '../../elements/Spinner';

class DigestingContent extends React.Component {

	constructor() {
		super();

		this.state = {
			report: false,
			dropdownOpen: false
		}

		this.toggleReportPerson = this.toggleReportPerson.bind(this);
		this.endExperience = this.endExperience.bind(this);
		this.thankPerson = this.thankPerson.bind(this);
		this.addUserToMyCircle = this.addUserToMyCircle.bind(this)
		this.removeUserFromMyCircle = this.removeUserFromMyCircle.bind(this);
		this.toggleDropdown = this.toggleDropdown.bind(this)
		this.onClickReportPerson = this.onClickReportPerson.bind(this)
		this.onClickAddUser = this.onClickAddUser.bind(this)
	}

	componentDidUpdate(prevProps){
		if (
			this.props.myCircle.addedByOther && 
			this.props.myCircle.alreadyInMyCircleAddedByMe && 
			(!prevProps.myCircle.addedByOther || !prevProps.myCircle.alreadyInMyCircleAddedByMe)) {
			this.props.addToMyCircleCount();

			let { experienceType, noRepeatMatchmakerOption, groupSpace } = this.props.peerRoom;
			let noRepeat = noRepeatMatchmakerOption[experienceType] || false;

			sendAmplitudeEvent('visual circle match', {'experience type': experienceType, 'no repeat': noRepeat, 'group space': groupSpace, 'end reason': formatEndReasonForUser(this.props.endConnectionReason)});
		}
	}


  toggleDropdown() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		})  	
  }
	
	toggleReportPerson() {
		if(!this.state.report) {
			let {experienceType,poolType,noRepeatMatchmakerOption,groupSpace} = this.props.peerRoom;
			let noRepeat = noRepeatMatchmakerOption[experienceType] || false;

			this.props.sendGAEventForDigestingState('Human Minute', 'Open report user', 'btnReportUser - scrDigest', this.props.completedHumanMinutes);
			sendAmplitudeEvent('click report', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'end reason': formatEndReasonForUser(this.props.endConnectionReason)});
		}
		this.setState({
			report: !this.state.report
		})
	}

	onClickReportPerson() {
		if (!this.props.userRole.email || this.props.userRole.email === "experience-guest") {
			this.props.logout();
			this.props.openAuthModal();
		} else {
			this.toggleReportPerson();
		}
	}

	endExperience() {
		let {experienceType,poolType,noRepeatMatchmakerOption,groupSpace} = this.props.peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
		this.props.sendGAEventForDigestingState('Human Minute', 'End digestion', '', this.props.completedHumanMinutes);
		sendAmplitudeEvent('move on', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'end reason': formatEndReasonForUser(this.props.endConnectionReason)});
		this.props.finishExperience(this.props.videoWrap);
	}

	thankPerson() {
		let {roomID,position,experienceType,poolType,noRepeatMatchmakerOption,groupSpace} = this.props.peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
		this.props.sendGAEventForDigestingState('Human Minute', 'Thank partner', '', this.props.completedHumanMinutes);
		sendAmplitudeEvent('thank partner', {'experience type': experienceType, 'no repeat': noRepeat, 'pool type': poolType, 'group space': groupSpace, 'end reason': formatEndReasonForUser(this.props.endConnectionReason)});
		this.props.thankyou(roomID,position)
	}

	addUserToMyCircle() {
		let { roomID, position, experienceType, noRepeatMatchmakerOption, groupSpace } = this.props.peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
		this.props.addUserToMyCircle(roomID, position)
		sendAmplitudeEvent('add partner to circle', {'experience type': experienceType, 'no repeat': noRepeat, 'group space': groupSpace, 'end reason': formatEndReasonForUser(this.props.endConnectionReason)});
	}

	onClickAddUser() {
		if (!this.props.userRole.email || this.props.userRole.email === "experience-guest") {
			this.props.logout();
			this.props.openAuthModal();
		} else {
			this.addUserToMyCircle();
		}
	}

	removeUserFromMyCircle() {
		let { roomID, position, experienceType, noRepeatMatchmakerOption, groupSpace } = this.props.peerRoom;
		let noRepeat = noRepeatMatchmakerOption[experienceType] || false;
		this.props.removeUserFromMyCircle(roomID, position);
		sendAmplitudeEvent('remove partner from circle', {'experience type': experienceType, 'no repeat': noRepeat, 'group space': groupSpace, 'end reason': formatEndReasonForUser(this.props.endConnectionReason)});
	}

	render() {
		let {haveReceivedThanks,haveGivenThanks} = this.props.peerRoom;
		let {myCircle} = this.props;
		return (
			<div className="digesting-screen">
				{
					this.state.report ?
					<div className="landing-page-wrap">
						<div className="report-person-wrapper">
							<ReportPersonContainer onCancel={this.toggleReportPerson}/>
						</div>
					</div> :
					<div className="landing-page-wrap">
				    {
				    	this.props.inappropriateBehaviourReport.sent
				    	? (<Alert color="success">
						      {textResource["digesting_content.received_report"]}<br/>
						      {textResource["digesting_content.sorry_for_bad_experience"]}<br/>
						      {textResource["digesting_content.look_report_and_touch_with_you"]}
						    </Alert>)
				    	: null
				    }
			  		<div className="landing-title">
			  			<h3>{textResource["digesting_content.how_do_you_feel.title"]}</h3>
			  		</div>
						<div className="digesting-button-container">
							{
								haveReceivedThanks && 
								<div className="thank-you-card">
									<img src={ThankyouLogo} />
									<p>{textResource["digesting_content.other_person_thanked_you"]}</p>
								</div>
							}

							{
								myCircle.addedByOther && myCircle.alreadyInMyCircleAddedByMe && 
								<div className="thank-you-card">
									<img src={MyCircleImage} style={{maxWidth: "50px", maxHeight: "50px"}}/>
									<p>You have added each other to your circle!</p>
								</div>
							}

							<div className="report-person">
								{
									haveGivenThanks ?
									<div className="replacement"></div> :								
									<Button id="BtnThankPerson" color="default" onClick={this.thankPerson}>
										<div className="button-content-wrap">
											<div className="button-content">
												<div className="button-image">
													<img src={ThankyouLogo} />
												</div>
												<div className="button-text">
													<p className="button-text-lead">
														Thank you
													</p>
													<p className="button-text-follow">
														Show your gratitude to the other person
													</p>
												</div>
											</div>
										</div>
									</Button>								
								}
							</div>

							<div className="landing-exit">

								{
									myCircle.isFetching && 
									<Spinner />
								}
								{
									!myCircle.isFetching && myCircle.otherIsGuest && 
									<Button id="BtnAddToMyCircle" color="default" disabled>
										<div className="button-content-wrap">
											<div className="button-content">
												<div className="button-image">
													<img src={MyCircleImage}/>
												</div>
												<div className="button-text">
													<p className="button-text-lead">
														{
															textResource["digesting_content.add_to_circle_first_time"]
														}
													</p>
													<p className="button-text-follow">
														The other person is a guest. You can add them if they create an account.
													</p>
												</div>
											</div>
										</div>
									</Button>			
								}
								{
									!myCircle.isFetching && !myCircle.otherIsGuest && !myCircle.alreadyInMyCircleAddedByMe && 
									<Button id="BtnAddToMyCircle" color="default" onClick={this.onClickAddUser}>
										<div className="button-content-wrap">
											<div className="button-content">
												<div className="button-image">
													<img src={MyCircleImage}/>
												</div>
												<div className="button-text">
													<p className="button-text-lead">
														{
															myCircle.removedFromCircleSuccess ?
															textResource["digesting_content.add_to_circle_again"] :
															textResource["digesting_content.add_to_circle_first_time"]
														}
													</p>
													<p className="button-text-follow">
														{
															myCircle.removedFromCircleSuccess ?
															"You have removed them" :
															"If they also want it, you will both be added to each other’s list"
														}													
														
													</p>
												</div>
											</div>
										</div>
									</Button>			
								}
								{
									!myCircle.isFetching && !myCircle.otherIsGuest && myCircle.alreadyInMyCircleAddedByMe &&
							    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} id="BtnPersonIsInCircle">
										<DropdownToggle color="default">
											<div className="button-content-wrap">
												<div className="button-content">
													<div className="button-image">
														<img src={MyCircleImage} />
													</div>
													<div className="button-text">
														<p className="button-text-lead">
															{
																myCircle.alreadyInMyCircleMatched || myCircle.addedByOther ?
																textResource["digesting_content.person_already_in_circle_matched"] :
																textResource["digesting_content.person_already_in_circle_added"]
															}
														</p>
														<p className="button-text-follow">
															{
																myCircle.alreadyInMyCircleMatched || myCircle.addedByOther ?
																"Click to manage" :
																"If they also want it, you will both be added to each other’s list"
															}
														</p>
													</div>
												</div>
											</div>
										</DropdownToggle>	

							      <DropdownMenu>
							        <DropdownItem id="BtnRemoveFromCircle" onClick={this.removeUserFromMyCircle}>
							        	{textResource["digesting_content.remove_from_circle"]}
							        </DropdownItem>
							      </DropdownMenu>
							    </ButtonDropdown>
							  }
							</div>


							<div className="report-person">
								{
									this.props.inappropriateBehaviourReport.sent ?
									<div className="replacement"></div> :								
									<Button id="BtnOpenReportPerson" color="default" onClick={this.onClickReportPerson}>
										<div className="button-content-wrap">
											<div className="button-content">
												<div className="button-image">
													<img src={ReportThumbIcon} className="whiteImg"/>
													<img src={ReportRedThumbIcon} className="redImg"/>
												</div>
												<div className="button-text">
													<p className="button-text-lead">
														Make a report
													</p>
													<p className="button-text-follow">
														Report inappropriate behaviour
													</p>
												</div>
											</div>
										</div>
									</Button>								
								}
							</div>
							<div className="landing-exit">
								<Button id="BtnMoveOn" color="default" onClick={this.endExperience}>
									<div className="button-content-wrap">
										<div className="button-content">
											<div className="button-image">
												<img src={MoveOnArrow} />
											</div>
											<div className="button-text">
												<p className="button-text-lead">{textResource["digesting_content.move_on_experience_btn"]}</p>
											</div>
										</div>
									</div>
								</Button>				
							</div>
						</div>
					</div>				
				}
			</div>
		)		
	}

	

}

export default DigestingContent;