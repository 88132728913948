import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Alert} from 'reactstrap';


class LobbyAlert extends Component {

	constructor() {
		super();

		this.clearAnyAlerts = this.clearAnyAlerts.bind(this);
	}

	componentWillUnmount() {
		this.clearAnyAlerts();
	}

	componentDidUpdate(prevProps){
		let lobbyStates = ["present", "absent"];
		let oldState = prevProps.userState;
		let newState = this.props.userState;

		if (lobbyStates.includes(oldState) && !lobbyStates.includes(newState)) {
			this.clearAnyAlerts();
		}

		if (prevProps.email && !this.props.email) {
			this.clearAnyAlerts();
		}

		if (!prevProps.authModalOpen && this.props.authModalOpen) {
			this.clearAnyAlerts();
		}
	}

	clearAnyAlerts() {
		if (this.props.showCondition) {
			this.props.clearFunction();
		}
	}

  render() {
		
		if (!["present", "absent"].includes(this.props.userState)) { return null; }

    return (
    	<div>
		    {
		    	this.props.showCondition 
		    	? (<Alert color={this.props.alertColor}>
				      {this.props.alertText}
				    </Alert>)
		    	: null
		    }
		  </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userState: state.userState,
    email: state.userRole.email,
    authModalOpen: state.uiControls.authModalOpen,
    showCondition: ownProps.showCondition,
    alertColor: ownProps.alertColor,
    alertText: ownProps.alertText
  };
};

const mapDispatchtoProps = (dispatch, ownProps) => {
  return {
    clearFunction: () => {
      dispatch(ownProps.clearFunction());
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(LobbyAlert);