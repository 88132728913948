export const OPEN_EXPERIENCE_MODAL = "OPEN_EXPERIENCE_MODAL";
export const CLOSE_EXPERIENCE_MODAL = "CLOSE_EXPERIENCE_MODAL";

export function openExperienceModal(data) {
  return {
    type: OPEN_EXPERIENCE_MODAL,
    data
  };
}

export function closeExperienceModal() {
    return {
      type: CLOSE_EXPERIENCE_MODAL
    };
}

