import React from 'react';
import './ConnectVideoStyles.css';
import { Row, Col } from 'reactstrap';
import textResource from '../../../lib/textLoader';
import { Link } from 'react-router-dom';
import VideoHowTo from './VideoHowTo';

class ConnectVideo extends React.Component{

	render() {
		let { userRole } = this.props;
		if (!userRole.ready || userRole.role === "guest") { return null; }

		return (
			<div className="connectVideoContainer mt-5 pt-3" >
				<Row>
					<Col md={6} sm={12}>
						<div className="videoTitleContainer">
							<p className="videoTitle">{textResource["connect_video.title"]}</p>
							<p>{textResource["connect_video.content"]}</p>
							<Link to="/howtouse">{textResource["connect_video.more"]}</Link>
						</div>
					</Col>
					<Col md={6} sm={12}>
						<VideoHowTo label="scrLobby"/>
					</Col>
				</Row>
			</div>
		)
	}
}

export default ConnectVideo;