import * as Actions from "../actions/gatheringsInfo";
import moment from 'moment-timezone';
import constants from '../constants';

const initialState = {
	ready: false,
	gatherings: getDailyGatherings(),
  isFetching: false,
  error: null
};

export function gatheringsInfo(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_GATHERING_INFO_START:
      return {
        ...state,
      	ready: false,
			  isFetching: true,
			  error: null
			};
    case Actions.GET_GATHERING_INFO_SUCCESS: {
      const gatheringsFromServer = action.data;

      const gatherings = [...getDailyGatherings(), ...gatheringsFromServer]
        .sort((gatheringA, gatheringB) => gatheringA.startTimeMillSec - gatheringB.startTimeMillSec);

      return {
        ...state,
      	ready: true,
			  isFetching: false,
			  error: null,
      	gatherings
      };
    }
    case Actions.GET_GATHERING_INFO_FAILURE:
      return {
        ...state,
        ready: false,
  			isFetching: false,
        error: action.error
      };
    case Actions.RECALCULATE_DAILY_GATHERINGS:
      const nonDailyGatherings = state.gatherings.filter(gathering => !gathering.isRegular);

      return {
        ...state,
        gatherings: nonDailyGatherings.concat(getDailyGatherings())
          .sort((gatheringA, gatheringB) => gatheringA.startTimeMillSec - gatheringB.startTimeMillSec)
      }
    case Actions.TRIGGER_RERENDER:
        return { ...state };
    default:
      return state;
  }
}

function getDailyGatherings() {
  const { gatherings } = constants;

  const localNow = moment();
  const gatheringStartDate = localNow.clone().tz(gatherings.daily.timezone).format('M-D-YYYY');

  const todaysGatheringStart = moment.tz(
    `${gatheringStartDate} ${gatherings.daily.time}`,
    'M-D-YYYY H:mm',
    gatherings.daily.timezone
  );
  const yesterdaysGatheringStart = todaysGatheringStart.clone().subtract(1, 'day');
  const tomorrowsGatheringStart = todaysGatheringStart.clone().add(1, 'day');

  return [
    yesterdaysGatheringStart,
    todaysGatheringStart,
    tomorrowsGatheringStart
  ].map(gatheringMoment => {
    const startTimeMillSec = gatheringMoment.valueOf();

    return {
      name: `Daily Regular Gathering ${gatheringMoment.format('M-D-YYYY')}`,
      endTimeMillSec: startTimeMillSec + gatherings.daily.lengthInTime,
      isRegular: true,
      startTimeMillSec
    };
  })
}
