import { connect } from 'react-redux';
import Partner from './Partner';


const mapStateToProps = (state, ownProps) => {
    return {
        userRole: state.userRole,
        withTitle: ownProps.withTitle
    };
};

export default connect(
    mapStateToProps,
    null
)(Partner);