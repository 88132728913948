import React from 'react';
import textResource from '../../../lib/textLoader';
import { FormGroup, Label, Input, CustomInput, UncontrolledTooltip } from 'reactstrap';
import NoRepeatInfoTooltip from "./NoRepeatInfoTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class NoRepeatSwitch extends React.Component {

  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    if (this.props.isChecked) {
      this.props.clearNoRepeatMatchmakerOption(this.props.experienceType);
    } else {
      this.props.setNoRepeatMatchmakerOption(this.props.experienceType);
    }
  }

  render() {
    let { isChecked, userState, userRole } = this.props;

    if (!["present", "absent"].includes(userState)) { return null; }
    if (!userRole.email) { return null; }
    let disabled = !!(userRole.role === "guest");
    let color = disabled ? "#545454" : "white";
    let style = { color };


    return (


      <div className="switchContainer">
          <div className="switchLabel">
            <p>
              Avoid repeating partner
              &nbsp;
              <FontAwesomeIcon
              icon="question-circle"
              id="NoRepeatInfoTrigger"
            />
            </p>
            
          </div>
          <NoRepeatInfoTooltip />
          <div className="switchMain">
            <CustomInput 
              id={`NoRepeatSwitch:${this.props.experienceType}`}
              type="switch" 
              bsSize="lg"
              name="SwitchNoRepeat" 
              disabled={disabled}
              checked={isChecked}
              onChange={this.onChange}
              />
          </div>
      </div>

    )

  }
}


export default NoRepeatSwitch;