import React from "react";
import moment from "moment-timezone";
import './GatheringInfoStyles.css';

class NextSpecialGathering extends React.Component {
  localTimezone = moment.tz.guess();

  render() {

    const { nextSpecialGathering } = this.props;

    const nextSpecialMoment = nextSpecialGathering
      ? moment.tz(nextSpecialGathering.startTimeMillSec, this.localTimezone)
      : null;

    const isNextSpecialToday = !!(
      nextSpecialMoment && moment().isSame(nextSpecialMoment, "day")
    );
    const nextSpecialFormattingToken = isNextSpecialToday
      ? "[today]"
      : "dddd Do MMMM";


    return (
      <div className="pb-2 mb-sm-4 pb-sm-2 next-gatherings carousel-gathering" >
        {nextSpecialMoment && (
          <div>
            <h5 className="every-day">
              {nextSpecialGathering.name}
            </h5>
            <h5 className="next-at pt-4">
              {nextSpecialMoment.format(`${nextSpecialFormattingToken} LT`)}
            </h5>
          </div>
        )}
      </div>
    );
  }
}

export default NextSpecialGathering;