import React from 'react';
import { Redirect } from 'react-router-dom';


class VerifyScreen extends React.Component {

	componentDidMount() {
		let hash = this.props.match.params.hash;
		if (hash) {
			this.props.sendVerification(hash)
		}				
	}

  render() {
  	// If verification is processed, return home, whether successful or not
  	if (this.props.emailVerification.processed) {
      return <Redirect to='/' /> 
    }

  	// If there is no hash, return home
  	if (!this.props.match.params.hash) { return <Redirect to='/' /> }

    return null;
  }
}

export default VerifyScreen;