import React from 'react';
import textResource from '../../lib/textLoader';

const PrivacyMarkup = () => {

let termsLink = window.location.hostname === "localhost" ?
	`http://localhost:3000/terms` :
	`https://${window.location.hostname}/terms`;

return (
<div>
	<p>{textResource["privacy_markup.content1"]}</p>
	<p>{textResource["privacy_markup.content2"]}</p>
	<p>{textResource["privacy_markup.content3"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.definitions_title"]}</h3>
	<p>{textResource["privacy_markup.definations.content"]} <a href={termsLink} target="_blank" rel="noopener noreferrer">{termsLink}</a></p>
	<ul>
		<li>{textResource["privacy_markup.definitions.list1"]}</li>
		<li>{textResource["privacy_markup.definitions.list2"]}</li>
		<li>{textResource["privacy_markup.definitions.list3"]}</li>
		<li>{textResource["privacy_markup.definitions.list4"]}</li>
		<li>{textResource["privacy_markup.definitions.list5"]}</li>
		<li>{textResource["privacy_markup.definitions.list6"]}</li>
		<li>{textResource["privacy_markup.definitions.list7"]}</li>
	</ul>

	<h3 className="pt-5 pb-3">{textResource["privacy_markup.information_collection_title"]}</h3>
	<p>{textResource["privacy_markup.information_collection.content"]}</p>
	<h4 className="pt-3">{textResource["privacy_markup.data_collected_title"]}</h4>
	<h5>{textResource["privacy_markup.personal_data_subtitle"]}</h5>
	<p>{textResource["privacy_markup.personal_data.content"]}</p>
	<ul>
		<li>{textResource["privacy_markup.personal_data.email_list"]}</li>
		<li>{textResource["privacy_markup.personal_data.first_and_last_name_list"]}</li>
		<li>{textResource["privacy_markup.personal_data.cookies_and_usage_list"]}</li>
	</ul>
	<h5>{textResource["privacy_markup.usage_data_title"]}</h5>
	<p>{textResource["privacy_markup.usage_data.content1"]}</p>
	<p>{textResource["privacy_markup.usage_data.content2"]}</p>
	<p>{textResource["privacy_markup.usage_data.content3"]}</p>
	<h5>{textResource["privacy_markup.tracking_and_cookies_title"]}</h5>
	<p>{textResource["privacy_markup.tracking_and_cookies.content1"]}</p>
	<p>{textResource["privacy_markup.tracking_and_cookies.content2"]}</p>
	<p>{textResource["privacy_markup.tracking_and_cookies.content3"]}</p>
	<p>{textResource["privacy_markup.tracking_and_cookies.content4"]}</p>
	<ul>
		<li>{textResource["privacy_markup.session_cookies_list"]}</li>
		<li>{textResource["privacy_markup.preference_cookies_list"]}</li>
		<li>{textResource["privacy_markup.security_cookies_list"]}</li>
	</ul>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.use_of_data_title"]}</h3>
	<p>{textResource["privacy_markup.use_of_data.content"]}</p>
	<ul>
		<li>{textResource["privacy_markup.use_of_data.list1"]}</li>
		<li>{textResource["privacy_markup.use_of_data.list2"]}</li>
		<li>{textResource["privacy_markup.use_of_data.list3"]}</li>
		<li>{textResource["privacy_markup.use_of_data.list4"]}</li>
		<li>{textResource["privacy_markup.use_of_data.list5"]}</li>
		<li>{textResource["privacy_markup.use_of_data.list6"]}</li>
		<li>{textResource["privacy_markup.use_of_data.list7"]}</li>
		<li>{textResource["privacy_markup.use_of_data.list8"]}</li>
	</ul>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.legal_basis_title"]}</h3>
	<p>{textResource["privacy_markup.legal_basis.content1"]}</p>
	<p>{textResource["privacy_markup.legal_basis.content2"]}</p>
	<ul>
		<li>{textResource["privacy_markup.legal_basis.list1"]}</li>
		<li>{textResource["privacy_markup.legal_basis.list2"]}</li>
		<li>{textResource["privacy_markup.legal_basis.list3"]}</li>
		<li>{textResource["privacy_markup.legal_basis.list4"]}</li>
	</ul>
	<h4 className="pt-3">{textResource["privacy_markup.retention_data_title"]}</h4>
	<p>{textResource["privacy_markup.retention_data.content1"]}</p>
	<p>{textResource["privacy_markup.retention_data.content2"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.transfer_data_title"]}</h3>
	<p>{textResource["privacy_markup.transfer_data.content1"]}</p>
	<p>{textResource["privacy_markup.transfer_data.content2"]}</p>
	<p>{textResource["privacy_markup.transfer_data.content3"]}</p>
	<p>{textResource["privacy_markup.transfer_data.content4"]}</p>
	<h4 className="pt-3">{textResource["privacy_markup.disclosure_data_title"]}</h4>
	<p>{textResource["privacy_markup.disclosure_data.content"]}</p>
	<h4 className="pt-3">{textResource["privacy_markup.legal_requirements_title"]}</h4>
	<p>{textResource["privacy_markup.legal_requirements.content"]}</p>
	<ul>
		<li>{textResource["privacy_markup.legal_requirements.list1"]}</li>
		<li>{textResource["privacy_markup.legal_requirements.list2"]}</li>
		<li>{textResource["privacy_markup.legal_requirements.list3"]}</li>
		<li>{textResource["privacy_markup.legal_requirements.list4"]}</li>
		<li>{textResource["privacy_markup.legal_requirements.list5"]}</li>
	</ul>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.security_data_title"]}</h3>
	<p>{textResource["privacy_markup.security_data.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.do_not_track_data_title"]}</h3>
	<p>{textResource["privacy_markup.do_not_track_data.content1"]}</p>
	<p>{textResource["privacy_markup.do_not_track_data.content2"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.data_protection_title"]}</h3>
	<p>{textResource["privacy_markup.data_protection.content1"]}</p>
	<p>{textResource["privacy_markup.data_protection.content2"]}</p>
	<p>{textResource["privacy_markup.data_protection.content3"]}</p>
	<p>{textResource["privacy_markup.data_protection.content4"]}</p>
	<p>{textResource["privacy_markup.data_protection.content5"]}</p>
	<p>{textResource["privacy_markup.data_protection.content6"]}</p>
	<p>{textResource["privacy_markup.data_protection.content7"]}</p>
	<p>{textResource["privacy_markup.data_protection.content8"]}</p>
	<p>{textResource["privacy_markup.data_protection.content9"]}</p>
	<p>{textResource["privacy_markup.data_protection.content10"]}</p>
	<p>{textResource["privacy_markup.data_protection.content11"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.service_provider_title"]}</h3>
	<p>{textResource["privacy_markup.service_provider.content1"]}</p>
	<p>{textResource["privacy_markup.service_provider.content2"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.analytics_and_marketing_title"]}</h3>
	<p>{textResource["privacy_markup.analytics_and_marketing.content1"]}</p>
	<p>{textResource["privacy_markup.analytics_and_marketing.content2"]}</p>
	<p>{textResource["privacy_markup.analytics_and_marketing.content3"]} <a href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer">{textResource["privacy_markup.analytics_and_marketing.ads_settings_link"]}</a></p>
	<p>{textResource["privacy_markup.analytics_and_marketing.content4"]} <a href="https://www.facebook.com/about/ads" target="_blank" rel="noopener noreferrer">{textResource["privacy_markup.analytics_and_marketing.about_ads_link"]}</a></p>
	<p>{textResource["privacy_markup.analytics_and_marketing.content5"]}</p>
	<p><a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">{textResource["privacy_markup.analytics_and_marketing.google_policies_link"]}</a></p>
	<p><a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noopener noreferrer">{textResource["privacy_markup.analytics_and_marketing.facebook_privacy_link"]}</a></p>

	<h4 className="pt-3">{textResource["privacy_markup.google_analytics_title"]}</h4>
	<p>{textResource["privacy_markup.google_analytics.content1"]}</p>
	<p>{textResource["privacy_markup.google_analytics.content2"]}</p>
	<ul>
		<li>{textResource["privacy_markup.google_analytics.list1"]}</li>
		<li>{textResource["privacy_markup.google_analytics.list2"]}</li>
		<li>{textResource["privacy_markup.google_analytics.list3"]}</li>
		<li>{textResource["privacy_markup.google_analytics.list4"]}</li>
	</ul>
	<p>{textResource["privacy_markup.google_analytics.content3"]} <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">{textResource["privacy_markup.google_analytics.goaptout_link"]}</a>. {textResource["privacy_markup.google_analytics.content4"]}</p>



	<h3 className="pt-5 pb-3">{textResource["privacy_markup.other_sites_links_title"]}</h3>
	<p>{textResource["privacy_markup.other_sites_links.content1"]}</p>
	<p>{textResource["privacy_markup.other_sites_links.content2"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.childrens_privacy_title"]}</h3>
	<p>{textResource["privacy_markup.childrens_privacy.content1"]}</p>
	<p>{textResource["privacy_markup.childrens_privacy.content2"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.changes_privacy_policy_title"]}</h3>
	<p>{textResource["privacy_markup.changes_privacy_policy.content1"]}</p>
	<p>{textResource["privacy_markup.changes_privacy_policy.content2"]}</p>
	<p>{textResource["privacy_markup.changes_privacy_policy.content3"]}</p>
	<h3 className="pt-5 pb-3">{textResource["privacy_markup.contact_us_title"]}</h3>
	<p>{textResource["privacy_markup.contact_us.content"]}</p>
</div>
)
}

export default PrivacyMarkup;