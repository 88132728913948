import constants from "../constants";
import * as Ably from 'ably';

export const SET_REALTIME = "SET_REALTIME";
export const CLEAR_REALTIME = "CLEAR_REALTIME";

export function setRealtime(realtime) {
  return {
    type: SET_REALTIME,
    realtime
  };
}

export function clearRealtime() {
  return {
    type: CLEAR_REALTIME
  };
}



export function instantiateRealtime() {
  return dispatch => {
    let clientOptions = buildAblyClientOptions();
    let realtime = new Ably.Realtime(clientOptions);
    
    realtime.connection.on('connected', function() {
      dispatch(setRealtime(realtime));
      console.log('Ably connected');
    });
  };
}


export function closeRealtime(realtime) {
  return dispatch => {
		realtime.connection.close();
    dispatch(clearRealtime());
  };
}





let ablyBaseUrl = constants.baseUrl;

const buildAblyAuthOptions = () => {
  let authOptions = {};
  authOptions.authUrl = `${ablyBaseUrl}/api/v1/realtime/auth`;

  authOptions.authHeaders = {
    Authorization: `bearer ${localStorage.getItem("token")}`
  }

  return authOptions;
}

const buildAblyClientOptions = () => {
  let clientOptions = buildAblyAuthOptions();
  clientOptions.echoMessages = false;
  clientOptions.closeOnUnload = false;

  return clientOptions;
}