import { connect } from 'react-redux';
import ConnectWithDropdown from './ConnectWithDropdown';
import { createExperienceInvitation, setExperienceType, setPoolType } from "../../../actions/peerRoom";


const mapStateToProps = (state, ownProps) => {
  return {
    userRole: state.userRole,
    experienceType: ownProps.experienceType
  };
};


const mapDispatchtoProps = {
  setExperienceType,
  createExperienceInvitation,
  setPoolType
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ConnectWithDropdown);