import React from 'react';
import textResource from '../../lib/textLoader';

const TermsMarkup = () => {

let privacyLink = window.location.hostname === "localhost" ?
	`http://localhost:3000/privacy` :
	`https://${window.location.hostname}/privacy`;

return (
<div>
	<p>{textResource["terms.terms_of_use.effective_date"]}</p>
	<p>{textResource["terms.terms_of_use.content"]}</p>
	<h3 className="pt-5 pb-3">Definitions</h3>
	<ul>
		<li>{textResource["terms.definitions.list1"]}</li>
		<li>{textResource["terms.definitions.list2"]}</li>
		<li>{textResource["terms.definitions.list3"]}</li>
		<li>{textResource["terms.definitions.list4"]}</li>
	</ul>
	<h3 className="pt-5 pb-3">{textResource["terms.right_to_modify.title"]}</h3>
	<p>{textResource["terms.right_to_modify.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.access_to_service.title"]}</h3>
	<p>{textResource["terms.access_to_service.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.privacy.title"]}</h3>
	<p>{textResource["terms.privacy.content_pre"]} <a href={privacyLink} target="_blank" rel="noopener noreferrer">{privacyLink}</a>{textResource["terms.privacy.content_post"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.intellectual_rights.title"]}</h3>
	<p>{textResource["terms.intellectual_rights.content1"]}</p>
	<p>{textResource["terms.intellectual_rights.content2"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.restrictions.title"]}</h3>
	<p>{textResource["terms.restrictions.list_text"]}</p>
	<ul>
		<li>{textResource["terms.restrictions.list1"]}</li>
		<li>{textResource["terms.restrictions.list2"]}</li>
		<li>{textResource["terms.restrictions.list3"]}</li>
		<li>{textResource["terms.restrictions.list4"]}</li>
		<li>{textResource["terms.restrictions.list5"]}</li>
		<li>{textResource["terms.restrictions.list6"]}</li>
		<li>{textResource["terms.restrictions.list7"]}</li>
		<li>{textResource["terms.restrictions.list8"]}</li>
		<li>{textResource["terms.restrictions.list9"]}</li>
	</ul>
	<p>{textResource["terms.specially_restrictions.list_text"]}</p>
	<ul>
		<li>{textResource["terms.specially_restrictions.list1"]}</li>
		<li>{textResource["terms.specially_restrictions.list2"]}</li>
	</ul>
	<p>{textResource["terms.restrictions.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.human_online_account.title"]}</h3>
	<p>{textResource["terms.human_online_account.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.your_content.title"]}</h3>
	<p>{textResource["terms.your_content.content1"]}</p>
	<p>{textResource["terms.your_content.content2"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.submissions.title"]}</h3>
	<p>{textResource["terms.submissions.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.content.title"]}</h3>
	<p>{textResource["terms.content.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.third_party_websites.title"]}</h3>
	<p>{textResource["terms.third_party_websites.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.no_warranties.title"]}</h3>
	<p>{textResource["terms.no_warranties.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.limitation_of_liability.title"]}</h3>
	<p>{textResource["terms.limitation_of_liability.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.indemnification.title"]}</h3>
	<p>{textResource["terms.indemnification.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.assignment.title"]}</h3>
	<p>{textResource["terms.assignment.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.governing_law.title"]}</h3>
	<p>{textResource["terms.governing_law.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.dispute_resolution.title"]}</h3>
	<h4 className="pt-3">{textResource["terms.informal_negotiations.title"]}</h4>
	<p>{textResource["terms.informal_negotiations.content"]}</p>
	<h4 className="pt-3">{textResource["terms.arbitrations.title"]}</h4>
	<p>{textResource["terms.arbitrations.content1"]}</p>
	<p>{textResource["terms.arbitrations.content2"]}</p>
	<p>{textResource["terms.arbitrations.content3"]}</p>
	<p>{textResource["terms.arbitrations.content4"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.severability.title"]}</h3>
	<p>{textResource["terms.severability.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.entire_agreement.title"]}</h3>
	<p>{textResource["terms.entire_agreement.content"]}</p>
	<h3 className="pt-5 pb-3">{textResource["terms.contact_us.title"]}</h3>
	<p>{textResource["terms.contact_us.content"]}</p>
	<p>{textResource["terms.info_link"]}</p>
	<p>{textResource["terms.human_online"]}</p>
	<p>{textResource["terms.sakala"]}</p>
	<p>{textResource["terms.tallinn"]}</p>
	<p>{textResource["terms.estonia"]}</p>

</div>
)
}

export default TermsMarkup;