import * as Actions from "../actions/iceServers";

const initialState = {
  servers: [],
  isFetching: false,
  error: null
};

export function iceServers(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_SERVERS_START:
      return {
			  servers: [],
			  isFetching: true,
			  error: null
			};
    case Actions.GET_SERVERS_SUCCESS:
      return {
			  servers: action.data.iceServers,
			  isFetching: false,
			  error: null
      };
    case Actions.GET_SERVERS_FAILURE:
      return {
			  ...state,
  			isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
}