import { connect } from 'react-redux';
import TopCarousel from './TopCarousel';
import { registerToGatheringsEmailList } from "../../actions/accountChanges";


const mapStateToProps = (state, ownProps) => {

	let items = ["nextRegularGathering"];

	if (ownProps.nextSpecialGathering) {
		items.push("nextSpecialGathering")
	}

  // if (!state.userRole.subscription.activeSubscription) {
  //   items.push("contributingMember1");
  //   items.push("contributingMember2");
  // }

	if (!state.userRole.solicitedEmails || state.accountChanges.registeredToEmails) {
		items.push("emailList")
	}

  return {
    userRole: state.userRole,
    registeredToEmails: state.accountChanges.registeredToEmails,
    currentGathering: ownProps.currentGathering,
    nextRegularGathering: ownProps.nextRegularGathering,
    nextSpecialGathering: ownProps.nextSpecialGathering,
    items
  }
}


const mapDispatchToProps = {
  registerToGatheringsEmailList
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopCarousel);